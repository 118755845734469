import { Component, OnInit} from '@angular/core';
import { ApiService } from '../api.service'
import { ObservablesService } from '../observables.service'
import { LoadingService } from '../loading.service'
import { SocketService } from '../socket.service'


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  // days:any=[{
  //   name:"Mon",
  //   full:"Monday",
  //   value:1
  // },{
  //   name:"Tue",
  //   full:"Tuesday",
  //   value:2
  // },{
  //   name:"Wed",
  //   full:"Wednesday",
  //   value:3
  // },{
  //   name:"Thu",
  //   full:"Thursday",
  //   value:4
  // },{
  //   name:"Fri",
  //   full:"Friday",
  //   value:5
  // },{
  //   name:"Sat",
  //   full:"Saturday",
  //   value:6
  // }];
  days:any=[]
  pointsArr:any = []
  totalPoints:any = []
  selectedDay:any=1
  selectedMarket:any="A"
  totalPointsArr:any=[]
  allPoints:any=[]
  netPoints:any=0;
  totalLots:number=0;
  daysWithDates:any=[];
  selectedDate:any;
  choosedDate:any;
  selectedIndex: number = 0;
  constructor(private loading: LoadingService, private socket:SocketService, private observables:ObservablesService, private api:ApiService) {}

  ngOnInit() {
    this.getDays();
    let today = new Date().getDay();
    if(today == 0){
      this.selectedIndex = 5;
    }else{

      this.selectedIndex = today - 1;
    }
    this.onDayChange({index:this.selectedIndex})

  }

   getDays(){
    let curr = new Date();
    let first = curr.getDate() - curr.getDay();
    for (var i = 1; i < 7; i++) {
      var next = new Date(curr.getTime());
      next.setDate(first + i);
      // await this.days.filter((item:any)=>{
      //   if(item.value == next.getDay()){
          let dd:any = next.getDate();
          let mm:any = next.getMonth()+1; //January is 0!
          var yyyy = next.getFullYear();
          if(dd<10){dd='0'+dd} if(mm<10){mm='0'+mm}
          this.days.push({date:yyyy+"-"+mm+"-"+dd,value:next.getDay()});
      //   }
      // })
    }
  }

  async refreshData(){
    this.loading.dataLoading(true);
    await this.getNewData()
    // await this.getData(market,status)
  }



  async getNewData(){
    let conditions:any;
    if(this.selectedMarket == "A"){
      conditions = {"DATE(L.created)":this.selectedDate}

    }else{

      conditions = {"DATE(L.created)":this.selectedDate,"L.day_night":this.selectedMarket}
    }
    await this.api.send("findDb",{
      table:"lots as L",
      fields:["COUNT(L.id) as totalLots, L.id as lot_id,L.user_id, L.day_night as market ,L.created as lot_created"],
      conditions:[conditions],
      groupBy:"L.user_id",
      order:"L.user_id ASC"
    }).then((lotResponse:any)=>{
      // console.log("lots==",lotResponse)
      if(lotResponse.data.length > 0){
         this.getNewTotalPoints(lotResponse)
      }else{
        this.pointsArr = [];
        this.totalPoints = [];
        this.totalLots = 0;
        this.netPoints = 0;
        this.loading.dataLoading(false);
      }
    })
  }

  async getData(market,status){
    let conditions:any;
    if(status){
      conditions = {"YEARWEEK(L.created, 1)":"$YEARWEEK(CURDATE(), 1)$","L.day_night":market}
    }else{
      conditions = {"YEARWEEK(L.created, 1)":"$YEARWEEK(CURDATE(), 1)$"}
    }
    await this.api.send("findDb",{
      table:"users as U",
      fields:[" L.id as lot_id,L.day_night as market,L.user_id ,L.created as lot_created,U.username"],
      joins:[{
        type:"left",
        table:"lots as L",
        conditions:["L.user_id = U.id"]
      }],
      conditions:[conditions],
      order:"L.user_id ASC"
    }).then((lotResponse:any)=>{
      // console.log("lots==",lotResponse)
      if(lotResponse.data.length > 0){
         this.getTotalPoints(lotResponse)
      }else{
        this.pointsArr = [];
        this.totalPoints = [];
        this.loading.dataLoading(false);
      }
    })
  }

  // sortByUsers(data){
  //   return new Promise((resolve,reject)=>{
  //
  //     resolve(data.sort((a, b) => a.user_id - b.user_id));
  //   })
  // }
  async getNewTotalPoints(lotResponse){

    let points = 0;
    let count = 0;
    this.pointsArr = []
    this.totalPoints = [];
    this.netPoints = 0;
    this.totalLots = 0;
    for(let i=0;i<lotResponse.data.length;i++){
      // select SUM(P.points) as total_sum , COUNT(P.user_id) as total_counts from points as P where P.user_id = 3 and DATE(P.created) = CURDATE()
      let conditions:any ;
      if(this.selectedMarket == "A"){
        conditions = {"P.user_id":lotResponse.data[i].user_id,"DATE(P.created)":this.selectedDate, "P.consider_status":1}

      }else{

        conditions = {"P.user_id":lotResponse.data[i].user_id,"DATE(P.created)":this.selectedDate , market : this.selectedMarket, "P.consider_status":1}
      }

      await this.api.send("findDb",{
        table:"points as P",
        fields:[" SUM(P.points) as total_sum , COUNT(P.lot_id) as total_counts, U.username, U.customer_max_amount"],
        joins:[{
          type:"left",
          table:"users as U",
          conditions:["U.id = P.user_id"]
        }],
        conditions:[conditions]
      }).then((pointsRes:any)=>{
        // console.log("points=",pointsRes)
        // console.log("lots=",lotResponse.data[i])
        if(pointsRes.data.length > 0){
          this.netPoints = this.netPoints + pointsRes.data[0].total_sum;
          this.totalLots = this.totalLots + lotResponse.data[i].totalLots;
          this.totalPoints.push({market:lotResponse.data[i].market,totalLots:lotResponse.data[i].totalLots,totalEntries:pointsRes.data[0].total_counts,totalPoints:pointsRes.data[0].total_sum,user_id:lotResponse.data[i].user_id,username:pointsRes.data[0].username,customer_max_amount:pointsRes.data[0].customer_max_amount})
          // console.log("losts===",this.totalPoints)
        }

      })

      if(i == lotResponse.data.length -1){
        // console.log("points arr 1=",this.pointsArr)
        // this.filterTableData(this.selectedMarket)
        this.loading.dataLoading(false);
      }

    }
  }
  async getTotalPoints(lotResponse){
    let points = 0;
    let count = 0;
    this.pointsArr = []
    this.totalPoints = [];
    for(let i=0;i<lotResponse.data.length;i++){

      await this.api.send("findDb",{
        table:"points",
        fields:[" SUM(points) as total_sum , COUNT(lot_id) as total_counts, lot_id,id"],
        conditions:[{"lot_id":lotResponse.data[i].lot_id, consider_status:1}]
      }).then((pointsRes:any)=>{

        if(i > 0 && (lotResponse.data[i].user_id == lotResponse.data[i-1].user_id) && (lotResponse.data[i].lot_created.split("T")[0] == lotResponse.data[i-1].lot_created.split("T")[0])){
          points=points + pointsRes.data[0].total_sum;
          count=count + pointsRes.data[0].total_counts;
          if(i == lotResponse.data.length - 1){
            this.pointsArr.push({day:new Date(lotResponse.data[i].lot_created.split("T")[0]).getDay(),date:lotResponse.data[i].lot_created,totalEntries:count,totalPoints:points,user_id:lotResponse.data[i].user_id,username:lotResponse.data[i].username})
            // points = 0;
          }else{
            if(i <= lotResponse.data.length){
              if((lotResponse.data[i].user_id != lotResponse.data[i+1].user_id) || (lotResponse.data[i].lot_created.split("T")[0] != lotResponse.data[i+1].lot_created.split("T")[0])){
                this.pointsArr.push({day:new Date(lotResponse.data[i].lot_created.split("T")[0]).getDay(),date:lotResponse.data[i].lot_created,totalEntries:count,totalPoints:points,user_id:lotResponse.data[i].user_id,username:lotResponse.data[i].username})
                points = 0;
                count = 0;
              }else{
                // console.log("else 2 index==",i)
              }
            }
          }
        }else{
          // console.log("inside elseee  ",i)
          points = 0;
          count = 0;
          points=points + pointsRes.data[0].total_sum;
          count=count + pointsRes.data[0].total_counts;
          if(i == lotResponse.data.length - 1){
            this.pointsArr.push({day:new Date(lotResponse.data[i].lot_created.split("T")[0]).getDay(),date:lotResponse.data[i].lot_created,totalEntries:count,totalPoints:points,user_id:lotResponse.data[i].user_id,username:lotResponse.data[i].username})
            points = 0;
            count = 0;
          }else{

            if(i <= lotResponse.data.length){
              if((lotResponse.data[i].user_id != lotResponse.data[i+1].user_id) || (lotResponse.data[i].lot_created.split("T")[0] != lotResponse.data[i+1].lot_created.split("T")[0])){

                this.pointsArr.push({day:new Date(lotResponse.data[i].lot_created.split("T")[0]).getDay(),date:lotResponse.data[i].lot_created,totalEntries:count,totalPoints:points,user_id:lotResponse.data[i].user_id,username:lotResponse.data[i].username})
                points = 0;
                count = 0;
              }
            }
          }
        }

      })

      if(i == lotResponse.data.length -1){
        // console.log("points arr 1=",this.pointsArr)
        this.filterTableData(this.selectedDay)
        this.loading.dataLoading(false);
      }

    }
  }

  onMarketChange(event){
    // console.log("selected market=",event)
    //
    this.totalPoints = [];


    if(event.index == 0){
      this.selectedMarket ="A";
    }else if(event.index == 1){
      this.selectedMarket ="D";
    }else if(event.index == 2){
      this.selectedMarket ="N";
    }

      this.refreshData();
  }

  onDayChange(event){
    // console.log("selected day=",event)
    this.days.filter((item:any)=>{

      if((item.value - 1) == event.index){
        // console.log("selected date=",item.date)
        this.selectedDate = item.date
        this.choosedDate = new Date(item.date)
        this.refreshData();
        // this.selectedDay = item.value;
        // this.filterTableData(this.selectedDay);
      }
    })
  }




  async filterTableData(market){
    this.totalPoints = [];
    // console.log("points arr=",this.pointsArr)
    // console.log("day==",day)
    this.netPoints = 0;
    await this.pointsArr.filter((item:any)=>{
      if(this.selectedMarket == 'A'){
        this.netPoints = this.netPoints + item.totalPoints;
        this.totalPoints.push(item);
      }else{

        if(market==item.market){
          this.netPoints = this.netPoints + item.totalPoints;
          this.totalPoints.push(item);
        }
      }
    })

  }
}
