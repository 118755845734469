import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ApiService } from '../api.service';
import { ObservablesService } from '../observables.service';

@Component({
  selector: 'app-sub-admin-summary',
  templateUrl: './sub-admin-summary.component.html',
  styleUrls: ['./sub-admin-summary.component.scss']
})
export class SubAdminSummaryComponent implements OnInit {
  subAdminList:any=[]
  selectedSubAdmin:any;
  activeElement:any='';
  _selectedData:any =null
  @Input() set selectedData(selectedData:any){
    this._selectedData = selectedData
    console.log("_selectedData=",this._selectedData)
    this.getPointsSum()
  }
  constructor(private api:ApiService,private observables:ObservablesService) { }

  ngOnInit() {
    this.getSubAdminList();
  }

  getPointsSum(){
    this.api.send("findDb",{
      table:'points',
      fields:["SUM(points) as points, added_sub_admin_id"],
      conditions:[{"added_sub_admin_id !":0, "DATE(created)":this._selectedData.date}],
      groupBy:"added_sub_admin_id"
    }).then((res:any)=>{
      for(let i = 0;i<this.subAdminList.length;i++){
        if(res.data.length > 0){
          for(let j=0;j<res.data.length;j++){
            if(res.data[j].added_sub_admin_id == this.subAdminList[i].sub_admin_id){
                this.subAdminList[i].total = res.data[j].points
                break;
            }

          }
        }else{
          this.subAdminList[i].total = 0
        }
      }
    })

  }

  getSubAdminList(){
    this.subAdminList = []
    this.api.send("findDb",{
      fields:["*, 0 as total"],
      table:"users",
      conditions:[{'role':'subadmin'}]
    }).then((userList:any)=>{
      // console.log("userList=",userList);

      userList.data.sort(function(a, b) {
      var nameA = a.username.toUpperCase(); // ignore upper and lowercase
      var nameB = b.username.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

        // names must be equal
        return 0;
      });
      this.subAdminList = userList.data;
      if(this.subAdminList.length > 0){
        this.activeElement = this.subAdminList[0].id;
        this.selectedSubAdmin = this.subAdminList[0]
      }
    }).catch(err=>{console.log("subAdminList Error=",err)})
  }

  getSubAdminData(data){
    this.activeElement = data.id;
    data.selectedMarketData = this._selectedData
    this.selectedSubAdmin = data;
  }

}
