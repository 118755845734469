import { Component, OnInit, OnDestroy, ElementRef} from '@angular/core';
import { Router } from '@angular/router';
import { ObservablesService } from '../observables.service'
import { SocketObservablesService } from '../socket-observables.service'
import { ApiService } from '../api.service'
import { LoadingService } from '../loading.service'
import { KeyboardEventService } from '../keyboard-event.service'
import { MatAutocompleteSelectedEvent } from '@angular/material';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators'
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-sub-admin-header',
  templateUrl: './sub-admin-header.component.html',
  styleUrls: ['./sub-admin-header.component.scss']
})
export class SubAdminHeaderComponent implements OnInit {
  today: number = Date.now();
  hours = (new Date()).getHours();
  result1:any='';
  result2:any='';
  middle_result:any='';
  market:any='';
  current_market_lots:any=[];
  pointsArr:any=[];
  showCurrentLotsStatusSubscription:any;
  updateResultStatusSubscription:any;
  refreshSessionStatusSubscription:any;
  saveUserPointsStatusSubscription:any;
  sendTicketDataSubscriber:any;
  showArrowSubscriber:any;
  hideArrowSubscriber:any;
  keyPadElements:any=[]
  saveLotStatus:boolean=false;
  alertElements:any=[]
  ticketData:any=[]
  userData:any=null
  sessionData:any=[]
  timer:any= 0;
  myControl = new FormControl();
  options: any=[];
  filteredOptions: Observable<string[]>;
  users:any=[]
  viewLotStatus:boolean = false;
  exportSaturdayStatus:boolean = false;
  exportSundayStatus:boolean = false;
  constructor(private loading:LoadingService,private key:KeyboardEventService, private elementRef:ElementRef, private socketObservables:SocketObservablesService, public router:Router,public observables:ObservablesService, private api:ApiService) { }

  ngOnInit() {


    var d2 = new Date();
    if(d2.getDay() == 6){
      this.exportSaturdayStatus = true;
    }else{
      this.exportSaturdayStatus = false;
    }

    if(d2.getDay() == 0){
      this.exportSundayStatus = true;
    }else{
      this.exportSundayStatus = false;
    }



    this.getCurrentMarket();
    // this.observables.getUserDataStatusStatus.subscribe(()=>{
    //   this.observables.refreshUserStatus.next(this.userData);
    // })
    this.showCurrentLotsStatusSubscription = this.observables.showUserCurrentLotsStatus.subscribe(()=>{
      if(this.userData){

        this.loading.dataLoading(true)
        // console.log("mine current market lots and points==",this.userData);
        this.getCurrentMarketLots().then((result:any)=>{
          this.observables.showLotsStatus.next(result);
        });
      }
    })
    this.updateResultStatusSubscription = this.socketObservables.updateResultStatus.subscribe(()=>{
      this.getSessionResult();
    })

    this.refreshSessionStatusSubscription = this.observables.refreshSessionStatus.subscribe(()=>{
      this.getCurrentMarket();
    });

    this.saveUserPointsStatusSubscription = this.observables.saveUserPointsStatus.subscribe(()=>{
      this.saveLot();
    });

    this.sendTicketDataSubscriber = this.key.sendTicketDataStatus.subscribe((ticketData:any)=>{
      //console.log("ticket Data==",ticketData)
       this.ticketData = ticketData;
    });

    this.showArrowSubscriber = this.observables.showArrowStatus.subscribe(()=>{
      this.viewLotStatus = true;
    })
    this.hideArrowSubscriber = this.observables.hideArrowStatus.subscribe(()=>{
      this.viewLotStatus = false;
    })


    // setTimeout(()=> {
    //   this.keyPadElements = this.elementRef.nativeElement.querySelectorAll('.saveLot');

      // this.keyPadToggleElements = this.elementRef.nativeElement.querySelectorAll('#mat-slide-toggle-1-input');

      // console.log("all elements in header=",this.keyPadElements);
      //this.renderer.addClass(this.keyPadElements.dateBlock[0],"date-block");
    //   this.key.getSaveElement({saveLotElements:this.keyPadElements,userStatus:true});
    //
    // }, 10);

    this.refreshClientList().then((res:any)=>{
      this.options = res;
      this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.username.toLowerCase().includes(filterValue));
  }

  goBack(){
    this.viewLotStatus = false;
    this.observables.hideArrowStatus.next()
  }

  onSelectionChanged(_event: any,userData) {

      if (_event.isUserInput) {
             //do something
             if(this.ticketData.length > 0){
                if(confirm("You have unsaved Points; are you sure you want to select User?")){

                  // console.log("selected user=",this.router.url)
                  this.userData = userData;
                  localStorage.setItem("selectedUserId",userData.id)
                  this.observables.clearAll();

                  if(this.router.url == '/sub-admin-lot-view'){
                    this.getCurrentMarketLots().then((result:any)=>{
                      this.observables.showLotsStatus.next(result);
                    });

                  }else if(this.router.url == '/sub-admin-dashboard'){
                    this.observables.refreshUserStatus.next(userData);

                  }


                }else{
                  this.myControl.patchValue(this.userData.username)
                }
             }else{
               // console.log("selected user=",userData)
               this.userData = userData;
               localStorage.setItem("selectedUserId",userData.id)
               if(this.router.url == '/sub-admin-lot-view'){
                 this.getCurrentMarketLots().then((result:any)=>{
                   this.observables.showLotsStatus.next(result);
                 });

               }else if(this.router.url == '/sub-admin-dashboard'){
                 this.observables.refreshUserStatus.next(userData);

               }
             }
          }
    }


  refreshClientList(){
    return new Promise((resolve,reject)=>{

      this.api.send("findDb",{
        table:"users",
        conditions:[{'role':'main-customer'}],
        order:"username ASC"
      }).then((res:any)=>{
        // console.log("\nRefreshed users list",res);

        this.users = res.data;
        resolve(this.users);
        });
    })


  }

  ngOnDestroy(){
    // console.log("ha ha ha, called me in header")
  //  this.showCurrentLotsStatusSubscription.unsubscribe();
    this.updateResultStatusSubscription.unsubscribe();
    this.refreshSessionStatusSubscription.unsubscribe();
    this.saveUserPointsStatusSubscription.unsubscribe();
    this.sendTicketDataSubscriber.unsubscribe();
    this.showArrowSubscriber.unsubscribe();
    this.hideArrowSubscriber.unsubscribe();
  }

  getSessionResult(){

    this.api.send("findDb",{
      table:"results",
      conditions:[{market:this.market,"DATE(created)":"$CURDATE()$"}]
    }).then((results:any)=>{
      // console.log("1st session result is=",results)
      if(results.data.length > 0){
        this.result1 = results.data[0].p1;
        this.result2 = results.data[0].p2;
        this.middle_result = results.data[0].oc;

      }
    }).catch(error=>{

      console.log(" session result error is=",error)
    })
  }

  openMenu(){
    //console.log(this.observables.menuStatus);

    this.observables.setMenuStatus({menuState:'in'})
  }

  checkUserDisableSastus(){
    return new Promise((resolve,reject)=>{
      this.api.send('findDb',{
        table:"users",
        conditions:[{active_status:"0",id:localStorage.getItem("userId")}]
      }).then((response:any)=>{
        // console.log("resonse",response)
        if(response.data.length > 0 && response.status){
          if(response.data[0].id == localStorage.getItem("userId")){
            resolve(true);
          }else{
            resolve(false);
          }
        }else{
          resolve(false);
        }
      })
    })
  }

  saveLot(){
    // this.sessionData.end_time =
    // console.log("session data=",this.sessionData)
    if(this.exportSaturdayStatus && this.market == 'N'){
        this.observables.showToastMessage({type:1,message:'Saturday Night Market Not Available...!'});
        return
    }

    if(this.exportSundayStatus){
        this.observables.showToastMessage({type:1,message:'Sunday Market Not Available...!'});
        return
    }

    this.checkUserDisableSastus().then((disableStatus:any)=>{
      if(disableStatus){
        this.getCurrentMarket().then((sessionData:any)=>{
          if(sessionData.data.length > 0){
            let currentDate = new Date()

            let endDate = new Date(currentDate.getTime());
            endDate.setHours(this.sessionData.end_time.split(":")[0]);
            endDate.setMinutes(this.sessionData.end_time.split(":")[1]);
            endDate.setSeconds(this.sessionData.end_time.split(":")[2]);
            // endDate > currentDate
            if(this.sessionData.session == 2 &&  currentDate > endDate){
              this.observables.showToastMessage({type:1,message:'Market closed, can\'t save Lot...!'});
            }else{

              if(this.ticketData.length > 0){
                if(confirm("Please Confirm Lot Entry..")){
                    this.observables.getLots(this.market);
                }else{
                  this.observables.cancelLotEntry();
                }
                // this.saveLotStatus=true;
                // setTimeout(()=>{
                //   this.alertElements = this.elementRef.nativeElement.querySelectorAll('.alertbtns');
                //   this.key.getAlertElement({alertElements:this.alertElements,userStatus:true});
                // },100)
              }else{
                this.observables.showToastMessage({type:1,message:'Points Not added yet...!'});
              }
            }
          }else{
            this.observables.showToastMessage({type:1,message:'Market closed, can\'t save Lot...!'});
          }
        })
      }else{
        this.observables.showToastMessage({type:1,message:'Can\'t save Lot...!, Please Contact Owner'});
      }
    })


  }

  saveLotConfirmation(){

      this.saveLotStatus=false;
      this.observables.getLots(this.market);

  }

  closeModal(){
    this.saveLotStatus=false;
    this.observables.cancelLotEntry();
  }

  clearAll(){
    if(confirm("Are you sure to clear all Points..?")){

      this.observables.clearAll();
    }
  }


  getCurrentMarket(){
    // console.log("getting current market")
    return new Promise((resolve,reject)=>{

      this.api.send("findDb",{
        table:"session_times",
        conditions:[{"curtime() >":"$start_time$","curtime() <":"$end_time$",pride_status:environment.prideStatus}]
      }).then((sessionTimeRes:any)=>{
        // console.log("current running session=",sessionTimeRes)
        if(sessionTimeRes.data.length>0){
          this.sessionData = sessionTimeRes.data[0];
          this.market = sessionTimeRes.data[0].market;
          this.getSessionResult();
          this.observables.sendMarketData(sessionTimeRes.data[0]);
          // console.log("session data=",this.sessionData)
          let end_time = this.sessionData.end_time.split(":")
          let end_date_time = new Date(new Date().setHours(end_time[0],end_time[1],end_time[2]))
          this.timer = 0
          let refreshInterval = setInterval(() => {

            let now = new Date();
            let timeDifference = end_date_time.getTime() - now.getTime();
            this.timer = timeDifference / 1000;
            // console.log("seconds=",this.timer)
            // this.timer += seconds;
            if(this.timer < 1){

              clearInterval(refreshInterval);
            }
          }, 1000);
          resolve(sessionTimeRes)
        }else{
          resolve(sessionTimeRes)
        }
      }).catch(err=>{console.log("error while fetching session times",err)})
    })
  }







  getCurrentMarketLots(){
    // console.log("user data=",this.userData)
    return new Promise((resolve,reject)=>{
      this.api.send("findDb",{
        table:"lots as L",
        fields:["L.*, L.id as lot_id"],
        conditions:[{'L.day_night':this.market,"DATE(L.created)":"$CURDATE()$",'L.user_id':this.userData.id}],
        groupBy:"L.id",
        order:"L.lot_no"
      }).then((lotResponse:any)=>{
        // console.log("current lots=",lotResponse)
        if(lotResponse.data.length > 0){
        //   this.current_market_lots = lotResponse.data;
          resolve(this.getPoints(lotResponse.data));
        }else{
          this.loading.dataLoading(false);
          resolve(lotResponse.data)
        }
      }).catch(error=>{console.log("error while fetching current lots=",error);reject(error)})
    })

  }

  getPoints(lotResponse){
    return new Promise((resolve,reject)=>{
      this.pointsArr=[];
      for(let i=0; i<lotResponse.length;i++){
        this.api.send("findDb",{
          table:"points",
          conditions:[{"lot_id":lotResponse[i].lot_id}]
        }).then((pointResult:any)=>{
          // console.log('point result=',pointResult.data);
          lotResponse[i].points = pointResult.data;
          this.pointsArr.push({lot:lotResponse[i],points:pointResult.data});
          // console.log("final points==",this.pointsArr)
          if(i == lotResponse.length-1){
            resolve(this.pointsArr);
          }
        }).catch(pointsErr=>{console.log("Error while getting points=",pointsErr);reject(pointsErr)});
      }
    })
  }

}
