import { saveAs } from 'file-saver';
import { Response } from '@angular/http';
export const saveFile = (blobContent: Blob, fileName: string) => {
    return new Promise((resolve,reject)=>{

      const blob = new Blob([blobContent], { type: 'application/octet-stream' });
      saveAs(blob, fileName);
      resolve(true);
    })
};


export const getFileNameFromResponseContentDisposition = (res: Response) => {
  // console.log("response==",res)

    // const contentDisposition = res.headers.get('content-disposition') || '';
    // console.log("contentDisposition=",contentDisposition)
    // const matches = /filename=([^;]+)/ig.exec(contentDisposition);
    // const fileName = (matches[1] || 'untitled').trim();
    const fileName = /[^/]*$/.exec(res.url)[0];
    // console.log("file name==",fileName)
    return fileName;
};
