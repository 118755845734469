import { Component, OnInit } from '@angular/core';
import {NgbTimepickerModule} from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../api.service';
import { ObservablesService } from '../observables.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-market-time-settings',
  templateUrl: './market-time-settings.component.html',
  styleUrls: ['./market-time-settings.component.scss']
})
export class MarketTimeSettingsComponent implements OnInit {
  day:any={
    session1:{
      start_time:"",
      end_time:""
    },
    session2:{
      start_time:"",
      end_time:""
    }
  }
  night:any={
    session1:{
      start_time:"",
      end_time:""
    },
    session2:{
      start_time:"",
      end_time:""
    }
  }

  meridian=true;

  daySession1Form:FormGroup
  daySession2Form:FormGroup
  nightSession1Form:FormGroup
  nightSession2Form:FormGroup

  constructor(private fb:FormBuilder,private api:ApiService,private observables:ObservablesService) {
    this.daySession1Form = this.fb.group({
      start_time:[null,Validators.compose([Validators.required])],
      end_time:[null,Validators.compose([Validators.required])],
      market:['D'],
      session:['1']
    });
    this.daySession2Form = this.fb.group({
      start_time:[null,Validators.compose([Validators.required])],
      end_time:[null,Validators.compose([Validators.required])],
      market:['D'],
      session:['2']
    });
    this.nightSession1Form = this.fb.group({
      start_time:[null,Validators.compose([Validators.required])],
      end_time:[null,Validators.compose([Validators.required])],
      market:['N'],
      session:['1']
    });
    this.nightSession2Form = this.fb.group({
      start_time:[null,Validators.compose([Validators.required])],
      end_time:[null,Validators.compose([Validators.required])],
      market:['N'],
      session:['2']
    });
  }

  ngOnInit() {
    this.getTime();
  }

  getTime(){
    this.api.send('findDb',{
      table:"session_times",
      fields:["session, market, update_count, HOUR(start_time) start_hour,MINUTE(start_time) start_minute,SECOND(start_time) start_second,HOUR(end_time) end_hour,MINUTE(end_time) end_minute,SECOND(end_time) end_second"],
      conditions:[{pride_status:environment.prideStatus}]
    }).then((res:any)=>{
      // console.log("timing res=",res)
      // end_time: {hour: 23, minute: 0, second: 0}
      for(let i=0; i<res.data.length;i++){
        if(res.data[i].market == 'D' && res.data[i].session == '1'){
          // console.log("patchig value")
          this.daySession1Form.patchValue({start_time:{hour: res.data[i].start_hour, minute: res.data[i].start_minute, second: res.data[i].start_second},
            end_time:{hour: res.data[i].end_hour, minute: res.data[i].end_minute, second: res.data[i].end_second}});
        }else if(res.data[i].market == 'D' && res.data[i].session == '2'){
          this.daySession2Form.patchValue({start_time:{hour: res.data[i].start_hour, minute: res.data[i].start_minute, second: res.data[i].start_second},
            end_time:{hour: res.data[i].end_hour, minute: res.data[i].end_minute, second: res.data[i].end_second}});
        }else if(res.data[i].market == 'N' && res.data[i].session == '1'){
          this.nightSession1Form.patchValue({start_time:{hour: res.data[i].start_hour, minute: res.data[i].start_minute, second: res.data[i].start_second},
            end_time:{hour: res.data[i].end_hour, minute: res.data[i].end_minute, second: res.data[i].end_second}});
        }else if(res.data[i].market == 'N' && res.data[i].session == '2'){
          this.nightSession2Form.patchValue({start_time:{hour: res.data[i].start_hour, minute: res.data[i].start_minute, second: res.data[i].start_second},
            end_time:{hour: res.data[i].end_hour, minute: res.data[i].end_minute, second: res.data[i].end_second}});
        }
      }
    })
  }

  setTime(session_times){
    // console.log("submitted date==",this.day)
    // console.log("submitted date==",this.night)
    // console.log("selected session is -",session_times)
    this.api.send("findDb",{
      table:"session_times",
      conditions:[{ session:session_times.session,market:session_times.market,pride_status:environment.prideStatus}]
    }).then((res:any)=>{
      // console.log("selected session earlier records=",res);
      if(res.data.length>0){
        this.api.send("updateDb",{
          table:"session_times",
          data:{
            start_time:session_times.start_time.hour+":"+session_times.start_time.minute,
            end_time:session_times.end_time.hour+":"+session_times.end_time.minute,
            session:session_times.session,
            market:session_times.market,
            update_count : res.data[0].update_count + 1
          },
          conditions:[{ session:session_times.session,market:session_times.market, pride_status:environment.prideStatus}]
        }).then((result:any)=>{
          // console.log("session timing updating response=",result);
          if(result.data.affectedRows){

            this.observables.showToastMessage({type:0,message:'Session '+session_times.session+' timing updated.'});
          }else{
            this.observables.showToastMessage({type:1,message:'Session '+session_times.session+' timing not updated, Try again!'});

          }
        }).catch(err=>{console.log("error while updating the session time",err)});
      }else{

        this.api.send("insertDb",{
          table:"session_times",
          data:{
              start_time:session_times.start_time.hour+":"+session_times.start_time.minute,
              end_time:session_times.end_time.hour+":"+session_times.end_time.minute,
              session:session_times.session,
              market:session_times.market,
              update_count : 1,
              pride_status:environment.prideStatus
            }
          }).then((result:any)=>{
            // console.log("session timing inserting response=",result);
            if(result.data.affectedRows){

              this.observables.showToastMessage({type:0,message:'Session '+session_times.session+' timing saved.'});
            }else{
              this.observables.showToastMessage({type:1,message:'Session '+session_times.session+' timing not saved, Try again!'});

            }
          }).catch(err=>{console.log("error while inserting session timing",err)})
      }
    })



  }

}
