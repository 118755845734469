import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class KeyboardEventService {
  selectedElementStatus: any = new Subject<any>();
  getAllElementStatus:any= new Subject<any>();
  getSaveElementStatus:any= new Subject<any>();
  sendTicketDataStatus:any= new Subject<any>();
  getAlertElementStatus:any= new Subject<any>();
  getEditElementsStatus:any= new Subject<any>();
  getFixedElementsStatus:any= new Subject<any>();
  constructor() { }
  selectedElement(data){
    this.selectedElementStatus.next(data);
  }

  getAllElement(elements){
    this.getAllElementStatus.next(elements)
  }
  getAlertElement(elements){
    this.getAlertElementStatus.next(elements)
  }

  getSaveElement(elements){
    this.getSaveElementStatus.next(elements)
  }

  sendTicketData(data){
    this.sendTicketDataStatus.next(data)
  }

  getEditElements(data){
    this.getEditElementsStatus.next(data);
  }
  getFixedElements(data){
    this.getFixedElementsStatus.next(data);
  }
}
