import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    // console.log("timer=",value);
    // if(value > 0){

      let minutes = Math.floor((value % 3600) / 60);
      let hours = Math.floor(value / 3600);
      let seconds = Math.floor(value % 60);
      return ('00' + hours).slice(-2) + ':' + ('00' + minutes).slice(-2) + ':' + ('00' + seconds).slice(-2) ;
    // }else{
    //   return '00:00:00' ;
    // }
  }

}
