import { Component,Input, OnInit,ViewChild,ElementRef,forwardRef } from '@angular/core';
import { ObservablesService } from '../observables.service';
import {ControlValueAccessor,NG_VALIDATORS,NG_VALUE_ACCESSOR,FormBuilder, Validators, FormGroup, ValidatorFn, AbstractControl, FormArray, FormControl } from '@angular/forms'

@Component({
  selector: 'app-copy-paste',
  templateUrl: './copy-paste.component.html',
  styleUrls: ['./copy-paste.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CopyPasteComponent),
      multi: true
    }
  ]
})
export class CopyPasteComponent implements ControlValueAccessor {
  message1:any=''
  message2:any=''
  points:any=[]
  myForm: FormGroup;
  specialForm: FormGroup;
  session:any;
  reverseFormatStatus:boolean = false
  replaceStatus:boolean = false
  replaceNewStatus:boolean = false
  continueStatus:boolean = false
  specialStatus:boolean = false
  pointsPatternWithOneDecimal:any = /^\d+(?:\.\d{1})?$/;
  pointsPattern:any = /^\d+(?:\.\d{1,2})?$/;
  numberPattern:any = /^(?=\d{1,3}$)1*2*3*4*5*6*7*8*9*0*$/;
  number1Pattern:any = /^\d{1,2}$/;
  numberPatternForSessionTwo1:any = /^(?=\d{4}$)1*2*3*4*5*6*7*8*9*0*$/;
  // format:any = /[°॰:=;#*$&+×xX<>/,.-]/; 
  format:any = /[ °॰*•₹:=;'~`÷€£¥₩○●□▪¤¡¿♤♡◇♧☆!#$%^&+×xX?${_"|}()\\<>[/\],.-]/; 
  @Input() set selectedType(selectedType:any){
    this.session = selectedType
    // console.log("type==",selectedType)
  }
  @Input() set _selectedType(_selectedType:any){
    this.session = _selectedType
    // console.log("type==",selectedType)
  }
  @ViewChild('input', { static: true }) numberInput:ElementRef;
  @ViewChild("backdrop", { static: false }) $backdrop: ElementRef<HTMLDivElement>;
  @ViewChild("textarea", { static: false }) $textarea: ElementRef<HTMLTextAreaElement>;

  highlightTexts: string[] = []
  constructor(private fb:FormBuilder, private observables:ObservablesService) {
    this.myForm = this.fb.group({
      items: this.initItems()
    });
    this.specialForm = this.fb.group({
      items: this.initItems()
    });
  }

  get highlightedText () {
    return this.applyHighlights(this.message1)
  }

  applyHighlights(text) {
    text = text ? text
      .replace(/\n$/g, "\n\n") : '';
    this.highlightTexts.forEach(x => {
      text = text
      .replace(new RegExp(x, 'g'), "<mark>$&</mark>");
    })
    return text;
    
  }

  handleScroll() {
    var scrollTop = this.$textarea.nativeElement.scrollTop;
    this.$backdrop.nativeElement.scrollTop = scrollTop;

    var scrollLeft = this.$textarea.nativeElement.scrollLeft;
    this.$backdrop.nativeElement.scrollLeft = scrollLeft;
  }

  onChanges: ($value: any) => void;
  onTouched: () => void;

  writeValue(value: any): void {
    if (value !== undefined) {
      this.message1 = value;
    }
  }
  registerOnChange(fn: any): void {
    this.onChanges = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }


  initItems() {
   var formArray = this.fb.array([]);
   return formArray;
 }

  ngOnInit() {
    // this.$textarea.nativeElement.focus()
  }
  
  ngAfterViewInit(){
    this.$textarea.nativeElement.focus()
  }

  clearsearch(){
    this.highlightTexts = []
  }

  findNumber(item, i, status = false,specialStatus = false,element = null){
    // console.log("item==",item.value.number)
    // this.highlight(item.value.number)
    if(specialStatus){
      this.highlightTexts = [element.value]
    }else{

      this.highlightTexts = [status?item.value.points:item.value.number]
    }
    // console.log(this.highlightTexts)
    this.applyHighlights(this.message1)
  }
  highlight(text) {
    
    var inputText = document.getElementById("inputText");
    console.log(inputText)
    var innerHTML = inputText.innerHTML;
    console.log(innerHTML)
    var index = innerHTML.indexOf(text);
    console.log(index)
    if (index >= 0) { 
     innerHTML = innerHTML.substring(0,index) + "<span class='highlight' style='background:red'>" + innerHTML.substring(index,index+text.length) + "</span>" + innerHTML.substring(index + text.length);
    // console.log("innerHTML==",innerHTML)
     inputText.innerHTML = innerHTML;
    }
  }
  constrainInput(str) {
    return str.replace(/[\r\n\v]+/g, '')
  }

  reverseFormat(event){
    if(event.checked){
      this.reverseFormatStatus = true
      this.continueStatus = false
       this.specialStatus = false
      this.replaceStatus = false
      this.replaceNewStatus = false
      this.points=[]
      this.myForm.setControl('items', this.fb.array([]));
      this.specialForm.setControl('items', this.fb.array([]));
    }else{

      this.reverseFormatStatus = false
      this.continueStatus = false
       this.specialStatus = false
      this.replaceStatus = false
      this.replaceNewStatus = false
      this.points=[]
      this.myForm.setControl('items', this.fb.array([]));
      this.specialForm.setControl('items', this.fb.array([]));
    }
  }

  replaceFormat(event){
    this.replaceStatus = event.checked
    this.continueStatus = false
    this.specialStatus = false
    this.reverseFormatStatus = false
    this.replaceNewStatus = false
    this.points=[]
    this.myForm.setControl('items', this.fb.array([]));
    this.specialForm.setControl('items', this.fb.array([]));
  }
  specialFormat(event){
 
      this.specialStatus = event.checked
      this.continueStatus = false
      this.replaceStatus = false
      this.reverseFormatStatus = false
      this.replaceNewStatus = false
      this.points=[]
      this.myForm.setControl('items', this.fb.array([]));
      this.specialForm.setControl('items', this.fb.array([]));
  }
  continueFormat(event){
 
      this.continueStatus = event.checked
      this.specialStatus = false
      this.replaceStatus = false
      this.reverseFormatStatus = false
      this.replaceNewStatus = false
      this.points=[]
      this.myForm.setControl('items', this.fb.array([]));
      this.specialForm.setControl('items', this.fb.array([]));
  }
  
  replaceNewFormat(event){
 
      this.replaceNewStatus = event.checked
      this.specialStatus = false
      this.replaceStatus = false
      this.reverseFormatStatus = false
      this.continueStatus = false
      this.points=[]
      this.myForm.setControl('items', this.fb.array([]));
      this.specialForm.setControl('items', this.fb.array([]));
  }
  
  processReplaceMessage(){
    this.points = []
    this.myForm.setControl('items', this.fb.array([]));
    this.specialForm.setControl('items', this.fb.array([]));
    // console.log(this.message1)
    let unProcessed = this.message1.split('\n');
    // console.log("unProcessed==",unProcessed)
    for(let i=0;i<unProcessed.length;i++){
      if(unProcessed[i].includes('@')){
        if(unProcessed[i].includes('@:')){
          unProcessed[i] = unProcessed[i].split("@:")[1]
        }
      }

      let processed = this.constrainInput(unProcessed[i].replace(/ /g, "")).split(/[ °•:=;'~`÷€£¥₩○●□▪¤¡¿♤♡◇♧☆!#$%^&+×xX?${_"|}()\\<>[/\],.]/);
      // console.log("processed==",processed)

      for(let j=0; j<processed.length;j++){
        if(processed[j].trim().length > 0){

          if(processed[j].includes("@")){
            let splited = this.constrainInput(processed[j]).split('@')

            if(splited[1].includes("*")){

              let splitProcessed:any = this.constrainInput(splited[1]).split('*');
              // console.log("splitProcessed==",splitProcessed)
              if(splitProcessed.length == 2){
                this.points.push({id:j+1,number:splitProcessed[0],points:splitProcessed[1],typeStatus:true})
                this.addNewItem({id:j+1,number:splitProcessed[0],points:splitProcessed[1],typeStatus:true})
              }else if(splitProcessed.length > 2){
                this.points.push({id:j+1,number:splitProcessed[0],points:splitProcessed.slice(1).join(),typeStatus:true})
                this.addNewItem({id:j+1,number:splitProcessed[0],points:splitProcessed.slice(1).join(),typeStatus:true})
              }else{

                this.points.push({id:j+1,number:splitProcessed[0],points:'',typeStatus:true})
                this.addNewItem({id:j+1,number:splitProcessed[0],points:'',typeStatus:true})
              }
            }else{
              // console.log("processed[j]==",processed[j])
              if(splited[1].length > 2){
                let a = splited[1].substr(0, 2);
                let b = splited[1].substr(2);
                // console.log("bb==",b)
                if(b.includes("-") || b.includes("‐")){
                  b = b.substr(1);
                }

                // console.log("bb==b=",b)

                this.points.push({id:i+1,number:a.replace(/[^0-9]/g, ""),points:b,typeStatus:true})
                this.addNewItem({id:i+1,number:a.replace(/[^0-9]/g, ""),points:b,typeStatus:true})
              }else{
                this.points.push({id:i+1,number:splited[1],points:'',typeStatus:true})
                this.addNewItem({id:i+1,number:splited[1],points:'',typeStatus:true})
              }
            }
          }else{
            if(processed[j].includes("*")){

              let splitProcessed:any = this.constrainInput(processed[j]).split('*');
              // console.log("splitProcessed==",splitProcessed)
              if(splitProcessed.length == 2){
                this.points.push({id:j+1,number:splitProcessed[0],points:splitProcessed[1],typeStatus:true})
                this.addNewItem({id:j+1,number:splitProcessed[0],points:splitProcessed[1],typeStatus:true})
              }else if(splitProcessed.length > 2){
                this.points.push({id:j+1,number:splitProcessed[0],points:splitProcessed.slice(1).join(),typeStatus:true})
                this.addNewItem({id:j+1,number:splitProcessed[0],points:splitProcessed.slice(1).join(),typeStatus:true})
              }else{

                this.points.push({id:j+1,number:splitProcessed[0],points:'',typeStatus:true})
                this.addNewItem({id:j+1,number:splitProcessed[0],points:'',typeStatus:true})
              }
            }else{
              // console.log("processed[j]==",processed[j])
              if(processed[j].length > 2){
                let a = processed[j].substr(0, 2);
                let b = processed[j].substr(2);
                if(b.includes("-") || b.includes("‐")){
                  b = b.substr(1);
                }

                this.points.push({id:i+1,number:a.replace(/[^0-9]/g, ""),points:b,typeStatus:true})
                this.addNewItem({id:i+1,number:a.replace(/[^0-9]/g, ""),points:b,typeStatus:true})
              }else{
                this.points.push({id:i+1,number:processed[j],points:'',typeStatus:true})
                this.addNewItem({id:i+1,number:processed[j],points:'',typeStatus:true})
              }
            }
          }
        }

      }


    }
  }

  processReplaceNewMessage(){
    this.points = []
    this.myForm.setControl('items', this.fb.array([]));
    this.specialForm.setControl('items', this.fb.array([]));
    // console.log(this.message1)
    let unProcessed = this.message1.split('\n');
    // console.log("unProcessed==",unProcessed)

    for(let i=0;i<unProcessed.length;i++){
      if(unProcessed[i].includes('@')){
        if(unProcessed[i].includes('@:')){
          unProcessed[i] = unProcessed[i].split("@:")[1]
        }
      }


      let processed = this.constrainInput(unProcessed[i]).split();
      //  /[ °•:=;'~`÷€£¥₩○●□▪¤¡¿♤♡◇♧☆!#$%^&+×xX?${_"|}()\\<>[/\],.]/
      // console.log("processed==",processed)
      for(let j=0; j<processed.length;j++){
        if(processed[j].trim().length > 0){
          processed[j] = processed[j].replace(/[a-z]/gi, '') 

          if(processed[j].includes("@")){
            let splited = this.constrainInput(processed[j]).split('@')

            // let this.format = /[°:=;#*$&+×xX<>/,.-]/
            if(this.format.test(splited[1])){
              // console.log("found special symbol")
              let splitProcessed:any = splited[1].split(this.format)
              // console.log("splitProcessed==",splitProcessed)
              if(splitProcessed.length == 2){
                this.points.push({id:j+1,number:splitProcessed[0],points:splitProcessed[1],typeStatus:true})
                this.addNewItem({id:j+1,number:splitProcessed[0],points:splitProcessed[1],typeStatus:true})
              }else if(splitProcessed.length > 2){
                splitProcessed = splitProcessed.filter(e =>  e);
                for(let k=0;k<splitProcessed.length-1;k++){
                  this.points.push({id:j+1,number:splitProcessed[k],points:splitProcessed[splitProcessed.length - 1],typeStatus:true})
                   this.addNewItem({id:j+1,number:splitProcessed[k],points:splitProcessed[splitProcessed.length - 1],typeStatus:true})
                }
              }else{

                this.points.push({id:j+1,number:splitProcessed[0],points:'',typeStatus:true})
                this.addNewItem({id:j+1,number:splitProcessed[0],points:'',typeStatus:true})
              }
            }else{
              console.log("not found special symbol",splited[1])
              this.points.push({id:j+1,number:splited[1],points:'',typeStatus:true})
              this.addNewItem({id:j+1,number:splited[1],points:'',typeStatus:true})
            }

           
          }else{
            // console.log("processed[j]==",processed[j].toString())
            // let this.format = /[:=;#*$&+×xX<>/,.-]/
            if(this.format.test(processed[j])){
              // console.log("found special symbol")
              let splitProcessed:any = processed[j].split(this.format)
              // console.log("splitProcessed==",splitProcessed)
              if(splitProcessed.length == 2){
                this.points.push({id:j+1,number:splitProcessed[0],points:splitProcessed[1],typeStatus:true})
                this.addNewItem({id:j+1,number:splitProcessed[0],points:splitProcessed[1],typeStatus:true})
              }else if(splitProcessed.length > 2){
                splitProcessed = splitProcessed.filter(e =>  e);
                for(let k=0;k<splitProcessed.length-1;k++){
                  this.points.push({id:j+1,number:splitProcessed[k],points:splitProcessed[splitProcessed.length - 1],typeStatus:true})
                   this.addNewItem({id:j+1,number:splitProcessed[k],points:splitProcessed[splitProcessed.length - 1],typeStatus:true})
                }
              }else{

                this.points.push({id:j+1,number:splitProcessed[0],points:'',typeStatus:true})
                this.addNewItem({id:j+1,number:splitProcessed[0],points:'',typeStatus:true})
              }
            }else{
              console.log("not found special symbol",processed[j])
              this.points.push({id:j+1,number:processed[j],points:'',typeStatus:true})
              this.addNewItem({id:j+1,number:processed[j],points:'',typeStatus:true})
              
            }

           
          }
        }else{
          this.observables.showToastMessage({type:1,message:"Invalid format"})
        }

      }


    }
  }
  
  processContinueMessage(){
    
    this.points = []
    this.myForm.setControl('items', this.fb.array([]));
    // console.log(this.message1)
    let unProcessed = this.message1.split('\n');
    console.log("unProcessed00==",unProcessed)
    unProcessed = unProcessed.filter(e =>  e);
    console.log("unProcessed11==",unProcessed)
    if(unProcessed[0].split(this.format)[1] == unProcessed[unProcessed.length - 1].split(this.format)[1]){
      for(let i=0;i<unProcessed.length;i++){
        if(unProcessed[i].includes('@')){
          if(unProcessed[i].includes('@:')){
            unProcessed[i] = unProcessed[i].split("@:")[1]
          }
        }
  
        console.log("unProcessed[i]==",unProcessed[i])
        let processed = this.constrainInput(unProcessed[i]).split();
  
        console.log("processed==",processed)
        for(let j=0; j<processed.length;j++){
          if(processed[j].trim().length > 0){
            processed[j] = processed[j].replace(/[a-z]/gi, '') 
  
            if(processed[j].includes("@")){
              let splited = this.constrainInput(processed[j]).split('@')

              if(this.format.test(splited[1])){
                console.log("found special symbol")
                let splitProcessed:any = splited[1].split(this.format)
                console.log("splitProcessed==",splitProcessed)
                splitProcessed = splitProcessed.filter(e =>  e);
                if(splitProcessed.length == 2){
                  if(this.hasNumber(splitProcessed[1])){
                   
                      this.points.push({id:j+1,number:splitProcessed[0],points:splitProcessed[1],typeStatus:true})
                      this.addNewItem({id:j+1,number:splitProcessed[0],points:splitProcessed[1],typeStatus:true})
                    

                  }else{
                    if(this.points.length > 0){
                      this.points.push({id:j+1,number:splitProcessed[0],points:this.points[0].points,typeStatus:true})
                      this.addNewItem({id:j+1,number:splitProcessed[0],points:this.points[0].points,typeStatus:true})
                    }  
                  }
  
                }else if(splitProcessed.length == 1){
                  if(splited[1].length > 0){
                    if(this.points.length > 0){
    
                      this.points.push({id:j+1,number:splitProcessed[0],points:this.points[0].points,typeStatus:true})
                      this.addNewItem({id:j+1,number:splitProcessed[0],points:this.points[0].points,typeStatus:true})
                    }
                  }
                }else{
  
                  this.points.push({id:j+1,number:splitProcessed[0],points:'',typeStatus:true})
                  this.addNewItem({id:j+1,number:splitProcessed[0],points:'',typeStatus:true})
                }
              }else{
                // console.log("not found special symbol",splited[1])
                // console.log("uuu==",unProcessed[i])
                // console.log("points==",this.points)
                if(splited[1].length > 0){
                  if(this.points.length > 0){
  
                    this.points.push({id:j+1,number:splited[1],points:this.points[0].points,typeStatus:true})
                    this.addNewItem({id:j+1,number:splited[1],points:this.points[0].points,typeStatus:true})
                  }
                }
              }

              
            }else{
              // console.log("processed[j]==",processed[j])
              if(this.format.test(processed[j])){
                // console.log("found special symbol")
                let splitProcessed:any = processed[j].split(this.format)
                // console.log("splitProcessed==",splitProcessed)
                splitProcessed = splitProcessed.filter(e =>  e);
                if(splitProcessed.length == 2){
                  if(this.hasNumber(splitProcessed[1])){
                   
                      this.points.push({id:j+1,number:splitProcessed[0],points:splitProcessed[1],typeStatus:true})
                      this.addNewItem({id:j+1,number:splitProcessed[0],points:splitProcessed[1],typeStatus:true})
                    

                  }else{
                    if(this.points.length > 0){
                      this.points.push({id:j+1,number:splitProcessed[0],points:this.points[0].points,typeStatus:true})
                      this.addNewItem({id:j+1,number:splitProcessed[0],points:this.points[0].points,typeStatus:true})
                    }  
                  }
  
                }else if(splitProcessed.length == 1){
                  if(processed[j].length > 0){
                    if(this.points.length > 0){
    
                      this.points.push({id:j+1,number:splitProcessed[0],points:this.points[0].points,typeStatus:true})
                      this.addNewItem({id:j+1,number:splitProcessed[0],points:this.points[0].points,typeStatus:true})
                    }
                  }
                }else{
  
                  this.points.push({id:j+1,number:splitProcessed[0],points:'',typeStatus:true})
                  this.addNewItem({id:j+1,number:splitProcessed[0],points:'',typeStatus:true})
                }
              }else{
                // console.log("not found special symbol",processed[j])
                // console.log("uuu==",unProcessed[i])
                // console.log("points==",this.points)
                if(processed[j].length > 0){
                  if(this.points.length > 0){
  
                    this.points.push({id:j+1,number:processed[j],points:this.points[0].points,typeStatus:true})
                    this.addNewItem({id:j+1,number:processed[j],points:this.points[0].points,typeStatus:true})
                  }
                }
              }
  
            }
          }
  
        }
  
  
      }
    }else{
      this.observables.showToastMessage({type:1,message:"Invalid Continue format"})
    }

    
  }


  processSpecialMessage(){
    this.points = []
    this.specialForm.setControl('items', this.fb.array([]));
    // console.log(this.message1)
    let unProcessed = this.message1.split('\n');
    console.log("unProcessed==",unProcessed)

    for(let i=0;i<unProcessed.length;i++){
      // if(unProcessed[i].includes('@')){
      //   if(unProcessed[i].includes('@:')){
      //     unProcessed[i] = unProcessed[i].split("@:")[1]
      //   }
      // }


      let processed = unProcessed[i].replace(/[\r\n\v]+/g, '')//this.constrainInput(unProcessed[i]).split();
      //  /[ °•:=;'~`÷€£¥₩○●□▪¤¡¿♤♡◇♧☆!#$%^&+×xX?${_"|}()\\<>[/\],.]/
      // if(processed.endsWith('g')){
        processed = processed.replace(/[a-wyz]/gi, '') 
        console.log("g processed==",processed)
        if(this.format.test(processed)){
          // console.log("found special symbol")
          let splitProcessed:any = processed.split(this.format)
          console.log("splitProcessed==",splitProcessed)
          if(splitProcessed.length == 3){
            if(splitProcessed[0].length == 3 && splitProcessed[1].length == 1){
              this.points.push({id:i+1, number:splitProcessed[0],points:splitProcessed[2],gunule:splitProcessed[1],relative_number:splitProcessed[1]});
              this.points.push({id:i+1, number:splitProcessed[1],points:splitProcessed[2],gunule:null,gunuleStatus:1,relative_number:splitProcessed[0]});
              this.addSpecialItem({id:i+1, number:splitProcessed[0],points:splitProcessed[2],gunule:splitProcessed[1],relative_number:splitProcessed[1]})
              // this.addSpecialItem({id:i+1, number:splitProcessed[1],points:splitProcessed[2],gunule:null,gunuleStatus:1,relative_number:splitProcessed[0]})
            
            }else if(splitProcessed[0].length == 1 && splitProcessed[1].length == 3){
              this.points.push({id:i+1, number:splitProcessed[0],points:splitProcessed[2],ulta_gunule:splitProcessed[1],relative_number:splitProcessed[1]});
              this.points.push({id:i+1, number:splitProcessed[1],points:splitProcessed[2],ulta_gunule:null,ultaGunuleStatus:1,relative_number:splitProcessed[0]});
              this.addSpecialItem({id:i+1, number:splitProcessed[0],points:splitProcessed[2],ulta_gunule:splitProcessed[1],relative_number:splitProcessed[1]})
              // this.addSpecialItem({id:i+1, number:splitProcessed[1],points:splitProcessed[2],ulta_gunule:null,ultaGunuleStatus:1,relative_number:splitProcessed[0]})
            
            }else if(splitProcessed[0].length == 3 && splitProcessed[1].length == 3){
              this.points.push({id:i+1, number:splitProcessed[0],points:splitProcessed[2],sangam:splitProcessed[1],relative_number:splitProcessed[1]});
              this.points.push({id:i+1, number:splitProcessed[1],points:splitProcessed[2],sangam:null,sangamStatus:1,relative_number:splitProcessed[0]});
              this.addSpecialItem({id:i+1, number:splitProcessed[0],points:splitProcessed[2],sangam:splitProcessed[1],relative_number:splitProcessed[1]})
              // this.addSpecialItem({id:i+1, number:splitProcessed[1],points:splitProcessed[2],sangam:null,sangamStatus:1,relative_number:splitProcessed[0]})
            
            }else{
              this.points.push({id:i+1, number:splitProcessed[0],points:''})
              this.addSpecialItem({id:i+1, number:splitProcessed[0],points:''})
              
            }
            
          }else{
            this.points.push({id:i+1, number:splitProcessed[0],points:''})
            this.addSpecialItem({id:i+1, number:splitProcessed[0],points:''})
          }
          //   //  this.points.push({id:i+1, number:values.pan,points:values.amount,gunule:values.items[i].single,relative_number:values.items[i].single});
          //   //  this.points.push({id:i+1, number:values.items[i].single,points:values.amount,gunule:null,gunuleStatus:1,relative_number:values.pan});
          //   this.points.push({id:i+1,number:splitProcessed[0],points:splitProcessed[1],typeStatus:true,type:'g',})
          //   this.addNewItem({id:i+1,number:splitProcessed[0],points:splitProcessed[1],typeStatus:true,type:'g',})
          // }else if(splitProcessed.length > 2){
          //   splitProcessed = splitProcessed.filter(e =>  e);
          //   for(let k=0;k<splitProcessed.length-1;k++){
          //     this.points.push({id:i+1,number:splitProcessed[k],points:splitProcessed[splitProcessed.length - 1],typeStatus:true,type:'g',})
          //      this.addNewItem({id:i+1,number:splitProcessed[k],points:splitProcessed[splitProcessed.length - 1],typeStatus:true,type:'g',})
          //   }
          // }else{

          //   this.points.push({id:i+1,number:splitProcessed[0],points:'',typeStatus:true,type:'g',})
          //   this.addNewItem({id:i+1,number:splitProcessed[0],points:'',typeStatus:true,type:'g',})
          // }
        }else{
          console.log("not found special symbol",processed)
          this.points.push({id:i+1,number:processed,points:'',typeStatus:true,type:'g',})
          this.addNewItem({id:i+1,number:processed,points:'',typeStatus:true,type:'g',})
          
        }
        // combinations.push({number:values.pan,points:values.amount,gunule:values.items[i].single,relative_number:values.items[i].single});
        // combinations.push({number:values.items[i].single,points:values.amount,gunule:null,gunuleStatus:1,relative_number:values.pan});
      // }else if(processed.endsWith('u')){
      //   processed = processed.replace(/[a-z]/gi, '') 
      //   console.log("u processed==",processed)
      // }else if(processed.endsWith('s')){
        
      //   processed =  processed.replace(/[a-z]/gi, '') 
      //   console.log("s processed==",processed)
      // }else{
      //   processed =  processed.replace(/[a-z]/gi, '') 
      //   console.log("else processed==",processed)
        
      // }
      // for(let j=0; j<processed.length;j++){
      //   if(processed[j].trim().length > 0){
          // processed[j] = processed[j].replace(/[a-z]/gi, '') 
          // console.log("processed[j]==",processed[j])
          // if(processed[j].includes("@")){
          //   let splited = this.constrainInput(processed[j]).split('@')

          //   // let this.format = /[°:=;#*$&+×xX<>/,.-]/
          //   if(this.format.test(splited[1])){
          //     // console.log("found special symbol")
          //     let splitProcessed:any = splited[1].split(this.format)
          //     // console.log("splitProcessed==",splitProcessed)
          //     if(splitProcessed.length == 2){
          //       this.points.push({id:j+1,number:splitProcessed[0],points:splitProcessed[1],typeStatus:true})
          //       this.addNewItem({id:j+1,number:splitProcessed[0],points:splitProcessed[1],typeStatus:true})
          //     }else if(splitProcessed.length > 2){
          //       splitProcessed = splitProcessed.filter(e =>  e);
          //       for(let k=0;k<splitProcessed.length-1;k++){
          //         this.points.push({id:j+1,number:splitProcessed[k],points:splitProcessed[splitProcessed.length - 1],typeStatus:true})
          //          this.addNewItem({id:j+1,number:splitProcessed[k],points:splitProcessed[splitProcessed.length - 1],typeStatus:true})
          //       }
          //     }else{

          //       this.points.push({id:j+1,number:splitProcessed[0],points:'',typeStatus:true})
          //       this.addNewItem({id:j+1,number:splitProcessed[0],points:'',typeStatus:true})
          //     }
          //   }else{
          //     console.log("not found special symbol",splited[1])
          //     this.points.push({id:j+1,number:splited[1],points:'',typeStatus:true})
          //     this.addNewItem({id:j+1,number:splited[1],points:'',typeStatus:true})
          //   }

           
          // }else{
          //   // console.log("processed[j]==",processed[j].toString())
          //   // let this.format = /[:=;#*$&+×xX<>/,.-]/
          //   if(this.format.test(processed[j])){
          //     // console.log("found special symbol")
          //     let splitProcessed:any = processed[j].split(this.format)
          //     // console.log("splitProcessed==",splitProcessed)
          //     if(splitProcessed.length == 2){
          //       this.points.push({id:j+1,number:splitProcessed[0],points:splitProcessed[1],typeStatus:true})
          //       this.addNewItem({id:j+1,number:splitProcessed[0],points:splitProcessed[1],typeStatus:true})
          //     }else if(splitProcessed.length > 2){
          //       splitProcessed = splitProcessed.filter(e =>  e);
          //       for(let k=0;k<splitProcessed.length-1;k++){
          //         this.points.push({id:j+1,number:splitProcessed[k],points:splitProcessed[splitProcessed.length - 1],typeStatus:true})
          //          this.addNewItem({id:j+1,number:splitProcessed[k],points:splitProcessed[splitProcessed.length - 1],typeStatus:true})
          //       }
          //     }else{

          //       this.points.push({id:j+1,number:splitProcessed[0],points:'',typeStatus:true})
          //       this.addNewItem({id:j+1,number:splitProcessed[0],points:'',typeStatus:true})
          //     }
          //   }else{
          //     console.log("not found special symbol",processed[j])
          //     this.points.push({id:j+1,number:processed[j],points:'',typeStatus:true})
          //     this.addNewItem({id:j+1,number:processed[j],points:'',typeStatus:true})
              
          //   }

           
          // }
      //   }else{
      //     this.observables.showToastMessage({type:1,message:"Invalid format"})
      //   }

      // }


    }
  }

  

  hasNumber(myString) {
    return /\d/.test(myString);
  }

 
  processMessage(){
    this.points=[]
    this.myForm.setControl('items', this.fb.array([]));
    // console.log("message1==",this.message1)
    // console.log("1=====",this.constrainInput(this.message1).split(/[R-]/))
    if(this.constrainInput(this.message1).includes("R") || this.constrainInput(this.message1).includes("r")){

      let unProcessed:any;


      unProcessed =  this.constrainInput(this.message1).split(/[@rR]/);


      // unProcessed =  this.constrainInput(this.message1).split(/[rR@]/);


      // console.log(unProcessed)
      // let unProcessed1:any =  this.constrainInput(this.message2).split(/[R.]/);
      let isNum = /^\d+$/;
      for(let i=0;i<unProcessed.length;i++){
        if(unProcessed[i].includes("=")){
          // this.constrainInput(unProcessed[i]).split('=');
          // console.log("2=====",this.constrainInput(unProcessed[i]).split('='))
          let splitUnProcessed:any = this.constrainInput(unProcessed[i]).split('=');
          // console.log("splitUnProcessed==",splitUnProcessed)

          // if(splitUnProcessed[1] == ''){//22 66 36 63=r25
            //
            // }else{//22 66 36 63=r25r
              //
              // }
              let numbers:any;
              if(this.reverseFormatStatus){

                // numbers = this.constrainInput(splitUnProcessed[0]).split(/[ °•:;'~`!#$%^&+×?${_"|}()\\<>[/\],.-○●□▪¤¡¿♤♡◇♧☆÷€£¥₩《》]/);
                numbers = this.constrainInput(splitUnProcessed[0]).split(/[ °•:;'~`÷€£¥₩○●□▪¤¡¿♤♡◇♧☆!#*$%^&+×?${_"|}()\\<>[/\],.-]/);
                // numbers = this.constrainInput(splitUnProcessed[0]).split(/[ +×÷=€£¥₩!#$%^&*{_"|}()-':;,?,`~\\<>[/\]°•○●□■▪¤¡¿]/);
                // numbers = this.constrainI nput(splitUnProcessed[0]).split(/[,.-]/);
              }else{
                numbers = this.constrainInput(splitUnProcessed[1]).split(/[ °•:;'~`÷€£¥₩○●□▪¤¡¿♤♡◇♧☆!#*$%^&+×?${_"|}()\\<>[/\],.-]/);
                // numbers = this.constrainInput(splitUnProcessed[1]).split(/[ +×÷=€£¥₩!#$%^&*{_"|}()-':;,?,`~\\<>[/\]°•○●□■▪¤¡¿]/);
                // numbers = this.constrainInput(splitUnProcessed[1]).split(/[,.-○●□▪¤¡¿♤♡◇♧☆]/);
              }
              // console.log("numbers===",numbers).replace(/[^0-9]+/ig, "")
              for(let j=0;j<numbers.length;j++){
                if(numbers[j].length > 0){
                  this.points.push({id:j+1,number:numbers[j],points:(this.reverseFormatStatus)?splitUnProcessed[1]:splitUnProcessed[0],typeStatus:true})
                  this.addNewItem({id:j+1,number:numbers[j],points:(this.reverseFormatStatus)?splitUnProcessed[1]:splitUnProcessed[0],typeStatus:true})
                }
                if(j == numbers.length - 1){

                  if(splitUnProcessed.length > 2){
                    for(let k=2;k<splitUnProcessed.length;k++){

                      this.points.push({id:j+1,number:splitUnProcessed[k],points:splitUnProcessed[k],typeStatus:null})
                      this.addNewItem({id:j+1,number:splitUnProcessed[k],points:splitUnProcessed[k],typeStatus:null})
                    }
                  }
                }
              }
            }else{

              // console.log("i=============",i)
              if(unProcessed[i] != ''){
                if(!unProcessed[i].includes("#")){

                  this.points.push({id:i+1,number:unProcessed[i],points:'',typeStatus:true})
                  this.addNewItem({id:i+1,number:unProcessed[i],points:'',typeStatus:true})
                }
              }

            }
          }
    }else{
      this.observables.showToastMessage({type:1,message:"Invalid this.format"})
    }

   // console.log("final points==",this.points)
  }

  addPoints(value){
    // console.log("valueee=",value)
    if(!this.myForm.valid){
      return
    }

    this.observables.addPoints(value.items)
  }

  addNewItem(value) {

    // console.log("values------",value)
     const controls = <FormArray>this.myForm.controls['items'];

       if(value.number.toString().length == 2){
         if(this.session == 2){
           let formGroup = this.fb.group({
             id:[value.id,Validators.compose([Validators.required])],
             number:[ value.number,Validators.compose([Validators.required, Validators.pattern(this.numberPatternForSessionTwo1)])],
             points:[value.points,Validators.compose([Validators.required,Validators.pattern(this.pointsPattern),Validators.maxLength(4)])],
             typeStatus:[value.typeStatus,Validators.compose([Validators.required])]
           });
           controls.push(formGroup);
         }else{
           let formGroup = this.fb.group({
             id:[value.id,Validators.compose([Validators.required])],
             number:[ value.number,Validators.compose([Validators.required, Validators.pattern(this.number1Pattern)])],
             points:[value.points,Validators.compose([Validators.required,Validators.pattern(this.pointsPattern),Validators.maxLength(4)])],
             typeStatus:[value.typeStatus,Validators.compose([Validators.required])]
           });
           controls.push(formGroup);
         }

       }else{

         let formGroup = this.fb.group({
           id:[value.id,Validators.compose([Validators.required])],
           number:[value.number,Validators.compose([Validators.required,Validators.pattern(this.numberPattern)])],
           points:[value.points,Validators.compose([Validators.required,Validators.pattern(this.pointsPattern),Validators.maxLength(4)])],
           typeStatus:[value.typeStatus,Validators.compose([Validators.required])]
         });

           controls.push(formGroup);
       }
     }

  addSpecialItem(value) {

    // console.log("values------",value)
     const controls = <FormArray>this.specialForm.controls['items'];
     let formGroup = this.fb.group({
      id:[value.id,Validators.compose([Validators.required])],
      number:[value.number,Validators.compose([Validators.required,Validators.pattern(this.numberPattern)])],
      points:[value.points,Validators.compose([Validators.required,Validators.pattern(this.pointsPattern),Validators.maxLength(4)])],
      gunule:[(value.hasOwnProperty('gunule'))?value.gunule:null],
      gunuleStatus:[(value.hasOwnProperty('gunuleStatus'))?1:0],
      ulta_gunule:[(value.hasOwnProperty('ulta_gunule'))?value.ulta_gunule:null],
      ultaGunuleStatus:[(value.hasOwnProperty('ultaGunuleStatus'))?1:0],
      sangam:[(value.hasOwnProperty('sangam'))?value.sangam:null],
      sangamStatus:[(value.hasOwnProperty('sangamStatus'))?1:0],
      // ab:[{value: (value.hasOwnProperty('operation'))?value.operation:null, disabled: true}],
      relative_number:[(value.hasOwnProperty('relative_number'))?value.relative_number:null]
    });
    controls.push(formGroup);
      //  if(value.number.toString().length == 2){
      //    if(this.session == 2){
      //      let formGroup = this.fb.group({
      //        id:[value.id,Validators.compose([Validators.required])],
      //        number:[ value.number,Validators.compose([Validators.required, Validators.pattern(this.numberPatternForSessionTwo1)])],
      //        points:[value.points,Validators.compose([Validators.required,Validators.pattern(this.pointsPattern),Validators.maxLength(4)])],
      //        typeStatus:[value.typeStatus,Validators.compose([Validators.required])]
      //      });
      //      controls.push(formGroup);
      //    }else{
      //      let formGroup = this.fb.group({
      //        id:[value.id,Validators.compose([Validators.required])],
      //        number:[ value.number,Validators.compose([Validators.required, Validators.pattern(this.number1Pattern)])],
      //        points:[value.points,Validators.compose([Validators.required,Validators.pattern(this.pointsPattern),Validators.maxLength(4)])],
      //        typeStatus:[value.typeStatus,Validators.compose([Validators.required])]
      //      });
      //      controls.push(formGroup);
      //    }

      //  }else{

      //    let formGroup = this.fb.group({
      //      id:[value.id,Validators.compose([Validators.required])],
      //      number:[value.number,Validators.compose([Validators.required,Validators.pattern(this.numberPattern)])],
      //      points:[value.points,Validators.compose([Validators.required,Validators.pattern(this.pointsPattern),Validators.maxLength(4)])],
      //      typeStatus:[value.typeStatus,Validators.compose([Validators.required])]
      //    });

      //      controls.push(formGroup);
      //  }
     }
   }
