import { Component, Input, OnInit } from '@angular/core';
import { ObservablesService } from '../observables.service'
import { ApiService } from '../api.service'
import { LoadingService } from '../loading.service'
@Component({
  selector: 'app-result-view',
  templateUrl: './result-view.component.html',
  styleUrls: ['./result-view.component.scss']
})
export class ResultViewComponent implements OnInit {
  winnigData:any=[];
  market:string='D';
  TotalWinningAmount:number=0;
  result1:any;
  result2:any;
  oc :any;
  op :any;
  cp :any;
  userLots:any=[]
  pointsArr:any=[]
  Math:any;
  date:any=null;

  days:any=[{
    name:"Mon",
    full:"Monday",
    value:1
  },{
    name:"Tue",
    full:"Tuesday",
    value:2
  },{
    name:"Wed",
    full:"Wednesday",
    value:3
  },{
    name:"Thu",
    full:"Thursday",
    value:4
  },{
    name:"Fri",
    full:"Friday",
    value:5
  },{
    name:"Sat",
    full:"Saturday",
    value:6
  }];

  userInfo:any=[]
  types:any=[8,9]
  selectedType:any
  selectedMarket:any="D"
  selectedDay:any=1
  tableData:any=[]
  weekResults:any=[]
  userId:any=''
  userStatus:boolean=false;
  netTotal:any = 0;
  _weekStatus = 1
  @Input() set weekStatus(weekStatus:number){
    this._weekStatus = weekStatus
    // console.log("Week Status ==",weekStatus)
    
  }
  constructor(private loading:LoadingService, private observables:ObservablesService, private api:ApiService) { }

  ngOnInit() {
    this.Math = Math;

    if(localStorage.getItem('userLoggedInStatus') == "1"){
     this.getLoggedUserInfo().then((userInfo:any)=>{
       this.userId=userInfo.id
       this.userStatus=false;

     })
   }else if(localStorage.getItem('adminLoggedInStatus') == "1"){
     this.userStatus=true;
   }

    this.observables.sendUserDataStatus.subscribe((userData:any)=>{
      // console.log("inside week result view",userData)
      this.userId=userData.data.id
      this.types = []
      this.types = userData.data.type.split(',');
      if(userData.weekResultViewStatus || userData.lastWeekResultViewStatus){

        this.onTypeChange({index:0})
      }
    })

    this.getSessionResult();
    // this.observables.marketChangedStatus.subscribe((res:any)=>{
    //   console.log("selected market in result view=",res)
    //   if(res.date){
    //     this.date = res.date;
    //   }
    //
    //   this.market = res.market;
    //   this.getWinningList(res.market,res.date);
    //   this.onLinkClick({index:1})
    // })

    this.observables.changeHeaderStatus.subscribe((response)=>{
      this.winnigData=[];
      this.market='D';
      this.TotalWinningAmount=0;
      this.result1='';
      this.result2='';
      this.oc='' ;
      this.op='' ;
      this.cp='' ;
      this.userLots=[]
      this.pointsArr=[]
      this.date=null;
    });
  }


  getLoggedUserInfo(){
    return new Promise((resolve,reject)=>{

      this.api.send("findDb",{
        table:"users",
        conditions:[{id:localStorage.getItem('userId')}]
      }).then((userInfo:any)=>{
        // console.log("userInfo in type wise report=",userInfo);
        this.userInfo = userInfo.data[0];
        this.types = this.userInfo.type.split(',');

        resolve(this.userInfo)// this.refreshTable(this.types[0]);
      }).catch(error=>{console.log("user info error",error)})
    })
  }


  onTypeChange(event){
    // console.log("selected type=",event)
    this.winnigData=[];
    this.tableData=[];
    this.pointsArr=[];
    this.userLots=[];
    if(event.index == 0){
      this.selectedType = this.types[0];
    }else{
      this.selectedType = this.types[1];
    }
    this.refreshTable(this.selectedType);

  }
  onDayChange(event){
    // console.log("selected day=",event)
    this.days.filter((item:any)=>{

      if((item.value - 1) == event.index){
        // console.log("selected day=",item.full)
        this.selectedDay = item.value;
        this.filterTableData(this.selectedType,this.selectedDay,this.selectedMarket);
      }
    })
  }

  onMarketChange(event){
    // console.log("selected market=",event)
    if(event.index == 0){

      this.selectedMarket = "D";
    }else{
      this.selectedMarket = "N";
    }

    this.filterTableData(this.selectedType,this.selectedDay,this.selectedMarket);
  }

    async filterTableData(type,day,market){
      // console.log("type=",type)
      // console.log("day=",day)
      // console.log("market=",market)
      // console.log("result Data=",this.weekResults)
      // console.log("winning Data=",this.winnigData)
      this.tableData = []
      this.userLots = []
      let matchedStatus = false;
      await this.weekResults.filter((item:any)=>{
        if(day==item.day && market == item.market){
          this.result1 = item.data.p1;
          this.result2 = item.data.p2;
          this.oc = item.data.oc;
          this.cp = item.data.cp;
          this.op = item.data.op;
          // console.log("result=",this.result1)
          matchedStatus = true;
          this.observables.setViewHeaderResultStatus.next({p1:item.data.p1,p2:item.data.p2,oc:item.data.oc,cp:item.data.cp,op:item.data.op});
        }else{
          if(!matchedStatus){

            this.observables.setViewHeaderResultStatus.next({p1:"",p2:"",oc:"",cp:"",op:""});
          }
        }
      })




      await this.winnigData.filter((item:any)=>{
        if(day==item.day && market == item.market && type == item.type){
          this.tableData.push(item);

        }
      })

      console.log("tableData result==",this.tableData)

      // console.log("table data==",this.tableData)
      this.TotalWinningAmount = 0;
      if(this.tableData.length > 0){
        this.tableData.filter(item=>{

          this.TotalWinningAmount = this.TotalWinningAmount + item.data.winning_amount;
        })
      }else{
        this.observables.showToastMessage({type:1,message:"Winning List is empty..!"});
      }

      // console.log("pointsArr Data=",this.pointsArr)
      await this.pointsArr.filter((item:any)=>{
        if(day==item.day && market == item.market && type == item.type){
          this.userLots.push(item);

        }
      })

      this.netTotal = 0;
      await this.userLots.filter((item:any)=>{
        // console.log('user lots=',item.lot.winning_amount)
        this.netTotal = this.netTotal + ((item.lot.winning_amount - item.lot.net_loosing_amount) - item.lot.loosing_amount);
      })
      // console.log("user lot data==",this.userLots)

    }

  onLinkClick(event){
    // console.log("event=",event)

    if(event.index==1){

    }else{
      //this.getWinningList(this.market,this.date);
    }
  }

  async getSessionResult(){


      this.api.send("findDb",{
        table:"results",
        conditions:[{"YEARWEEK(created, 1)":(this._weekStatus == 1?"$YEARWEEK(CURDATE(), 1)$":"$YEARWEEK(CURDATE() - INTERVAL 1 WEEK, 1)$")}]
      }).then((result:any)=>{
        // console.log("session result is=",result)

        this.weekResults = []
        for(let i=0;i<result.data.length;i++){
          this.weekResults.push({day:new Date(result.data[i].created.split("T")[0]).getDay(),date:result.data[i].created,market:result.data[i].market,data:result.data[i]});
        }

        if(!this.userStatus){
          this.onTypeChange({index:0})
        }
      }).catch(error=>{

        console.log(" session result error is=",error)
      })
  }

  async refreshTable(type){
    this.loading.dataLoading(true);
    await this.getWinningList(type)
    await this.getAllLots(type)
  }

  async getWinningList(type){
    this.winnigData = []
    let conditions:any;

      conditions = [{"C.user_id":this.userId,
      "L.type_no":type,"YEARWEEK(C.created, 1)":(this._weekStatus == 1?"$YEARWEEK(CURDATE(), 1)$":"$YEARWEEK(CURDATE() - INTERVAL 1 WEEK, 1)$"),
      "C.winning_amount IS NOT NULL":'',"P.consider_status":1}];


      // SELECT C.*,C.id as calculation_id, L.id as lot_id,L.type_no as type, L.lot_no as lot_no, L.day_night as market,P.points as points,P.number as number,P.point_no as point_no, P.gunule_point_id,P.gunule,P.ulta_gunule_point_id,P.ulta_gunule,P.sangam_point_id,P.sangam,P.adik,P.berij,P.gs_number, T.* FROM calculations as C left join lots as L on(L.id = C.lot_id) left join points as P on(P.id = C.point_id) left join type_variables as T on(T.id = C.type_variable_id) WHERE L.type_no = 9 and DATE(C.created) = CURDATE() and C.winning_amount IS NOT NULL and P.consider_status = 1 order by C.created ASC
    await this.api.send("findDb",{
      table:"calculations as C",
      fields:["C.*,C.id as calculation_id, L.id as lot_id,L.type_no as type, L.lot_no as lot_no, L.day_night as market,P.points as points,P.number as number,P.point_no as point_no, P.gunule_point_id,	P.gunule,	P.ulta_gunule_point_id,	P.ulta_gunule,	P.sangam_point_id,	P.sangam,	P.adik,	P.berij,P.gs_number, T.*"],
      joins : [
        {
          type: "left",
          table: "lots as L",
          conditions: ["L.id = C.lot_id"],
        },{
          type: "left",
          table:"points as P",
          conditions:["P.id = C.point_id"]
        },{
          type: "left",
          table:"type_variables as T",
          conditions:["T.id = C.type_variable_id"]
        }],
      conditions:conditions,
      order:"C.created ASC"
    }).then((result:any)=>{
      console.log("calculation result==",result)

      this.winnigData = []
      for(let i=0;i<result.data.length;i++){
        this.winnigData.push({day:new Date(result.data[i].created.split("T")[0]).getDay(),date:result.data[i].created,market:result.data[i].market,type:result.data[i].type,data:result.data[i]});
      }

   
      this.filterTableData(this.selectedType,this.selectedDay,this.selectedMarket);

    }).catch(err=>{console.log("err==",err)})
  }

  async getAllLots(type){
    this.pointsArr=[];
    let points = []
    let conditions:any;
      
      conditions = [{'L.user_id':this.userId,"L.type_no":type,"YEARWEEK(C.created, 1)":(this._weekStatus == 1?"$YEARWEEK(CURDATE(), 1)$":"$YEARWEEK(CURDATE() - INTERVAL 1 WEEK, 1)$")}];

      await this.api.send("findDb",{
        table:"calculations as C",
        fields:["L.*, L.id as lot_id,C.user_id,C.result_id,C.type_variable_id, SUM(C.winning_amount) as winning_amount, SUM(C.net_loosing_amount) as net_loosing_amount, SUM(C.loosing_amount) as loosing_amount"],
        joins:[{
          type:"left",
          table:"lots as L",
          conditions:["L.id = C.lot_id"]
        }],
        conditions:conditions,
        groupBy:"C.lot_id",
        order:"L.lot_no DESC"
      }).then((lotResponse:any)=>{
        // console.log("current lots=",lotResponse)
        if(lotResponse.data.length > 0){
        //  this.current_market_lots = lotResponse.data;
          this.getPoints(lotResponse.data)
        }else{
          this.userLots = [];
          this.observables.showToastMessage({type:1,message:"No Record Found Available..!"});
          // this.message = "No Record Found Available..!";
          this.loading.dataLoading(false);
        }
      }).catch(error=>{console.log("error while fetching current lots=",error);})
    //})

  }

  async getPoints(lotResponse){

    // return new Promise((resolve,reject)=>{
      this.pointsArr=[];
      let points = []
      for(let i=0; i<lotResponse.length;i++){

        await this.api.send("findDb",{
          table:"calculations as C",
          fields:["C.*,C.id as calculation_id, L.id as lot_id, L.lot_no as lot_no, L.day_night as market,P.session_no as session_no, P.points as points,P.number as number,P.point_no as point_no,  P.gunule_point_id as gunule_point_id,	P.gunule as gunule,	P.ulta_gunule_point_id as ulta_gunule_point_id,	P.ulta_gunule as ulta_gunule,	P.sangam_point_id as sangam_point_id,	P.sangam as sangam,	P.adik as adik,	P.berij as berij,P.gs_number as gs_number, T.*"],
          joins : [
            {
              type: "left",
              table: "lots as L",
              conditions: ["L.id = C.lot_id"],
            },{
              type: "left",
              table:"points as P",
              conditions:["P.id = C.point_id"]
            },{
              type: "left",
              table:"type_variables as T",
              conditions:["T.id = C.type_variable_id"]
            }],
          conditions:[{"C.lot_id":lotResponse[i].lot_id}]
        }).then((pointResult:any)=>{

          lotResponse[i].points = pointResult.data;
          // this.pointsArr.push({lot:lotResponse[i],points:pointResult.data});
          // console.log("final points==",this.pointsArr)
          this.pointsArr.push({day:new Date(lotResponse[i].created.split("T")[0]).getDay(),date:lotResponse[i].created,market:lotResponse[i].day_night,type:lotResponse[i].type_no, lot:lotResponse[i],points:points});
          if(i == lotResponse.length-1){
          //   resolve(this.pointsArr);

          this.filterTableData(this.selectedType,this.selectedDay,this.selectedMarket);
          this.loading.dataLoading(false);
          }
        }).catch(pointsErr=>{console.log("Error while getting points=",pointsErr);});
      }
    // })
  }

  getWeekNetTotal(){
    let total = 0;

    for(let i=0;i<this.pointsArr.length;i++){
      total= total + ((this.pointsArr[i].lot.winning_amount - this.pointsArr[i].lot.net_loosing_amount) - this.pointsArr[i].lot.loosing_amount);
      if(i==this.pointsArr.length - 1){
        return total;
      }
    }

  }

  sortLots(data){
    // console.log("sorting data-",data)
    return data.sort((a, b) => a.lot.lot_no - b.lot.lot_no);
  }
  sortPoints(data){
    // console.log("sorting data-",data)
    return data.sort((a, b) => a.point_no - b.point_no);
  }


}
