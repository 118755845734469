import { Component, QueryList, OnInit,ElementRef,ViewChildren,ViewChild } from '@angular/core';
import { ObservablesService } from '../observables.service';
import { ApiService } from '../api.service';
import { SocketService } from '../socket.service';
import { LoadingService } from '../loading.service';
import { KeyboardEventService } from '../keyboard-event.service';
import { SocketObservablesService } from '../socket-observables.service';
import { FormBuilder, Validators, FormGroup, ValidatorFn, AbstractControl, FormArray, FormControl } from '@angular/forms'
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material';
import {Observable} from 'rxjs';
import {Subject} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import * as _ from 'underscore';
import * as moment from 'moment'
import  { sameDateValidator, sameMarketValidator}  from '../same-date.validators'
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit  {
  pointsPattern:any = /^\d+(?:\.\d{1,2})?$/;
  number1Pattern:any = /^\d{1,2}$/;
  numberPattern:any = /^(?=\d{1,3}$)1*2*3*4*5*6*7*8*9*0*$/;
  numberPatternForSessionTwo1:any = /^(?=\d{4}$)1*2*3*4*5*6*7*8*9*0*$/;
  stringPattern:any = /^[a-z{1}A-Z_]*$/;
  setTimeStatus:boolean = false;
  setResultReport:boolean = false;
  totalPointsStatus:boolean = false;
  subAdminSummaryStatus:boolean = false;
  cashEntryStatus:boolean =false;
  loadingStatus:boolean =false;
  dailyReportStatus:boolean =false;
  cashDetailsStatus:boolean =false;
  digitsStatus:boolean =false;
  registerUserStatus:boolean = false;
  registerSubAdminStatus:boolean = false;
  typeVariableStatus:boolean = false;
  selectedType:any = 1;
  reportStatus:boolean = false;
  dashboardStatus:boolean = true;
  market:any;
  currentDateSelectedStatus:boolean = false;
  currentMarketResult:any;
  resultData:any=[];
  typeVariableList:any=[];
  calculatedResult:any=[];
  resultForm:FormGroup
  pointsForm:FormGroup
  myControl = new FormControl('', Validators.required);
  options: any=[];
  keyPadElements:any=[]
  ticketData:any=[]
  customerPoints:any=[]
  keyPadSaveElements:any=[]
  type:number=1;
  types:any=[]
  usersData:any=[]
  selectedUserData:any=null;
  selectedEditUserData:any=null;
  imageSubscribeVariable:any;
  imageSubscribeVariable1:any;
  userSubscribeVariable:any;
  userSubscribeVariable1:any;
  calculationSubscribeVariable:any;
  loadingSubscribeVariable:any;
  myForm: FormGroup;
  editForm: FormGroup;
  fileForm: FormGroup;
  fixedForm: FormGroup;
  pointsValueFixed:boolean =false;
  editPointsStatus:boolean =false;
  reportViewStatus:boolean =true;
  lotViewStatus:boolean =false;
  typeViewStatus:boolean =false;
  weekResultViewStatus:boolean =false;
  weekStatus:number = 1
  lastWeekResultViewStatus:boolean =false;
  tabViewStatus:boolean =false;
  singleDoubleStatus:boolean =false;
  typeVaribles:any=[]
  filteredOptions1: Observable<string[]>;
  filteredOptions: Observable<string[]>[] = [];
  viewImageObserver:any = new Subject<any>();
  viewImageObserver1:any = new Subject<any>();
  calculationObserver:any = new Subject<any>();
  userObserver:any = new Subject<any>();
  userObserver1:any = new Subject<any>();
  loadingObserver:any = new Subject<any>();

  currentRunnningMarket:any;
  formatedDate:any='';
  refreshListStatus:boolean = false;
  editListStatus:boolean = false;
  enableEdit:boolean = true;
  userPoints:any=[]
  totalAmount:number= 0;
  cash_limit:any;
  amountArr:any=[]
  initialValue:number = 0;
  userInitialId:any='';
  userInitialValue:any=0;
  initialPointValue:any=0;
  rate:any;
  selectedData:any;
  subscribeVariable:any;

  showCopyStateStatus = false
  showImageStatus = false
  importFileStatus = false
  fixedPointStatus = false
  iterationObserver:any = new Subject<any>();
  fileReaded:any;
  csvData :any = []
  csvJsonData :any = []
  headerRow :any = []
  filePattern: any = /^(.*?)\.(csv)$/;
  gunuleStatus:number = 0
  ultaGunuleStatus:number = 0
  sangamStatus:number = 0
  customerLots:any = []
  selectedLot:any = null
  editLotForm:FormGroup
  chartStatus:boolean = false
  @ViewChild('numberInput', { static: false }) numberInput:ElementRef;
  @ViewChild('user', { static: false }) user:ElementRef;
  @ViewChild('type', { static: false }) typeInput:ElementRef;
  @ViewChild('fileInput', { static: false }) fileInput:any;
  @ViewChildren('fixInput') rows:  QueryList<any>;
  constructor(private loading:LoadingService,private elementRef:ElementRef, private key:KeyboardEventService, private socketObservables:SocketObservablesService, private fb:FormBuilder, private observables:ObservablesService, private api:ApiService, private socket:SocketService) {
    this.myForm = this.fb.group({
      items: this.initItems()
    });
    this.fileForm = this.fb.group({
      items: this.fb.array([])
    });
    this.fixedForm = this.fb.group({
      items: this.fb.array([]),
      points:[null,Validators.compose([Validators.pattern(this.pointsPattern)])]
    });
    this.editForm = this.fb.group({
      items: this.initEditItems()
    });


    this.pointsForm = this.fb.group({
      rate:[null,Validators.compose([Validators.required])],
      type:[1,Validators.compose([Validators.required])],
      number:[null,Validators.compose([Validators.required,Validators.pattern(this.numberPattern)])],
      points:[null,Validators.compose([Validators.required,Validators.pattern(this.pointsPattern)])],
    });
  }

  initItems() {
   var formArray = this.fb.array([]);
   return formArray;
 }

 showCopyState(){
   this.showCopyStateStatus = true
 }

 closeCopyState(){
   this.showCopyStateStatus = false
 }
 showImage(){
   this.showImageStatus = true
 }

 closeImage(){
   this.showImageStatus = false
 }
 importFile(){
   this.fileForm.setControl('items', this.fb.array([]));
   this.importFileStatus = true
 }

 closeImportFile(){
   this.importFileStatus = false
 }
 showFixedPoint(){
   this.fixedForm.reset()
   this.fixedForm.setControl('items', this.fb.array([]));
   this.fixedPointStatus = true
   setTimeout(()=>{

     this.addBox()
   },200)

 }

 closeFixedPoint(status = false){
   this.fixedPointStatus = false
   if(status){

     this.observables.fixedCloseStatus.next()
   }
 }


 csv2Array(fileInput: any){
   this.fileForm.setControl('items', this.fb.array([]));
   //read file from input
   // console.log("\nFile data:",fileInput)
   this.fileReaded = fileInput.target.files[0];
   if (!(this.filePattern.test(this.fileReaded.name.toLowerCase()))) {
     this.fileReaded = '';
    alert('Please select csv file format');
  }else{
    let reader: FileReader = new FileReader();
    reader.readAsText(this.fileReaded);

    reader.onload = (e) => {
      let csv: any = reader.result;
      let allTextLines = csv.split(/\r|\n|\r/);
      let headers = allTextLines[0].split(',');
      let lines = [];

      for (let i = 0; i < allTextLines.length; i++) {
        // split content based on comma
        let data = allTextLines[i].split(',');
        if (data.length === headers.length) {
          let tarr = [];
          for (let j = 0; j < headers.length; j++) {
            tarr.push(data[j]);
          }

          // log each row to see output
          // console.log(tarr);
          lines.push(tarr);
        }
      }
      // all rows in the csv file
      // console.log("Calculated records:", lines);
        // this.csvData = lines;
        this.headerRow = lines.splice(0,1)[0];
        this.csvData = lines;
        this.csvJSON()
      }
  }
 }

 csvJSON(){
   this.csvJsonData = []

     for(var i=0;i<this.csvData.length;i++){

         var obj = {};
         var currentline=this.csvData[i];

         for(var j=0;j<this.headerRow.length;j++){
             obj[this.headerRow[j]] = currentline[j];
         }

         this.csvJsonData.push(obj);

     }

    //  console.log("csvJsonData records:", this.csvJsonData);
     this.prepareForm()

   }

   dateValidator(control:AbstractControl):{[key:string]:boolean} | null{

     if(control.value && (!moment(control.value).isSame(this.formatedDate)) ){
         return { 'dateMismatch': true };
     }
     return null;
   }

   marketValidator(control:AbstractControl):{[key:string]:boolean} | null{
     // let pattern = ''
     // if(this.market == 'D'){
     //   pattern = '^N$'
     // }else if(this.market == 'N'){
     //   pattern = '^D$'
     // }
     //
     if(control.value && control.value != this.market){
       return { 'marketMismatch': true };
     }
     return null;
   }
   prepareForm(){

     var arrayControl = this.fileForm.get('items') as FormArray;
     for(let i=0;i<this.csvJsonData.length;i++){


       if(this.csvJsonData[i].number.toString().length == 2){
         if(this.selectedType == 2){
           let formGroup = this.fb.group({
             date:[this.csvJsonData[i].created,sameDateValidator(this.formatedDate)],
             market:[this.csvJsonData[i].market,sameMarketValidator(this.market)],
             type_no:[this.csvJsonData[i].u_type_no,Validators.compose([Validators.required])],
             session_no:[this.csvJsonData[i].session_no,Validators.compose([Validators.required])],
             number:[ this.csvJsonData[i].number,Validators.compose([Validators.required, Validators.pattern(this.numberPatternForSessionTwo1)])],
             points:[this.csvJsonData[i].points,Validators.compose([Validators.required,Validators.pattern(this.pointsPattern),Validators.maxLength(4)])],
           });
           arrayControl.push(formGroup);
         }else{
           let formGroup = this.fb.group({
             date:[this.csvJsonData[i].created,sameDateValidator(this.formatedDate)],
             market:[this.csvJsonData[i].market,sameMarketValidator(this.market)],
             type_no:[this.csvJsonData[i].u_type_no,Validators.compose([Validators.required])],
             session_no:[this.csvJsonData[i].session_no,Validators.compose([Validators.required])],
             number:[ this.csvJsonData[i].number,Validators.compose([Validators.required, Validators.pattern(this.number1Pattern)])],
             points:[this.csvJsonData[i].points,Validators.compose([Validators.required,Validators.pattern(this.pointsPattern),Validators.maxLength(4)])],
           });
           arrayControl.push(formGroup);
         }

       }else{

         let formGroup = this.fb.group({
           date:[this.csvJsonData[i].created,sameDateValidator(this.formatedDate)],
           market:[this.csvJsonData[i].market,sameMarketValidator(this.market)],
           type_no:[this.csvJsonData[i].u_type_no,Validators.compose([Validators.required])],
           session_no:[this.csvJsonData[i].session_no,Validators.compose([Validators.required])],
           number:[this.csvJsonData[i].number,Validators.compose([Validators.required,Validators.pattern(this.numberPattern)])],
           points:[this.csvJsonData[i].points,Validators.compose([Validators.required,Validators.pattern(this.pointsPattern),Validators.maxLength(4)])],
         });

           arrayControl.push(formGroup);
       }
     }

    //  console.log("fileForm==",this.fileForm)

   }




   addCSVData(value){
     // console.log("valueee=",value)
     // return
     if(!this.fileForm.valid){
       return
     }

     // this.observables.addPoints(value.items)
     this.importFileStatus = false;
     this.addToList(value.items,true)
   }

   addBox(){
    var arrayControl = this.fixedForm.get('items') as FormArray;
     let formGroup = this.fb.group({
       number:[null,Validators.compose([Validators.required,Validators.pattern(this.numberPattern)])],
     });

       arrayControl.push(formGroup);

       setTimeout(()=>{
         var objDiv = document.getElementById("scroll-box");
         objDiv.scrollTop = objDiv.scrollHeight;
        this.rows.last.nativeElement.focus();
       },10)

       setTimeout(()=>{
         let fixedElement:any = this.elementRef.nativeElement.querySelectorAll('.fixed-focusable');
         let addBoxElement:any = this.elementRef.nativeElement.querySelectorAll('.add-box')[0];
         let saveButtonElement:any = this.elementRef.nativeElement.querySelectorAll('.save-box')[0];
         this.key.getFixedElements({focussableElement:fixedElement,addBoxElement:addBoxElement,saveButtonElement:saveButtonElement});
       },200)
   }

   onFixedNumberChange(number:any,numberControl){
       // console.log("entered number is =", numberControl);

       if(number.length==2 && this.type == 2){
         numberControl.setValidators([Validators.required, Validators.pattern(this.numberPatternForSessionTwo1)]);
       }else if(number.length == 2){
         numberControl.setValidators([Validators.required, Validators.pattern(this.number1Pattern)]);
       }else{

         numberControl.setValidators([Validators.required, Validators.pattern(this.numberPattern)]);
       }
       numberControl.updateValueAndValidity();
     }


     removeNumber(item,i){
       // console.log(item);
       (this.fixedForm.get("items") as FormArray).removeAt(i);
       // item.removeAt(i);
     }

   addFixedData(value){
     // console.log("valueee=",value)
     // return
     if(!this.fixedForm.valid){
       this.observables.showToastMessage({type:1,message:"Some Fields are empty.."})
       return
     }

     if(!value.points){
       this.observables.showToastMessage({type:1,message:"Please Enter Amount.."})
       return

     }

     let combinations:any=[];

       for(let j=0;j<value.items.length;j++){
         combinations.push({number:value.items[j].number,points:value.points,typeStatus:true});
       }
     // this.observables.addPoints(value.items)
     this.closeFixedPoint(true)
     this.addToList(combinations)
   }


 initEditItems(){
   var formArray = this.fb.array([]);
   return formArray;
 }

 addEditItem(value) {
   // console.log("values------",value)
    const controls = <FormArray>this.editForm.controls['items'];
      let formGroup = this.fb.group({
        lot_no:[(value.added_by == 1)?"A/"+value.lot_no:(value.added_by > 1)?value.added_by+"/"+value.lot_no:value.lot_no],
        point_id:[value.id],
        rate:[ {value: value.type_no, disabled: true},Validators.compose([Validators.required])],
        user_id:[ value.user_id,Validators.compose([Validators.required])],
        username:[value.username,Validators.compose([Validators.required])],
        type:[{value: value.session_no, disabled: true},Validators.compose([Validators.required])],
        number:[value.number],
        points:[value.points,Validators.compose([Validators.required,Validators.pattern(this.pointsPattern)])],
        initial_points:[value.points],
        edit_status:[value.edit_status],
        delete_status:[false],
        single_edit_status:[false],
      });
      controls.push(formGroup);
  }


 ManageNameControl(index: number) {
   var arrayControl = this.myForm.get('items') as FormArray;
   this.filteredOptions[index] = arrayControl.at(index).get('username').valueChanges
   .pipe(
     startWith(''),
     map(value => this._filter(value))
   );

 }





 addNewItem(value) {

  //  console.log("values------",value)
    const controls = <FormArray>this.myForm.controls['items'];

      if(value.number.toString().length == 2){
        let formGroup = this.fb.group({
          rate:[ {value: value.rate, disabled: true},Validators.compose([Validators.required])],
          user_id:[ value.user_id,Validators.compose([Validators.required])],
          username:[value.userData.username,Validators.compose([Validators.required])],
          type:[{value: value.type, disabled: true},Validators.compose([Validators.required])],
          number:[value.number],
          points:[value.points,Validators.compose([Validators.required,Validators.pattern(this.pointsPattern)])],
          messageStatus:[value.typeStatus],
          gunule:[(value.hasOwnProperty('gunule'))?value.gunule:null],
          gunuleStatus:[(value.hasOwnProperty('gunuleStatus'))?1:0],
          ulta_gunule:[(value.hasOwnProperty('ulta_gunule'))?value.ulta_gunule:null],
          ultaGunuleStatus:[(value.hasOwnProperty('ultaGunuleStatus'))?1:0],
          sangam:[(value.hasOwnProperty('sangam'))?value.sangam:null],
          sangamStatus:[(value.hasOwnProperty('sangamStatus'))?1:0],
          ab:[{value: (value.hasOwnProperty('operation'))?value.operation:null, disabled: true}],
          relative_number:[(value.hasOwnProperty('relative_number'))?value.relative_number:null]
        });

          controls.push(formGroup);
      }else{

        let formGroup = this.fb.group({
          rate:[ {value: value.rate, disabled: true},Validators.compose([Validators.required])],
          user_id:[ value.user_id,Validators.compose([Validators.required])],
          username:[value.userData.username,Validators.compose([Validators.required])],
          type:[{value: value.type, disabled: true},Validators.compose([Validators.required])],
          number:[value.number,Validators.compose([Validators.required,Validators.pattern(this.numberPattern)])],
          points:[value.points,Validators.compose([Validators.required,Validators.pattern(this.pointsPattern)])],
          messageStatus:[value.typeStatus],
          gunule:[(value.hasOwnProperty('gunule'))?value.gunule:null],
          gunuleStatus:[(value.hasOwnProperty('gunuleStatus'))?1:0],
          ulta_gunule:[(value.hasOwnProperty('ulta_gunule'))?value.ulta_gunule:null],
          ultaGunuleStatus:[(value.hasOwnProperty('ultaGunuleStatus'))?1:0],
          sangam:[(value.hasOwnProperty('sangam'))?value.sangam:null],
          sangamStatus:[(value.hasOwnProperty('sangamStatus'))?1:0],
          ab:[{value: (value.hasOwnProperty('operation'))?value.operation:null, disabled: true}],
          relative_number:[(value.hasOwnProperty('relative_number'))?value.relative_number:null]
        });

          controls.push(formGroup);
      }

      // console.log("form group==",formGroup)
    // Build the account Auto Complete values
    this.ManageNameControl(controls.length - 1);

  }


  goForEdit(){
    this.editPointsStatus = true;

    if(this.selectedUserData){
      // console.log("amount arr in if=",this.selectedUserData.id)
      const index = this.amountArr.findIndex(ticket => ticket.id === this.selectedUserData.id);
      this.amountArr.splice(index, 1, {id:this.selectedUserData.id,amount:this.totalAmount})
    }
    setTimeout(()=>{
      let focussableElement:any = this.elementRef.nativeElement.querySelectorAll('.e-focussable');
      for(let i=0;i<focussableElement.length;i++){
        focussableElement[i].removeAttribute('readonly');
        focussableElement[i].removeAttribute('disabled');

      }

      this.key.getEditElements({focussableElement:focussableElement,editStatus:true});

      let entryElement:any = this.elementRef.nativeElement.querySelectorAll('.focusable');
      for(let i=0;i<entryElement.length;i++){
        entryElement[i].setAttribute('readonly',true);
        entryElement[i].setAttribute('disabled',true);

      }
    },200)
  }

  allowEdit(){
    const control = (<FormArray>this.myForm.controls['items']);
    if(control.value.length > 0){
      this.observables.showToastMessage({type:1,message:'Please, Submit Entered points, to active edit panel.'});
    }else{

      this.enableEdit = false

      let editableElement:any=[]
      setTimeout(()=>{
        editableElement = this.elementRef.nativeElement.querySelectorAll('.edit-row');
        for(let i=0;i<editableElement.length;i++){
          editableElement[i].removeAttribute('readonly');
          editableElement[i].removeAttribute('disabled');

        }

      },200)
    }
  }

  closeEditPanel(){
    if(confirm("Are you sure to close Edit Panel?")){
      this.editListStatus = false;
      this.enableEdit = true;
    }
  }
  SaveEditedPoints(formData){
    const control = (<FormArray>this.editForm.controls['items']);
    if(control.valid){
      // console.log("edit form data=",formData)
      let deleteCount = 0;
      let i = 0;
      let deletePoints:number = 0;
      formData.items.filter((item:any)=>{
        i++
        if(item.delete_status){
          deleteCount++;
          deletePoints = deletePoints + item.points;
        }

        if(i == formData.items.length){
          this.updatePoints(deleteCount,deletePoints);
        }

      })
    }else{
      this.observables.showToastMessage({type:1,message:"Can\'t Edit, Invalid Entry Found..!"});
    }

    // console.log("Form data=",formValue)


  }

  updatePoints(deleteCount,deletePoints){

    let alertMessage;
    let newOcbAfterDelete:number;
    let totalOCB:number;
    let editOCB:number = 0
    let user_limit:any = 0
    let total_amount:any = 0
    let formValue = this.editForm.getRawValue()
    if(deleteCount > 0){
      // console.log("in if")
       totalOCB = this.getEditTypeTotal(true);
       newOcbAfterDelete = totalOCB - deletePoints;

       for(let i=0;i<formValue.items.length;i++){
         if(formValue.items[i].delete_status){
         }else{
           editOCB = editOCB + formValue.items[i].points;
         }
       }
    }else{
      // console.log("in else")
      for(let i=0;i<formValue.items.length;i++){

          editOCB = editOCB + parseFloat(formValue.items[i].points);

      }
    }

    // console.log("editOCB==",editOCB)
    //
    // console.log("formValue.items[0].user_id==",formValue.items)
    this.usersData.filter((item:any)=>{
      if(item.id == formValue.items[0].user_id){
        user_limit = item.limitation;

        this.getUserOCB(formValue.items[0].user_id).then((ocbRes:any)=>{

          total_amount = parseFloat(user_limit) + parseFloat(ocbRes[0].balance_cash);

          if(editOCB > total_amount){
            this.observables.showToastMessage({type:1,message:'Can\'t edit, Limit is overFlow...!'});
          }else{
            if(deleteCount > 0){
               alertMessage = "Are you sure you want to edit and delete "+deleteCount+" points..?";

            }else{
              alertMessage = "Are you sure you want to edit points..?";
            }
            if(confirm(alertMessage)){
              let formValue = this.editForm.getRawValue()
              let promises = [];
              let totalPoints:number = 0;
              for(let i=0;i<formValue.items.length;i++){

                if(formValue.items[i].delete_status){
                  promises[i] = this.api.send('deleteDb',{
                    "table":"points",
                    conditions:[{id:formValue.items[i].point_id}]
                  })
                }else{

                  totalPoints = totalPoints + parseFloat(formValue.items[i].points)
                  promises[i] = this.api.send('updateDb',{
                    "table":"points",
                    data:{
                      session_no:formValue.items[i].type,
                      number:formValue.items[i].number,
                      points:formValue.items[i].points,
                      single_edit_status:(formValue.items[i].single_edit_status)?1:0,
                      edit_status:1
                    },
                    conditions:[{id:formValue.items[i].point_id}]
                  })
                }
              }

              Promise.all(promises).then(res1=>{

                if(this.currentDateSelectedStatus){
                  let data:any;
                  if(ocbRes[0].status == 0 && this.market == "D"){
                    this.updateOCB(totalPoints,formValue,ocbRes)
                  }else if(ocbRes[0].status == 1 && this.market == "N"){
                    this.updateOCB(totalPoints,formValue,ocbRes)
                  }else{
                    this.updateAmountArr(totalPoints,formValue,ocbRes);
                  }
                }else{
                  this.updateAmountArr(totalPoints,formValue,ocbRes);
                }
              }).catch
            }
          }
        })
      }
    })
  }

  updateOCB(totalPoints,formValue,ocbRes){
    this.api.send("updateDb",{
      table:"user_ocb",
      data:{
        ocb:totalPoints
      },
      conditions:[{user_id:formValue.items[0].user_id}]
    }).then((cashRes:any)=>{
      if(cashRes.data.affectedRows){

        this.updateAmountArr(totalPoints,formValue,ocbRes);
      }
    })
  }

  updateAmountArr(totalPoints,formValue,ocbRes){
    const index = this.amountArr.findIndex(ticket => ticket.id === formValue.items[0].user_id);
    this.totalAmount = parseFloat(ocbRes[0].balance_cash) - totalPoints;
    this.amountArr.splice(index, 1, {id:formValue.items[0].user_id,amount:this.totalAmount})
    this.observables.showToastMessage({type:0,message:'Points Updated..'});

    setTimeout(()=>{
      let editableElement:any = this.elementRef.nativeElement.querySelectorAll('.edit-row');
      for(let i=0;i<editableElement.length;i++){
        editableElement[i].setAttribute('readonly',true);
        editableElement[i].setAttribute('disabled',true);
      }

      this.showCustomersEditData();
    },200)

    this.enableEdit = true;


  }



  ngOnInit() {
    //this.getCurrentMarket();
    this.observables.showPageStatus.subscribe((res:any)=>{

      // console.log('form data=',this.pointsForm)
        if(res.page != "Dashboard"){
          if(this.myForm.getRawValue()["items"].length > 0){
            confirm("Please Submit Data Entry or Clear Data Entry.")
            this.observables.setActiveTabStatus.next()
          }else{
            this.myControl.setValue(null);
            this.selectedUserData = null;
            this.pointsForm.patchValue({rate:null,type:1,number:null,points:null})
            this.myForm.setControl('items', this.fb.array([]));
            this.entryBlockFocused();
            this.customerPoints=[]
            this.ticketData=[]
            this.refreshListStatus = false;
            this.editListStatus = false;
            this.dashboardStatus = false;
            this.activePage(res.page,res.data);
          }
        }else{
          this.dashboardStatus = false;
          this.activePage(res.page,res.data);
        }


    })

    this.observables.savePointsStatus.subscribe(()=>{
      this.saveData();
    })
    this.observables.copyPointsStatus.subscribe(()=>{
      this.showCopyStateStatus = true;
    })
    this.observables.showImageStatus.subscribe(()=>{
      this.showImageStatus = true;
    })
    this.observables.showEditLotsStatus.subscribe(()=>{
      this.showLots()
    })
    this.observables.importFileStatus.subscribe(()=>{
      this.fileForm.setControl('items', this.fb.array([]));
      this.importFileStatus = true;
    })
    this.observables.fixedPointStatus.subscribe(()=>{
      this.showFixedPoint()
    })



    this.observables.showPointsStatus.subscribe(()=>{
      this.refreshListStatus = true;
      this.editListStatus = false;
      this.customerPoints = []
      this.showCustomersData();
    })
    this.observables.moreOptionsStatus.subscribe(()=>{
      if(this.selectedType == 1 && this.selectedUserData){
        this.moreOptions();
      }
    })
    // this.observables.moreSubOptionsStatus.subscribe((data)=>{
    //   console.log("pressed key==",data)
    //   if(this.tabViewStatus){

    //   }
    // })
    this.observables.moreOptionsCloseStatus.subscribe(()=>{
      this.closeOptions()
    })
    this.observables.fixedCloseStatus.subscribe(()=>{
      this.closeFixedPoint()
    })

    this.observables.editPointsStatus.subscribe(()=>{
      this.editListStatus = true;
      this.refreshListStatus = false;
      this.showCustomersEditData();
    })

    this.observables.enableEntryBlockStatus.subscribe(()=>{
      this.entryBlockFocused();
    })


    this.observables.submitResultStatus.subscribe((resultFormData)=>{
      this.SaveResult(resultFormData)
    })

    this.observables.addPointsStatus.subscribe((pointsData:any)=>{
      this.tabViewStatus = false;
      this.singleDoubleStatus = false;
      this.showCopyStateStatus = false;
      this.showImageStatus = false;
      // console.log("points Data===",pointsData)
      // for(let i =0;i<pointsData.length;i++){
      //   pointsData[i].rate = this.rate;
      //   pointsData[i].type = 1;
      //   // console.log("points in dashboard==",pointsData[i])
      //   this.addPoints(pointsData[i])
      // }
      this.addToList(pointsData);
    })

    this.observables.calculateSubCustomerResultStatus.subscribe((data)=>{
      this.api.send('calculation-sub-customer',{
        market:this.market,
        date:this.formatedDate,
        currentDateStatus:this.currentDateSelectedStatus
      }).then((result:any)=>{
        if(result.result){
          this.loading.dataLoading(false);
          this.observables.showToastMessage({type:0,message:result.message});
          this.observables.updateProgressBarStatus.next()
        }else{
          this.loading.dataLoading(false);
          this.observables.showToastMessage({type:1,message:'Sub Customer Calculation Failed, Try Again...!'});
        }
      })
    })
    this.observables.calculateCustomerResultStatus.subscribe((data)=>{
      this.api.send('calculation-customer',{
        market:this.market,
        date:this.formatedDate,
        currentDateStatus:this.currentDateSelectedStatus
      }).then((result:any)=>{
        if(result.result){
          this.loading.dataLoading(false);
          this.observables.showToastMessage({type:0,message:result.message});
          this.observables.updateProgressBarStatus.next()
        }else{
          this.loading.dataLoading(false);
          this.observables.showToastMessage({type:1,message:'Customer Calculation Failed, Try Again...!'});
        }
      })
    })
    this.observables.calculateResultStatus.subscribe((data)=>{
      if(data.editStatus){
        this.deleteExistingCalculatedData(data.result_id).then((deleteRes:any)=>{

            // this.calculateFinalResult()
            this.api.send('calculation',{
              market:this.market,
              date:this.formatedDate,
              currentDateStatus:this.currentDateSelectedStatus
            }).then((result:any)=>{
              // console.log("result==response=",result)
              if(result.result){
                this.loading.dataLoading(false);
                this.observables.showToastMessage({type:0,message:'Calculated Successfully'});
                this.observables.updateProgressBarStatus.next()
              }else{
                this.loading.dataLoading(false);
                this.observables.showToastMessage({type:1,message:'Calculation Failed, Try Again...!'});
              }
            }).catch((calErr:any)=>{console.log("calculation err=",calErr);this.loading.dataLoading(false);this.observables.showToastMessage({type:1,message:'Calculation Failed, Try Again...!'});})

        })
      }else{

        // this.calculateFinalResult()
        this.api.send('calculation',{
          market:this.market,
          date:this.formatedDate,
          currentDateStatus:this.currentDateSelectedStatus
        }).then((result:any)=>{
          // console.log("result==response=",result)
          if(result.result){
            this.loading.dataLoading(false);
            this.observables.showToastMessage({type:0,message:'Calculated Successfully'});
            this.observables.updateProgressBarStatus.next()
          }else{
            this.loading.dataLoading(false);
            this.observables.showToastMessage({type:1,message:'Calculation Failed, Try Again...!'});
          }
        }).catch((calErr:any)=>{console.log("calculation err=",calErr);this.loading.dataLoading(false);this.observables.showToastMessage({type:1,message:'Calculation Failed, Try Again...!'});})
      }
    })

    this.refreshClientList().then((res:any)=>{
      this.usersData = res;
      this.options = res;
      this.filteredOptions1 = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

        // console.log("filteredOptions1==",this.filteredOptions1)
      this.sendKeyElements();


    });

    // console.log("my control==",this.myControl)
    this.getTypeVaribles()
  }

  getTypeVaribles(){
    this.api.send("findDb",{
      table:'type_variables'
    }).then((res:any)=>{
      this.typeVaribles = res.data
    })
  }

  addToList(data,dynamicRate = false,){
    // console.log(data)
    // console.log("type==",this.type)
    let k = 0;
    this.subscribeVariable = this.iterationObserver.subscribe((k)=>{
      // console.log("count and filelist length=",k+" And "+data.length)
      if(k>=data.length){
        // console.log("\n\n--------------END of iteration------------------",k);
        this.subscribeVariable.unsubscribe();
      }else{
        // if(k+1 == data.length){
        //
        // }else{
        if(dynamicRate){
          // console.log("dynamic===",dynamicRate)
          if(data[k].type_no != this.types[0]){
            if(this.types.length > 1){
              if(data[k].type_no != this.types[1] && data[k].type_no != 0){
                this.observables.showToastMessage({type:1,message:"File Rates are Different..Please check..",time:5000})
                this.iterationObserver.next(data.length+1);
              }
            }else if(data[k].type_no != 0){
              this.observables.showToastMessage({type:1,message:"File Rates are Different..Please check..",time:5000})
              this.iterationObserver.next(data.length+1);
            }
          }

          if(data[k].type_no == 0){

            data[k].rate = this.rate;
          }else{

            data[k].rate = data[k].type_no;
            data[k].type =  data[k].session_no;
          }
        }else{
          data[k].rate = this.rate;
          if(data[k].typeStatus){
            data[k].type =  this.type
          }else{

            data[k].type = 1;
          }

          if(data[k].hasOwnProperty('gunuleStatus') || data[k].hasOwnProperty('ultaGunuleStatus') || data[k].hasOwnProperty('sangamStatus')){
            data[k].type = 2
          }else if(data[k].hasOwnProperty('operation')){
            data[k].type = 2
          }

        }

        // console.log("points in dashboard==",pointsData[i])
        this.addPoints(data[k])
        this.iterationObserver.next(k+1);

        // }
      }

    })

    this.iterationObserver.next(k);
  }

  showCustomersDataBtn(){
    this.refreshListStatus = true;
    this.editListStatus = false;
    this.customerPoints = []
    this.showCustomersData();
  }

  showCustomersEditDataBtn(){

    this.editListStatus = true;
    this.refreshListStatus = false;
    this.showCustomersEditData();
  }

  moreOptions(){
    this.tabViewStatus = true;
  }

  closeOptions(){
    this.tabViewStatus = false;
  }

  showSingleDoublePan(){
    this.singleDoubleStatus = true
  }

 
  closeSingleDouble(){
    this.singleDoubleStatus = false;
  }


  deleteExistingCalculatedData(result_id){
    return new Promise((resolve,reject)=>{
      this.api.send("deleteDb",{
        table:'calculations',
        conditions:[{result_id:result_id}]
      }).then((response:any)=>{
        // console.log("deleteResponse=",response)
        if(response.data.affectedRows){
          resolve(true)
        }
      })
    })
  }



  getUserTypes(user_id){
    return this.usersData.filter((item:any)=>{
      if(item.id == user_id){
        return item;
      }
    })
  }


  toggleChange(event){
      // console.log("toggle changed event=",event)
      if(event.checked){
        this.pointsValueFixed = event.checked;
      }else{
        this.pointsValueFixed = event.checked;
        this.pointsForm.patchValue({'points':null});
      }
    }

  clearAllPoints(){
    if(confirm("Please confirm to clear all Data?")){
      this.myControl.setValue(null);
      this.selectedUserData = null;
      this.pointsForm.patchValue({rate:null,type:1,number:null,points:null})
      this.amountArr=[];
      this.myForm.setControl('items', this.fb.array([]));
      this.entryBlockFocused();
      this.customerPoints=[]
      this.ticketData=[]
      this.refreshListStatus = false;
      this.editListStatus = false;

      this.gunuleStatus = 0;
      this.sangamStatus = 0;
      this.ultaGunuleStatus = 0;
    }
  }

  async showCustomersEditData(){
    if(this.selectedUserData){
      this.enableEdit = true;
      await this.api.send("findDb",{
        table:"lots as L",
        fields:["L.*, L.id as lot_id,U.*"],
        joins:[{
          type:"left",
          table:"users as U",
          conditions:["U.id=L.user_id"]
        }],
        conditions:[{'L.day_night':this.market,"DATE(L.created)":this.formatedDate,'L.user_id':this.selectedUserData.id}],
        groupBy:"L.id",
        order:"L.lot_no"
      }).then((lotResponse:any)=>{
        this.editForm.setControl('items', this.fb.array([]));
        if(lotResponse.data.length > 0){
          this.getUserPoints(lotResponse.data)
        }else{
          this.userPoints = [];
          this.observables.showToastMessage({type:1,message:"No Record Found Available..!"});
        }

      }).catch(error=>{console.log("error while fetching current lots=",error);})
    }else{
      this.observables.showToastMessage({type:1,message:'Please select the User'});
      this.user.nativeElement.focus();
    }

  }

  async getUserPoints(lotResponse){

      this.userPoints=[];
      for(let i=0; i<lotResponse.length;i++){
        await this.api.send("findDb",{
          table:"points",
          conditions:[{"lot_id":lotResponse[i].lot_id, consider_status:1}]
        }).then((pointResult:any)=>{
          // console.log("points data==",pointResult)

          pointResult.data.filter((item:any)=>{
            item.lot_no = lotResponse[i].lot_no;
            item.user_id = lotResponse[i].user_id;
            item.username = lotResponse[i].username;
            item.added_by = lotResponse[i].added_by;

            this.userPoints.push(item);
            this.addEditItem(item)
            // console.log("users data=",this.userPoints)
          })

        }).catch(pointsErr=>{console.log("Error while getting points=",pointsErr);});
      }
  }


  addPoints(value){
    // console.log("value==",value)

    if(this.enableEdit){

      let minusAmount = 0;

      minusAmount = this.totalAmount - parseFloat(value.points);

      if(minusAmount < (- this.cash_limit) && !value.hasOwnProperty('gunuleStatus') && !value.hasOwnProperty('ultaGunuleStatus') && !value.hasOwnProperty('sangamStatus')){
        this.observables.showToastMessage({type:1,message:'Can\'t add, Limit is overFlow...!'});
      }else{
        if(!value.hasOwnProperty('gunuleStatus') && !value.hasOwnProperty('ultaGunuleStatus') && !value.hasOwnProperty('sangamStatus')){

          this.totalAmount = minusAmount;
        }

        value.userData = this.selectedUserData;
        value.user_id = this.selectedUserData.id;
        if(!value.hasOwnProperty('gunuleStatus') && !value.hasOwnProperty('ultaGunuleStatus') && !value.hasOwnProperty('sangamStatus')){

          this.ticketData.push(value);
        }

        this.addNewItem(value);

        if(!value.hasOwnProperty('gunuleStatus') && !value.hasOwnProperty('ultaGunuleStatus') && !value.hasOwnProperty('sangamStatus')){
          const index = this.amountArr.findIndex(ticket => ticket.id === this.selectedUserData.id);
          this.amountArr.splice(index, 1, {id:this.selectedUserData.id,amount:this.totalAmount})
        }

        this.pointsForm.patchValue({'number':null});
        if(!this.pointsValueFixed){
          this.pointsForm.patchValue({'points':null});
        }

        const numberControl = this.pointsForm.get('number');
        numberControl.setValidators([Validators.required, Validators.pattern(this.numberPattern)]);
        numberControl.updateValueAndValidity();
        this.numberInput.nativeElement.focus()
        // this.key.sendTicketData(this.ticketData);
        // console.log("form data=",this.myForm)
      }
    }else{
      this.observables.showToastMessage({type:1,message:'Edit Panel is active, Please close or submit Edit Panel,then Try..!'});
    }

  }


  updateOCBStatus(status){
    return new Promise((resolve,reject)=>{

      this.api.send("updateDb",{
        table:"user_ocb",
        data:{
          status:status
        }
      }).then((updateRes:any)=>{
        if(updateRes.data.affectedRows){
          resolve(true);
        }else{
          resolve(false);
        }
      })
    })
  }

  continueSaveData(){
    this.loading.dataLoading(true);
    //using underscrore.js for grouping
    let result:any = _.groupBy(this.myForm.getRawValue()["items"], "user_id")

    let values = _.values(result);
    // console.log("\n\n\nresult of sort==",result)
    // console.log("\n\n\nvalues of sort==",values)


    let k = 0;
    this.imageSubscribeVariable = this.viewImageObserver.subscribe((k)=>{
        // console.log("count and filelist length=",k+" And "+values.length)
      if(k>=values.length){

          // console.log("\n\n--------------END of iteration------------------",k);
          this.imageSubscribeVariable.unsubscribe();

       }else{
         let rate:any = _.groupBy(values[k], "rate")
         let rateValues = _.values(rate);
         this.pushData(rateValues).then((result:any)=>{
           // console.log("data resolved==",result)
           if(result.status){
             if(result.ocb){

               if(k+1 == values.length){

                 this.myControl.setValue(null);
                 this.selectedUserData = null;
                 this.pointsForm.patchValue({rate:null,type:1,number:null,points:null})

                 this.myForm.setControl('items', this.fb.array([]));
                 this.entryBlockFocused();
                 this.customerPoints=[]
                 this.ticketData=[]
                 this.refreshListStatus = false;
                 this.editListStatus = false;
                 this.observables.showToastMessage({type:0,message:' Points are added Successfully..!'});
                 this.loading.dataLoading(false);
               }

               this.viewImageObserver.next(k+1);
             }else{
               this.observables.showToastMessage({type:1,message:'OCB updation Failed, contact Support'});

               this.loading.dataLoading(false);
               this.imageSubscribeVariable.unsubscribe();
             }
           }else{
             if(!result.lots && !result.points && result.all){
               this.observables.showToastMessage({type:1,message:'Lots & Points Not saved, Data currupted, try again or contact Support'});
               this.loading.dataLoading(false);
               this.imageSubscribeVariable.unsubscribe();
             }else if(result.lots && !result.points && result.all){
               this.observables.showToastMessage({type:1,message:'Some Points Not saved, Data currupted, try again or contact Support'});
               this.loading.dataLoading(false);
               this.imageSubscribeVariable.unsubscribe();
             }else if(result.lots && !result.points && !result.all){
               this.observables.showToastMessage({type:1,message:'Some Points Not saved, Data currupted, try again or contact Support'});
               this.loading.dataLoading(false);
               this.imageSubscribeVariable.unsubscribe();
             }
           }
         }).catch(err=>{
            console.log("error =",err)
            this.loading.dataLoading(false);
            this.imageSubscribeVariable.unsubscribe();
          })
        }

      })
      this.viewImageObserver.next(k);
  }

  getCurrentMarket(){
    return new Promise((resolve,reject)=>{
      this.api.send("findDb",{
        table:"session_times",
        conditions:[{"curtime() >":"$start_time$","curtime() <":"$end_time$", pride_status:environment.prideStatus}]
      }).then((sessionTimeRes:any)=>{
        // console.log("current==",sessionTimeRes)
        if(sessionTimeRes.data.length>0){
          this.currentRunnningMarket = sessionTimeRes.data[0].market;
          resolve({status:true,runningMarket:sessionTimeRes.data[0].market})
        }else{
          resolve({status:false});
        }
      }).catch(err=>{console.log("error while fetching session times",err)})

    })
  }

  saveData(){

    // console.log("ticket data array=",this.myForm.getRawValue())
    if(this.myForm.getRawValue()["items"].length <= 0){
      this.observables.showToastMessage({type:1,message:'No points added Yet..!'});
    }else if(this.myForm.valid){
      if(confirm("Please confirm to Add Lot..!")){
        // console.log("this.currentDateSelectedStatus=",this.currentDateSelectedStatus)
        if(this.currentDateSelectedStatus){
          // console.log("in if=",this.currentDateSelectedStatus)
          this.getCurrentMarket().then((runningMarketRes:any)=>{
            if(runningMarketRes.status){

              if(this.market == 'D' && runningMarketRes.runningMarket == 'N'){
                this.submitData(runningMarketRes.runningMarket,true);
              }else{
                if(this.market == 'D' && runningMarketRes.runningMarket == 'D'){

                  this.submitData(runningMarketRes.runningMarket,true);
                }else{

                  this.submitData(runningMarketRes.runningMarket,false);
                }
              }
            }else{
              this.continueSaveData()
            }
          })
        }else{
          // console.log("in else=",this.currentDateSelectedStatus)
          // console.log("current market== in else",this.currentDateSelectedStatus)
          this.continueSaveData()
        }

    }

    }else{
       this.observables.showToastMessage({type:1,message:'Points List is not Valid..!'});
    }

  }

  submitData(currentMarket,status){
    // console.log('currentMarket=',currentMarket)
    // console.log('market=',this.market)
    this.api.send("findDb",{
      fields:['id'],
      table:"points",
      conditions:[{"DATE(created)":this.formatedDate,market:this.market}],
      limit:1
    }).then((pointsRecord:any)=>{
      // console.log("aasdasd=",pointsRecord)
      if(pointsRecord.data.length <= 0){
        if(status && currentMarket=='N'){
          this.observables.showToastMessage({type:1,message:"Selected Market is Holiday.."})
        }else{
          // console.log("==1==")
          if(this.market == 'D'){
            // console.log("==2==")
            this.updateOCBStatus(0).then((updateRes:any)=>{
              if(updateRes){
                this.continueSaveData()
              }else{
                this.observables.showToastMessage({type:1,message:"OBC Status Not Updated..Contact Support"})
              }
            });
          }else{
            // console.log("==2==")
            this.updateOCBStatus(1).then((updateRes:any)=>{
              if(updateRes){
                this.continueSaveData()
              }else{
                this.observables.showToastMessage({type:1,message:"OBC Status Not Updated..Contact Support"})
              }
            });
          }
        }

      }else{
        // console.log("=1=")
        if(status){
          // console.log("=2=")
          this.updateOCBStatus(0).then((updateRes:any)=>{
            if(updateRes){
              this.continueSaveData()
            }else{
              this.observables.showToastMessage({type:1,message:"OBC Status Not Updated..Contact Support"})
            }
          });
        }else{
          // console.log("=3=")
          this.updateOCBStatus(1).then((updateRes:any)=>{
            if(updateRes){
              this.continueSaveData()
            }else{
              this.observables.showToastMessage({type:1,message:"OBC Status Not Updated..Contact Support"})
            }
          });
        }



      }

  })
  }


  pushData(rateValues){
    return new Promise((resolve,reject)=>{

    let k = 0;

    this.imageSubscribeVariable1 = this.viewImageObserver1.subscribe((k)=>{
        // console.log("count and filelist length=",k+" And "+rateValues.length)
      if(k>=rateValues.length){

          // console.log("\n\n--------------END of iteration------------------",k);
          this.imageSubscribeVariable1.unsubscribe();

       }else{
          // console.log("user id ==",rateValues[k]);
         this.gunuleStatus = 0
         this.ultaGunuleStatus = 0
         this.sangamStatus = 0
         this.getUserLotCount(rateValues[0][0].user_id).then((count:any)=>{
           // console.log("\n\n\nlots length res=",res)
             this.insertLot(rateValues[k],count).then((insertRes:any)=>{
               // console.log("\n\n\ninserted=",insertRes)
               if(insertRes.data.affectedRows){
                 this.socket.socket.emit("insertLot",{"data":"Lot","action":"insert"})
                 this.insertPoints(rateValues[k],insertRes.data.insertId).then((res:any)=>{
                   // console.log("\n\n\npoints data insert response=------------------------")
                   if(res.status){
                     if(res.ocb){
                       if(k+1 == rateValues.length){
                         // console.log("data insertion saved ")
                         // resolve(true);
                         resolve({status:true,lots:true,points:true,ocb:true})
                       }

                       this.viewImageObserver1.next(k+1);
                     }else{

                       this.imageSubscribeVariable1.unsubscribe();
                       resolve({status:true,lots:true,points:true,ocb:false})
                     }
                   }else{
                     this.imageSubscribeVariable1.unsubscribe();
                     resolve(res)
                   }
                 }).catch((errrr:any)=>{console.log("Error while insert lot",errrr); this.imageSubscribeVariable1.unsubscribe(); reject(errrr)})
               }
             }).catch((errr:any)=>{console.log("Error while insert lot",errr); this.imageSubscribeVariable1.unsubscribe(); reject(errr)})

         }).catch(err=>{console.log("error while getting lot count",err); this.imageSubscribeVariable1.unsubscribe(); reject(err)})
       }
     })

     this.viewImageObserver1.next(k);


    })
  }

  viewInfoSelected(data:any){
    //  console.log("view info =", data)

    if(this.market == data.selectedMarket && this.formatedDate == data.selectedDate){

    }else{

      this.editListStatus = false;
      this.refreshListStatus = false;
      this.market = data.selectedMarket;
      this.formatedDate = data.selectedDate;
      this.selectedData = {
        date:this.formatedDate,
        market:this.market
      }
      this.myControl.setValue(null);
      this.selectedUserData = null;
      this.pointsForm.patchValue({rate:null,type:1,number:null,points:null})
      this.amountArr=[];
      this.myForm.setControl('items', this.fb.array([]));
      this.entryBlockFocused();
      this.customerPoints=[]
      this.ticketData=[]

      var d1 = new Date();
      var d2 = new Date(this.formatedDate);
      if(d1.setHours(0,0,0,0) == d2.setHours(0,0,0,0)){
        this.currentDateSelectedStatus = true;
      }else{
        this.currentDateSelectedStatus = false;
      }
    }

  }

  getuserLotData(user_id){
    return new Promise((resolve,reject)=>{
      this.api.send("findDb",{
        table:"lots",
        conditions:[{'user_id':user_id,"DATE(created)":this.formatedDate,'day_night':this.market}]
      }).then((res:any)=>{
        resolve(res.data);
      })
    })
  }

  getUserLotCount(user_id){
    return new Promise((resolve,reject)=>{
      this.api.send("findDb",{
        table:"lots",
        fields:["COUNT(id) as lot_Count"],
        conditions:[{'user_id':user_id,"DATE(created)":this.formatedDate,'day_night':this.market}]
      }).then((countRes:any)=>{
        resolve(countRes.data[0].lot_Count);
      }).catch(err=>{console.log("error while getting lots count=",err); reject(err)})
    })
  }

  insertLot(rateValues,lotLength){
    let data:any;

    for(let i=0;i<rateValues.length;i++){

       if(rateValues[i].gunuleStatus){
         this.gunuleStatus = 1
       }

       if(rateValues[i].ultaGunuleStatus){
         this.ultaGunuleStatus = 1
       }

       if(rateValues[i].sangamStatus){
         this.sangamStatus = 1
       }


      //  console.log("gunuleStatus==",this.gunuleStatus)
      //  console.log("ultaGunuleStatus==",this.ultaGunuleStatus)
      //  console.log("sangamStatus==",this.sangamStatus)
    }
    if(this.currentDateSelectedStatus){
      data = {
        user_id:rateValues[0].user_id,
        lot_no:lotLength+1,
        type_no:rateValues[0].rate,
        day_night:this.market,
        added_by:1,
        gunule_status:this.gunuleStatus,
        sangam_status:this.sangamStatus,
        ulta_gunule_status:this.ultaGunuleStatus
      }
    }else{
      data = {
        user_id:rateValues[0].user_id,
        lot_no:lotLength+1,
        type_no:rateValues[0].rate,
        day_night:this.market,
        added_by:1,
        gunule_status:this.gunuleStatus,
        sangam_status:this.sangamStatus,
        ulta_gunule_status:this.ultaGunuleStatus,
        created:this.formatedDate
      }
    }

    return new Promise((resolve, reject)=>{
      this.api.send('insertDb',{
        table:"lots",
        data:data
      }).then((insertRes:any)=>{

        resolve(insertRes)
      }).catch((err:any)=>{console.log("Error while insert lots",err); reject(err)})
    })
  }

  onRateChange(rate){
    this.rate = rate;
  }

  getGunulePoint(points,type,ultaStatus){
    for(let i=0;i<this.typeVaribles.length;i++){
      if(this.typeVaribles[i].type == type){
        if(ultaStatus){

          return points * this.typeVaribles[i].single_digit
        }else{

          return points * this.typeVaribles[i].triple_digit
        }

      }
    }
  }

  insertPoints(pointsData,insertId){
    return new Promise((resolve,reject)=>{

        let promises = [];
        let totalPoints:number = 0;
        let actual_points:any = null;
        let gs_number:any = null
        for(let i=0;i<pointsData.length;i++){
          let data:any;
          if(pointsData[i].gunuleStatus != 1 && pointsData[i].ultaGunuleStatus != 1 && pointsData[i].sangamStatus != 1){

            totalPoints = totalPoints + parseFloat(pointsData[i].points);

          }else{
            // if(pointsData[i].gunuleStatus == 1){
            //   if(pointsData[i-1].gunule !== null){
            //     gs_number = pointsData[i-1].gunule
            //   }
            // }else if(pointsData[i].ultaGunuleStatus == 1){
            //     if(pointsData[i-1].ulta_gunule !== null){
            //       gs_number = pointsData[i-1].ulta_gunule
            //     }
            // }else if(pointsData[i].sangamStatus == 1){
            //     if(pointsData[i-1].sangam !== null){
            //       gs_number = pointsData[i-1].sangam
            //     }
            // }

            actual_points = this.getGunulePoint(pointsData[i].points,pointsData[i].rate,(pointsData[i].ultaGunuleStatus == 1)?true:false)
          }


          if(this.currentDateSelectedStatus){
            data = {
              "lot_id":insertId,
              "user_id":pointsData[i].user_id,
              "market":this.market,
              "point_no":i+1,
              "session_no":pointsData[i].type,
              "type_no":pointsData[i].rate,
              "number":pointsData[i].number,
              "points":pointsData[i].points,
              "actual_points":actual_points,
              "added_by":1,
              "copy_msg_status":(pointsData[i].messageStatus)?1:0,
              "gunule":pointsData[i].gunule,
              "gunule_point_id":(pointsData[i].gunuleStatus == 1)?((pointsData[i-1].gunule !== null)?i:null):null,
              "ulta_gunule":pointsData[i].ulta_gunule,
              "ulta_gunule_point_id":(pointsData[i].ultaGunuleStatus == 1)?((pointsData[i-1].ulta_gunule !== null)?i:null):null,
              "sangam":pointsData[i].sangam,
              "sangam_point_id":(pointsData[i].sangamStatus == 1)?((pointsData[i-1].sangam !== null)?i:null):null,
              "adik":(pointsData[i].ab == 'A')?pointsData[i].relative_number:null,
              "berij":(pointsData[i].ab == 'B')?pointsData[i].relative_number:null,
              "gs_number":pointsData[i].relative_number,
              "consider_status":(pointsData[i].gunuleStatus == 1 || pointsData[i].ultaGunuleStatus == 1 || pointsData[i].sangamStatus == 1 )?0:1,
              "loading_status":(pointsData[i].gunule !== null || pointsData[i].ultaGunule  !== null || pointsData[i].sangam !== null )?1:0
            }
          }else{
            data = {
              "lot_id":insertId,
              "point_no":i+1,
              "user_id":pointsData[i].user_id,
              "market":this.market,
              "session_no":pointsData[i].type,
              "type_no":pointsData[i].rate,
              "number":pointsData[i].number,
              "points":pointsData[i].points,
              "actual_points":actual_points,
              "added_by":1,
              "copy_msg_status":(pointsData[i].messageStatus)?1:0,
              "gunule":pointsData[i].gunule,
              "gunule_point_id":(pointsData[i].gunuleStatus == 1)?((pointsData[i-1].gunule !== null)?i:null):null,
              "ulta_gunule":pointsData[i].ulta_gunule,
              "ulta_gunule_point_id":(pointsData[i].ultaGunuleStatus == 1)?((pointsData[i-1].ulta_gunule !== null)?i:null):null,
              "sangam":pointsData[i].sangam,
              "sangam_point_id":(pointsData[i].sangamStatus == 1)?((pointsData[i-1].sangam !== null)?i:null):null,
              "adik":(pointsData[i].ab == 'A')?pointsData[i].relative_number:null,
              "berij":(pointsData[i].ab == 'B')?pointsData[i].relative_number:null,
              "gs_number":pointsData[i].relative_number,
              "consider_status":(pointsData[i].gunuleStatus == 1 || pointsData[i].ultaGunuleStatus == 1 || pointsData[i].sangamStatus == 1)?0:1,
              "loading_status":(pointsData[i].gunule !== null || pointsData[i].ultaGunule  !== null || pointsData[i].sangam !== null )?1:0,
              created:this.formatedDate
            }
          }

          // console.log("data==",data)
          promises[i]=this.api.send("insertDb",{
            table:"points",
            data:data
          })

        }

        Promise.all(promises).then(res=>{
          //  console.log("Response from promise",res);
          let totalRes:boolean = true;
          for(let j=0;j<res.length;j++){
            if(res[j].data.affectedRows){

            }else{
              totalRes = false;
            }

            if(j==res.length - 1){
              if(totalRes){
                let cashAmount:number = 0;

                if(this.currentDateSelectedStatus){
                  this.getUserOCB(pointsData[0].user_id).then((res1:any)=>{
                    // console.log("res1==",res1)
                    let data:any;
                      if(res1[0].status == 0 && this.market == "D"){
                        this.api.send("updateDb",{
                          table:"user_ocb",
                          data:{ocb:parseFloat(res1[0].ocb)+totalPoints},
                          conditions:[{user_id:pointsData[0].user_id}]
                        }).then((cashRes:any)=>{
                          if(cashRes.data.affectedRows){
                            // console.log("1--")
                            resolve({status:true,lots:true,points:true,ocb:true})
                            // resolve(res)
                            // this.updateLoadingData(pointsData).then((loadingRes:any)=>{
                            //
                            // }).catch(loadingErr=>{console.log("loading error=",loadingErr)})
                          }else{
                            // console.log("2--")
                            resolve({status:true,lots:true,points:true,ocb:false})
                            // this.updateLoadingData(pointsData).then((loadingRes:any)=>{
                            //
                            // }).catch(loadingErr=>{console.log("loading error=",loadingErr)})
                          }
                        })
                      }else if(res1[0].status == 1 && this.market == "N"){

                        this.api.send("updateDb",{
                          table:"user_ocb",
                          data:{ocb:parseFloat(res1[0].ocb)+totalPoints},
                          conditions:[{user_id:pointsData[0].user_id}]
                        }).then((cashRes:any)=>{
                          if(cashRes.data.affectedRows){
                            // console.log("3--")
                            // resolve(res)
                            resolve({status:true,lots:true,points:true,ocb:true})
                            // this.updateLoadingData(pointsData).then((loadingRes:any)=>{
                            // }).catch(loadingErr=>{console.log("loading error=",loadingErr)})
                          }else{
                            // console.log("4--")
                            resolve({status:true,lots:true,points:true,ocb:false})
                            // this.updateLoadingData(pointsData).then((loadingRes:any)=>{
                            // }).catch(loadingErr=>{console.log("loading error=",loadingErr)})
                          }
                        })
                      }else{
                        // console.log("5--")
                        // resolve(res)
                          resolve({status:true,lots:true,points:true,ocb:false})
                      }
                  })
                }else{
                  // console.log("6--")
                  resolve({status:true,lots:true,points:true,ocb:true})
                }
              }else{
                this.api.send('deleteDb',{
                  table:'points',
                  conditions:[{  "lot_id":insertId}]
                }).then((deleteRes:any)=>{

                  if(deleteRes.data.affectedRows){

                    this.api.send('deleteDb',{
                      table:'lots',
                      conditions:[{ id:insertId}]
                    }).then((deleteLotRes:any)=>{
                      if(deleteLotRes.data.affectedRows){

                        // this.observables.showToastMessage({type:1,message:'Lots & Points Not saved, Data currupted, try again or contact Support'});
                        // this.loading.dataLoading(false);
                        resolve({status:false,lots:false,points:false,ocb:false,all:true})
                      }else{
                        resolve({status:false,lots:true,points:false,ocb:false,all:true})
                        // this.observables.showToastMessage({type:1,message:'Some Points Not saved, Data currupted, try again or contact Support'});
                        // this.loading.dataLoading(false);
                      }
                    })
                  }else{
                    // this.observables.showToastMessage({type:1,message:'Some Points Not saved, Data currupted, try again or contact Support'});
                    // this.loading.dataLoading(false);
                    resolve({status:false,lots:true,points:false,ocb:false,all:false})
                  }
                })
              }
            }
          }


        }).catch

    })
  }

  // updateLoadingData(lotData){
  //   return new Promise((resolve,reject)=>{
  //
  //     let n = 0;
  //     this.loadingSubscribeVariable = this.loadingObserver.subscribe((n)=>{
  //       if(n>=lotData.length){
  //
  //           this.loadingSubscribeVariable.unsubscribe();
  //
  //        }else{
  //
  //          let data={
  //            "user_id":lotData[n].user_id,
  //            "market":this.market,
  //            "session_no":lotData[n].session,
  //            "type_no":lotData[n].type,
  //            "number":lotData[n].number,
  //            "points":lotData[n].points
  //          }
  //
  //          if(lotData[n].number.length == 1){
  //            this.addToLoading(data,'single_digits').then((resp:any)=>{
  //               if(resp){
  //                 if(n+1 == lotData.length){
  //                   // console.log("data insertion saved ")
  //                   this.loadingSubscribeVariable.unsubscribe();
  //                   resolve(true);
  //                 }
  //
  //                 this.loadingObserver.next(n+1);
  //               }else{
  //                 this.loadingSubscribeVariable.unsubscribe();
  //                 resolve(false);
  //               }
  //
  //            }).catch((error:any)=>{console.log("error single==",error);reject(error)});
  //          }else if(lotData[n].number.length == 2){
  //            this.addToLoading(data,'double_digits').then((resp:any)=>{
  //              if(resp){
  //                if(n+1 == lotData.length){
  //                  // console.log("data insertion saved ")
  //                  this.loadingSubscribeVariable.unsubscribe();
  //                  resolve(true);
  //                }
  //
  //                this.loadingObserver.next(n+1);
  //              }else{
  //                this.loadingSubscribeVariable.unsubscribe();
  //                resolve(false);
  //              }
  //            }).catch((error:any)=>{console.log("error double==",error);reject(error)});
  //          }else{
  //            this.addToLoading(data,'triple_digits').then((resp:any)=>{
  //              if(resp){
  //                if(n+1 == lotData.length){
  //                  // console.log("data insertion saved ")
  //                  this.loadingSubscribeVariable.unsubscribe();
  //                  resolve(true);
  //                }
  //
  //                this.loadingObserver.next(n+1);
  //              }else{
  //                this.loadingSubscribeVariable.unsubscribe();
  //                resolve(false);
  //              }
  //            }).catch((error:any)=>{console.log("error triple==",error);reject(error)});
  //          }
  //
  //       }
  //
  //     })
  //     this.loadingObserver.next(n);
  //
  //   })
  // }

  // addToLoading(data,tableName){
  //   return new Promise((resolve,reject)=>{
  //
  //     this.api.send('findDb',{
  //       table:"`INFORMATION_SCHEMA`.`COLUMNS`",
  //       conditions:[{'TABLE_SCHEMA':'game','TABLE_NAME':tableName}]
  //     }).then((columnRes:any)=>{
  //       console.log("column names=",columnRes)
  //       for(let i=0;i<columnRes.data.length;i++){
  //
  //         if("lab_"+data.number == columnRes.data[i].COLUMN_NAME){
  //           console.log("got number is =",columnRes.data[i].COLUMN_NAME)
  //           this.api.send("findDb",{
  //             table:tableName,
  //             conditions:[{
  //               user_id:data.user_id,
  //               market:data.market,
  //               "DATE(date)":"$CURDATE()$"
  //             }]
  //           }).then((columnDigitRes:any)=>{
  //             console.log("columnDigitRes==",columnDigitRes)
  //             if(columnDigitRes.data.length <= 0){
  //               this.api.send('insertDb',{
  //                 table:tableName,
  //                 data:{
  //                   user_id:data.user_id,
  //                   market:data.market,
  //                   [columnRes.data[i].COLUMN_NAME]:data.points
  //                 }
  //               }).then((insertRes:any)=>{
  //                 console.log("insert res:",insertRes)
  //                 resolve(true)
  //               })
  //
  //             }else{
  //
  //               // let points:any = columnDigitRes.data[0].digit
  //               let points:any = columnDigitRes.data[0][`${columnRes.data[i].COLUMN_NAME}`]
  //               this.api.send('updateDb',{
  //                 table:tableName,
  //                 data:{
  //                   [columnRes.data[i].COLUMN_NAME]:points + parseFloat(data.points)
  //                 },
  //                 conditions:[{user_id:data.user_id,market:data.market}]
  //               }).then((updateRes:any)=>{
  //                 console.log("update res:",updateRes)
  //                 resolve(true)
  //               })
  //             }
  //           })
  //         }
  //       }
  //     })
  //   })
  //
  // }

    showCustomersData(){
      this.customerPoints = []
      if(this.selectedUserData){
        this.api.send("findDb",{
          fields:["type_no as rate,session_no as type,number,points, user_id, true as saved_status"],
          table:"points",
          conditions:[{market:this.market, user_id:this.selectedUserData.id,"DATE(created)":"$CURDATE()$",consider_status:1}]
        }).then((res:any)=>{
          if(res.data.length > 0){
            this.customerPoints = res.data;
            if(this.ticketData.length > 0){
              let i=0;
              this.ticketData.filter((item:any)=>{
                i++;
                  if((item.user_id == this.selectedUserData.id) && item.rate == this.pointsForm.get('rate').value ){
                    item.saved_status = false
                    this.customerPoints.push(item);
                  }

                  if(i == this.ticketData.length){
                    if(this.customerPoints.length == 0){
                      this.observables.showToastMessage({type:1,message:'No points added for selected User..!'});
                      this.user.nativeElement.focus();
                      this.user.nativeElement.select();
                    }
                  }
              })
            }else{
              if(this.customerPoints.length == 0){
                this.observables.showToastMessage({type:1,message:'No points added yet..!'});
                this.user.nativeElement.focus();
                this.user.nativeElement.select();
              }
            }
          }else{
            if(this.ticketData.length > 0){
              let i=0;
              this.ticketData.filter((item:any)=>{
                i++;
                  if((item.user_id == this.selectedUserData.id) && item.rate == this.pointsForm.get('rate').value ){
                    this.customerPoints.push(item);
                  }

                  if(i == this.ticketData.length){
                    if(this.customerPoints.length == 0){
                      this.observables.showToastMessage({type:1,message:'No points added for selected User..!'});
                      this.user.nativeElement.focus();
                      this.user.nativeElement.select();
                    }
                  }
              })
            }else{
              this.observables.showToastMessage({type:1,message:'No points added yet..!'});
            }
          }
        })

      }else{
        this.observables.showToastMessage({type:1,message:'Please select the User'});
        this.user.nativeElement.focus();
      }
    }

  // showCustomersData(){
  //
  //   if(this.selectedUserData){
  //     if(this.ticketData.length > 0){
  //       let i=0;
  //       this.ticketData.filter((item:any)=>{
  //         i++;
  //           if((item.user_id == this.selectedUserData.id) && item.rate == this.pointsForm.get('rate').value ){
  //             this.customerPoints.push(item);
  //           }
  //
  //           if(i == this.ticketData.length){
  //             if(this.customerPoints.length == 0){
  //               this.observables.showToastMessage({type:1,message:'No points added for selected User..!'});
  //               this.user.nativeElement.focus();
  //               this.user.nativeElement.select();
  //             }
  //           }
  //       })
  //     }else{
  //       this.observables.showToastMessage({type:1,message:'No points added yet..!'});
  //     }
  //   }else{
  //     this.observables.showToastMessage({type:1,message:'Please select the User'});
  //     this.user.nativeElement.focus();
  //   }
  // }

  getTypeTotal(status,type=null){
    let total_points:any=0;

      this.customerPoints.filter((item:any)=>{
        if(item.type == type && !status){

          total_points= total_points + parseInt(item.points);
        }else if(status){
          total_points= total_points + parseInt(item.points);
        }
      })

      return total_points;

  }
  getEditTypeTotal(status,type=null){
    let total_points:any=0;
    // console.log("this.userPoints=",this.userPoints)
      this.userPoints.filter((item:any)=>{
        if(item.session_no == type && !status){

          total_points= total_points + parseInt(item.points);
        }else if(status){
          total_points= total_points + parseInt(item.points);
        }
      })

      return total_points;

  }
  getEditRateTotal(status,rate){
    let total_points:any=0;
    // console.log("this.userPoints=",this.userPoints)
      this.userPoints.filter((item:any)=>{
        if(item.type_no == rate && !status){

          total_points= total_points + parseInt(item.points);
        }else if(status){
          total_points= total_points + parseInt(item.points);
        }
      })

      return total_points;

  }

  onEditTypeChange(type,number,index){
    this.onPointsEditNumberChange(number,type,index)
  }

  onTypeChange(type){
    this.type = type;
    // console.log("types==",this.type)
    this.pointsForm.patchValue({number:null,points:null})
  }
  onTypeChanged(type){
    this.type = type;
    this.selectedType = type;
    this.pointsForm.patchValue({number:null,points:null})
  }

  onNumberChange(number:any){
      // console.log("entered number is =", number);
      const numberControl = this.pointsForm.get('number');


      if(number.length==2 && this.type == 2){
        numberControl.setValidators([Validators.required, Validators.pattern(this.numberPatternForSessionTwo1)]);
      }else if(number.length == 2){
        numberControl.setValidators([Validators.required, Validators.pattern(this.number1Pattern)]);
      }else{

        numberControl.setValidators([Validators.required, Validators.pattern(this.numberPattern)]);
      }
      numberControl.updateValueAndValidity();
    }


    onPointsEditNumberChange(number:any,type,index){

      const control = (<FormArray>this.editForm.controls['items']);
      let a = control.at(index) as FormGroup
      if(number.length==2 && type == 2){
        // console.log("inside 1");
        a.controls['number'].setValidators([Validators.required, Validators.pattern(this.numberPatternForSessionTwo1)])
      }else if(number.length == 2){
        // console.log("inside 2");
        a.controls['number'].setValidators([Validators.required, Validators.pattern(this.number1Pattern)])
        // a.controls['number'].clearValidators();
      }else{
        // console.log("inside 3");
        a.controls['number'].setValidators([Validators.required, Validators.pattern(this.numberPattern)]);
      }
      a.controls['number'].updateValueAndValidity();

      a.patchValue({single_edit_status:true})
    }

    onPointsEditPointsChange(number:any,type,index){
      const control = (<FormArray>this.editForm.controls['items']);
      let a = control.at(index) as FormGroup
      a.patchValue({single_edit_status:true})
    }


  onEditNumberChange(number:any,type,index){
      // console.log("entered for type "+type+" at index "+index+" edit number is =", number);
      // console.log("numner===",number)
      // console.log("typer=",type)
      const control = (<FormArray>this.myForm.controls['items']);
      let a = control.at(index) as FormGroup
      if(number.length==2 && type == 2){
        // console.log("inside 1");
        a.controls['number'].setValidators([Validators.required, Validators.pattern(this.numberPatternForSessionTwo1)])
      }else if(number.length == 2){
        // console.log("inside 2");
        a.controls['number'].setValidators([Validators.required, Validators.pattern(this.number1Pattern)])
        // a.controls['number'].clearValidators();
      }else{
        // console.log("inside 3");
        a.controls['number'].setValidators([Validators.required, Validators.pattern(this.numberPattern)]);
      }
      a.controls['number'].updateValueAndValidity();
    }


  sendKeyElements(){
    setTimeout(()=> {
      this.keyPadElements = this.elementRef.nativeElement.querySelectorAll('.focusable');
      this.keyPadSaveElements = this.elementRef.nativeElement.querySelectorAll('.saveLot');

      //console.log("all type elements=",this.keyPadElements);
      this.key.getAllElement({mainElements:this.keyPadElements,userStatus:false});
      this.key.getSaveElement({saveLotElements:this.keyPadSaveElements,userStatus:false});
      this.key.getEditElements({focussableElement:this.keyPadElements,editStatus:false});
    }, 10);
  }

  private _filter(value: string): string[] {
    if(value){

      const filterValue = value.toLowerCase();
      return this.options.filter(option => option.username.toLowerCase().includes(filterValue));
    }

  }

  onSelectionChanged(_event: any,userData) {

    if (_event.isUserInput) {

           if(this.selectedUserData){
             this.customerLots = []
             this.selectedLot = null

             // console.log("amount arr in if=",this.selectedUserData.id)
             const index = this.amountArr.findIndex(ticket => ticket.id === this.selectedUserData.id);
             this.amountArr.splice(index, 1, {id:this.selectedUserData.id,amount:this.totalAmount})
           }

           this.selectedUserData= userData;

           // console.log("selected user-",this.selectedUserData)
           this.types = userData.type.split(',');
           this.rate = this.types[0];
           this.pointsForm.patchValue({'rate':this.types[0]})
           this.cash_limit= userData.limitation;
           let foundStatus = false;
           let count = 0;
           if(this.amountArr.length > 0){
             this.amountArr.filter((item:any)=>{
               // console.log("inside if=",userData.id)
               // console.log("inside if=",item.id)
               count++;
               if(item.id == userData.id){
                 foundStatus = true;
                 this.totalAmount = item.amount;
               }

               if(count == this.amountArr.length){
                 if(!foundStatus){
                   // console.log("inside if======",item.id)
                   this.setUserOBC(userData.id)
                 }
               }
             })
           }else{
             // console.log("inside else")
             this.setUserOBC(userData.id)
           }

        }

  }

  getUserOCB(user_id){
    return new Promise((resolve,reject)=>{
      this.api.send("findDb",{
        table:"user_ocb",
        conditions:[{"user_id":user_id}]
      }).then((res:any)=>{
        resolve(res.data)
      })
    })
  }

  setUserOBC(user_id){
    return new Promise((resolve,reject)=>{
      this.totalAmount = 0;
      this.getUserOCB(user_id).then((ocbRes:any)=>{
        if(ocbRes.length > 0){
          this.totalAmount = parseFloat(ocbRes[0].balance_cash) - parseFloat(ocbRes[0].ocb);
          this.amountArr.push({id:user_id,amount:this.totalAmount})

        }else{
          this.amountArr.push({id:user_id,amount:0})
        }

        resolve(true)
      })
    })
  }


checkUser() {

    if (!this.selectedUserData || this.selectedUserData.username !== this.myControl.value) {
      this.myControl.setValue(null);
      this.selectedUserData = null;
    }
  }

  saveUserInitialValue(id,value){
    // console.log("edit user_id=",id)
    // console.log("edit value=",value)
    this.userInitialId = id;
    this.userInitialValue = value;
  }


  resetUserInitialValue(){
    this.userInitialId = '';
    this.userInitialValue = 0;
  }

  onEditSelectionChanged1(_event: any,userData,index,points) {

    if (_event.isUserInput) {
      if(this.userInitialId == userData.id){
        // console.log("same user selected")
      }else{
        const control = (<FormArray>this.myForm.controls['items']);
        const index1 = this.amountArr.findIndex(ticket => ticket.id === userData.id);
        // console.log("index=",index1)
        if(index1 < 0){
          this.setUserOBC(userData.id).then((res:any)=>{
            this.setValue(0,userData.id,index)
            this.selectedEditUserData= userData;

            control.at(index).patchValue({"user_id":userData.id})
            control.at(index).patchValue({"rate":userData.type.split(',')[0]})
            this.checkData1(points,userData.id,index,true)
          })
        }else{
          this.setValue(0,userData.id,index)
          this.selectedEditUserData= userData;

          control.at(index).patchValue({"user_id":userData.id})
          control.at(index).patchValue({"rate":userData.type.split(',')[0]})
          //....
          this.checkData1(points,userData.id,index,true)
        }

      }

    }
  }

  refreshClientList(){
    return new Promise((resolve,reject)=>{

      this.api.send("findDb",{
        table:"users",
        conditions:[{'role':'main-customer'}],
        order:"username ASC"
      }).then((res:any)=>{
        res.data.sort(function(a, b) {
        var nameA = a.username.toUpperCase(); // ignore upper and lowercase
        var nameB = b.username.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

          // names must be equal
          return 0;
        });

        resolve(res.data);
        });
    })


  }



  focused(selectedElement){
    // console.log("focused= ",selectedElement);
    if(selectedElement == 'user'){
      this.user.nativeElement.select();
      this.refreshClientList().then((res:any)=>{
        this.usersData = res;
        this.options = res;
        this.filteredOptions1 = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );

        this.sendKeyElements();


      });
    }

    this.key.selectedElement({elementName:selectedElement,status:true});
  }

  keyPressEevnt(e){
    // console.log("keydown event fired=",e)
    switch(e.keyCode) {
       // User pressed "left" or "right" arrow
       case 33:
       case 34:
       case 37:
       case 39:
           e.preventDefault();
       break;
    }
  }


  setValue(data,user_id,index){
    this.initialPointValue = data;
    this.initialValue = data;
    // console.log("form array =",this.amountArr)
  }


  checkData1(points,user_id,index,status=false){

    if(this.initialPointValue == points){

    }else{
      let user_limit:any = 0;
      this.usersData.filter((item:any)=>{
        if(item.id == user_id){
          user_limit = item.limitation;
          const control = (<FormArray>this.myForm.controls['items']);
          let a = control.at(index) as FormGroup
          let totalPoints:number = 0;
          // console.log("form control==",control)
          for(let i=0;i<control.controls.length;i++){
            if(control.controls[i].value.user_id == user_id){
              // console.log("points control="+i+"=",parseFloat(control.controls[i].value.points))
              totalPoints = totalPoints + parseFloat(control.controls[i].value.points);
            }

            if(i == control.controls.length -1){
              let amount:any = 0;

              this.getUserOCB(user_id).then((res:any)=>{
                // console.log("total cash res=",res)
                if(res.length > 0){
                  amount = parseFloat(res[0].balance_cash) - parseFloat(res[0].ocb)
                }

                let totalAmount:number = parseFloat(user_limit) + parseFloat(amount);
                // console.log("totalAmount=",totalAmount)
                if(totalPoints <= totalAmount){
                  // console.log("points else=",totalPoints)
                  a.controls['points'].setValidators([Validators.required, Validators.pattern(this.pointsPattern)])

                }else{
                  // console.log("points in else=",totalPoints)
                  this.observables.showToastMessage({type:1,message:'Can\'t add, Limit is overFlow...!'});
                  a.controls['points'].setValidators([Validators.required, Validators.pattern(this.stringPattern)])
                }

                a.controls['points'].updateValueAndValidity();
                let pointsArr:number=0
                let intialUserPoints:number=0;
                // console.log("initial user id ==",this.userInitialId)
                // console.log("status ==",status)
                for(let j=0;j<control.controls.length;j++){
                  if(control.controls[j].value.user_id == user_id && !control.controls[j].invalid){
                    pointsArr = pointsArr + parseFloat(control.controls[j].value.points);
                  }else if(control.controls[j].value.user_id == user_id && control.controls[j].invalid){

                  }else if((control.controls[j].value.user_id == this.userInitialId) && (!control.controls[j].invalid) && status){

                    intialUserPoints = intialUserPoints + parseFloat(control.controls[j].value.points);
                  }

                  if(j == control.controls.length - 1){
                    // console.log("pointsArr==",pointsArr)
                    this.totalAmount = amount - pointsArr;
                    const index1 = this.amountArr.findIndex(ticket => ticket.id === user_id);
                    this.amountArr.splice(index1, 1, {id:user_id,amount:this.totalAmount})
                    if(status){
                      let amount1:any = 0;

                      this.getUserOCB(this.userInitialId).then((res:any)=>{
                        // console.log("total cash res=",res)
                        if(res.data.length > 0){
                          amount1 = res[0].balance_cash - res[0].ocb
                        }

                        const index2 = this.amountArr.findIndex(ticket1 => ticket1.id === this.userInitialId);
                        this.amountArr.splice(index2, 1, {id:this.userInitialId,amount:parseFloat(amount1)-intialUserPoints})
                      })
                    }
                  }
                }

              })
            }
          }
        }
      })

    }
  }


  blured(selectedElement){
    // console.log("blured= ",selectedElement);
    this.key.selectedElement({elementName:selectedElement,status:false});
    if(selectedElement == 'user'){
      this.checkUser();
    }
  }

  entryBlockFocused(){
    this.editPointsStatus = false;
    if(this.selectedUserData){
      // console.log("amount arr in if=",this.selectedUserData.id)
      this.amountArr.filter((item:any)=>{
        if(item.id == this.selectedUserData.id){
          this.totalAmount = item.amount;
        }
      })
    }
    setTimeout(()=>{
      let focussableElement:any = this.elementRef.nativeElement.querySelectorAll('.e-focussable');
      for(let i=0;i<focussableElement.length;i++){

        focussableElement[i].setAttribute('readonly',true);
        focussableElement[i].setAttribute('disabled',true);
      }

      let entryElement:any = this.elementRef.nativeElement.querySelectorAll('.focusable');
      let editableElement:any = this.elementRef.nativeElement.querySelectorAll('.editable');
      for(let i=0;i<editableElement.length;i++){
        editableElement[i].removeAttribute('readonly');
        editableElement[i].removeAttribute('disabled');

      }

      this.key.getEditElements({focussableElement:entryElement,editStatus:false});
      this.pointsForm.patchValue({number:null,points:null})
    },200)

  }



  activePage(page,data){
    switch(page){
      case "dashboard":
      this.dashboardStatus = true;
       this.totalPointsStatus = false;
       this.subAdminSummaryStatus = false
       this.cashEntryStatus =false;
       this.loadingStatus =false;
       this.dailyReportStatus =false;
       this.cashDetailsStatus =false;
       this.digitsStatus =false;
       this.setTimeStatus = false;
       this.registerUserStatus = false;
       this.registerSubAdminStatus = false;
       this.typeVariableStatus = false;
       this.reportStatus = false;
       this.chartStatus = false;
       this.setResultReport = false
       this.refreshClientList().then((res:any)=>{
         this.usersData = res;
         this.options = res;
         this.filteredOptions1 = this.myControl.valueChanges
         .pipe(
           startWith(''),
           map(value => this._filter(value))
         );

         this.sendKeyElements();


       });
      break;
      case "userPoints":
      this.totalPointsStatus = true;
      this.subAdminSummaryStatus = false
      this.cashEntryStatus =false;
      this.loadingStatus =false;
      this.dailyReportStatus =false;
      this.cashDetailsStatus =false;
      this.digitsStatus =false;
       this.setTimeStatus = false;
       this.registerUserStatus = false;
       this.registerSubAdminStatus = false;
       this.typeVariableStatus = false;
       this.reportStatus = false;
       this.chartStatus = false;
       this.setResultReport = false
      break;
      case "subAdmin":
      this.subAdminSummaryStatus = true;
      this.cashEntryStatus =false;
      this.loadingStatus =false;
      this.dailyReportStatus =false;
      this.cashDetailsStatus =false;
      this.digitsStatus =false;
      this.totalPointsStatus = false;
       this.setTimeStatus = false;
       this.registerUserStatus = false;
       this.registerSubAdminStatus = false;
       this.typeVariableStatus = false;
       this.reportStatus = false;
       this.chartStatus = false;
       this.setResultReport = false
      break;
      case "cashEntry":
      this.cashEntryStatus =true;
      this.loadingStatus =false;
      this.dailyReportStatus =false;
      this.cashDetailsStatus =false;
      this.digitsStatus =false;
      this.subAdminSummaryStatus = false;
      this.totalPointsStatus = false;
       this.setTimeStatus = false;
       this.registerUserStatus = false;
       this.registerSubAdminStatus = false;
       this.typeVariableStatus = false;
       this.reportStatus = false;
       this.chartStatus = false;
       this.setResultReport = false
      break;
      case "loading":
      this.loadingStatus =true;
      this.dailyReportStatus =false;
      this.cashDetailsStatus =false;
      this.digitsStatus =false;
      this.cashEntryStatus =false;
      this.subAdminSummaryStatus = false;
      this.totalPointsStatus = false;
       this.setTimeStatus = false;
       this.registerUserStatus = false;
       this.registerSubAdminStatus = false;
       this.typeVariableStatus = false;
       this.reportStatus = false;
       this.chartStatus = false;
       this.setResultReport = false
      break;
      case "dailyReport":
      this.dailyReportStatus =true;
      this.loadingStatus =false;
      this.cashDetailsStatus =false;
      this.digitsStatus =false;
      this.cashEntryStatus =false;
      this.subAdminSummaryStatus = false;
      this.totalPointsStatus = false;
       this.setTimeStatus = false;
       this.registerUserStatus = false;
       this.registerSubAdminStatus = false;
       this.typeVariableStatus = false;
       this.reportStatus = false;
       this.chartStatus = false;
       this.setResultReport = false
      break;
      case "cashDetails":
      this.cashDetailsStatus =true;
      this.digitsStatus =false;
      this.dailyReportStatus =false;
      this.loadingStatus =false;
      this.cashEntryStatus =false;
      this.subAdminSummaryStatus = false;
      this.totalPointsStatus = false;
       this.setTimeStatus = false;
       this.registerUserStatus = false;
       this.registerSubAdminStatus = false;
       this.typeVariableStatus = false;
       this.reportStatus = false;
       this.chartStatus = false;
       this.setResultReport = false
      break;
      case "digits":
      this.digitsStatus =true;
      this.cashDetailsStatus =false;
      this.dailyReportStatus =false;
      this.loadingStatus =false;
      this.cashEntryStatus =false;
      this.subAdminSummaryStatus = false;
      this.totalPointsStatus = false;
       this.setTimeStatus = false;
       this.registerUserStatus = false;
       this.registerSubAdminStatus = false;
       this.typeVariableStatus = false;
       this.reportStatus = false;
       this.chartStatus = false;
       this.setResultReport = false
      break;
      case "setTime":
       this.setTimeStatus = true;
       this.totalPointsStatus = false;
       this.cashEntryStatus =false;
       this.loadingStatus =false;
       this.dailyReportStatus =false;
       this.cashDetailsStatus =false;
       this.digitsStatus =false;
       this.subAdminSummaryStatus = false
       this.registerUserStatus = false;
       this.registerSubAdminStatus = false;
       this.typeVariableStatus = false;
       this.reportStatus = false;
       this.chartStatus = false;
       this.setResultReport = false
      break;
      case "resultReport":
       this.setResultReport = true;
       this.setTimeStatus = false;
       this.totalPointsStatus = false;
       this.cashEntryStatus =false;
       this.loadingStatus =false;
       this.dailyReportStatus =false;
       this.cashDetailsStatus =false;
       this.digitsStatus =false;
       this.subAdminSummaryStatus = false
       this.registerUserStatus = false;
       this.registerSubAdminStatus = false;
       this.typeVariableStatus = false;
       this.reportStatus = false;
       this.chartStatus = false;
      break;
      case "registerUser":
      this.registerUserStatus = true;
      this.totalPointsStatus = false;
      this.subAdminSummaryStatus = false;
      this.cashEntryStatus =false;
      this.loadingStatus =false;
      this.dailyReportStatus =false;
      this.cashDetailsStatus =false;
      this.digitsStatus =false;
      this.typeVariableStatus = false;
      this.registerSubAdminStatus = false;
      this.setTimeStatus = false;
      this.reportStatus = false;
      this.chartStatus = false;
      this.setResultReport = false
      break;
      case "registerSubAdmin":
      this.registerSubAdminStatus = true;
      this.registerUserStatus = false;
      this.totalPointsStatus = false;
      this.subAdminSummaryStatus = false;
      this.cashEntryStatus =false;
      this.loadingStatus =false;
      this.dailyReportStatus =false;
      this.cashDetailsStatus =false;
      this.digitsStatus =false;
      this.typeVariableStatus = false;
      this.setTimeStatus = false;
      this.reportStatus = false;
      this.chartStatus = false;
      this.setResultReport = false
      break;
      case "typeVariable":
      this.registerUserStatus = false;
      this.registerSubAdminStatus = false;
      this.totalPointsStatus = false;
      this.subAdminSummaryStatus = false;
      this.cashEntryStatus =false;
      this.loadingStatus =false;
      this.dailyReportStatus =false;
      this.cashDetailsStatus =false;
      this.digitsStatus =false;
      this.typeVariableStatus = true;
      this.setTimeStatus = false;
      this.reportStatus = false;
      this.chartStatus = false;
      this.setResultReport = false
      break;
      case "chart":
      this.registerUserStatus = false;
      this.registerSubAdminStatus = false;
      this.totalPointsStatus = false;
      this.subAdminSummaryStatus = false;
      this.cashEntryStatus =false;
      this.loadingStatus =false;
      this.dailyReportStatus =false;
      this.cashDetailsStatus =false;
      this.digitsStatus =false;
      this.typeVariableStatus = false;
      this.setTimeStatus = false;
      this.reportStatus = false;
      this.chartStatus = true;
      this.setResultReport = false
      break;
      case "reports":
      this.registerUserStatus = false;
      this.registerSubAdminStatus = false;
      this.totalPointsStatus = false;
      this.subAdminSummaryStatus = false;
      this.cashEntryStatus =false;
      this.loadingStatus =false;
      this.dailyReportStatus =false;
      this.cashDetailsStatus =false;
      this.digitsStatus =false;
      this.typeVariableStatus = false;
      this.setTimeStatus = false;
      this.reportStatus = true;
      this.chartStatus = false;
      this.setResultReport = false
      this.lotViewStatus = data.lotViewStatus;
      this.typeViewStatus = data.typeViewStatus;
      this.reportViewStatus = data.reportViewStatus;
      this.weekResultViewStatus = data.weekResultViewStatus;
      this.lastWeekResultViewStatus = data.lastWeekResultViewStatus;
      if(this.lastWeekResultViewStatus){

        this.weekStatus = data.weekStatus
      }else{
        this.weekStatus = 1
      }

      break;
    }

    this.observables.setUserInHeader({status:this.reportStatus,activeStatus:this.reportViewStatus})
  }



  calculateResult(number,session){
    // console.log("entered key=",number)
    // console.log("session key=",session)
    number = number.toString();
    if(number.length == 3){
      let calculateValues:number = parseInt(number.charAt(0))+parseInt(number.charAt(1))+parseInt(number.charAt(2));
      if(session == 1){

        this.resultForm.patchValue({'opResult':parseInt(calculateValues.toString().split('').pop())})
       this.resultForm.patchValue({'ocResult':this.resultForm.get('opResult').value.toString()+this.resultForm.get('cpResult').value.toString()})
      }else{
        this.resultForm.patchValue({'cpResult':parseInt(calculateValues.toString().split('').pop())})
        this.resultForm.patchValue({'ocResult':this.resultForm.get('opResult').value.toString()+this.resultForm.get('cpResult').value.toString()})
      }
    }else{
      if(session == 1){

        this.resultForm.patchValue({'opResult':''})
        this.resultForm.patchValue({'ocResult':''})
      }else{
        this.resultForm.patchValue({'cpResult':''})
        this.resultForm.patchValue({'ocResult':''})
      }
    }
  }

  SaveResult(resultFormData){
    let data:any = resultFormData.value;
    this.resultData = resultFormData.resultData;
    // console.log("result data=",data)

    let apiType:string='';
    let dataValues:any;
    let session;
    if(data.p1!='' && data.p2!=''){
      session = 2
    }else if(data.p1 != '' && data.p2==''){
      session = 1
    }else if(data.p1=='' && data.p2!=''){
      session=2
    }
    // console.log('session==',session+" ==")
    // console.log('data==',data)
    // return
    let insertData:any;
    if(this.currentDateSelectedStatus){
      insertData = {
        market:this.market,
        session:session,
        p1:data.p1,
        p2:data.p2,
        oc:data.ocResult,
        op:data.opResult,
        cp:data.cpResult
      }
    }else{
      insertData = {
        market:this.market,
        session:session,
        p1:data.p1,
        p2:data.p2,
        oc:data.ocResult,
        op:data.opResult,
        cp:data.cpResult,
        created:this.formatedDate
      }
    }

      if(this.resultData.length <= 0){

        apiType = "insertDb";
        dataValues={
          table:"results",
          data:insertData
        }

    }else{

        apiType= "updateDb";
        dataValues={
          table:"results",
          data:insertData,
          conditions:[{id:this.resultData[0].id}]
        }
    }


    this.api.send(apiType,dataValues).then((result:any)=>{
      if(result.data.affectedRows){
        this.loading.dataLoading(false);
        this.observables.showToastMessage({type:0,message:"Result Updated.."})
        this.socket.socket.emit("updateResult",{"data":"Result","action":"update"})
        this.observables.updateProgressBarStatus.next()

        if(session  == 1){

          this.checkGunulePoints(session,data)
          this.checkUltaGunulePoints(session,data)
          this.checkSangamPoints(session,data)
          this.checkABPoints(session,data)
        }else{
          this.checkGunuleClosePoints(session,data)
          this.checkUltaGunuleClosePoints(session,data)
          this.checkSangamClosePoints(session,data)
          this.checkABClosePoints(session,data)
        }
      }else{
        this.loading.dataLoading(false);
        this.observables.showToastMessage({type:1,message:"Result not Updated, Try again.."})

      }
    }).catch(err=>{console.log("Result Not updated",err); this.loading.dataLoading(false);})
  }

  checkGunulePoints(session, data){
    return new Promise((resolve)=>{
      this.api.send("queryDb",{
        sql:`SELECT P1.id as P1_id, P2.id as P2_id, P1.number as P1_number, P2.number as P2_number, P1.consider_status as P1_consider_status, P2.consider_status as P2_consider_status  FROM points as P1 left join points as P2 on (P2.gunule_point_id = P1.point_no and P2.lot_id = P1.lot_id and P1.gunule = P2.number) WHERE P1.gunule IS NOT NULL AND P2.gunule_point_id IS NOT NULL AND P1.market = '${this.market}' and DATE(P1.created) = ${(this.currentDateSelectedStatus?"CURDATE()":`'${this.formatedDate}'`)}`,
      }).then((res:any)=>{
        if(res.data.length > 0){
          for(let i=0;i<res.data.length;i++){
            if(data.p1 == res.data[i].P1_number){
              this.api.send("queryDb",{
                sql:`UPDATE points set consider_status = 1, loading_status = 1, match_status = 0 where id = ${res.data[i].P2_id}`,
              }).then((res1:any)=>{
                  if(res1.data.affectedRows){
                    this.api.send("queryDb",{
                      sql:`UPDATE points set consider_status = 0, loading_status = 0, match_status = 0 where id = ${res.data[i].P1_id}`,
                    }).then((res2:any)=>{
                        if(res2.data.affectedRows){
                          resolve(true)
                        }else{
                          resolve(false)
                        }
                    })
                  }else{
                    resolve(false)
                  }
              })
            }else if(data.p1 != res.data[i].P1_number){
              this.api.send("queryDb",{
                sql:`UPDATE points set consider_status = 0, loading_status = 0, match_status = 0 where id = ${res.data[i].P2_id}`,
              }).then((res1:any)=>{
                  if(res1.data.affectedRows){
                    this.api.send("queryDb",{
                      sql:`UPDATE points set consider_status = 1, loading_status = 1, match_status = 0 where id = ${res.data[i].P1_id}`,
                    }).then((res2:any)=>{
                        if(res2.data.affectedRows){
                          resolve(true)
                        }else{
                          resolve(false)
                        }
                    })
                  }else{
                    resolve(false)
                  }
              })
            }else{
              resolve(true)
            }
          }
        }else{
          resolve(true)
        }
      })
    })
  }

  checkGunuleClosePoints(session,data){
    return new Promise((resolve)=>{

      this.api.send("queryDb",{
        sql:`SELECT P1.id as P1_id, P2.id as P2_id, P1.number as P1_number, P2.number as P2_number, P1.consider_status as P1_consider_status, P2.consider_status as P2_consider_status  FROM points as P1 left join points as P2 on (P2.gunule_point_id = P1.point_no and P2.lot_id = P1.lot_id and P1.gunule = P2.number) WHERE P1.gunule IS NOT NULL AND P2.gunule_point_id IS NOT NULL AND P1.market = '${this.market}' and DATE(P1.created) = ${(this.currentDateSelectedStatus?"CURDATE()":`'${this.formatedDate}'`)}`,
        // conditions:[{"gunule IS NOT NULL":"","market":this.market,"DATE(created)":(this.currentDateSelectedStatus?"$CURDATE()$":this.formatedDate)}]
      }).then((res:any)=>{
        if(res.data.length > 0){
          for(let i=0;i<res.data.length;i++){
            if(data.p1 == res.data[i].P1_number && data.cpResult != res.data[i].P2_number){
              this.api.send("queryDb",{
                sql:`UPDATE points set consider_status = 0, loading_status = 0, match_status = 0 where id = ${res.data[i].P2_id}`,
              }).then((res1:any)=>{
                if(res1.data.affectedRows){
                  this.api.send("queryDb",{
                    sql:`UPDATE points set consider_status = 1, loading_status = 1, match_status = 0 where id = ${res.data[i].P1_id}`,
                  }).then((res2:any)=>{
                    if(res2.data.affectedRows){
                        resolve(true)
                    }else{
                      resolve(false)
                    }
                  })
                }else{
                  resolve(false)
                }
              })
            }else if(data.p1 == res.data[i].P1_number && data.cpResult == res.data[i].P2_number){
              this.api.send("queryDb",{
                sql:`UPDATE points set consider_status = 1, loading_status = 1, match_status = 1 where id = ${res.data[i].P2_id}`,
              }).then((res1:any)=>{
                if(res1.data.affectedRows){
                  this.api.send("queryDb",{
                    sql:`UPDATE points set consider_status = 0, loading_status = 0, match_status = 1 where id = ${res.data[i].P1_id}`,
                  }).then((res2:any)=>{
                    if(res2.data.affectedRows){
                        resolve(true)
                    }else{
                      resolve(false)
                    }
                  })
                }else{
                  resolve(false)
                }
              })
            }else{
              resolve(true)
            }
          }
        }else{
          resolve(true)
        }
      })
    })
  }
  checkUltaGunulePoints(session, data){
    return new Promise((resolve)=>{
      this.api.send("queryDb",{
        sql:`SELECT P1.id as P1_id, P2.id as P2_id, P1.number as P1_number, P2.number as P2_number, P1.consider_status as P1_consider_status, P2.consider_status as P2_consider_status  FROM points as P1 left join points as P2 on (P2.ulta_gunule_point_id = P1.point_no and P2.lot_id = P1.lot_id and P1.ulta_gunule = P2.number) WHERE P1.ulta_gunule IS NOT NULL AND P2.ulta_gunule_point_id IS NOT NULL AND P1.market = '${this.market}' and DATE(P1.created) = ${(this.currentDateSelectedStatus?"CURDATE()":`'${this.formatedDate}'`)}`,
      }).then((res:any)=>{
        if(res.data.length > 0){
          for(let i=0;i<res.data.length;i++){
            if(data.opResult == res.data[i].P1_number){
              this.api.send("queryDb",{
                sql:`UPDATE points set consider_status = 1, loading_status = 1, match_status = 0 where id = ${res.data[i].P2_id}`,
              }).then((res1:any)=>{
                  if(res1.data.affectedRows){
                    this.api.send("queryDb",{
                      sql:`UPDATE points set consider_status = 0, loading_status = 0, match_status = 0 where id = ${res.data[i].P1_id}`,
                    }).then((res2:any)=>{
                        if(res2.data.affectedRows){
                          resolve(true)
                        }else{
                          resolve(false)
                        }
                    })
                  }else{
                    resolve(false)
                  }
              })
            }else if(data.opResult != res.data[i].P1_number){
              this.api.send("queryDb",{
                sql:`UPDATE points set consider_status = 0, loading_status = 0, match_status = 0 where id = ${res.data[i].P2_id}`,
              }).then((res1:any)=>{
                  if(res1.data.affectedRows){
                    this.api.send("queryDb",{
                      sql:`UPDATE points set consider_status = 1, loading_status = 1, match_status = 0 where id = ${res.data[i].P1_id}`,
                    }).then((res2:any)=>{
                        if(res2.data.affectedRows){
                          resolve(true)
                        }else{
                          resolve(false)
                        }
                    })
                  }else{
                    resolve(false)
                  }
              })
            }else{
              resolve(true)
            }
          }
        }else{
          resolve(true)
        }
      })
    })
  }

  checkUltaGunuleClosePoints(session,data){
    return new Promise((resolve)=>{

      this.api.send("queryDb",{
        sql:`SELECT P1.id as P1_id, P2.id as P2_id, P1.number as P1_number, P2.number as P2_number, P1.consider_status as P1_consider_status, P2.consider_status as P2_consider_status  FROM points as P1 left join points as P2 on (P2.ulta_gunule_point_id = P1.point_no and P2.lot_id = P1.lot_id and P1.ulta_gunule = P2.number) WHERE P1.ulta_gunule IS NOT NULL AND P2.ulta_gunule_point_id IS NOT NULL AND P1.market = '${this.market}' and DATE(P1.created) = ${(this.currentDateSelectedStatus?"CURDATE()":`'${this.formatedDate}'`)}`,
        // conditions:[{"gunule IS NOT NULL":"","market":this.market,"DATE(created)":(this.currentDateSelectedStatus?"$CURDATE()$":this.formatedDate)}]
      }).then((res:any)=>{
        if(res.data.length > 0){
          for(let i=0;i<res.data.length;i++){
            if(data.opResult == res.data[i].P1_number && data.p2 != res.data[i].P2_number){
              this.api.send("queryDb",{
                sql:`UPDATE points set consider_status = 0, loading_status = 0, match_status = 0 where id = ${res.data[i].P2_id}`,
              }).then((res1:any)=>{
                if(res1.data.affectedRows){
                  this.api.send("queryDb",{
                    sql:`UPDATE points set consider_status = 1, loading_status = 1, match_status = 0 where id = ${res.data[i].P1_id}`,
                  }).then((res2:any)=>{
                    if(res2.data.affectedRows){
                        resolve(true)
                    }else{
                      resolve(false)
                    }
                  })
                }else{
                  resolve(false)
                }
              })

            // }else if(data.p1 == res.data[i].P1_number && data.cpResult == res.data[i].P2_number){
            }else if(data.opResult == res.data[i].P1_number && data.p2 == res.data[i].P2_number){
              this.api.send("queryDb",{
                sql:`UPDATE points set consider_status = 1, loading_status = 1, match_status = 1 where id = ${res.data[i].P2_id}`,
              }).then((res1:any)=>{
                if(res1.data.affectedRows){
                  this.api.send("queryDb",{
                    sql:`UPDATE points set consider_status = 0, loading_status = 0, match_status = 1 where id = ${res.data[i].P1_id}`,
                  }).then((res2:any)=>{
                    if(res2.data.affectedRows){
                        resolve(true)
                    }else{
                      resolve(false)
                    }
                  })
                }else{
                  resolve(false)
                }
              })
            }else{
              resolve(true)
            }
          }
        }else{
          resolve(true)
        }
      })
    })
  }
  checkSangamPoints(session, data){
    return new Promise((resolve)=>{
      this.api.send("queryDb",{
        sql:`SELECT P1.id as P1_id, P2.id as P2_id, P1.number as P1_number, P2.number as P2_number, P1.consider_status as P1_consider_status, P2.consider_status as P2_consider_status  FROM points as P1 left join points as P2 on (P2.sangam_point_id = P1.point_no and P2.lot_id = P1.lot_id and P1.sangam = P2.number) WHERE P1.sangam IS NOT NULL AND P2.sangam_point_id IS NOT NULL AND P1.market = '${this.market}' and DATE(P1.created) = ${(this.currentDateSelectedStatus?"CURDATE()":`'${this.formatedDate}'`)}`,
      }).then((res:any)=>{
        if(res.data.length > 0){
          for(let i=0;i<res.data.length;i++){
            // console.log("data==",data)
            // console.log("P1_number==",res.data[i].P1_number)
            if(data.p1 == res.data[i].P1_number){
              this.api.send("queryDb",{
                sql:`UPDATE points set consider_status = 1, loading_status = 1, match_status = 0, where id = ${res.data[i].P2_id}`,
              }).then((res1:any)=>{
                  if(res1.data.affectedRows){
                    this.api.send("queryDb",{
                      sql:`UPDATE points set consider_status = 0, loading_status = 0, match_status = 0 where id = ${res.data[i].P1_id}`,
                    }).then((res2:any)=>{
                        if(res2.data.affectedRows){
                          resolve(true)
                        }else{
                          resolve(false)
                        }
                    })
                  }else{
                    resolve(false)
                  }
              })
            }else if(data.p1 != res.data[i].P1_number){
              this.api.send("queryDb",{
                sql:`UPDATE points set consider_status = 0, loading_status = 0, match_status = 0, where id = ${res.data[i].P2_id}`,
              }).then((res1:any)=>{
                  if(res1.data.affectedRows){
                    this.api.send("queryDb",{
                      sql:`UPDATE points set consider_status = 1, loading_status = 1, match_status = 0 where id = ${res.data[i].P1_id}`,
                    }).then((res2:any)=>{
                        if(res2.data.affectedRows){
                          resolve(true)
                        }else{
                          resolve(false)
                        }
                    })
                  }else{
                    resolve(false)
                  }
              })
            }else{
              resolve(true)
            }
          }
        }else{
          resolve(true)
        }
      })
    })
  }
  checkABPoints(session, data){
    return new Promise((resolve)=>{
      this.api.send("queryDb",{
        sql:`SELECT * from points WHERE (adik IS NOT NULL OR berij IS NOT NULL) AND market = '${this.market}' and DATE(created) = ${(this.currentDateSelectedStatus?"CURDATE()":`'${this.formatedDate}'`)}`,
      }).then((res:any)=>{
        if(res.data.length > 0){
          for(let i=0;i<res.data.length;i++){

            if(res.data[i].adik !== null){
              let adik = parseInt(res.data[i].adik) + parseInt(data.opResult)
              this.api.send("queryDb",{
                sql:`UPDATE points set number = ${( adik > 9)? adik.toString().slice(-1):adik},consider_status = 1, loading_status = 1 where id = ${res.data[i].id}`,
              }).then((res1:any)=>{
                if(res1.data.affectedRows){
                  resolve(true)
                }else{
                  resolve(false)
                }
              })

            }else if(res.data[i].berij !== null){
              // let berij = (parseInt(data.opResult) < parseInt(res.data[i].berij))?((parseInt(data.opResult) + 10) - parseInt(res.data[i].berij)):parseInt(data.opResult) - parseInt(res.data[i].berij)
              let berij = (parseInt(data.opResult) <= parseInt(res.data[i].berij))?(parseInt(res.data[i].berij) - parseInt(data.opResult)):(parseInt(res.data[i].berij) + 10) - parseInt(data.opResult)
              this.api.send("queryDb",{
                sql:`UPDATE points set number = ${ berij },consider_status = 1, loading_status = 1 where id = ${res.data[i].id}`,
              }).then((res1:any)=>{
                if(res1.data.affectedRows){
                  resolve(true)
                }else{
                  resolve(false)
                }
              })
            }
          }

        }else{
          resolve(true)
        }
      })
    })
  }
  checkABClosePoints(session, data){
    return new Promise((resolve)=>{
      this.api.send("queryDb",{
        sql:`SELECT * from points WHERE (adik IS NOT NULL OR berij IS NOT NULL) AND market = '${this.market}' and DATE(created) = ${(this.currentDateSelectedStatus?"CURDATE()":`'${this.formatedDate}'`)}`,
      }).then((res:any)=>{
        if(res.data.length > 0){
          for(let i=0;i<res.data.length;i++){

            if(res.data[i].adik !== null){
              if(data.cpResult != res.data[i].number){

                this.api.send("queryDb",{
                  sql:`UPDATE points set consider_status = 1, loading_status = 1, adhik_consider_status = 1, match_status= 0 where id = ${res.data[i].id}`,
                }).then((res1:any)=>{
                  if(res1.data.affectedRows){
                    resolve(true)
                  }else{
                    resolve(false)
                  }
                })
              }else if(data.cpResult == res.data[i].number){
                this.api.send("queryDb",{
                  sql:`UPDATE points set consider_status = 1, loading_status = 1, adhik_consider_status = 1, match_status= 1 where id = ${res.data[i].id}`,
                }).then((res1:any)=>{
                  if(res1.data.affectedRows){
                    resolve(true)
                  }else{
                    resolve(false)
                  }
                })
              }else{
                resolve(false)
              }

            }else if(res.data[i].berij !== null){
              if(data.cpResult != res.data[i].number){

                this.api.send("queryDb",{
                  sql:`UPDATE points set consider_status = 1, loading_status = 1, berij_consider_status = 1, match_status= 0 where id = ${res.data[i].id}`,
                }).then((res1:any)=>{
                  if(res1.data.affectedRows){
                    resolve(true)
                  }else{
                    resolve(false)
                  }
                })
              }else if(data.cpResult == res.data[i].number){

                this.api.send("queryDb",{
                  sql:`UPDATE points set consider_status = 1, loading_status = 1, berij_consider_status = 1, match_status= 1 where id = ${res.data[i].id}`,
                }).then((res1:any)=>{
                  if(res1.data.affectedRows){
                    resolve(true)
                  }else{
                    resolve(false)
                  }
                })
              }else{
                resolve(false)
              }
            }
          }

        }else{
          resolve(true)
        }
      })
    })
  }

  checkSangamClosePoints(session,data){
    return new Promise((resolve)=>{

      this.api.send("queryDb",{
        sql:`SELECT P1.id as P1_id, P2.id as P2_id, P1.number as P1_number, P2.number as P2_number, P1.consider_status as P1_consider_status, P2.consider_status as P2_consider_status  FROM points as P1 left join points as P2 on (P2.sangam_point_id = P1.point_no and P2.lot_id = P1.lot_id and P1.sangam = P2.number) WHERE P1.sangam IS NOT NULL AND P2.sangam_point_id IS NOT NULL AND P1.market = '${this.market}' and DATE(P1.created) = ${(this.currentDateSelectedStatus?"CURDATE()":`'${this.formatedDate}'`)}`,
        // conditions:[{"gunule IS NOT NULL":"","market":this.market,"DATE(created)":(this.currentDateSelectedStatus?"$CURDATE()$":this.formatedDate)}]
      }).then((res:any)=>{
        if(res.data.length > 0){
          for(let i=0;i<res.data.length;i++){
            if(data.p1 == res.data[i].P1_number && data.p2 != res.data[i].P2_number){
              this.api.send("queryDb",{
                sql:`UPDATE points set consider_status = 0, loading_status = 0, match_status=0 where id = ${res.data[i].P2_id}`,
              }).then((res1:any)=>{
                if(res1.data.affectedRows){
                  this.api.send("queryDb",{
                    sql:`UPDATE points set consider_status = 1, loading_status = 1, match_status=0 where id = ${res.data[i].P1_id}`,
                  }).then((res2:any)=>{
                    if(res2.data.affectedRows){
                        resolve(true)
                    }else{
                      resolve(false)
                    }
                  })
                }else{
                  resolve(false)
                }
              })
            }else if(data.p1 == res.data[i].P1_number && data.p2 == res.data[i].P2_number){
              this.api.send("queryDb",{
                sql:`UPDATE points set consider_status = 1, loading_status = 1, match_status = 1 where id = ${res.data[i].P2_id}`,
              }).then((res1:any)=>{
                if(res1.data.affectedRows){
                  this.api.send("queryDb",{
                    sql:`UPDATE points set consider_status = 0, loading_status = 0, match_status=1 where id = ${res.data[i].P1_id}`,
                  }).then((res2:any)=>{
                    if(res2.data.affectedRows){
                        resolve(true)
                    }else{
                      resolve(false)
                    }
                  })
                }else{
                  resolve(false)
                }
              })
            }else{
              resolve(true)
            }
          }
        }else{
          resolve(true)
        }
      })
    })
  }

  async showLots(){
    this.customerLots = []
    this.selectedLot = null
    if(this.selectedUserData){
      await this.api.send("findDb",{
        table:"lots as L",
        fields:["L.*, L.id as lot_id,U.*, L.created as lot_created"],
        joins:[{
          type:"left",
          table:"users as U",
          conditions:["U.id=L.user_id"]
        }],
        conditions:[{'L.day_night':this.market,"DATE(L.created)":this.formatedDate,'L.user_id':this.selectedUserData.id}],
        groupBy:"L.id",
        order:"L.lot_no"
      }).then((lotResponse:any)=>{

        if(lotResponse.data.length > 0){
          this.editLotForm = this.fb.group({
            rate:[null,Validators.compose([Validators.required])]
          })
          this.getLotPoints(lotResponse.data)

        }else{
          this.customerLots = [];
          this.observables.showToastMessage({type:1,message:"No Record Found Available..!"});
        }

      }).catch(error=>{console.log("error while fetching current lots=",error);})
    }else{
      this.observables.showToastMessage({type:1,message:'Please select the User'});
      this.user.nativeElement.focus();
    }

  }

  closeLots(){
    this.cancelLotEdit()
    this.editLotForm.reset()
    this.customerLots = [];
  }


  async getLotPoints(lotResponse){
    // console.log("lotResponse==",lotResponse)

      this.customerLots=[];
      for(let i=0; i<lotResponse.length;i++){
        await this.api.send("findDb",{
          table:"points",
          conditions:[{"lot_id":lotResponse[i].lot_id, consider_status:1}]
        }).then((pointResult:any)=>{
          lotResponse[i].points = pointResult.data
          this.customerLots.push({lot:lotResponse[i],points:pointResult.data});
          // console.log("customerLots==",this.sortLots(this.customerLots))


        }).catch(pointsErr=>{console.log("Error while getting points=",pointsErr);});
      }
  }

  sortLots(data){
    // console.log("sorting data-",data)
    return data.sort((a, b) => a.lot.lot_no - b.lot.lot_no);
  }

  sortPoints(data){
    // console.log("sorting data-",data)
    return data.sort((a, b) => a.point_no - b.point_no);
  }

  getLotPointsSum(lotData){
    let total_points:any=0;
    if(!lotData){
      return total_points;
    }else{
      lotData.filter((item:any)=>{
        total_points= total_points + ((item.gunule_point_id !== null || item.sangam_point_id !== null)?(parseFloat(item.points)/parseFloat(item.triple_digit)):((item.ulta_gunule_point_id !== null)?(parseFloat(item.points)/parseFloat(item.single_digit)):item.points));
      })
      return total_points;
    }
  }


    getTotalPointsSum(){
      // console.log("lot data==",this.userLots)
      let total_points:number=0;
        this.customerLots.filter((item:any)=>{
          for(let i=0;i<item.points.length;i++){
            total_points = total_points + item.points[i].points
          }
        })
        return total_points;

      }

      getTotalEntries(){
        let totalEntries:number=0;
        this.customerLots.filter((item:any)=>{
            totalEntries = totalEntries + item.points.length;
        })
        return totalEntries;
      }


      deleteLot(lotData){
        // console.log("lot data==",lotData)
        if(confirm("Are you sure to Delete Lot??")){
          this.api.send("deleteDb",{
            table:"lots",
            conditions:[{id:lotData.lot_id}]
          }).then((lotDeleteRes:any)=>{
            if(lotDeleteRes.data.affectedRows){
              this.observables.showToastMessage({type:0,message:"Lot Deleted..!"})
              this.api.send("findDb",{
                fields:["SUM(points) as pointsTotal"],
                table:"points",
                conditions:[{lot_id:lotData.lot_id}]
              }).then((sumRes:any)=>{
                if(sumRes.data.length > 0){
                  this.api.send("deleteDb",{
                    table:"points",
                    conditions:[{lot_id:lotData.lot_id}]
                  }).then((pointsDeleteRes:any)=>{
                    if(pointsDeleteRes.data.affectedRows){
                      this.observables.showToastMessage({type:0,message:"Lot Points Deleted..!"})
                      this.getCustomerOCB(lotData.user_id).then((ocbRes:any)=>{
                        // console.log("old ocb=",parseFloat(ocbRes.ocb))
                        // console.log("pointsTotal=",pointsTotal)
                        // if(ocbRes.ocb >= sumRes.data[0].pointsTotal){
                          this.api.send("updateDb",{
                            table:"user_ocb",
                            data:{
                              ocb:parseFloat(ocbRes.ocb)-parseFloat(sumRes.data[0].pointsTotal)
                            },
                            conditions:[{user_id:lotData.user_id}]
                          }).then((ocbUpdateRes:any)=>{
                            if(ocbUpdateRes.data.affectedRows){
                              this.observables.showToastMessage({type:0,message:"OCB Table Updated..!"})
                            }else{
                              this.observables.showToastMessage({type:1,message:"OCB Table Not Updated..!"})
                            }
                          })
                        // }else{
                        //   this.observables.showToastMessage({type:0,message:"OCB Table Updated..!"})
                        //   this.observables.showCurrentLotsStatus.next();
                        // }

                      })
                    }else{
                      this.observables.showToastMessage({type:0,message:"Points Can't Delete...!"})
                    }
                  })
                }
              })

            }else{
                this.observables.showToastMessage({type:0,message:"Lot Can't Delete..Try again!"})
            }
          })
        }
      }

      getCustomerOCB(userId){
        return new Promise((resolve,reject)=>{
          this.api.send("findDb",{
            table:"user_ocb",
            conditions:[{"user_id":userId}]
          }).then((res:any)=>{
            resolve(res.data[0])
          })
        })
      }

      getGunulesPoint(points){
        return (points.gunule_point_id !== null || points.sangam_point_id !== null)?(points.points/points.triple_digit):((points.ulta_gunule_point_id !== null)?(points.points/points.single_digit):points.points);
      }

      editLot(lot){
        // console.log("lot==",lot)
        if(lot.gunule_status || lot.ulta_gunule_status || lot.sangam_status){
          this.observables.showToastMessage({type:1,message:"Lot consists Gunule or Sangam points. Can't edit selected Lot.."})
          return
        }
        this.selectedLot = lot
        this.editLotForm.patchValue({rate:lot.type_no})
      }

      onEditRateChange(rate){

      }

      updateLot(value){
        if(value.rate == this.selectedLot.type_no){
          this.observables.showToastMessage({type:1,message:'No Changes Made..'})
          return
        }

        if(confirm("Are you sure to Update Rate for Selected Lot?")){
          // console.log("value==",value)
          this.api.send("updateDb",{
            table:"lots",
            data:{
              type_no:value.rate
            },
            conditions:[{id:this.selectedLot.lot_id}]
          }).then((res:any)=>{
            if(res.data.affectedRows){
              this.api.send("updateDb",{
                table:"points",
                data:{
                  type_no:value.rate
                },
                conditions:[{lot_id:this.selectedLot.lot_id}]
              }).then((res1:any)=>{
                if(res1.data.affectedRows){
                  this.observables.showToastMessage({type:0,message:"Lot Updated.."})
                  this.showLots()
                }else{
                  this.api.send("updateDb",{
                    table:"lots",
                    data:{
                      type_no:this.selectedLot.type_no
                    },
                    conditions:[{id:this.selectedLot.lot_id}]
                  }).then((res2:any)=>{
                    if(res2.data.affectedRows){
                      this.observables.showToastMessage({type:1,message:"Can\'t Update Rate for Selected Lot & Points, Try again.."})
                    }else{
                      this.observables.showToastMessage({type:1,message:"Some thing went wrong, Only Lots Rate Updated.."})
                    }
                  })
                }
              })
            }else{
              this.observables.showToastMessage({type:1,message:"Can\'t Update Rate for Selected Lot, Try again.."})
            }
          })
        }
      }

      cancelLotEdit(){
        this.selectedLot = null
      }

}
