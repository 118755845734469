import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { ViewportScroller } from '@angular/common';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit {
  types:any = ["K","B"]
  dayData:any = []
  nightData:any = []
  iterationObserver:any = new Subject<any>();
  subscribeVariable:any

  doc:any = new jsPDF({
    orientation: 'landscape'
  })

  constructor(private api:ApiService, private datePipe:DatePipe,private viewportScroller: ViewportScroller) { }

  ngOnInit() {
    // this.getDayResults()
    // this.getNightResults()
    this.chartDayData()
    this.chartNightData()
  }

  createRow(){
    this.api.send("queryDb",{
      sql:"CALL insert_or_update_weekly_records();"
    }).then((res:any)=>{
      console.log("insert_or_update_weekly_records call==",res)
    })
  }

  
  getpdf(){
    // Define a variable to store the column styles
    var columnStyles = {};
    var rowStyles = {};
  
    // Get the HTML table element
    var originalTable:any = document.getElementById('result');
  
    // Clone the table element
    var table:any = originalTable.cloneNode(true);
  
    // console.log("table==",table.rows)
    // Determine the number of columns in the table
    var numColumns = table.rows[1].cells.length;
    var cell = table.rows[0].cells[0];
    cell.innerHTML = '';
  
    // var lastRowColumns = table.rows[table.rows.length - 1].cells.length;
    // console.log("columns==",lastRowColumns)
    // Define styles for all columns except the first one
    columnStyles[0] = { // Apply styles to the first column
      cellWidth: 'auto', // Set the width to auto
      halign: 'center', // Horizontal alignment
      valign: 'middle' // Vertical alignment
    };
    // for (let i = 1; i < numColumns; i++) {
    //   columnStyles[i] = { halign: 'right', valign: 'middle' };
    // }
  
    
    autoTable(this.doc, { 
      theme:'grid',
      body: [
        [{ content: 'Text', colSpan: 2, rowSpan: 2, styles: { halign: 'center' } }],
      ],
      html: table,
      columnStyles: columnStyles,
      headStyles: {
        // fillColor: [200, 200, 200], // Background color for header row
        // textColor: [0, 0, 0], // Text color for header row
        lineWidth: 0.1, // Border width for header row
        lineColor: [0, 0, 0] // Border color for header row
      },
      footStyles: {
        // fillColor: [200, 200, 200], // Background color for header row
        // textColor: [0, 0, 0], // Text color for header row
        lineWidth: 0.1, // Border width for header row
        lineColor: [0, 0, 0] // Border color for header row
      },
      didParseCell: function(data:any) {
        console.log("data==",data)
        if (data.cell.raw.tagName === 'TH') { // If the cell is a <th> element
          // Center align <th> content
          // if(data.cell.text[0] == '"filter_listprint"'){
          //   data.cell.styles. = 'none'
          // }
          
          data.cell.styles.halign = 'center';
        }
  
        if (data.section == 'foot') {
          if(data.cell.text != 'Total'){
  
            data.cell.styles.halign = 'right';
          }
        }
          
        
      }
    })
  
   
  
    this.doc.save('result.pdf')
  }
  

  scrollToBottom() {
    this.viewportScroller.scrollToPosition([0, document.documentElement.scrollHeight]);
  }

  scrollToTop() {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  //  getNextWeekMondayDate(date: Date): Date {
  //   const dayOfWeek = date.getDay();
  //   const mondayOffset = (dayOfWeek + 6) % 7; // Calculate the difference between Thursday and Monday
  //   const mondayDate = new Date(date); // Create a new date object with the same date as Thursday
  //   mondayDate.setDate(date.getDate() - mondayOffset); // Subtract the offset to get the Monday date
  //   return mondayDate;
  // }

  getNextWeekMondayDate(previousWeekMonday: Date): Date {
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
  
    // Calculate the next week's Monday date
    const nextWeekMonday = new Date(previousWeekMonday.getTime() + 7 * oneDayInMilliseconds);
  
    // Increment the date until we find the next Monday
    while (nextWeekMonday.getDay() !== 1) { // Monday is represented by 1
      nextWeekMonday.setDate(nextWeekMonday.getDate() + 1);
    }
  
    return nextWeekMonday;
  }

  getNextWeekSaturdayDate(previousWeekDate: Date): Date {
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
  
    // Calculate the next week's Saturday date
    let nextWeekSaturday = new Date(previousWeekDate.getTime() + 7 * oneDayInMilliseconds);
  
    // Increment the date until we find the next Saturday
    while (nextWeekSaturday.getDay() !== 6) { // Saturday is represented by 6
      nextWeekSaturday.setDate(nextWeekSaturday.getDate() + 1);
    }
  
    return nextWeekSaturday;
  }
  getNextWeekFridayDate(previousWeekDate: Date): Date {
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
  
    // Calculate the next week's Saturday date
    let nextWeekFriday = new Date(previousWeekDate.getTime() + 7 * oneDayInMilliseconds);
  
    // Increment the date until we find the next Saturday
    while (nextWeekFriday.getDay() !== 5) { // Saturday is represented by 6
      nextWeekFriday.setDate(nextWeekFriday.getDate() + 1);
    }
  
    return nextWeekFriday;
  }

  getStartDate(item:any){
    if(item.mon_date != '*'){
      return this.getNextWeekMondayDate(new Date(item.mon_date));
    }else if(item.tue_date != '*'){
      return this.getNextWeekMondayDate(new Date(item.tue_date));
    }else if(item.wed_date != '*'){
      return this.getNextWeekMondayDate(new Date(item.wed_date));
    }else if(item.thu_date != '*'){
      return this.getNextWeekMondayDate(new Date(item.thu_date));
    }else if(item.fri_date != '*'){
      return this.getNextWeekMondayDate(new Date(item.fri_date));
    }else if(item.sat_date != '*'){
      return this.getNextWeekMondayDate(new Date(item.sat_date));
    }
  }
  getLastDate(item:any, type:any = 'D'){
    if(type == 'D'){

      if(item.mon_date != '*'){
        return this.getNextWeekSaturdayDate(new Date(item.mon_date));
      }else if(item.tue_date != '*'){
        return this.getNextWeekSaturdayDate(new Date(item.tue_date));
      }else if(item.wed_date != '*'){
        return this.getNextWeekSaturdayDate(new Date(item.wed_date));
      }else if(item.thu_date != '*'){
        return this.getNextWeekSaturdayDate(new Date(item.thu_date));
      }else if(item.fri_date != '*'){
        return this.getNextWeekSaturdayDate(new Date(item.fri_date));
      }else if(item.sat_date != '*'){
        return this.getNextWeekSaturdayDate(new Date(item.sat_date));
      }
    }else{
      if(item.mon_date != '*'){
        return this.getNextWeekFridayDate(new Date(item.mon_date));
      }else if(item.tue_date != '*'){
        return this.getNextWeekFridayDate(new Date(item.tue_date));
      }else if(item.wed_date != '*'){
        return this.getNextWeekFridayDate(new Date(item.wed_date));
      }else if(item.thu_date != '*'){
        return this.getNextWeekFridayDate(new Date(item.thu_date));
      }else if(item.fri_date != '*'){
        return this.getNextWeekFridayDate(new Date(item.fri_date));
      }else if(item.sat_date != '*'){
        return this.getNextWeekFridayDate(new Date(item.sat_date));
      }
    }
  }

  chartDayData(){
    this.api.send("findDb",{
      table:"charts",
      conditions:[{market:"D"}],
      orderBy:"chart_id ASC"//"ORDER BY DATE(mon_date) ASC, DATE(tue_date) ASC, DATE(wed_date) ASC, DATE(thu_date) ASC, DATE(fri_date) ASC, DATE(sat_date) ASC;"
    }).then((res:any)=>{
      this.dayData = res.data
    })
  }
  chartNightData(){
    this.api.send("findDb",{
      table:"charts",
      conditions:[{market:"N"}],
      orderBy:"chart_id ASC"//"ORDER BY DATE(mon_date) ASC, DATE(tue_date) ASC, DATE(wed_date) ASC, DATE(thu_date) ASC, DATE(fri_date) ASC, DATE(sat_date) ASC;"
    }).then((res:any)=>{
      this.nightData = res.data
    })
  }

  getDayResults(){
    this.api.send("queryDb",{
      sql:`SELECT 
  
      MAX(CASE WHEN DAYOFWEEK(created) = 2 THEN created ELSE '*' END) AS mon_created,
      MAX(CASE WHEN DAYOFWEEK(created) = 2 THEN p1 ELSE '***' END) AS mon_p1,
      MAX(CASE WHEN DAYOFWEEK(created) = 2 THEN p2 ELSE '***' END) AS mon_p2,
      MAX(CASE WHEN DAYOFWEEK(created) = 2 THEN oc ELSE '**' END) AS mon_oc,
      MAX(CASE WHEN DAYOFWEEK(created) = 2 THEN market ELSE '*' END) AS mon_market,
      MAX(CASE WHEN DAYOFWEEK(created) = 3 THEN created ELSE '*' END) AS tue_created,
      MAX(CASE WHEN DAYOFWEEK(created) = 3 THEN p1 ELSE '***' END) AS tue_p1,
      MAX(CASE WHEN DAYOFWEEK(created) = 3 THEN p2 ELSE '***' END) AS tue_p2,
      MAX(CASE WHEN DAYOFWEEK(created) = 3 THEN oc ELSE '**' END) AS tue_oc,
      MAX(CASE WHEN DAYOFWEEK(created) = 3 THEN market ELSE '*' END) AS tue_market,
      MAX(CASE WHEN DAYOFWEEK(created) = 4 THEN created ELSE '*' END) AS wed_created,
      MAX(CASE WHEN DAYOFWEEK(created) = 4 THEN p1 ELSE '***' END) AS wed_p1,
      MAX(CASE WHEN DAYOFWEEK(created) = 4 THEN p2 ELSE '***' END) AS wed_p2,
      MAX(CASE WHEN DAYOFWEEK(created) = 4 THEN oc ELSE '**' END) AS wed_oc,
      MAX(CASE WHEN DAYOFWEEK(created) = 4 THEN market ELSE '*' END) AS wed_market,
      MAX(CASE WHEN DAYOFWEEK(created) = 5 THEN created ELSE '*' END) AS thu_created,
      MAX(CASE WHEN DAYOFWEEK(created) = 5 THEN p1 ELSE '***' END) AS thu_p1,
      MAX(CASE WHEN DAYOFWEEK(created) = 5 THEN p2 ELSE '***' END) AS thu_p2,
      MAX(CASE WHEN DAYOFWEEK(created) = 5 THEN oc ELSE '**' END) AS thu_oc,
      MAX(CASE WHEN DAYOFWEEK(created) = 5 THEN market ELSE '*' END) AS thu_market,
      MAX(CASE WHEN DAYOFWEEK(created) = 6 THEN created ELSE '*' END) AS fri_created,
      MAX(CASE WHEN DAYOFWEEK(created) = 6 THEN p1 ELSE '***' END) AS fri_p1,
      MAX(CASE WHEN DAYOFWEEK(created) = 6 THEN p2 ELSE '***' END) AS fri_p2,
      MAX(CASE WHEN DAYOFWEEK(created) = 6 THEN oc ELSE '**' END) AS fri_oc,
      MAX(CASE WHEN DAYOFWEEK(created) = 6 THEN market ELSE '*' END) AS fri_market,
      MAX(CASE WHEN DAYOFWEEK(created) = 7 THEN created ELSE '*' END) AS sat_created,
      MAX(CASE WHEN DAYOFWEEK(created) = 7 THEN p1 ELSE '***' END) AS sat_p1,
      MAX(CASE WHEN DAYOFWEEK(created) = 7 THEN p2 ELSE '***' END) AS sat_p2,
      MAX(CASE WHEN DAYOFWEEK(created) = 7 THEN oc ELSE '**' END) AS sat_oc,
      MAX(CASE WHEN DAYOFWEEK(created) = 7 THEN market ELSE '*' END) AS sat_market
  FROM 
      results where market = 'D'
  GROUP BY 
      YEAR(created), WEEK(created)
  ORDER BY 
      YEAR(created) ASC, WEEK(created) ASC`
    }).then((res:any)=>{
      this.dayData = res.data
      console.log("day data==",this.dayData)
      let k = 0;
      this.subscribeVariable = this.iterationObserver.subscribe((k)=>{
        if(k>=this.dayData.length){
          console.log('k==',k)
          this.subscribeVariable.unsubscribe();
        }else{
          this.saveRecords(this.dayData[k],'D').then((res:any)=>{
            console.log("res==",k)
            this.iterationObserver.next(k+1);
          }).catch((e)=>{
            console.log("er==",k)
            this.iterationObserver.next(k+1);
          })
        }  
      })

      this.iterationObserver.next(k);
      
    })
  }

  getPreviousDay(dateString: string): string {
    const date = new Date(dateString);
    date.setDate(date.getDate() - 1);
    return this.datePipe.transform(date, 'yyyy-MM-dd') || '';
  }

  saveRecords(item,market){
    return new Promise((resolve,reject)=>{

      this.api.send("insertDb",{
        table:'charts',
        data:{
          market:market, 
          mon_date:item.mon_created == '*'?this.getPreviousDay(item.tue_created):item.mon_created,
          mon_p1:item.mon_p1,
          mon_p2:item.mon_p2,
          mon_oc:item.mon_oc,
          tue_date:item.tue_created == '*'?this.getPreviousDay(item.wed_created):item.tue_created,
          tue_p1:item.tue_p1,
          tue_p2:item.tue_p2,
          tue_oc:item.tue_oc,
          wed_date:item.wed_created == '*'?this.getPreviousDay(item.tue_created):item.wed_created,
          wed_p1:item.wed_p1,
          wed_p2:item.wed_p2,
          wed_oc:item.wed_oc,
          thu_date:item.thu_created == '*'?this.getPreviousDay(item.tue_created):item.thu_created,
          thu_p1:item.thu_p1,
          thu_p2:item.thu_p2,
          thu_oc:item.thu_oc,
          fri_date:item.fri_created == '*'?this.getPreviousDay(item.tue_created):item.fri_created,
          fri_p1:item.fri_p1,
          fri_p2:item.fri_p2,
          fri_oc:item.fri_oc,
          sat_date:item.sat_created == '*'?this.getPreviousDay(item.tue_created):item.sat_created,
          sat_p1:item.sat_p1,
          sat_p2:item.sat_p2,
          sat_oc:item.sat_oc,
        }
      }).then((res:any)=>{
        resolve(true)
      }).catch((err:any)=>{
        console.log("err==",err)
        reject(true)
      })
    })
  }

  getNightResults(){
    this.api.send("queryDb",{
      sql:`SELECT 
  
      MAX(CASE WHEN DAYOFWEEK(created) = 2 THEN created ELSE '*' END) AS mon_created,
      MAX(CASE WHEN DAYOFWEEK(created) = 2 THEN p1 ELSE '***' END) AS mon_p1,
      MAX(CASE WHEN DAYOFWEEK(created) = 2 THEN p2 ELSE '***' END) AS mon_p2,
      MAX(CASE WHEN DAYOFWEEK(created) = 2 THEN oc ELSE '**' END) AS mon_oc,
      MAX(CASE WHEN DAYOFWEEK(created) = 2 THEN market ELSE '*' END) AS mon_market,
      MAX(CASE WHEN DAYOFWEEK(created) = 3 THEN created ELSE '*' END) AS tue_created,
      MAX(CASE WHEN DAYOFWEEK(created) = 3 THEN p1 ELSE '***' END) AS tue_p1,
      MAX(CASE WHEN DAYOFWEEK(created) = 3 THEN p2 ELSE '***' END) AS tue_p2,
      MAX(CASE WHEN DAYOFWEEK(created) = 3 THEN oc ELSE '**' END) AS tue_oc,
      MAX(CASE WHEN DAYOFWEEK(created) = 3 THEN market ELSE '*' END) AS tue_market,
      MAX(CASE WHEN DAYOFWEEK(created) = 4 THEN created ELSE '*' END) AS wed_created,
      MAX(CASE WHEN DAYOFWEEK(created) = 4 THEN p1 ELSE '***' END) AS wed_p1,
      MAX(CASE WHEN DAYOFWEEK(created) = 4 THEN p2 ELSE '***' END) AS wed_p2,
      MAX(CASE WHEN DAYOFWEEK(created) = 4 THEN oc ELSE '**' END) AS wed_oc,
      MAX(CASE WHEN DAYOFWEEK(created) = 4 THEN market ELSE '*' END) AS wed_market,
      MAX(CASE WHEN DAYOFWEEK(created) = 5 THEN created ELSE '*' END) AS thu_created,
      MAX(CASE WHEN DAYOFWEEK(created) = 5 THEN p1 ELSE '***' END) AS thu_p1,
      MAX(CASE WHEN DAYOFWEEK(created) = 5 THEN p2 ELSE '***' END) AS thu_p2,
      MAX(CASE WHEN DAYOFWEEK(created) = 5 THEN oc ELSE '**' END) AS thu_oc,
      MAX(CASE WHEN DAYOFWEEK(created) = 5 THEN market ELSE '*' END) AS thu_market,
      MAX(CASE WHEN DAYOFWEEK(created) = 6 THEN created ELSE '*' END) AS fri_created,
      MAX(CASE WHEN DAYOFWEEK(created) = 6 THEN p1 ELSE '***' END) AS fri_p1,
      MAX(CASE WHEN DAYOFWEEK(created) = 6 THEN p2 ELSE '***' END) AS fri_p2,
      MAX(CASE WHEN DAYOFWEEK(created) = 6 THEN oc ELSE '**' END) AS fri_oc,
      MAX(CASE WHEN DAYOFWEEK(created) = 6 THEN market ELSE '*' END) AS fri_market,
      MAX(CASE WHEN DAYOFWEEK(created) = 7 THEN created ELSE '*' END) AS sat_created,
      MAX(CASE WHEN DAYOFWEEK(created) = 7 THEN p1 ELSE '***' END) AS sat_p1,
      MAX(CASE WHEN DAYOFWEEK(created) = 7 THEN p2 ELSE '***' END) AS sat_p2,
      MAX(CASE WHEN DAYOFWEEK(created) = 7 THEN oc ELSE '**' END) AS sat_oc,
      MAX(CASE WHEN DAYOFWEEK(created) = 7 THEN market ELSE '*' END) AS sat_market
  FROM 
      results where market = 'N'
  GROUP BY 
      YEAR(created), WEEK(created)
  ORDER BY 
      YEAR(created) ASC, WEEK(created) ASC`
    }).then((res:any)=>{
      this.nightData = res.data
      
      let k = 0;
      this.subscribeVariable = this.iterationObserver.subscribe((k)=>{
        if(k>=this.nightData.length){
          console.log('k==',k)
          this.subscribeVariable.unsubscribe();
        }else{
          this.saveRecords(this.nightData[k],'N').then((res:any)=>{
            console.log("res==",k)
            this.iterationObserver.next(k+1);
          }).catch((e)=>{
            console.log("er==",k)
            this.iterationObserver.next(k+1);
          })
        }  
      })

      this.iterationObserver.next(k);
      
    })
  }

  
  async onLinkClick(event){
    // console.log("selected tab=",event)
      if(event.index == 0){
       
        // await this.getVariableValues(this.types[0])
        // this.filterData(this.types[0])
      }else if(event.index == 1){
        
        // await this.getVariableValues(this.types[1])
        // this.filterData(this.types[1])
      }
  }

  getAdik(oc:any){
    let o = oc.split("")[0]
    let c = oc.split("")[1]
    let adik = parseInt(o) + parseInt(c)
    return  ( adik > 9)? adik.toString().slice(-1):adik
  }

  getBerij(oc:any){
    let o = oc.split("")[0]
    let c = oc.split("")[1]
    let berij = (parseInt(o) <= parseInt(c))?(parseInt(c) - parseInt(o)):(parseInt(c) + 10) - parseInt(o)
    return berij
  }
}
