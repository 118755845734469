import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  status:any = new Subject<any>()
  loadingStatus:any = new Subject<any>()
  statusMessage:string = ''
  iconName:string = ''

  constructor() { }


  loading(status,iconName="hourglass-half", message:string="Please wait &hellip;"){
    // console.log("RXJS for loading enabling");
    this.status.next({status:status,message:message,iconName:iconName})
  }

  dataLoading(status,message=''){
      this.loadingStatus.next({status:status,message:message})
  }



}
