import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DatePipe } from '@angular/common';
import { MatIconModule } from "@angular/material/icon";
import { ObservablesService } from './observables.service'
import { FileService } from './file.service'
import { NgSwipeToDeleteModule } from 'ng-swipe-to-delete';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { MatChipsModule, MatTabsModule,MatBadgeModule, MatAutocompleteModule, MatRadioModule, MatDatepickerModule,MatNativeDateModule, MatSelectModule, MatButtonModule, MatCheckboxModule, MatInputModule } from '@angular/material';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import { SideNavComponent } from './side-nav/side-nav.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminSideNavComponent } from './admin-side-nav/admin-side-nav.component';
import { AdminHeaderComponent } from './admin-header/admin-header.component';
import { MarketTimeSettingsComponent } from './market-time-settings/market-time-settings.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { TypeVariableComponent } from './type-variable/type-variable.component';
import { ReportsComponent } from './reports/reports.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserLotInfoComponent } from './user-lot-info/user-lot-info.component';
import { LotViewComponent } from './lot-view/lot-view.component';
import { ResultViewComponent } from './result-view/result-view.component';
import { ViewHeaderComponent } from './view-header/view-header.component';
import { LoadingViewComponent } from './loading-view/loading-view.component';
import { AdminEntryComponent } from './admin-entry/admin-entry.component';
import { TypeWiseReportComponent } from './type-wise-report/type-wise-report.component';
import { AuthGuard } from './auth.guard';
import { AdminGuard } from './admin.guard';
import { SubAdminGuard } from './sub-admin.guard';
import { AnonymousGuard } from './anonymous.guard';
import { TotalViewComponent } from './total-view/total-view.component';
import { TimeFormatPipe } from './time-format.pipe';
import { SubAdminDashboardComponent } from './sub-admin-dashboard/sub-admin-dashboard.component';
import { SubAdminHeaderComponent } from './sub-admin-header/sub-admin-header.component';
import { SubAdminLotViewComponent } from './sub-admin-lot-view/sub-admin-lot-view.component';
import { UserLotViewComponent } from './user-lot-view/user-lot-view.component';
import { SubAdminSummaryComponent } from './sub-admin-summary/sub-admin-summary.component';
import { CashEntryComponent } from './cash-entry/cash-entry.component';
import { MoreOptionsComponent } from './more-options/more-options.component';
import { TransactionViewComponent } from './transaction-view/transaction-view.component';
import { AllUserDailyReportComponent } from './all-user-daily-report/all-user-daily-report.component';
import { AllUserCashDetailsComponent } from './all-user-cash-details/all-user-cash-details.component';
import { HomeComponent } from './home/home.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CopyPasteComponent } from './copy-paste/copy-paste.component';
import { AdminRightSideNavComponent } from './admin-right-side-nav/admin-right-side-nav.component';
import { CustomersComponent } from './customers/customers.component';
import { SubAdminsComponent } from './sub-admins/sub-admins.component';
import { TwoDigitsComponent } from './two-digits/two-digits.component';
import { ThreeDigitsComponent } from './three-digits/three-digits.component';
import { DigitsViewComponent } from './digits-view/digits-view.component';
import { ScanImageComponent } from './scan-image/scan-image.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ContenteditableModule } from '@ng-stack/contenteditable';
import { ChartsComponent } from './charts/charts.component';
import { ResultReportComponent } from './result-report/result-report.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    DashboardComponent,
    PageNotFoundComponent,
    SideNavComponent,
    AdminDashboardComponent,
    AdminSideNavComponent,
    AdminHeaderComponent,
    MarketTimeSettingsComponent,
    UserRegistrationComponent,
    TypeVariableComponent,
    ReportsComponent,
    UserListComponent,
    UserLotInfoComponent,
    LotViewComponent,
    ResultViewComponent,
    ViewHeaderComponent,
    LoadingViewComponent,
    AdminEntryComponent,
    TypeWiseReportComponent,
    TotalViewComponent,
    TimeFormatPipe,
    SubAdminDashboardComponent,
    SubAdminHeaderComponent,
    SubAdminLotViewComponent,
    UserLotViewComponent,
    SubAdminSummaryComponent,
    CashEntryComponent,
    MoreOptionsComponent,
    TransactionViewComponent,
    AllUserDailyReportComponent,
    AllUserCashDetailsComponent,
    HomeComponent,
    CopyPasteComponent,
    AdminRightSideNavComponent,
    CustomersComponent,
    SubAdminsComponent,
    TwoDigitsComponent,
    ThreeDigitsComponent,
    DigitsViewComponent,
    ScanImageComponent,
    ChartsComponent,
    ResultReportComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    FormsModule,ContenteditableModule,
    MatIconModule,MatChipsModule,MatBadgeModule, MatTabsModule, MatAutocompleteModule, MatRadioModule, MatDatepickerModule,MatNativeDateModule, MatSelectModule, MatButtonModule, MatCheckboxModule, MatInputModule,
    NgSwipeToDeleteModule,HttpClientModule,HttpModule,NgbModule,MatSlideToggleModule,MatExpansionModule, ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [DatePipe,ObservablesService,FileService,AuthGuard,AnonymousGuard,AdminGuard,SubAdminGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
