import { Component, OnInit } from '@angular/core';
import { FormControl,FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ApiService } from '../api.service';
import { SocketService } from '../socket.service';
import { ObservablesService } from '../observables.service';
@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss']
})
export class UserRegistrationComponent implements OnInit {
  registerForm:FormGroup
  cashForm:FormGroup
  typesList:any = [];
  userList:any=[];
  numberPattern:any = /^[0-9]*$/;
  editStatus:boolean=false;
  enabledStatus:boolean=false;
  btnStatus:boolean=true;
  cashEntryStatus:boolean=false;
  editId:any='';
  editUsername:any='';
  edit_count:any= 0;
  username:any=''
  cash_user_id:any=''
  totalAmount:any=0;
  userTotalCashList:any=[]
  dataLoadedStatus:boolean = false;
  action:boolean = true;
  cash_limit:any;
  message:string=''
  ErrorMessage:string=''
  extraTimeStatus:boolean = false
  extra_time:any = null
  selectedUser:any = null
  ctrl:FormControl
  selectedSession:any = 1
  constructor(private socket:SocketService, private fb:FormBuilder,private api:ApiService,private observables:ObservablesService) {

    this.registerForm = this.fb.group({
        username:[null,Validators.compose([Validators.required])],
        password:[null,Validators.compose([Validators.required])],
        mobile_no:[null,Validators.compose([Validators.required])],
        type:[null,Validators.compose([Validators.required,Validators.minLength(1),Validators.maxLength(2)])],
        foreign_user_id:[null],
        limitation:[null,Validators.compose([Validators.required,Validators.minLength(3),Validators.maxLength(9),Validators.pattern(this.numberPattern)])],
        // city:[null,Validators.compose([Validators.required])],
      });

      this.cashForm = this.fb.group({
        cash:[null]
      });

   }



  ngOnInit() {
    this.getTypeVariableList()
    this.getUsersList();
    this.ctrl = new FormControl( {hour:0,minute:0,second:0}, (control: FormControl) => {
       const value = control.value;

       if (!value) {
         return null;
       }

       if (value.minute > 14) {
         return {tooMuchTime: true};
       }


       return null;
     });
  }

  cancleUpdate(){
    this.editStatus = false
    this.editId = null
    this.editUsername = null
    this.edit_count = 0

    this.registerForm.reset()
  }

  changeMessageStatus(event,user,element){

        if(event.checked){
          if(confirm("Are you sure to enable message formating?")){
            this.api.send("updateDb",{
              table:"users",
              data:{
                copy_message_status:1
              },
              conditions:[{id:user.id}]
            }).then((res:any)=>{
              if(res.data.affectedRows){

                this.observables.showToastMessage({type:0, message:"Message formating enabled.."})
              }else{

                this.observables.showToastMessage({type:1, message:"Message formating can\'t be enable, try again.."})
              }
            })
          }else{
            element.checked = false
          }

        }else{
          if(confirm("Are you sure to disable message formating?")){
            this.api.send("updateDb",{
              table:"users",
              data:{
                copy_message_status:0
              },
              conditions:[{id:user.id}]
            }).then((res:any)=>{
              if(res.data.affectedRows){

                this.observables.showToastMessage({type:0, message:"Message formating disabled.."})
              }else{

                this.observables.showToastMessage({type:1, message:"Message formating can\'t be disable, try again.."})
              }
            })
          }else{
            element.checked = true
          }



      }

  }

   getTotalCash(userList){
     // console.log("calling again=")
    for(let i=0;i<userList.length;i++){

      this.api.send("findDb",{
        table:"cash_entries",
        fields:["balance_cash as total_cash"],
        conditions:[{"user_id":userList[i].id}],
        order:"id DESC",
        limit:1
      }).then((res:any)=>{
        // console.log("total cash res=",res)
        if(res.data.length > 0){

          this.userTotalCashList.push({user_id:userList[i].id,total_cash:res.data[0].total_cash});
          if(i == this.userList.length - 1){
            this.dataLoadedStatus = true;
          }
        }else{
          this.userTotalCashList.push({user_id:userList[i].id,total_cash:0});
          if(i == this.userList.length - 1){
            this.dataLoadedStatus = true;
          }
        }
      })


    }
  }

  filterTotalCash(user_id){

    for(let i=0;i<this.userTotalCashList.length;i++){
      if(this.userTotalCashList[i].user_id == user_id){

        return this.userTotalCashList[i].total_cash;
      }
    }
  }

  cashToUser(user,action){
    this.message = '';
    this.action = action;
    this.cashEntryStatus = true;
    this.username = user.username;
    this.cash_user_id = user.id;
    this.cash_limit = user.limitation;
    // console.log("calling=",this.userTotalCashList)
    this.totalAmount = this.filterTotalCash(this.cash_user_id);
  }

  addCashEntry(value){
    // console.log("cash value=",value)
    // console.log("cash_user_id=",this.cash_user_id)
    if(!this.action){
      let minus_amt = 0;
      minus_amt = parseFloat((this.totalAmount))-parseFloat(value.cash);
      // console.log("minus amt=",minus_amt)
      if(minus_amt < (- this.cash_limit)){

        this.message = "Total amount crossing limit, Kindly increase user limit and try again..!"
      }else{
        this.message = '';
      }
    }

    if(this.message.length <= 0){
      // this.api.saveTransaction({
      //   user_id:this.cash_user_id,
      //   credit:(this.action)?value.cash:0,
      //   debit:(this.action)?0:value.cash,
      //   points:value.cash,
      //   tr_type:"Cash Entry"
      // })
      let balance_cash:any = (this.action)?parseFloat((this.totalAmount))+parseFloat(value.cash):parseFloat((this.totalAmount))-parseFloat(value.cash)
      this.api.send("insertDb",{
        table:"cash_entries",
        data:{
          user_id:this.cash_user_id,
          cash_taken:(this.action)?value.cash:null,
          cash_given:(this.action)?null:value.cash,
          action:(this.action)?1:2,
          balance_cash:balance_cash
        }

      }).then((res:any)=>{
        // console.log("res=",res)
        if(res.data.affectedRows){
          this.api.send("updateDb",{
            table:"user_ocb",
            data:{
              balance_cash:balance_cash
            },
            conditions:[{user_id:this.cash_user_id}]
          }).then((updateOcbRes:any)=>{
            if(updateOcbRes.data.affectedRows){

              this.cashEntryStatus= false;
              this.cashForm.reset()
              this.getUsersList();
              this.ErrorMessage = ''
              this.socket.socket.emit("updateAmount",{"data":{user_id:this.cash_user_id,action:(this.action)?"taken":"given",amount:value.cash}})
            }else{
              this.api.send("deleteDb",{
                table:'cash_entries',
                conditions:[{id:res.data['insertId']}]
              }).then((cashEntryDeleteRes:any)=>{
                if(cashEntryDeleteRes.data.affectedRows){

                  this.ErrorMessage = 'Transaction Failed, Try Again';
                }else{
                  this.cashEntryStatus= false;
                  this.cashForm.reset()
                  this.getUsersList();
                  this.ErrorMessage = '';
                }
              }).catch(err=>console.log("delete cash entry error=",err))
            }
          }).catch(err1=>console.log("update user ocb error== ",err1))
        }else{
          this.ErrorMessage = 'Transaction Failed, Try Again';
        }
      }).catch(err2=>console.log("insert cash entry error=",err2))
    }

  }

  closeModal(){
    this.cashEntryStatus = false;
    this.cash_user_id = '';
    this.cashForm.reset()
    this.username = ''
  }
  getTypeVariableList(){
    this.api.send("findDb",{
      table:"type_variables",
      fields:["type"]
    }).then((typeVariableList:any)=>{
      // console.log("typeVariableList=",typeVariableList);
      this.typesList = typeVariableList.data;
    }).catch(err=>{console.log("typeVariableList Error=",err)})
  }

  checkChange(event){
    // console.log("checkbox=",event)
    if(event.target.checked){
      this.registerForm.patchValue({'limitation':999999999})
    }else{
      this.registerForm.patchValue({'limitation':null})
    }
  }
  register(value){
      // console.log(value);
      this.btnStatus = false;
      this.api.send("findDb",{
        table:"users",
        conditions:[{username:value.username}]
      }).then((present:any)=>{
        if(present.data.length > 0 && present.status){
          this.observables.showToastMessage({type:1,message:'Username already Present'});
        }else{
          this.api.send("insertDb",{
            "table":"users",
            "data":{
              "username":value.username,
              "password":value.password,
              "role":"main-customer",
              "mobile_no":value.mobile_no,
              "type":value.type.toString(),
              "foreign_user_id":value.foreign_user_id,
              "limitation":value.limitation
            }
          }).then((response:any)=>{
            // console.log("resonse",response)
            if( response.data["affectedRows"]){
              // console.log("\n\nInsert successfull");
              this.api.send('insertDb',{
                table:"user_ocb",
                data:{
                  user_id:response.data['insertId'],
                  ocb:0,
                  balance_cash:0
                }
              }).then((insertOCB:any)=>{
                if( insertOCB.data["affectedRows"]){
                  this.observables.showToastMessage({type:0,message:'Registration successfull'});
                  this.registerForm.reset()
                  this.btnStatus = true;
                  this.getUsersList();
                }else{
                  this.api.send("deleteDb",{
                    table:'users',
                    conditions:[{id:response.data['insertId']}]
                  }).then((userDeleteRes:any)=>{
                    if(userDeleteRes.data.affectedRows){
                      this.observables.showToastMessage({type:1,message:'Registration failed..!'});
                    }else{
                      this.observables.showToastMessage({type:0,message:'Registration successfull,But OCB not updated, Please Delete user and Try Again'});
                      this.registerForm.reset()
                      this.btnStatus = true;
                      this.getUsersList();
                    }
                  })
                }
              })

            }else{
              this.observables.showToastMessage({type:1,message:'Registration failed..!'});
            }
          }).catch(err=>console.log("\n reg failed: ",err))
        }
      }).catch(error=>{console.log("\n user present check failed: ",error)})

    }

    updateData(value){
      if(confirm("Are you sure to edit user data..?")){
        this.api.send("updateDb",{
          "table":"users",
          "data":{
            "username":value.username,
            "password":value.password,
            "role":"main-customer",
            "mobile_no":value.mobile_no,
            "type":value.type.toString(),
            "foreign_user_id":value.foreign_user_id,
            "limitation":value.limitation,
            "edit_count":this.edit_count + 1
          },
          "conditions":[{"id":this.editId}]
        }).then((res:any)=>{
          // console.log("update User res",res)
          if(res.data.affectedRows){
            this.editStatus=false;
            this.observables.showToastMessage({type:0,message:'User Details Updated Successfully...!'});
            this.registerForm.reset();
            this.getUsersList();
          }else{
            this.observables.showToastMessage({type:1,message:'Some problem occured while updating user details...!'});

          }
        })
      }
    }

    update(value){
      if(this.editUsername != value.username){
        this.api.send("findDb",{
          table:"users",
          conditions:[{username:value.username}]
        }).then((present:any)=>{
          if(present.data.length > 0 && present.status){
            this.observables.showToastMessage({type:1,message:'Username already Present'});
          }else{
            this.updateData(value)
          }
        })
      }else{
        this.updateData(value)
      }


    }

    deleteUser(user){
      if(confirm("Are you sure to delete user..?")){
        this.api.send("deleteDb",{
          table:"users",
          conditions:[{"id":user.id}]
        }).then((res:any)=>{
          // console.log("Delete User res",res)
          if(res.data.affectedRows){
            this.observables.showToastMessage({type:0,message:'User Deleted Successfully...!'});
            this.getUsersList();
          }else{
            this.observables.showToastMessage({type:1,message:'Some problem occured while deleting user...!'});

          }
        })
      }
    }

    getUsersList(){
      this.userList = []
      this.userTotalCashList = []
      this.dataLoadedStatus = false;
      this.api.send("findDb",{
        table:"users",
        conditions:[{'role':'main-customer'}]
      }).then((userList:any)=>{
        // console.log("userList=",userList);
        userList.data.sort(function(a, b) {
        var nameA = a.username.toUpperCase(); // ignore upper and lowercase
        var nameB = b.username.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

          // names must be equal
          return 0;
        });
        this.userList = userList.data;
        this.getTotalCash(this.userList)
        this.api.send("findDb",{
          table:"users",
          fields:["COUNT(id) as activeCount"],
          conditions:[{"active_status":0}]
        }).then((countRes:any)=>{
          // console.log("countRes==",countRes)
          if(countRes.data[0].activeCount > 0){
            this.enabledStatus = true
          }else{
            this.enabledStatus = false
          }
        })


      }).catch(err=>{console.log("User list Error=",err)})
    }

    allowUserToLogin(user){
      if(confirm("Are you sure to allow user to login..?")){

        this.api.send("updateDb",{
          table:"users",
          data:{
            "login_status":0
          },
          conditions:[{"id":user.id}]
        }).then((res:any)=>{
          // console.log("Enabled User res",res)
          if(res.data.affectedRows){
            this.getUsersList();
            this.observables.showToastMessage({type:0,message:'Allowed user to Login...!'});
          }else{
            this.observables.showToastMessage({type:1,message:'Some problem occured while allowig user to login...!'});

          }
        })
      }
    }

    toggleChange(event,user){
      // console.log("log==",event)
      // console.log("user==",user)
      if(event.checked){
        if(confirm("Are you sure to enable user..?")){

          this.api.send("updateDb",{
            table:"users",
            data:{
              "active_status":0
            },
            conditions:[{"id":user.id}]
          }).then((res:any)=>{
            // console.log("Enabled User res",res)
            if(res.data.affectedRows){
              this.getUsersList();
              this.observables.showToastMessage({type:0,message:'User enabled Successfully...!'});
            }else{
              this.observables.showToastMessage({type:1,message:'Some problem occured while enabling...!'});

            }
          })
        }
      }else{
        if(confirm("Are you sure to disable user..?")){
          this.api.send("updateDb",{
            table:"users",
            data:{
                "active_status":1
            },
            conditions:[{"id":user.id}]
          }).then((res:any)=>{
            // console.log("disabled User res",res)
            if(res.data.affectedRows){
              this.socket.socket.emit("updateActiveStatus",{"data":{user_id:user.id}})
              this.getUsersList();
              this.observables.showToastMessage({type:0,message:'User disabled Successfully...!'});
            }else{
              this.observables.showToastMessage({type:1,message:'Some problem occured while disabling...!'});

            }
          })
        }
      }
    }

    editUser(user){
      this.editStatus=true;
      this.editId = user.id;
      this.editUsername = user.username;
      this.edit_count = user.edit_count;
      this.registerForm.patchValue({
        username:user.username,
        password:user.password,
        mobile_no:user.mobile_no,
        type:user.type.split(','),
        foreign_user_id:user.foreign_user_id,
        limitation:user.limitation
      });
    }

    disableAll(event){
      if(event.checked){
        if(confirm("Are you Sure??")){
          this.api.send("updateDb",{
            table:"users",
            data:{
              active_status:0
            },
            conditions:[{role:"main-customer"}]
          }).then((res:any)=>{
            // console.log("disable all response==",res);
            if(res.data.affectedRows){
              this.observables.showToastMessage({type:0,message:"All users are disabled"})
              this.getUsersList();
            }
          })
        }
      }else{
        if(confirm("Are you Sure??")){
          this.api.send("updateDb",{
            table:"users",
            data:{
              active_status:1
            },
            conditions:[{role:"main-customer"}]
          }).then((res:any)=>{
            // console.log("disable all response==",res);
            if(res.data.affectedRows){
              this.observables.showToastMessage({type:0,message:"All users are Enabled"})
              this.getUsersList();
            }
          })
        }
      }
    }

    setTimeLimit(user){
      // console.log("user==",user)
      this.username = user.username
      this.selectedUser = user
      this.extraTimeStatus = true
      if(user.extra_time_1){
        let time  = user.extra_time_1.split(':')
       
        this.ctrl.patchValue({hour:parseInt(time[0]),minute:parseInt(time[1]),second:parseInt(time[2])})
      }else if(user.extra_time_2){
        let time  = user.extra_time_2.split(':')
        this.ctrl.patchValue({hour:parseInt(time[0]),minute:parseInt(time[1]),second:parseInt(time[2])})
      }else{
        this.ctrl.patchValue({hour:0,minute:0,second:0})
      }

      // console.log("ctrl===",this.ctrl)
      // setTimeout(()=>{
      //
      //   document.getElementsByClassName('ngb-tp-hour')[0].remove();
      //   document.getElementsByClassName('ngb-tp-spacer')[0].remove();
      // },200)
    }

    closeExtraTimeModel(){
      this.username = ''
      this.selectedUser = null
      this.extraTimeStatus = false
    }

    selectSession(session){
      this.selectedSession = session
      if(session == 1){
        let time  = this.selectedUser.extra_time_1.split(':')
        this.ctrl.patchValue({hour:parseInt(time[0]),minute:parseInt(time[1]),second:parseInt(time[2])})
      }else if(this.selectedUser.extra_time_2){
        let time  = this.selectedUser.extra_time_2.split(':')
        this.ctrl.patchValue({hour:parseInt(time[0]),minute:parseInt(time[1]),second:parseInt(time[2])})
      }else{
        this.ctrl.patchValue({hour:0,minute:0,second:0})
      }

     
    }

    setExtraTime(){
      // console.log('extra time==',this.ctrl)
      // return
      if(!this.ctrl){
        this.observables.showToastMessage({type:1,message:'Invalid Time, Please Select Both Fields'})
        return
      }

      if(confirm("Are you sure to set extra time to "+this.username+" ?")){
        this.api.send("updateDb",{
          table:"users",
          data:{
            ["extra_time_"+ this.selectedSession]:`00:${this.ctrl.value.minute}:${this.ctrl.value.second}`
          },
          conditions:[{id:this.selectedUser.id}]
        }).then((res:any)=>{
          if(res.data.affectedRows){
            this.observables.showToastMessage({type:0,message:'Extra Time Added..'})
            this.closeExtraTimeModel()
            this.getUsersList()
          }else{
            this.observables.showToastMessage({type:1,message:'Some thing Went wrong while setting time..'})
          }
        })
      }
    }


}
