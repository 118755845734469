import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { LoadingService } from '../loading.service';
@Component({
  selector: 'app-transaction-view',
  templateUrl: './transaction-view.component.html',
  styleUrls: ['./transaction-view.component.scss']
})
export class TransactionViewComponent implements OnInit {
  cashEntryList:any=[]
  balance_cash:any;
  constructor(private loading:LoadingService,private api:ApiService) { }

  ngOnInit() {
    this.getUserCashEntryDetails(localStorage.getItem("userId"))
  }

  getUserCashEntryDetails(user_id){
    return new Promise((resolve,reject)=>{
      this.api.send('findDb',{
        fields:["C.*, U.username"],
        table:'cash_entries as C',
        joins:[{
          type:"left",
          table:"users as U",
          conditions:["U.id = C.user_id"]
        }],
        conditions:[{"C.user_id":user_id,"C.action !":0}]
      }).then((res:any)=>{
        // console.log("cash Entry res==",res)
        this.cashEntryList = res.data;
        this.api.send("findDb",{
         table:"cash_entries",
         conditions:[{user_id:user_id}],
         order:"id DESC",
         limit:1
       }).then((cashEntryResponse:any)=>{
         if(cashEntryResponse.data.length > 0){
           this.balance_cash=cashEntryResponse.data[0].balance_cash
         }else{
           this.balance_cash=0
         }
         resolve(this.cashEntryList)
       })
      })
    })
  }

}
