import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ApiService } from '../api.service';
import { SocketService } from '../socket.service';
import { LoadingService } from '../loading.service';
import { ObservablesService } from '../observables.service';
import {FormControl} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators'

@Component({
  selector: 'app-cash-entry',
  templateUrl: './cash-entry.component.html',
  styleUrls: ['./cash-entry.component.scss']
})
export class CashEntryComponent implements OnInit {
  options: any=[];
  cashEntryList: any=[];
  cashEntryForm:FormGroup
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  userData:any;
  actions:any= [
    {value: '1', viewValue: 'Received (Cr)'},
    {value: '2', viewValue: 'Paid (Dr)'}
  ];
  balance_cash:any=0;
  message:string=''
  cash_limit:any;
  constructor(private loading:LoadingService,private socket:SocketService, private fb:FormBuilder,private api:ApiService,private observables:ObservablesService) {

    this.cashEntryForm = this.fb.group({
        actions:[null,Validators.compose([Validators.required])],
        amount:[null,Validators.compose([Validators.required])],
        particulars:[null]
      });

   }

  ngOnInit() {

    this.refreshClientList().then((res:any)=>{
      this.options = res;
      this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

    });
  }



  refreshClientList(){
    return new Promise((resolve,reject)=>{

      this.api.send("findDb",{
        table:"users",
        conditions:[{'role':'main-customer'}]
      }).then((res:any)=>{
        // console.log("\nRefreshed users list",res);

        // this.users = res.data;
        resolve(res.data);
        });
    })


  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.username.toLowerCase().includes(filterValue));
  }
  onSelectionChanged(_event: any,userData) {

    if (_event.isUserInput) {
      this.loading.dataLoading(true);
       this.userData = userData;
       // console.log("userData==",this.userData)
       this.cash_limit = this.userData.limitation;
       this.message = '';
       this.getUserCashEntryDetails(this.userData.id).then((res:any)=>{
         this.loading.dataLoading(false);
       })
    }
  }

  getUserCashEntryDetails(user_id){
    return new Promise((resolve,reject)=>{
      this.api.send('findDb',{
        fields:["C.*, U.username"],
        table:'cash_entries as C',
        joins:[{
          type:"left",
          table:"users as U",
          conditions:["U.id = C.user_id"]
        }],
        conditions:[{"C.user_id":user_id,"C.action !":0}]
      }).then((res:any)=>{
        // console.log("cash Entry res==",res)
        this.cashEntryList = res.data;
        this.api.send("findDb",{
         table:"cash_entries",
         conditions:[{user_id:this.userData.id}],
         order:"id DESC",
         limit:1
       }).then((cashEntryResponse:any)=>{
         if(cashEntryResponse.data.length > 0){
           this.balance_cash=cashEntryResponse.data[0].balance_cash
         }else{
           this.balance_cash=0
         }
         resolve(this.cashEntryList)
       })
      })
    })
  }

  submit(values){
    if(values.actions == 2){
      let minus_amt = 0;
      minus_amt = parseFloat((this.balance_cash))-parseFloat(values.amount);
      // console.log("minus amt=",minus_amt)
      if(minus_amt < (- this.cash_limit)){

        this.message = "Can\'t Pay, Total amount crossing limit, Kindly increase the user limit and try again..!"
      }else{
        this.message = '';
      }
    }

    // console.log("form value=",values)
    if(this.message.length <= 0 || values.actions == 1){
      this.loading.dataLoading(true);
      let balance_cash:any = (values.actions == 1)?parseFloat((this.balance_cash))+parseFloat(values.amount):parseFloat((this.balance_cash))-parseFloat(values.amount)
      this.api.send("insertDb",{
        table:"cash_entries",
        data:{
          user_id:this.userData.id,
          cash_taken:(values.actions == 1)?values.amount:null,
          cash_given:(values.actions == 2)?values.amount:null,
          action:values.actions,
          particulars:values.particulars,
          balance_cash:balance_cash
        }

      }).then((res:any)=>{
        // console.log("res=",res)
        if(res.data.affectedRows){
          this.api.send("updateDb",{
            table:"user_ocb",
            data:{
              balance_cash:balance_cash
            },
            conditions:[{user_id:this.userData.id}]
          }).then((updateOcbRes:any)=>{
            if(updateOcbRes.data.affectedRows){

              this.cashEntryForm.reset()
              this.getUserCashEntryDetails(this.userData.id);
              this.message = '';
              this.loading.dataLoading(false);
              this.observables.showToastMessage({type:0,message:"Cash Entry Updated Successfully"});
              this.socket.socket.emit("updateAmount",{"data":{user_id:this.userData.id,action:(values.actions == 1)?"taken":"given",amount:values.amount}})
            }else{
              this.api.send("deleteDb",{
                table:'cash_entries',
                conditions:[{id:res.data['insertId']}]
              }).then((cashEntryDeleteRes:any)=>{
                if(cashEntryDeleteRes.data.affectedRows){
                  this.message = '';
                  this.loading.dataLoading(false);
                  this.observables.showToastMessage({type:1,message:"Transaction Failed, try again..!"});
                }else{
                  this.message = '';
                  this.cashEntryForm.reset()
                  this.loading.dataLoading(false);
                  this.getUserCashEntryDetails(this.userData.id);
                  this.observables.showToastMessage({type:0,message:"Cash Entry Updated Successfully"});
                }
              }).catch(err=>{console.log("delete cash entry error=",err);this.loading.dataLoading(false);})
            }
          }).catch(err1=>{console.log("update user ocb error== ",err1);this.loading.dataLoading(false);})
        }else{
          this.message = '';
          this.observables.showToastMessage({type:1,message:"Transaction Failed, try again..!"});
        }
      }).catch(err2=>{console.log("insert cash entry error=",err2);this.loading.dataLoading(false);})
    }
  }
}
