import { Component, OnInit, Input} from '@angular/core';
import { ApiService } from '../api.service';
import { LoadingService } from '../loading.service';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as _ from 'underscore';

@Component({
  selector: 'app-all-user-cash-details',
  templateUrl: './all-user-cash-details.component.html',
  styleUrls: ['./all-user-cash-details.component.scss']
})
export class AllUserCashDetailsComponent implements OnInit {
  data:any;
  selectedDate:any;
  selectedDay:any;
  Math:any;
  totalCredit:any;
  totalDebit:any;
  weekData:any=[];
  today:any;
  @Input() set selectedData(selectedData:any){
   this.data = selectedData;
   // console.log("selected data==",this.data)
   this.selectedDate = new Date(this.data.date);
   this.selectedDay = this.selectedDate.getDay();
   this.today = new Date();
   this.getUserWeeklyReport();
  }
  constructor(private api:ApiService,private loading:LoadingService) {
      this.Math = Math;

     }

  ngOnInit() {
  }

  async getUserWeeklyReport(){
    this.loading.dataLoading(true);
    this.weekData = [];
    await this.api.send("findDb",{
      table:"cash_entries as C",
      fields:["SUM(C.debit) as sum_debit, SUM(C.credit) as sum_credit,SUM(C.cash_taken) as sum_cash_taken, SUM(C.cash_given) as sum_cash_given,C.user_id,U.username"],
      joins:[{
        type:"left",
        table:"users as U",
        conditions:["U.id = C.user_id"]
      }],
      conditions:[{"YEARWEEK(C.on_taken_given, 1)":"$YEARWEEK('"+this.data.date+"', 1)$"}],
      groupBy:"C.user_id,C.rate",
      order:"user_id ASC"
    }).then((res:any)=>{
      // console.log("week resport==",res)
      if(res.data.length > 0){

        let result:any = _.groupBy(res.data, "user_id")
        let userWise= _.values(result);
        // console.log("user wise=",userWise)

        for(let i=0;i<userWise.length;i++){
          if(userWise[i].length > 1){
            let totalSumDebit =  userWise[i][0].sum_debit + userWise[i][1].sum_debit;
            let totalSumCredit =  userWise[i][0].sum_credit + userWise[i][1].sum_credit;
            let netTotal = totalSumDebit - totalSumCredit;
            this.getCurrentBalance(userWise[i][0].user_id).then((curBal:any)=>{
              this.getOldBalance(userWise[i][0].user_id).then((oldBal:any)=>{
                if(oldBal.data.length > 0){
                  this.weekData.push({sum_debit:(netTotal >= 0)?netTotal:0,
                     sum_credit:(netTotal < 0)?netTotal:0,
                     sum_cash_taken:userWise[i][0].sum_cash_taken+userWise[i][1].sum_cash_taken,
                     sum_cash_given:userWise[i][0].sum_cash_given+userWise[i][1].sum_cash_given,
                     user_id:userWise[i][0].user_id,
                     username:userWise[i][0].username,
                     old_bal_debit: (oldBal.data[0].balance_cash < 0)?this.Math.abs(oldBal.data[0].balance_cash):0,
                     old_bal_credit: (oldBal.data[0].balance_cash > 0)?this.Math.abs(oldBal.data[0].balance_cash):0,
                     cur_bal_debit: (curBal.data[0].balance_cash < 0)?this.Math.abs(curBal.data[0].balance_cash):0,
                     cur_bal_credit: (curBal.data[0].balance_cash > 0)?this.Math.abs(curBal.data[0].balance_cash):0
                   })
                 }
               })
             })
          }else{
            let netTotal = userWise[i][0].sum_debit - userWise[i][0].sum_credit;
            this.getCurrentBalance(userWise[i][0].user_id).then((curBal:any)=>{
              this.getOldBalance(userWise[i][0].user_id).then((oldBal:any)=>{
                if(oldBal.data.length > 0){
                  this.weekData.push({
                    sum_debit:(netTotal >= 0)?Math.abs(netTotal):0,
                     sum_credit:(netTotal < 0)?Math.abs(netTotal):0,
                     sum_cash_taken:userWise[i][0].sum_cash_taken,
                     sum_cash_given:userWise[i][0].sum_cash_given,
                     user_id:userWise[i][0].user_id,
                     username:userWise[i][0].username,
                     old_bal_debit: (oldBal.data[0].balance_cash < 0)?this.Math.abs(oldBal.data[0].balance_cash):0,
                     old_bal_credit: (oldBal.data[0].balance_cash > 0)?this.Math.abs(oldBal.data[0].balance_cash):0,
                     cur_bal_debit: (curBal.data[0].balance_cash < 0)?this.Math.abs(curBal.data[0].balance_cash):0,
                     cur_bal_credit: (curBal.data[0].balance_cash > 0)?this.Math.abs(curBal.data[0].balance_cash):0
                   })
                 }
               })
             })
          }

          if(i == userWise.length - 1){
            // console.log("week data==",this.weekData)

            this.loading.dataLoading(false);
          }
        }
      }else{
        this.loading.dataLoading(false);
      }

    })
  }

  getPdf(){
    this.loading.dataLoading(true);
    var footer = document.getElementById('footer');
    var pdfImg = document.getElementById('pdfimg');
    footer.classList.remove("table-footer");
    pdfImg.classList.add("hide");
    var data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 200;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 3;
      pdf.addImage(contentDataURL, 'PNG', 3, position, imgWidth, imgHeight)
      pdf.save('MYPdf.pdf'); // Generated PDF

      footer.classList.add("table-footer");
      pdfImg.classList.remove("hide");
      this.loading.dataLoading(false);
    });
  }

  getTotalDebit(){

    return this.weekData.reduce(function (acc, obj) { return acc + obj.cur_bal_debit; }, 0)
  }
  getTotalCredit(){
    return this.totalCredit = this.weekData.reduce(function (acc, obj) { return acc + obj.cur_bal_credit; }, 0)
  }

  getCurrentBalance(user_id){
    return new Promise((resolve,reject)=>{

      this.api.send("findDb",{
        table:"cash_entries as C",
        fields:["C.balance_cash,C.user_id"],
        conditions:[{"C.user_id":user_id}],
        order:"id DESC",
        limit:1
      }).then((curBal:any)=>{
        resolve(curBal)
      })
    })
  }

  getOldBalance(user_id){
    return new Promise((resolve,reject)=>{

      this.api.send("findDb",{
        table:"cash_entries as C",
        fields:["C.balance_cash,C.user_id"],
        conditions:[{"C.user_id":user_id,"C.on_taken_given":this.getLastSaturday(this.data.date)}],
        order:"id DESC",
        limit:1
      }).then((oldBal:any)=>{
        resolve(oldBal);
      })
    })
  }

getLastSaturday(d) {
  var t = new Date(d);
  t.setDate(t.getDate() - t.getDay()-1);
  return t.getFullYear()+"-"+(t.getMonth()+1)+"-"+t.getDate() ;
}


  getMonday(d){
    d = new Date(d);
    var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

}
