import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators'
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-result-report',
  templateUrl: './result-report.component.html',
  styleUrls: ['./result-report.component.scss']
})
export class ResultReportComponent implements OnInit {
  _selectedData:any = null
  selectedType:number = 9
  tableDataTemp:any =[]
  @Input() set selectedData(selectedData:any){
    console.log('selected data ==',selectedData)

   this._selectedData = selectedData;
   
   this.getResult()
  }

  tableData:any = []
  admin_mobile_no:any = null
  adminSMSstatus:boolean = false
  options: any=[];
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  userData:any = null
  constructor(private api:ApiService, private datePipe:DatePipe) { }

  ngOnInit() {
    this.getCustomers().then((res:any)=>{
      this.options = res;
      this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

    });
    this.getResult()
  }

  getCustomers(){
    return new Promise((resolve,reject)=>{

      this.api.send("findDb",{
        table:"users",
        conditions:[{'role':'main-customer'}]
      }).then((res:any)=>{
        // console.log("\nRefreshed users list",res);

        // this.users = res.data;
        resolve(res.data);
        });
    })

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.username.toLowerCase().includes(filterValue));
  }
  onSelectionChanged(_event: any,userData) {

    if (_event.isUserInput) {
      
       this.userData = userData;
      // console.log("userData==",userData)
      this.filter(this.userData.username)
    }
  }

  getResult(){
    this.tableData = []
    this.tableDataTemp = []
    this.api.send("queryDb",{
      sql:`SELECT U.username, U.mobile_no, C.*,C.id as calculation_id, L.id as lot_id,L.type_no as type, L.lot_no as lot_no, L.day_night as market,L.lot_mobile_no,P.points as points,P.number as number,P.point_no as point_no, P.gunule_point_id,P.gunule,P.ulta_gunule_point_id,P.ulta_gunule,P.sangam_point_id,P.sangam,P.adik,P.berij,P.gs_number, T.* FROM calculations as C \ 
      left join lots as L on(L.id = C.lot_id) left join points as P on(P.id = C.point_id) left join type_variables as T on(T.id = C.type_variable_id) left join users as U on(U.id = C.user_id) WHERE DATE(C.created) = '${this._selectedData.date}' and C.winning_amount IS NOT NULL and P.consider_status = 1 and P.market = '${this._selectedData.market}' ORDER BY C.market ASC`
    }).then((res:any)=>{
      this.tableData = res.data
      this.tableDataTemp = res.data
    })
  }

  filter(filterValue:any){
    this.tableDataTemp = this.tableData.filter((item:any)=> item.username.toLowerCase() == filterValue.toLowerCase())
    // if(event.target.value.length > 0){
    // }else{

    
  }

  resetUser(event:any){
    // console.log(event)
    if(event.target.value.length == 0){
      this.userData = null
      this.tableDataTemp = this.tableData
    }
  }

  sendSMS(){
    if(confirm("Are you sure to send SMS?")){

      if(this.tableDataTemp.length > 0){
        // Congrats, U hv won 4500 Pts for S-2/3-500 D-23-Nov-24
        this.api.send('winner-sms',{
          date:this.datePipe.transform(this._selectedData.date,'d-MMM-yy'),
          market:this._selectedData.market,
          adminSMSstatus:this.adminSMSstatus,
          admin_mobile_no:this.admin_mobile_no,
          userData:this.userData,
          dateFormate:this.datePipe.transform(this._selectedData.date,'yyyy-MM-dd')
        })
      }
    }
  }

}
