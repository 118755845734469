import { Component, OnInit,OnDestroy } from '@angular/core';
import { ApiService } from '../api.service'
import { LoadingService } from '../loading.service'
import { ObservablesService } from '../observables.service'
@Component({
  selector: 'app-sub-admin-lot-view',
  templateUrl: './sub-admin-lot-view.component.html',
  styleUrls: ['./sub-admin-lot-view.component.scss']
})
export class SubAdminLotViewComponent implements OnInit {
  userLots:any=[]
  showLotsStatusSubscription:any;
  market:any;
  result1:any='';
  result2:any='';
  session:any='';
  oc:any='';
  op:any='';
  cp:any='';
  userStatus:boolean=false;
  constructor(private loading:LoadingService,private api:ApiService, private observables:ObservablesService) { }

  ngOnInit() {
    if(localStorage.getItem('adminLoggedInStatus') == "1"){
      this.userStatus=true;
    }else if(localStorage.getItem('userLoggedInStatus') == "1"){
      this.userStatus=false;
    }

    this.showLotsStatusSubscription = this.observables.showLotsStatus.subscribe((res:any)=>{
      // console.log("in lot view =",res)
      //checking logged user for showing repective view changes

      if(res.length > 0){

        this.market = res[0].lot.day_night;

        this.getSessionResult().then((result:any)=>{
          this.loading.dataLoading(false)
          this.userLots=[]
          this.userLots=res;
          if(result.data.length > 0){

            this.result1 = result.data[0].p1;
            this.result2 = result.data[0].p2;
            this.op = result.data[0].op;
            this.cp = result.data[0].cp;
            this.oc = result.data[0].oc;
            this.session = result.data[0].session
            // this.middle_result = result.data[0].oc;
            // this.middle_result = result.data[0].oc;
            // console.log("result==",result.data[0])
            // console.log("middle_result==",this.oc)
            // console.log("op==",this.op)
            // console.log("cp==",this.cp)
          }
        });
      }else{
        this.userLots=[]
        this.loading.dataLoading(false)
      }
    })


  }

  ConvertToUTCString(date){
    // console.log('date string =',date)
    let date1:any = new Date(date).toUTCString();
    // console.log('convertion string =',date1)
    return date1;
  }

  ngOnDestroy(){
    this.showLotsStatusSubscription.unsubscribe();
  }

  sortLots(data){
    // console.log("sorting data-",data)
    return data.sort((a, b) => a.lot.lot_no - b.lot.lot_no);
  }
  sortPoints(data){
    // console.log("sorting data-",data)
    return data.sort((a, b) => a.point_no - b.point_no);
  }

  getLotPointsSum(lotData){
    let total_points:any=0;
    if(!lotData){
      return total_points;
    }else{
      lotData.filter((item:any)=>{
        total_points= total_points + item.points;
      })
      return total_points;
    }
  }

  getSessionResult(){
    return new Promise((resolve,reject)=>{
      this.api.send("findDb",{
        table:"results",
        conditions:[{market:this.market,"DATE(created)":"$CURDATE()$"}]
      }).then((results:any)=>{
        // console.log("session result is=",results)
        //if(results.data.length > 0){
          resolve(results);
        //}
      }).catch(error=>{

        console.log(" session result error is=",error);
        this.loading.dataLoading(false)
      })
    })
  }

  getTotalPointsSum(){
    // console.log("lot data==",this.userLots)
    let total_points:number=0;
      this.userLots.filter((item:any)=>{
        for(let i=0;i<item.points.length;i++){
          total_points = total_points + item.points[i].points
        }
      })
      return total_points;

    }

    getTotalEntries(){
      let totalEntries:number=0;
      this.userLots.filter((item:any)=>{
          totalEntries = totalEntries + item.points.length;
      })
      return totalEntries;
    }

}
