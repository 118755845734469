import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ApiService } from '../api.service';
import { SocketService } from '../socket.service';
import { ObservablesService } from '../observables.service';
@Component({
  selector: 'app-admin-entry',
  templateUrl: './admin-entry.component.html',
  styleUrls: ['./admin-entry.component.scss']
})
export class AdminEntryComponent implements OnInit {
  registerForm:FormGroup
  cashForm:FormGroup
  typesList:any = [];
  userList:any=[];
  subAdminPattern:any = /^0*(?:[2-9]|[1-9]\d\d*)$/;
  editStatus:boolean=false;
  enabledStatus:boolean=false;
  btnStatus:boolean=true;
  cashEntryStatus:boolean=false;
  editId:any='';
  username:any=''
  cash_user_id:any=''
  totalAmount:any=0;
  userTotalCashList:any=[]
  dataLoadedStatus:boolean = false;
  action:boolean = true;
  cash_limit:any;
  message:string=''
  editUsername:string=''
  editSubAdminId:string=''
  constructor(private socket:SocketService, private fb:FormBuilder,private api:ApiService,private observables:ObservablesService) {

    this.registerForm = this.fb.group({
        username:[null,Validators.compose([Validators.required])],
        password:[null,Validators.compose([Validators.required])],
        mobile_no:[null,Validators.compose([Validators.required])],
        sub_admin_id:[null,Validators.compose([Validators.required,Validators.pattern(this.subAdminPattern),Validators.max(99)])],
      });

      this.cashForm = this.fb.group({
        cash:[null]
      });

   }

  ngOnInit() {
    this.getTypeVariableList()
    this.getUsersList();

  }

  filterTotalCash(user_id){

    for(let i=0;i<this.userTotalCashList.length;i++){
      if(this.userTotalCashList[i].user_id == user_id){

        return this.userTotalCashList[i].total_cash;
      }
    }
  }



  closeModal(){
    this.cashEntryStatus = false;
    this.cash_user_id = '';
    this.cashForm.reset()
  }
  getTypeVariableList(){
    this.api.send("findDb",{
      table:"type_variables",
      fields:["type"]
    }).then((typeVariableList:any)=>{
      // console.log("typeVariableList=",typeVariableList);
      this.typesList = typeVariableList.data;
    }).catch(err=>{console.log("typeVariableList Error=",err)})
  }

  register(value){
      // console.log(value);
      this.btnStatus = false;
      this.api.send("findDb",{
        table:"users",
        conditions:[{username:value.username},{sub_admin_id:value.sub_admin_id}]
      }).then((present:any)=>{
        if(present.data.length > 0 && present.status){
          this.observables.showToastMessage({type:1,message:'Username/Sub Admin ID already Present'});
        }else{
          this.api.send("insertDb",{
            "table":"users",
            "data":{
              "username":value.username,
              "password":value.password,
              "role":"subadmin",
              "mobile_no":value.mobile_no,
              "type":'',
              "limitation":'',
              "sub_admin_id":value.sub_admin_id
            }
          }).then((response:any)=>{
            // console.log("resonse",response)
            if( response.data["affectedRows"]){
              // console.log("\n\nInsert successfull");
              this.observables.showToastMessage({type:0,message:'Registration successfull'});
              this.registerForm.reset()
              this.btnStatus = true;
              this.getUsersList();
            }else{
              this.observables.showToastMessage({type:1,message:'Registration failed..!'});
            }
          }).catch(err=>console.log("\n reg failed: ",err))
        }
      }).catch(error=>{console.log("\n user present check failed: ",error)})

    }

    update(value){
      if(this.editUsername != value.username){
        this.api.send("findDb",{
          table:"users",
          conditions:[{username:value.username}]
        }).then((present:any)=>{
          if(present.data.length > 0 && present.status){
            this.observables.showToastMessage({type:1,message:'Username already Present'});
          }else{
            if(this.editSubAdminId !=value.sub_admin_id){
              this.api.send("findDb",{
                table:"users",
                conditions:[{sub_admin_id:value.sub_admin_id}]
              }).then((present1:any)=>{
                if(present1.data.length > 0 && present1.status){
                    this.observables.showToastMessage({type:1,message:'Sub Admin Id already Present'});
                }else{
                  this.updateData(value)
                }
              })
            }else{
              this.updateData(value)
            }

          }
        })
      }else if(this.editSubAdminId !=value.sub_admin_id){
        this.api.send("findDb",{
          table:"users",
          conditions:[{sub_admin_id:value.sub_admin_id}]
        }).then((present1:any)=>{
          if(present1.data.length > 0 && present1.status){
              this.observables.showToastMessage({type:1,message:'Sub Admin Id already Present'});
          }else{
            this.updateData(value)
          }
        })
      }else{
        this.updateData(value)
      }

    }

    updateData(value){
      if(confirm("Are you sure to edit user data..?")){
        this.api.send("updateDb",{
          "table":"users",
          "data":{
            "username":value.username,
            "password":value.password,
            "role":"subadmin",
            "mobile_no":value.mobile_no,
            "type":'',
            "limitation":'',
            "sub_admin_id":value.sub_admin_id
          },
          "conditions":[{"id":this.editId}]
        }).then((res:any)=>{
          // console.log("update User res",res)
          if(res.data.affectedRows){
            this.editStatus=false;
            this.observables.showToastMessage({type:0,message:'User Details Updated Successfully...!'});
            this.registerForm.reset();
            this.getUsersList();
          }else{
            this.observables.showToastMessage({type:1,message:'Some problem occured while updating user details...!'});

          }
        })
      }
    }

    deleteUser(user){
      if(confirm("Are you sure to delete user..?")){
        this.api.send("deleteDb",{
          table:"users",
          conditions:[{"id":user.id}]
        }).then((res:any)=>{
          // console.log("Delete User res",res)
          if(res.data.affectedRows){
            this.observables.showToastMessage({type:0,message:'User Deleted Successfully...!'});
            this.getUsersList();
          }else{
            this.observables.showToastMessage({type:1,message:'Some problem occured while deleting user...!'});

          }
        })
      }
    }

    getUsersList(){
      this.userList = []
      this.userTotalCashList = []
      this.dataLoadedStatus = false;
      this.api.send("findDb",{
        table:"users",
        conditions:[{'role':'subadmin'}]
      }).then((userList:any)=>{
        // console.log("userList=",userList);
        userList.data.sort(function(a, b) {
        var nameA = a.username.toUpperCase(); // ignore upper and lowercase
        var nameB = b.username.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

          // names must be equal
          return 0;
        });
        this.userList = userList.data;

        this.api.send("findDb",{
          table:"users",
          fields:["COUNT(id) as activeCount"],
          conditions:[{"active_status":0}]
        }).then((countRes:any)=>{
          // console.log("countRes==",countRes)
          if(countRes.data[0].activeCount > 0){
            this.enabledStatus = true
          }else{
            this.enabledStatus = false
          }
        })


      }).catch(err=>{console.log("User list Error=",err)})
    }

    allowUserToLogin(user){
      if(confirm("Are you sure to allow user to login..?")){

        this.api.send("updateDb",{
          table:"users",
          data:{
            "login_status":0
          },
          conditions:[{"id":user.id}]
        }).then((res:any)=>{
          // console.log("Enabled User res",res)
          if(res.data.affectedRows){
            this.getUsersList();
            this.observables.showToastMessage({type:0,message:'Allowed user to Login...!'});
          }else{
            this.observables.showToastMessage({type:1,message:'Some problem occured while allowig user to login...!'});

          }
        })
      }
    }

    toggleChange(event,user){
      // console.log("log==",event)
      // console.log("user==",user)
      if(event.checked){
        if(confirm("Are you sure to enable user..?")){

          this.api.send("updateDb",{
            table:"users",
            data:{
              "active_status":0
            },
            conditions:[{"id":user.id}]
          }).then((res:any)=>{
            // console.log("Enabled User res",res)
            if(res.data.affectedRows){
              this.getUsersList();
              this.observables.showToastMessage({type:0,message:'User enabled Successfully...!'});
            }else{
              this.observables.showToastMessage({type:1,message:'Some problem occured while enabling...!'});

            }
          })
        }
      }else{
        if(confirm("Are you sure to disable user..?")){
          this.api.send("updateDb",{
            table:"users",
            data:{
                "active_status":1
            },
            conditions:[{"id":user.id}]
          }).then((res:any)=>{
            // console.log("disabled User res",res)
            if(res.data.affectedRows){
              this.getUsersList();
              this.observables.showToastMessage({type:0,message:'User disabled Successfully...!'});
            }else{
              this.observables.showToastMessage({type:1,message:'Some problem occured while disabling...!'});

            }
          })
        }
      }
    }

    cancleUpdate(){
      this.editStatus = false
      this.editId = null
      this.editUsername = null
      this.editSubAdminId = null
      this.registerForm.reset()
    }

    editUser(user){
      this.editStatus=true;
      this.editId = user.id;
      this.editUsername = user.username;
      this.editSubAdminId = user.sub_admin_id;
      this.registerForm.patchValue({
        username:user.username,
        password:user.password,
        mobile_no:user.mobile_no,
        sub_admin_id:user.sub_admin_id
      });
    }

    disableAll(event){
      if(event.checked){
        if(confirm("Are you Sure??")){
          this.api.send("updateDb",{
            table:"users",
            data:{
              active_status:0
            }
          }).then((res:any)=>{
            // console.log("disable all response==",res);
            if(res.data.affectedRows){
              this.observables.showToastMessage({type:0,message:"All users are disabled"})
              this.getUsersList();
            }
          })
        }
      }else{
        if(confirm("Are you Sure??")){
          this.api.send("updateDb",{
            table:"users",
            data:{
              active_status:1
            }
          }).then((res:any)=>{
            // console.log("disable all response==",res);
            if(res.data.affectedRows){
              this.observables.showToastMessage({type:0,message:"All users are Enabled"})
              this.getUsersList();
            }
          })
        }
      }
    }


      changeMessageStatus(event,user,element){

            if(event.checked){
              if(confirm("Are you sure to enable message formating?")){
                this.api.send("updateDb",{
                  table:"users",
                  data:{
                    copy_message_status:1
                  },
                  conditions:[{id:user.id}]
                }).then((res:any)=>{
                  if(res.data.affectedRows){

                    this.observables.showToastMessage({type:0, message:"Message formating enabled.."})
                  }else{

                    this.observables.showToastMessage({type:1, message:"Message formating can\'t be enable, try again.."})
                  }
                })
              }else{
                element.checked = false
              }

            }else{
              if(confirm("Are you sure to disable message formating?")){
                this.api.send("updateDb",{
                  table:"users",
                  data:{
                    copy_message_status:0
                  },
                  conditions:[{id:user.id}]
                }).then((res:any)=>{
                  if(res.data.affectedRows){

                    this.observables.showToastMessage({type:0, message:"Message formating disabled.."})
                  }else{

                    this.observables.showToastMessage({type:1, message:"Message formating can\'t be disable, try again.."})
                  }
                })
              }else{
                element.checked = true
              }



          }

      }

}
