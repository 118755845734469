import { Component, OnInit,OnDestroy, Input } from '@angular/core';
import { ApiService } from '../api.service'
import { LoadingService } from '../loading.service'
import { ObservablesService } from '../observables.service'
@Component({
  selector: 'app-user-lot-view',
  templateUrl: './user-lot-view.component.html',
  styleUrls: ['./user-lot-view.component.scss']
})
export class UserLotViewComponent implements OnInit {
  days:any=[]
  selectedDate:any;
  choosedDate:any;
  selectedMarket:any="A";
  userLots:any=[]
  showLotsStatusSubscription:any;
  market:any;
  result1:any='';
  result2:any='';
  session:any='';
  oc:any='';
  op:any='';
  cp:any='';
  adminStatus:boolean=false;
  userData:any;
  pointsArr:any=[]
  selectedIndex:number=0;
  selectedMarketIndex:number = 0
  subAdminId:any;
  @Input() set selectedData(selectedUser:any){
    this.userData = selectedUser;
    this.selectedIndex = selectedUser.index;
    this.subAdminId = selectedUser.subAdminId;

    this.getDays();
    this.onDayChange({index:this.selectedIndex})

    // console.log("selected Info=",this.userData)
  }
  constructor(private loading:LoadingService,private api:ApiService, private observables:ObservablesService) { }

  ngOnInit() {

    // console.log("selected in ng=",this.userData)
    if(localStorage.getItem('subAdminLoggedInStatus') == "1"){
       this.adminStatus=false;

   }else if(localStorage.getItem('adminLoggedInStatus') == "1"){
     this.adminStatus=true;
   }
  }

  getDays(){
   let curr = new Date();
   let first = curr.getDate() - curr.getDay();
   for (var i = 1; i < 7; i++) {
     var next = new Date(curr.getTime());
     next.setDate(first + i);
         let dd:any = next.getDate();
         let mm:any = next.getMonth()+1; //January is 0!
         var yyyy = next.getFullYear();
         if(dd<10){dd='0'+dd} if(mm<10){mm='0'+mm}
         this.days.push({date:yyyy+"-"+mm+"-"+dd,value:next.getDay()});
   }
  }

  refreshData(){
    this.getCurrentMarketLots().then((res:any)=>{
      if(res.length > 0){

        //this.market = res[0].lot.day_night;

        this.getSessionResult().then((result:any)=>{
          this.loading.dataLoading(false)
          this.userLots=[]
          this.userLots=res;
          if(result.data.length > 0){

            this.result1 = result.data[0].p1;
            this.result2 = result.data[0].p2;
            this.op = result.data[0].op;
            this.cp = result.data[0].cp;
            this.oc = result.data[0].oc;
            this.session = result.data[0].session
            // this.middle_result = result.data[0].oc;
            // this.middle_result = result.data[0].oc;
            // console.log("result==",result.data[0])
            // console.log("middle_result==",this.oc)
            // console.log("op==",this.op)
            // console.log("cp==",this.cp)
          }
        });
      }else{
        this.userLots=[]
        this.loading.dataLoading(false)
      }
    })
  }
  onDayChange(event){
    // console.log("selected day=",event)
    this.days.filter((item:any)=>{

      if((item.value - 1) == event.index){
        // console.log("selected date=",item.date)
        this.selectedDate = item.date
        this.choosedDate = new Date(item.date)

        if(this.userData.selectedMarketData.market == 'D'){
          this.selectedMarketIndex = 1
          this.onMarketChange({index:1})
        }else{
          this.selectedMarketIndex = 2
          this.onMarketChange({index:2})
        }
        // this.refreshData();
        // this.selectedDay = item.value;
        // this.filterTableData(this.selectedDay);
      }
    })
  }

  getSessionResult(){
    return new Promise((resolve,reject)=>{
      let conditions:any;
      if(this.selectedMarket == "A"){
        conditions={market:"D","DATE(created)":this.selectedDate};
      }else{
        conditions={market:this.selectedMarket,"DATE(created)":this.selectedDate};

      }
      this.api.send("findDb",{
        table:"results",
        conditions:[conditions]
      }).then((results:any)=>{
        // console.log("session result is=",results)
        //if(results.data.length > 0){
          resolve(results);
        //}
      }).catch(error=>{

        console.log(" session result error is=",error);
        this.loading.dataLoading(false)
      })
    })
  }
  onMarketChange(event){
    // console.log("selected market=",event)

    // this.totalPoints = [];

    if(event.index == 0){
      this.selectedMarket ="A";
    }else if(event.index == 1){
      this.selectedMarket ="D";
    }else if(event.index == 2){
      this.selectedMarket ="N";
    }

      this.refreshData();
  }

  getCurrentMarketLots(){
    let conditions:any;
    if(this.selectedMarket == "A"){
      conditions={"DATE(L.created)":this.selectedDate,'L.user_id':this.userData.user_id, added_by:2, added_sub_admin_id:this.subAdminId};
    }else{
      conditions={'L.day_night':this.selectedMarket,"DATE(L.created)":this.selectedDate,'L.user_id':this.userData.user_id, added_by:2, added_sub_admin_id:this.subAdminId};

    }
    // console.log("user data=",this.userData)
    return new Promise((resolve,reject)=>{
      this.api.send("findDb",{
        table:"lots as L",
        fields:["L.*, L.id as lot_id"],
        conditions:[conditions],
        groupBy:"L.id",
        order:"L.lot_no"
      }).then((lotResponse:any)=>{
        // console.log("current lots=",lotResponse)
        if(lotResponse.data.length > 0){
        //   this.current_market_lots = lotResponse.data;
          resolve(this.getPoints(lotResponse.data));
        }else{
          // this.loading.dataLoading(false);
          resolve(lotResponse.data)
        }
      }).catch(error=>{console.log("error while fetching current lots=",error);reject(error)})
    })

  }

  getPoints(lotResponse){
    return new Promise((resolve,reject)=>{
      this.pointsArr=[];
      for(let i=0; i<lotResponse.length;i++){
        this.api.send("findDb",{
          table:"points",
          conditions:[{"lot_id":lotResponse[i].lot_id}]
        }).then((pointResult:any)=>{
          // console.log('point result=',pointResult.data);
          lotResponse[i].points = pointResult.data;
          this.pointsArr.push({lot:lotResponse[i],points:pointResult.data});
          // console.log("final points==",this.pointsArr)
          if(i == lotResponse.length-1){
            resolve(this.pointsArr);
          }
        }).catch(pointsErr=>{console.log("Error while getting points=",pointsErr);reject(pointsErr)});
      }
    })
  }

  sortLots(data){
    // console.log("sorting data-",data)
    return data.sort((a, b) => a.lot.lot_no - b.lot.lot_no);
  }
  sortPoints(data){
    // console.log("sorting data-",data)
    return data.sort((a, b) => a.point_no - b.point_no);
  }

  getLotPointsSum(lotData){
    let total_points:any=0;
    if(!lotData){
      return total_points;
    }else{
      lotData.filter((item:any)=>{
        total_points= total_points + item.points;
      })
      return total_points;
    }
  }

  getTotalPointsSum(){
    // console.log("lot data==",this.userLots)
    let total_points:number=0;
      this.userLots.filter((item:any)=>{
        for(let i=0;i<item.points.length;i++){
          total_points = total_points + item.points[i].points
        }
      })
      return total_points;

    }

    getTotalEntries(){
      let totalEntries:number=0;
      this.userLots.filter((item:any)=>{
          totalEntries = totalEntries + item.points.length;
      })
      return totalEntries;
    }

}
