import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ApiService } from '../api.service';
import { ObservablesService } from '../observables.service';
@Component({
  selector: 'app-type-variable',
  templateUrl: './type-variable.component.html',
  styleUrls: ['./type-variable.component.scss']
})
export class TypeVariableComponent implements OnInit {
typeVariableForm:FormGroup
typeVariableList:any=[]
editStatus:boolean = false;
editId:any;
constructor(private fb:FormBuilder,private api:ApiService,private observables:ObservablesService) {

  this.typeVariableForm = this.fb.group({
      type:[null,Validators.compose([Validators.required])],
      single_digit:[null,Validators.compose([Validators.required])],
      double_digit:[null,Validators.compose([Validators.required])],
      triple_digit:[null,Validators.compose([Validators.required])],
      lost:[null,Validators.compose([Validators.required])],
    });

 }

ngOnInit() {
  this.getTypeVariableList();
}

addVariable(value){
    // console.log(value);
    this.api.send("findDb",{
      table:"type_variables",
      conditions:[{type:value.type}]
    }).then((present:any)=>{
      // console.log("type present",present)
      if(present.data.length > 0 && present.status){
        this.observables.showToastMessage({type:1,message:'Type Variable already Present'});
      }else{
        this.api.send("insertDb",{
          "table":"type_variables",
          "data":{
            "type":value.type,
            "single_digit":value.single_digit,
            "double_digit":value.double_digit,
            "triple_digit":value.triple_digit,
            "lost":value.lost
          }
        }).then((response:any)=>{
          // console.log("resonse",response)
          if( response.data["affectedRows"]){
            // console.log("\n\nInsert successfull");
            this.observables.showToastMessage({type:0,message:'Type Variable added successfully'});
            this.typeVariableForm.reset()
            this.getTypeVariableList();
          }else{
            this.observables.showToastMessage({type:1,message:'Type Variable Inserting failed..!'});
          }
        }).catch(err=>console.log("\n Type Variable Inserting failed: ",err))
      }
    }).catch(error=>{console.log("\n Type Variable present check failed: ",error)})

  }

  getTypeVariableList(){
    this.api.send("findDb",{
      table:"type_variables"
    }).then((typeVariableList:any)=>{
      // console.log("typeVariableList=",typeVariableList);
      this.typeVariableList = typeVariableList.data;
    }).catch(err=>{console.log("typeVariableList Error=",err)})
  }

  edit(variable){
    this.editStatus=true;
    this.editId = variable.id;
    this.typeVariableForm.patchValue({
      type:variable.type,
      single_digit:variable.single_digit,
      double_digit:variable.double_digit,
      triple_digit:variable.triple_digit,
      lost:variable.lost,
    });
  }

  update(value){
    if(confirm("Are you sure to edit variable data..?")){
      this.api.send("updateDb",{
        "table":"type_variables",
        "data":{
          "type":value.type,
          "single_digit":value.single_digit,
          "double_digit":value.double_digit,
          "triple_digit":value.triple_digit,
          "lost":value.lost

        },
        "conditions":[{"id":this.editId}]
      }).then((res:any)=>{
        // console.log("update Variable res",res)
        if(res.data.affectedRows){
          this.editStatus=false;
          this.observables.showToastMessage({type:0,message:'Variable Details Updated Successfully...!'});
          this.typeVariableForm.reset();
          this.getTypeVariableList();
        }else{
          this.observables.showToastMessage({type:1,message:'Some problem occured while updating variable details...!'});

        }
      })
    }
  }

  delete(variable){
    if(confirm("Are you sure to delete variable..?")){
      this.api.send("deleteDb",{
        table:"type_variables",
        conditions:[{"id":variable.id}]
      }).then((res:any)=>{
        // console.log("Delete Variable res",res)
        if(res.data.affectedRows){
          this.observables.showToastMessage({type:0,message:'Variable Deleted Successfully...!'});
          this.getTypeVariableList();
        }else{
          this.observables.showToastMessage({type:1,message:'Some problem occured while deleting variable...!'});

        }
      })
    }
  }

}
