import { Component, OnInit, ViewChild, ElementRef,HostListener, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { ObservablesService } from '../observables.service'
import { KeyboardEventService } from '../keyboard-event.service'
import { ApiService } from '../api.service';
import { LoadingService } from '../loading.service';
import { SocketService } from '../socket.service';
import { SocketObservablesService } from '../socket-observables.service';
import {Subject} from 'rxjs';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

   pointsForm:FormGroup
   numberPattern:any = /^(?=\d{1,3}$)1*2*3*4*5*6*7*8*9*0*$/;
   pointsPattern:any = /^\d+(?:\.\d{1,2})?$/;
   number1Pattern:any = /^\d{1,2}$/;
   numberPatternForSessionTwo1:any = /^(?=\d{4}$)1*2*3*4*5*6*7*8*9*0*$/;
   ticketData:any=[];
   lots:any=[];
   session:number;
   type:number;
   messageStatus:boolean= false;
   reverseFormatStatus:boolean= false;
   lotMessageStatus:boolean= false;
   startPosition:any;
   scrollElement:boolean =false;
   pointsValueFixed:boolean =false;
   tabViewStatus:boolean =false;
   total: number=0;
   market:any;
   activeSession:any;
   currentRunningSession :any;
   totalAmount:number=0;
   constantAmount:number=0;
   editPoints:number=0;
   tabIndex:number;
   showCopyPopupStatus:boolean = false
   @ViewChild('numberInput', { static: false }) numberInput:ElementRef;
   // @ViewChild ('type') typeBtn:ElementRef;
   @ViewChild('navWrap', { static: false }) navWrap:ElementRef;
   @ViewChild('stckWrap', { static: false }) stckWrap:ElementRef;
   @HostListener('window:scroll', ['$event'])
    onScroll(event) {
      // console.log('You scrolled!',event);
      // console.debug("Scroll Event", window.pageYOffset );
      const rect = this.navWrap.nativeElement.getBoundingClientRect();
      this.startPosition = rect.top + window.pageYOffset - document.documentElement.clientTop;
      if (window.pageYOffset > this.startPosition) {
          this.scrollElement =true;

      } else {
          this.scrollElement = false;
      }
    }

  index=0;
  swipeCoord?: [number, number];
  swipeTime?: number;
  activeElement:string='';
  editStatus:boolean=false;

  blockTypeStatus:boolean=false;
  editId:any='';
  deleteId:any='';
  userInfo:any;
  lotsData:any=[]
  sessionMarketData:any=[];
  keyPadElements:any=[];
  keyPadSessionElements:any=[];
  keyPadTypeElements:any=[];
  keyPadToggleElements:any=[];
  updateLotStatusSubscription:any;
  checkSessionStatusSubscription:any;
  loadingSubscribeVariable:any;
  loadingObserver:any = new  Subject<any>();
  message1:any=''
  myForm: FormGroup;
  points:any=[]
  @ViewChild('input', { static: false }) copyInput:ElementRef;

  constructor(private loading:LoadingService,private elementRef:ElementRef, private key:KeyboardEventService, private fb:FormBuilder, private observables:ObservablesService, private api:ApiService, private socket:SocketService, private socketObservables:SocketObservablesService) {
    this.pointsForm = this.fb.group({
      number:[null,Validators.compose([Validators.required,Validators.pattern(this.numberPattern)])],
      points:[null,Validators.compose([Validators.required,Validators.pattern(this.pointsPattern)])],
    });
  }

  ngOnDestroy(){


      this.ticketData=[];
      this.total=0;
      this.key.sendTicketData(this.ticketData);
      this.pointsForm.patchValue({'number':null,'points':null});
      this.updateLotStatusSubscription.unsubscribe();
      this.checkSessionStatusSubscription.unsubscribe();
  }



  ngOnInit() {
    this.getLoggedUserInfo().then((userData:any)=>{
      this.userInfo = userData;
      // console.log("this.userInfo==",this.userInfo)
      this.type = this.userInfo.type.split(',')[0];
      this.sendKeyboardElements();

      this.setUserOBC();

    });

    this.myForm = this.fb.group({
      items: this.initItems()
    });

    this.socketObservables.updateAmountStatus.subscribe((data)=>{
      // console.log("amount updated",data);
      if(data.data.action == 'taken'){
        this.totalAmount = this.totalAmount + parseFloat(data.data.amount);
      }else{
        this.totalAmount = this.totalAmount - parseFloat(data.data.amount);
      }
    })
    //
    // this.observables.showCopyPopupStatus.subscribe(()=>{
    //   this.message1=''
    //   this.myForm.setControl('items', this.fb.array([]));
    //   this.showCopyPopupStatus = true
    //   setTimeout(() => {
    //
    //     this.copyInput.nativeElement.focus()
    //   }, 200);
    // })

    this.checkSessionStatusSubscription = this.observables.checkSessionStatus.subscribe((marketResult:any)=>{
      console.log("Checking for status==")
    })
    this.observables.sendMarketDataStatus.subscribe((marketResult:any)=>{
      // console.log("sendMarketData")
      this.sessionMarketData=marketResult;
      this.session = this.sessionMarketData.session;
      this.activeSession = this.sessionMarketData.session;
      this.market = marketResult.market;
      // console.log("current Market=",this.market)
      this.getLots();
    })
    this.getCurrentMarket()

    this.observables.clearAllStatus.subscribe((result:any)=>{
      // console.log(result);
      this.ticketData=[];
      this.total=0;
      this.pointsForm.patchValue({'number':null,'points':null});
      this.blockTypeStatus = false;
      this.totalAmount = 0;
      this.index = 0;
      this.setUserOBC();

      this.key.sendTicketData(this.ticketData);

    })

    this.observables.cancelLotEntryStatus.subscribe(()=>{
      this.pointsForm.patchValue({'number':null,'points':null});
      this.numberInput.nativeElement.focus();
    })

    this.observables.getLotStatus.subscribe((res:any)=>{
      // console.log("Response=",res);
      if(this.ticketData.length <=0){
        this.observables.showToastMessage({type:1,message:'No points added yet..!'});
      }else{
        this.api.send("findDb",{
          table:"points",
          conditions:[{"DATE(created)":"CURDATE()",market:res.market}]
        }).then((pointsRecord:any)=>{
          if(pointsRecord.data.length <= 0){
            if(res.market == "D"){

              this.updateOCBStatus(0).then((updateRes:any)=>{
                if(updateRes){
                  this.continueSaveData(res)
                }else{
                  this.observables.showToastMessage({type:1,message:"OBC Status Not Updated..Contact Support"})
                }
              });
            }else{
              this.updateOCBStatus(1).then((updateRes:any)=>{
                if(updateRes){
                  this.continueSaveData(res)
                }else{
                  this.observables.showToastMessage({type:1,message:"OBC Status Not Updated..Contact Support"})
                }
              });

            }
          }
        })

      }

    })

    this.updateLotStatusSubscription = this.socketObservables.updateLotStatus.subscribe(()=>{
      this.getLots();
    })

    this.observables.addPointsStatus.subscribe((pointsData:any)=>{
      // console.log("points in dashboard==",pointsData)
      this.tabViewStatus = false;
      this.sendKeyboardElements()
      for(let i =0;i<pointsData.length;i++){
        this.addPoints(pointsData[i])
      }
    })


  }
  initItems() {
   var formArray = this.fb.array([]);
   return formArray;
 }
  closeModal(){
    this.showCopyPopupStatus = false
  }

  formateOptions(){

    this.message1=''
    this.myForm.setControl('items', this.fb.array([]));
    this.showCopyPopupStatus = true
    setTimeout(() => {

      this.copyInput.nativeElement.focus()
    }, 200);
  }

  updateOCBStatus(status){
    return new Promise((resolve,reject)=>{

      this.api.send("updateDb",{
        table:"user_ocb",
        data:{
          status:status
        }
      }).then((updateRes:any)=>{
        if(updateRes.data.affectedRows){
          resolve(true);
        }else{
          resolve(false);
        }
      })
    })
  }

  continueSaveData(res){
    this.loading.dataLoading(true);
    setTimeout(()=>{ this.lotMessageStatus = false }, 3000);
    this.lots.lot_no = this.lotsData.length + 1;
    this.lots.day_night = res.market;
    this.lots.push(this.ticketData);
    // console.log("lots data==",this.lots);
    // console.log("typeeeeeee lots data==",this.lots[0][0].type);
    this.getLots(res.market).then((lotRes:any)=>{

      this.api.send("insertDb",{
        table:"lots",
        data:{
          user_id:localStorage.getItem('userId'),
          lot_no:lotRes.length+1,
          type_no:this.lots[0][0].type,
          day_night:this.lots.day_night
        }
      }).then((result:any)=>{
        // console.log("lot insertion response=",result);

        if(result.data["affectedRows"]){
          let promises = [];
          let pointsTotal:number = 0;
          for(let i=0;i<this.lots[0].length;i++){


            pointsTotal = pointsTotal + parseFloat(this.lots[0][i].points);

            promises[i]=this.api.send("insertDb",{
              table:"points",
              data:{
                "lot_id":result.data['insertId'],
                "user_id":localStorage.getItem('userId'),
                "market":this.lots.day_night,
                "point_no":i+1,
                "session_no":this.lots[0][i].session,
                "type_no":this.lots[0][i].type,
                "number":this.lots[0][i].number,
                "points":this.lots[0][i].points,
                "added_by":0,
                "copy_msg_status":(this.lots[0][i].messageStatus)?1:0
              }
            })

          }

          Promise.all(promises).then((res:any)=>{
            // console.log("Response from promise",res);
            // console.log("total points==",pointsTotal)
            let totalRes:boolean = true;
            for(let j=0;j<res.length;j++){
              if(res[j].data.affectedRows){

              }else{
                totalRes = false;
              }

              if(j==res.length - 1){
                if(totalRes){
                  this.getUserOCB().then((ocbRes:any)=>{
                    // console.log("old ocb=",parseFloat(ocbRes.ocb))
                    // console.log("pointsTotal=",pointsTotal)

                    this.api.send("updateDb",{
                      table:"user_ocb",
                      data:{
                        ocb:parseFloat(ocbRes.ocb)+pointsTotal
                      },
                      conditions:[{user_id:localStorage.getItem('userId')}]
                    }).then((ocbUpdateRes:any)=>{
                      if(ocbUpdateRes.data.affectedRows){
                        // this.updateLoadingData(this.lots[0]).then((loadingRes:any)=>{
                          this.ticketData = [];
                          this.lots=[];
                          this.total=0;
                          this.blockTypeStatus = false;
                          this.pointsForm.patchValue({'number':null,'points':null});
                          this.observables.showToastMessage({type:0,message:'Thank you, Lot Added..'});
                          this.getLots();
                          this.keyPadElements[0].focus()
                          this.loading.dataLoading(false);
                        // })
                      }else{
                        this.observables.showToastMessage({type:1,message:'OCB updation Failed, contact Support'});

                        this.loading.dataLoading(false);
                      }
                    })
                  })
                }else{
                  this.api.send('deleteDb',{
                    table:'points',
                    conditions:[{  "lot_id":result.data['insertId']}]
                  }).then((deleteRes:any)=>{

                    if(deleteRes.data.affectedRows){

                      this.api.send('deleteDb',{
                        table:'lots',
                        conditions:[{ id:result.data['insertId']}]
                      }).then((deleteLotRes:any)=>{
                        if(deleteLotRes.data.affectedRows){

                          this.observables.showToastMessage({type:1,message:'Lots & Points Not saved, Data currupted, try again or contact Support'});
                          this.loading.dataLoading(false);
                        }else{
                          this.observables.showToastMessage({type:1,message:'Some Points Not saved, Data currupted, try again or contact Support'});
                          this.loading.dataLoading(false);
                        }
                      })
                    }else{
                      this.observables.showToastMessage({type:1,message:'Some Points Not saved, Data currupted, try again or contact Support'});
                      this.loading.dataLoading(false);
                    }
                  })
                }
              }
            }

          }).catch((pointsError:any)=>{console.log("pointsError==",pointsError);this.loading.dataLoading(false);})

        }else{
          this.observables.showToastMessage({type:1,message:'Lots & Points not saved, try again or contact Support'});
          this.loading.dataLoading(false);
        }
      }).catch(err=>{console.log(err);this.loading.dataLoading(false);})
    })
  }

  sendKeyboardElements(){
    setTimeout(()=> {
      this.keyPadElements = this.elementRef.nativeElement.querySelectorAll('.focusable');
      this.keyPadSessionElements = this.elementRef.nativeElement.querySelectorAll('.session-btn');
      this.keyPadTypeElements = this.elementRef.nativeElement.querySelectorAll('.type-btn');
      this.keyPadToggleElements = this.elementRef.nativeElement.querySelectorAll('.toggleBtn');

      // console.log("all date elements=",this.keyPadElements);
      this.key.getAllElement({mainElements:this.keyPadElements,sessionElements:this.keyPadSessionElements,typeElements:this.keyPadTypeElements,toggleElements:this.keyPadToggleElements,userStatus:true});

    }, 10);
  }


  moreOptions(){
    this.tabViewStatus = true;
  }

  closeOptions(){
    this.tabViewStatus = false;
  }

  setUserOBC(){
    this.getUserOCB().then((ocbRes:any)=>{
      this.totalAmount = parseFloat(ocbRes.balance_cash) - parseFloat(ocbRes.ocb);
    })
  }

  getUserOCB(){
    return new Promise((resolve,reject)=>{
      this.api.send("findDb",{
        table:"user_ocb",
        conditions:[{"user_id":localStorage.getItem("userId")}]
      }).then((res:any)=>{
        resolve(res.data[0])
      })
    })
  }

  focused(selectedElement){
    // console.log("focused= ",selectedElement);
    this.key.selectedElement({elementName:selectedElement,status:true});
    if(selectedElement == 'number'){
      const rect = this.numberInput.nativeElement.getBoundingClientRect();
      return rect.top + window.pageYOffset - document.documentElement.clientTop;
    }
  }
  blured(selectedElement){
    // console.log("blured= ",selectedElement);
    this.key.selectedElement({elementName:selectedElement,status:false});

  }


  getCurrentMarket(){
    this.api.send("findDb",{
      table:"session_times",
      conditions:[{"curtime() >":"$start_time$","curtime() <":"$end_time$",pride_status:environment.prideStatus}]
    }).then((sessionTimeRes:any)=>{
      if(sessionTimeRes.data.length>0){

        this.sessionMarketData=sessionTimeRes.data[0];
        this.session = sessionTimeRes.data[0].session;
        this.activeSession = sessionTimeRes.data[0].session;
        this.market = sessionTimeRes.data[0].market;
      }
    }).catch(err=>{console.log("error while fetching session times",err)})
  }

  getLoggedUserInfo(){
    return new Promise((resolve,reject)=>{

      this.api.send("findDb",{
        table:"users",
        conditions:[{id:localStorage.getItem('userId')}]
      }).then((userInfo:any)=>{
        // console.log("userInfo=",userInfo);
        resolve(userInfo.data[0])

      }).catch(error=>{console.log("user info error",error)})
    })
  }

  getLots(market=this.market){

    return new Promise((resolve,reject)=>{

      this.api.send("findDb",{
        table:"lots",
        conditions:[{'user_id':localStorage.getItem('userId'),"DATE(created)":"$CURDATE()$",'day_night':market}]

      }).then((lots:any)=>{
        // console.log("lots=",lots)
        this.lotsData = lots.data;
        // console.log("market in get lots",this.market)
        resolve(this.lotsData);
      }).catch(err=>{console.log("error while getting lots=",err)})
    })
  }



  selectedSession(number){
    this.session = number;
    // console.log("selected session=",this.market);
    //this.numberInput.nativeElement.focus();
  }
  selectedType(number){
    // console.log("selected type=",number);
    this.type = number;
    //this.numberInput.nativeElement.focus();
  }

  onNumberChange(number:any){
    // console.log("entered number is =", number);
    const numberControl = this.pointsForm.get('number');

    if(number.length==2 && this.session == 2){
      numberControl.setValidators([Validators.required, Validators.pattern(this.numberPatternForSessionTwo1)]);
    }else if(number.length == 2){
      // numberControl.clearValidators();
      numberControl.setValidators([Validators.required, Validators.pattern(this.number1Pattern)]);
    }else{

      numberControl.setValidators([Validators.required, Validators.pattern(this.numberPattern)]);
    }
    numberControl.updateValueAndValidity();

    // console.log("current market while adding input",this.market)
  }
  addPoints(value,copyStatus = false){
    // console.log("values==",value)
    // console.log("current market while adding",this.market)
    if(this.market){
      //...
      let minusAmount = 0;
      if(this.editStatus){
        minusAmount = this.totalAmount + (this.editPoints - parseFloat(value.points))
      }else{

        minusAmount = this.totalAmount - parseFloat(value.points);
      }

      if(minusAmount < (- this.userInfo.limitation)){
        this.observables.showToastMessage({type:1,message:'Can\'t add, Limit is overFlow...!'});
      }else{
        this.totalAmount = minusAmount;
        this.messageStatus = true;

        this.blockTypeStatus = true;
        setTimeout(()=>{ this.messageStatus = false }, 3000);
        // console.log("session=",this.session);
        // console.log("type=",this.type);
        // console.log(value);
        if(this.editStatus){
          value.session = this.session;
          value.type = this.type;
          value.id = this.editId;
          value.messageStatus = copyStatus
          this.ticketData.splice(this.editId, 1, value);
          this.editStatus = false;
          this.editId = '';
          this.editPoints=0;
        }else{
          // console.log('market==',this.market)

          value.session = this.session;
          value.type = this.type;
          value.id = this.index++;
          value.messageStatus = copyStatus
          this.ticketData.push(value);

        }


        this.pointsForm.patchValue({'number':null});
        if(!this.pointsValueFixed){
          this.pointsForm.patchValue({'points':null});
        }


        this.total=0;

        this.ticketData.forEach((item,index) => {
          this.total += parseFloat(item.points);
        });

        const numberControl = this.pointsForm.get('number');
        numberControl.setValidators([Validators.required, Validators.pattern(this.numberPattern)]);
        numberControl.updateValueAndValidity();
        setTimeout(()=>{

          this.numberInput.nativeElement.focus();
        },10)
        // console.log("ticketData=",this.ticketData);
        this.key.sendTicketData(this.ticketData);
      }

      //..
    }else{
      this.observables.showToastMessage({type:1,message:'Market closed, can\'t add points...!'});
    }
  }


swipe(e: TouchEvent, when: string,item): void {

  const coord: [number, number] = [e.changedTouches[0].pageX, e.changedTouches[0].pageY];
  const time = new Date().getTime();

  if (when === 'start') {
    this.swipeCoord = coord;
    this.swipeTime = time;
  } else if (when === 'end') {

    const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
    const duration = time - this.swipeTime;

    if (duration < 1000 //
      && Math.abs(direction[0]) > 30 // Long enough
      && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // Horizontal enough

        this.deleteId = item.id
          setTimeout(()=>{
            if(confirm("Are you sure to delete?")){
              this.activeElement = item.id;
            setTimeout(()=>{
              this.deleteItem(item);
            },1000)
          }else{
            this.deleteId = ''
          }
      },100)
    }
  }
}

deleteItem(item){
  // console.log("deleting item ==",item);
  // console.log("ticket data =",this.ticketData);

    const index = this.ticketData.findIndex(ticket => ticket.id === item.id);
    this.ticketData.splice(index, 1);
    //..
    this.totalAmount = this.totalAmount + parseFloat(item.points);
    this.total=0;
    this.editStatus = false;
    this.editId = '';
    this.deleteId = '';
    this.ticketData.forEach((item,index) => {
        this.total += parseFloat(item.points);
    });
    if(this.ticketData.length == 0){
      this.blockTypeStatus = false;
    }

}

editItem(item){
  this.editStatus = true;
  this.session = item.session;
  this.type = item.type;
  this.editId = item.id;
  this.editPoints = item.points;
  if(item.number.length == 2){
    this.onNumberChange(item.number)
  }

  this.pointsForm.patchValue({number:item.number,points:item.points});

}

toggleChange(event){
    // console.log("toggle changed event=",event)
    if(event.checked){
      this.pointsValueFixed = event.checked;
    }else{
      this.pointsValueFixed = event.checked;
      this.pointsForm.patchValue({'points':null});
    }
  }


  constrainInput(str) {
    return str.replace(/[\r\n\v]+/g, '')
  }

  processMessage(){
    this.points=[]
    this.myForm.setControl('items', this.fb.array([]));
    // console.log("message1==",this.message1)
    // console.log("1=====",this.constrainInput(this.message1).split(/[R-]/))
    if(this.constrainInput(this.message1).includes("R") || this.constrainInput(this.message1).includes("r")){

      let unProcessed:any =  this.constrainInput(this.message1).split(/[rR@]/);
      let isNum = /^\d+$/;
      for(let i=0;i<unProcessed.length;i++){
        if(unProcessed[i].includes("=")){
          // this.constrainInput(unProcessed[i]).split('='); /// Changed
          let splitUnProcessed:any = this.constrainInput(unProcessed[i]).split('=');
          // console.log("2=====",splitUnProcessed)
          let numbers:any// = this.constrainInput(splitUnProcessed[1]).split(/[,.-]/);
          if(this.reverseFormatStatus){
            numbers = this.constrainInput(splitUnProcessed[0]).split(/[ °•:;'~`÷€£¥₩○●□▪¤¡¿♤♡◇♧☆!#*$%^&+×?${_"|}()\\<>[/\],.-]/);
            // numbers = this.constrainInput(splitUnProcessed[0]).split(/[ °•:;'~`!@#$%^&*+?${_"|}()\\<>|[/\],.-]/);
          }else{
            numbers = this.constrainInput(splitUnProcessed[1]).split(/[ °•:;'~`÷€£¥₩○●□▪¤¡¿♤♡◇♧☆!#*$%^&+×?${_"|}()\\<>[/\],.-]/);
            // numbers = this.constrainInput(splitUnProcessed[1]).split(/[ °•:;'~`!@#$%^&*+?${_"|}()\\<>|[/\],.-]/);
            // numbers = this.constrainInput(splitUnProcessed[1]).split(/[,.-]/);
          }
          // console.log(numbers)

          for(let j=0;j<numbers.length;j++){
            if(numbers[j].length > 0){
              this.points.push({id:j+1,number:numbers[j],points:(this.reverseFormatStatus)?splitUnProcessed[1]:splitUnProcessed[0],typeStatus:true})
              this.addNewItem({id:j+1,number:numbers[j],points:(this.reverseFormatStatus)?splitUnProcessed[1]:splitUnProcessed[0],typeStatus:true})
            }
            if(j == numbers.length - 1){

              if(splitUnProcessed.length > 2){
                for(let k=2;k<splitUnProcessed.length;k++){

                  this.points.push({id:j+1,number:splitUnProcessed[k],points:splitUnProcessed[k],typeStatus:null})
                  this.addNewItem({id:j+1,number:splitUnProcessed[k],points:splitUnProcessed[k],typeStatus:null})
                }
              }
            }
          }
        }else{

          // console.log("i=============",i)
          if(unProcessed[i] != ''){
            if(!unProcessed[i].includes("#")){

              this.points.push({id:i+1,number:unProcessed[i],points:'',typeStatus:true})
              this.addNewItem({id:i+1,number:unProcessed[i],points:'',typeStatus:true})
            }
          }

        }
      }
    }else{
      this.observables.showToastMessage({type:1,message:"Invalid format"})
    }
   // console.log("final points==",this.points)
  }

  reverseFormat(event){
    if(event.checked){
      this.reverseFormatStatus = true
      this.points=[]
      this.myForm.setControl('items', this.fb.array([]));
    }else{

      this.reverseFormatStatus = false
      this.points=[]
      this.myForm.setControl('items', this.fb.array([]));
    }
  }

  addCopyPoints(value){
    console.log("valueee=",value)
    if(!this.myForm.valid){
      return
    }

    this.showCopyPopupStatus = false;
    this.sendKeyboardElements()
    for(let i =0;i<value.items.length;i++){
      this.addPoints(value.items[i],true)
    }
    // this.observables.addPoints(value.items)
  }

  addNewItem(value) {

    // console.log("values------",value)
     const controls = <FormArray>this.myForm.controls['items'];

       if(value.number.toString().length == 2){
         if(this.session == 2){
           let formGroup = this.fb.group({
             id:[value.id,Validators.compose([Validators.required])],
             number:[ value.number,Validators.compose([Validators.required, Validators.pattern(this.numberPatternForSessionTwo1)])],
             points:[value.points,Validators.compose([Validators.required,Validators.pattern(this.pointsPattern),Validators.maxLength(4)])],
             typeStatus:[value.typeStatus,Validators.compose([Validators.required])]
           });
           controls.push(formGroup);
         }else{
           let formGroup = this.fb.group({
             id:[value.id,Validators.compose([Validators.required])],
             number:[ value.number,Validators.compose([Validators.required, Validators.pattern(this.number1Pattern)])],
             points:[value.points,Validators.compose([Validators.required,Validators.pattern(this.pointsPattern),Validators.maxLength(4)])],
             typeStatus:[value.typeStatus,Validators.compose([Validators.required])]
           });
           controls.push(formGroup);
         }


       }else{

         let formGroup = this.fb.group({
           id:[value.id,Validators.compose([Validators.required])],
           number:[value.number,Validators.compose([Validators.required,Validators.pattern(this.numberPattern)])],
           points:[value.points,Validators.compose([Validators.required,Validators.pattern(this.pointsPattern),Validators.maxLength(4)])],
           typeStatus:[value.typeStatus,Validators.compose([Validators.required])]
         });

           controls.push(formGroup);
       }


   }

}
