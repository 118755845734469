import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../api.service'
import { ObservablesService } from '../observables.service'
import { FormBuilder, Validators, FormGroup, ValidatorFn, AbstractControl, FormArray, FormControl } from '@angular/forms'
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
@Component({
  selector: 'app-digits-view',
  templateUrl: './digits-view.component.html',
  styleUrls: ['./digits-view.component.scss']
})
export class DigitsViewComponent implements OnInit {
  _selectedData:any = null
  selectedType:any = 1
  total:any = 0
  diff:any = 0
  numbers:any=[]
  groups:any=[]
  myControl = new FormControl('', Validators.required);
  options: any=[];
  filteredOptions1: Observable<string[]>;
  selectedUserData:any = null
  @Input() set selectedData(selectedData:any){
    console.log('selected data ==',selectedData)
    selectedData.selectedType = "1"
   this._selectedData = selectedData;
  }
  constructor(private observables:ObservablesService,private api:ApiService) { }

  ngOnInit() {
    this.refreshClientList().then((res:any)=>{
      // this.usersData = res;
      this.options = res;
      this.filteredOptions1 = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
      // console.log("options==",this.options)
      // console.log("filteredOptions1==",this.filteredOptions1)
    });
    this.getSingleDigit()
    this.getDigitGroups()
  }

  getDigitGroups(){
    this.api.send("findDb",{
      fields:["D.*, U.username"],
      table:"digit_groups as D",
      joins:[{type:'left',table:"users as U", conditions:["U.id = D.user_id"]}]
    }).then((res:any)=>{
      this.groups = res.data
    })
  }
  refreshClientList(){
    return new Promise((resolve,reject)=>{

      this.api.send("findDb",{
        table:"users",
        conditions:[{'role':'main-customer'}],
        order:"username ASC"
      }).then((res:any)=>{
        resolve(res.data);
      });
    })
  }

  private _filter(value: string): string[] {
    // console.log(value)
    if(value){

      const filterValue = value.toLowerCase();
      return this.options.filter(option => option.username.toLowerCase().includes(filterValue));
    }


  }

  onSelectionChanged(_event: any,userData) {

    if (_event.isUserInput) {

       this.selectedUserData= userData;
       console.log("selected user==",this.selectedUserData)
       this.getSingleDigit(true)
    }

  }

  addUser(){
    this.api.send("findDb",{
      table:'digit_groups',
      conditions:[{user_id:this.selectedUserData.id}]
    }).then((res:any)=>{
      if(res.data.length > 0){
        this.observables.showToastMessage({type:1,message:`Already exist in group..`})
      }else{
        this.api.send("insertDb",{
          table:'digit_groups',
          data:{
            user_id:this.selectedUserData.id
          }
        }).then((res1:any)=>{
          if(res1.data.affectedRows){
            this.observables.showToastMessage({type:0,message:`Added in Group`})
            this.getDigitGroups()
          }else{
            this.observables.showToastMessage({type:1,message:`Can't Add.. Try again`})
          }
        })
      }
    })
  }

  removeFromGroup(item){
    this.api.send("deleteDb",{
      table:"digit_groups",
      conditions:[{id:item.id}]
    }).then((res:any)=>{
      if(res.data.affectedRows){
        this.observables.showToastMessage({type:0,message:`Removed From Group`})
        this.getDigitGroups()
      }else{
        this.observables.showToastMessage({type:1,message:`Can't Remove.. Try again`})
      }
    })
  }

  cancel(){
    this.selectedUserData = null
    this.diff = 0
    this.myControl.setValue(null);
    this.getSingleDigit()
  }

  async getSingleDigit(userStatus = false){
    this.total = 0
    this.numbers = []
    for(let i =0;i<10;i++){
      let sql = ''
      if(userStatus){
        sql = `SELECT ${i} as number, SUM(points) as points FROM points WHERE (number like '${i}' OR number like '${i}_') and DATE(created)='${this._selectedData.date}' and market = '${this._selectedData.market}' AND user_id = ${this.selectedUserData.id} AND session_no = 1 AND consider_status = 1 ORDER BY number ASC`
      }else{
        sql = `SELECT ${i} as number, SUM(points) as points FROM points WHERE (number like '${i}' OR number like '${i}_') and DATE(created)='${this._selectedData.date}' and market = '${this._selectedData.market}' AND session_no = 1 AND consider_status = 1 ORDER BY number ASC`
      }

      await this.api.send("queryDb",{
        sql:sql
      }).then((res:any)=>{

        this.numbers.push(res.data)
        this.total = this.total + res.data[0].points
      })
    }

    console.log("one digit==",this.numbers)


  }

  calc(rate, amt){
    let diff = this.total - (this.total*((rate == 8)?20:10)/100)
    let diff1 = diff+parseFloat(amt)
    this.diff = diff1/rate
  }

  onTabChange(event){
  }


}
