import { Component, OnInit, Output , EventEmitter } from '@angular/core';
import { ObservablesService } from '../observables.service';
import { ApiService } from '../api.service';
import { FileService } from "../file.service"
import { LoadingService } from "../loading.service"
import { Router } from '@angular/router';
import { KeyboardEventService } from '../keyboard-event.service'
import { SocketObservablesService } from '../socket-observables.service'
import {Subject} from 'rxjs';
import {NgbDatepickerConfig, NgbCalendar, NgbDate, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, FormGroup, ValidatorFn, AbstractControl, FormArray, FormControl } from '@angular/forms'
import { saveAs } from 'file-saver';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material';
import {Observable} from 'rxjs';
import * as _ from 'underscore';
import {map, startWith} from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-admin-side-nav',
  templateUrl: './admin-side-nav.component.html',
  styleUrls: ['./admin-side-nav.component.scss']
})
export class AdminSideNavComponent implements OnInit {
  exportModalStatus:boolean = false;
  resultModalStatus:boolean = true;
  currentDateSelectedStatus:boolean = false;
  minDate:any;
  maxDate:any;
  selectedResultDate:any='';
  model;
  resultModel: NgbDateStruct;
  market:string='D';
  viewMarket:string='D';
  selectedViewMarket:String='D'
  start_time:any;
  currentMarket:any;
  currentRunningSession:any;
  end_time:any;
  meridian=true;
  formatedDate:any;
  activeBtn:any=1;
  resultData:any=[]
  resultForm:FormGroup
  opForm:FormGroup
  numberPattern:any = /^(?=\d{1,3}$)1*2*3*4*5*6*7*8*9*0*$/;
  viewDateSelectedStatus:boolean = true;
  viewSaturdayStatus:boolean = false;
  exportDateSelectedStatus:boolean = true;
  exportSaturdayStatus:boolean = false;
  actualCurrentMarketStatus:boolean = false;
  resultCalculatedStatus:any=0;
  calculatedStatus:any;
  calculationFailedStatus:boolean = false;
  lotObserver:any = new Subject<any>();
  lotSubscribeVariable:any;
  opStatus:boolean = false;
  opData:any = [];
  op_total:any = 0;
  pan_total:any = 0;
  myControl = new FormControl('', Validators.required);
  options: any=[];
  filteredOptions1: Observable<string[]>;
  selectedUserData:any = null
  userOCData:any = null
  all_oc_total:any = 0
  userUniqueOCData:any = null
  unique_oc_total:any = 0
  userPoints:any = null
  pan_no:any = ''
  op_no:any = ''
  pan_no1:any = ''
  op_no1:any = ''
  gunule_points:any = []
  ulta_gunule_points:any = []
  sangam_points:any = []
  adik_points:any = []
  berij_points:any = []
  all_points:any = []
  writeRes:any = null
  @Output() onDatePicked: EventEmitter<any> = new EventEmitter<any>();
  constructor(private datePipe:DatePipe, private socketObservables:SocketObservablesService,private key:KeyboardEventService,private loading:LoadingService, private config: NgbDatepickerConfig, private calendar: NgbCalendar, private fb:FormBuilder,private file:FileService, private api:ApiService, private observables:ObservablesService,private router:Router) {
    this.resultForm = this.fb.group({
        p1:['',Validators.compose([Validators.minLength(3),Validators.maxLength(3),Validators.pattern(this.numberPattern)])],
        opResult:[''],
        p2:['',Validators.compose([Validators.minLength(3),Validators.maxLength(3),Validators.pattern(this.numberPattern)])],
        cpResult:[''],
        ocResult:[''],
      });
    this.opForm = this.fb.group({
        pan:['',Validators.compose([Validators.minLength(3),Validators.maxLength(3),Validators.pattern(this.numberPattern)])],
        op:['',Validators.compose([Validators.required])]
      });


        config.markDisabled = (date: NgbDate) => calendar.getWeekday(date) > 6;
   }

   openSetViewModel(){


     this.resultModalStatus=true;
   }

   setView(){
     // console.log("view market =",this.viewMarket)
     // console.log("view date =",this.resultModel)

     if(this.selectedViewMarket == this.viewMarket && this.formatedDate == this.resultModel.year+"-"+this.resultModel.month+"-"+this.resultModel.day){
       this.onDatePicked.emit({selectedMarket:this.selectedViewMarket,selectedDate:this.formatedDate});
       this.resultModalStatus = false;
     }else{

         if(confirm("Are you sure?, Current market Data will reset..!")){
           this.calculatedStatus = 0;
           this.selectedViewMarket = this.viewMarket;
           this.currentMarket = this.selectedViewMarket
           this.formatedDate = this.resultModel.year+"-"+this.resultModel.month+"-"+this.resultModel.day;
           this.selectedResultDate = new Date(this.formatedDate)
           var d1 = new Date();
           var d2 = new Date(this.formatedDate);
           if(d1.setHours(0,0,0,0) == d2.setHours(0,0,0,0)){
             this.currentDateSelectedStatus = true;

           }else{
             this.currentDateSelectedStatus = false;
           }
           this.onDatePicked.emit({selectedMarket:this.selectedViewMarket,selectedDate:this.formatedDate});
           this.resultModalStatus = false;
           this.getSessionResult(true).then((result:any)=>{
             // console.log("result===",result)
             if(result.length > 0){

               this.resultForm.patchValue({p1:result[0].p1,p2:result[0].p2,opResult:result[0].op,ocResult:result[0].oc,cpResult:result[0].cp})
             }else{
               this.resultForm.patchValue({p1:"",p2:"",opResult:"",ocResult:"",cpResult:""})
             }

           })
         }
     }
   }

   getCurrentMarket(){
     return new Promise((resolve,reject)=>{

       this.api.send("findDb",{
         table:"session_times",
         conditions:[{"curtime() >":"$start_time$","curtime() <":"$end_time$",pride_status:environment.prideStatus}]
       }).then((sessionTimeRes:any)=>{
         if(sessionTimeRes.data.length>0){
           console.log("sessionTimeRes.data===",sessionTimeRes.data)
           if(sessionTimeRes.data[0].market == 'D'){
             resolve(true);
           }else{
             resolve(false);
           }
         }
       }).catch(err=>{console.log("error while fetching session times",err)})
     })
   }

  ngOnInit() {
    let date = new Date();
    let maxDate = new Date();
    let minDate = new Date(maxDate);
    minDate.setDate(maxDate.getDate() - 7);
    this.config.maxDate = {year: maxDate.getFullYear(), month: maxDate.getMonth()+1, day: maxDate.getDate()};
    this.config.minDate = {year: minDate.getFullYear(), month: minDate.getMonth()+1, day: minDate.getDate()};
    this.end_time = {hour: date.getHours(), minute: date.getMinutes(), second: date.getSeconds()};
    this.getMarketTimes();

    this.observables.setActiveTabStatus.subscribe(()=>{
      this.activeBtn = 1;
    })

    this.observables.setCurrentDataStatus.subscribe((res:any)=>{
      this.currentMarket = res.currentMarket;
      this.currentRunningSession = res.currentSession;
      this.getSessionResult();

    })
    this.socketObservables.updateProgressBarStatus.subscribe((res:any)=>{
      if(res.status){

        this.calculatedStatus = res.response;
        this.calculationFailedStatus = false;
      }else{
        this.calculatedStatus = res.response;
        this.calculationFailedStatus = true;
        // setTimeout(()=>{
        //   this.calculationFailedStatus = false;
        //
        // },5000)
      }
      //
      // setTimeout(()=>{
      //   this.calculatedStatus = 2;
      //   setTimeout(()=>{
      //     this.calculatedStatus = 3;
      //     setTimeout(()=>{
      //       this.calculatedStatus = 0;
      //     },5000)
      //   },5000)
      // },5000)
    })

    this.observables.updateProgressBarStatus.subscribe((res:any)=>{
      this.getSessionResult(true);
    })

  }

  viewDateSelected(event){
    this.viewDateSelectedStatus = false;
    console.log("event==",event)
    var selectedDate = new Date(event.year+"-"+event.month+"-"+event.day);
    console.log("selectedDate=======",selectedDate)
    if(selectedDate.getDay() == 6){
      this.viewSaturdayStatus = true;
    }else{
      this.viewSaturdayStatus = false;
      var d1 = new Date();
      var d2 = new Date(selectedDate);
      console.log("d1==",d1)
      console.log("d2==",d2)
      if(d1.setHours(0,0,0,0) == d2.setHours(0,0,0,0)){
        this.getCurrentMarket().then((res:any)=>{
          console.log("res=========",res)
          this.actualCurrentMarketStatus = res;
        })
      }else{
        this.actualCurrentMarketStatus = false;
      }
    }
  }

  getMarketTimes(){
    this.api.send('findDb',{
      table:"session_times",
      fields:["session, market, HOUR(start_time) start_hour,MINUTE(start_time) start_minute,SECOND(start_time) start_second,HOUR(end_time) end_hour,MINUTE(end_time) end_minute,SECOND(end_time) end_second"],
      conditions:[{pride_status:environment.prideStatus}]
    }).then((res:any)=>{
      // console.log("timing res=",res)


      for(let i=0; i<res.data.length;i++){
        if(res.data[i].market == 'D' && res.data[i].session == '1'){
          if(this.market == 'D'){

            this.start_time={hour: res.data[i].start_hour, minute: res.data[i].start_minute, second: res.data[i].start_second};
          }

        }

        if(res.data[i].market == 'N' && res.data[i].session == '1'){
          if(this.market == 'N'){

            this.start_time={hour: res.data[i].start_hour, minute: res.data[i].start_minute, second: res.data[i].start_second};
          }

        }


      }

    })
  }


  dateSelected(event){
    // console.log("date selected",event)
    this.exportDateSelectedStatus = false;
    var d1 = new Date();
    var d2 = new Date(event.year+"-"+event.month+"-"+event.day);
    if(d2.getDay() == 6){
      this.exportSaturdayStatus = true;
    }else{
      this.exportSaturdayStatus = false;
    }

    if(d1.setHours(0,0,0,0) == d2.setHours(0,0,0,0)){
      this.currentDateSelectedStatus = true;
    }else{
      this.currentDateSelectedStatus = false;
    }
  }

  selectedMarket(market){
    // console.log("selected market=",market)
    this.market = market;

    this.getMarketTimes();
  }
  selectViewMarket(market){
    // console.log("selected market=",market)
    this.viewMarket = market;

  }

  exportFile(){
    // console.log("selected start time=",this.start_time)
    // console.log("selected end time=",this.end_time)

    let data:any;
    if(this.start_time && this.end_time && this.currentDateSelectedStatus){

      data={
        model:this.model,
        market:this.market,
        start_time:this.start_time,
        end_time:this.end_time,
        timeStatus:true
      }
    }else{

      data={
        model:this.model,
        market:this.market,
        timeStatus:false
      }
    }

    // console.log("data==",data)
    this.loading.dataLoading(true);
    this.api.send("findDb",{
      table:"lots",
      fields:["COUNT(id) as lotCount"],
      conditions:[{day_night:this.market,"DATE(created)":this.model.year+"-"+((this.model.month.length==1)?"0"+this.model.month:this.model.month)+"-"+((this.model.day.length==1)?"0"+this.model.day:this.model.day)}]
    }).then((countRes:any)=>{
      // console.log("count==",countRes.data[0].lotCount)
      let totalLots = countRes.data[0].lotCount;
      // let totalData:any = [];
      // let tempArr:any=[]
      // console.log("total itetations==",Math.ceil(totalLots/50))
      let last_id = 0;


      let n = 0;
       this.lotSubscribeVariable = this.lotObserver.subscribe((n)=>{
         if(n>=Math.ceil(totalLots/2)){

             this.lotSubscribeVariable.unsubscribe();

          }else{
            if(n==0){
              data.moreStatus = false;
            }else{
              data.moreStatus = true;
              data.last_id = last_id;
            }

            this.api.send("exportFile",data).then((res:any)=>{
              // console.log("response=+"+res[0].lot_id+"+",res)
              this.checkLotsHavingPoints(res).then((rep:any)=>{
                // console.log("response====+",rep)
                if(rep.length > 0){
                  this.api.send("writeFile",{result:rep,data:data}).then((writeRes:any)=>{
                    // console.log("write res=",writeRes)
                    this.writeRes = writeRes
                    last_id = res[res.length - 1].lot_id;
                    // tempArr.push(res);

                    if(n+1 == Math.ceil(totalLots/2)){
                      // console.log("tempArr=",tempArr)
                      this.loading.dataLoading(false);
                      this.lotSubscribeVariable.unsubscribe();

                      let filename:string='';

                      filename = "/exports/"+writeRes.response.file;

                      this.api.downloadReport(filename).subscribe(
                        data => {
                          saveAs(data, writeRes.response.file);
                          let date = new Date();
                          this.model ={year: date.getFullYear(), month: date.getMonth()+1, day: date.getDate()};
                          this.market='D';
                          this.exportModalStatus = false;
                        },
                        err => {
                          alert("Problem while downloading the file.");
                          this.loading.dataLoading(false);
                          console.error(err);
                        }
                      );
                      // this.file.downloadFile(writeRes.response).then((result:any)=>{
                        //   // console.log(" File Downloaded Successfully==",result);
                        //   let date = new Date();
                        //   this.model ={year: date.getFullYear(), month: date.getMonth()+1, day: date.getDate()};
                        //   this.market='D';
                        //   this.exportModalStatus = false;
                        //
                        //
                        // }).catch(err=>{
                          //   this.loading.dataLoading(false);
                          //   console.log("Error While DOwnloading, Try again!",err);
                          // });

                        }

                        this.lotObserver.next(n+1);

                      }).catch(err1=>{
                        this.loading.dataLoading(false);
                        console.log("Error While Writing into file, Try again!",err1);
                      })
                }else{
                  last_id = res[res.length - 1].lot_id;
                  // tempArr.push(res);

                  if(n+1 == Math.ceil(totalLots/2)){
                    // console.log("tempArr=",tempArr)
                    this.loading.dataLoading(false);
                    this.lotSubscribeVariable.unsubscribe();

                    let filename:string='';

                    filename = "/exports/"+this.writeRes.response.file;

                    this.api.downloadReport(filename).subscribe(
                      data => {
                        saveAs(data, this.writeRes.response.file);
                        let date = new Date();
                        this.model ={year: date.getFullYear(), month: date.getMonth()+1, day: date.getDate()};
                        this.market='D';
                        this.exportModalStatus = false;
                      },
                      err => {
                        alert("Problem while downloading the file.");
                        this.loading.dataLoading(false);
                        console.error(err);
                      }
                    );

                  }

                  this.lotObserver.next(n+1);
                }

              })

            }).catch(err2=>{
              this.loading.dataLoading(false);
              console.log("err file export=",err2);
            })


         }

       })
       this.lotObserver.next(n);

    })
  }

  checkLotsHavingPoints(res){
    return new Promise((resolve)=>{
      let arr = []
      if(res.length > 0){
        for(let i=0;i<res.length;i++){
          if(res[i].points.length > 0){
            arr.push(res[i])
          }

          if(i == res.length - 1){
            resolve(arr)
          }
        }
      }else{
        resolve(res)
      }
    })
  }

  downloadFile(fileData){
    let filename:string='';

    filename = "/exports/"+fileData.file;

   this.api.downloadReport(filename).subscribe(
     data => {
       saveAs(data, fileData.file_name);
     },
     err => {
       alert("Problem while downloading the file.");
       console.error(err);
     }
   );
 }


  gotoDashboard(){
    this.activeBtn = 1;
    this.observables.showPage({page:'dashboard',data:{}});

  }

  setTime(){
    this.activeBtn = 2;
    this.observables.showPage({page:'setTime',data:{}});
  }

  registerUser(){
    this.activeBtn = 3;
    this.observables.showPage({page:'registerUser',data:{}});
  }
  registerSubAdmin(){
    this.activeBtn = 8;
    this.observables.showPage({page:'registerSubAdmin',data:{}});
  }


  gotoReports(){
    this.activeBtn = 4;
    this.observables.showPage({page:'reports',data:{lotViewStatus:false,reportViewStatus:true}});
  }
  gotoTypeSettings(){
    this.activeBtn = 5;
    this.observables.showPage({page:'typeVariable',data:{}});
  }


  exportModal(){
    this.activeBtn = 6;
    this.exportModalStatus = true;
    this.getMarketTimes()
    this.market='D';
    let date = new Date();
    this.end_time = {hour: date.getHours(), minute: date.getMinutes(), second: date.getSeconds()};
  }

  closeModal(){

    this.exportModalStatus = false;

  }
  closeViewModal(){

    this.resultModalStatus = false;

  }

  logout(){
    this.activeBtn = 7;
    localStorage.clear();
    localStorage.setItem("logOutStatus",'1')
    this.observables.logout();
    this.router.navigate(['/home']);
  }

  SaveChartResult(value){
    // console.log("value==",value)
    // console.log("date and day ==",this.datePipe.transform(this.formatedDate,'EE').toLowerCase())
    
    if(value.ocResult.length == 2){
      
      const dayOfWeek = this.datePipe.transform(this.formatedDate, 'EE').toLowerCase() + '_date';
      const obj = {};
      obj['market'] = this.currentMarket;
      obj[`DATE(${dayOfWeek})`] = this.formatedDate;

            //check it is present
            this.api.send("findDb",{
              table:"charts",
              conditions:[obj]
            }).then((res:any)=>{
              console.log("res==",res)
              if(res.data.length > 0){
                obj['chart_id'] = res.data[0].chart_id
                this.api.send("updateDb",{
                  table:"charts",
                  data:{[`${this.datePipe.transform(this.formatedDate,'EE').toLowerCase()}_p1`]:value.p1,[`${this.datePipe.transform(this.formatedDate,'EE').toLowerCase()}_p2`]:value.p2,[`${this.datePipe.transform(this.formatedDate,'EE').toLowerCase()}_oc`]:value.ocResult},
                  conditions:[obj]
                }).then((res:any)=>{
                  console.log("res==",res)
                  if(res.data.affectedRows){
                    //done
                    this.observables.showToastMessage({type:0,message:"Result added into chart."})
                  }else{
                    // show error
                    this.observables.showToastMessage({type:1,message:"Unable to add Result into chart."})
                  }
                })
              }else{

                this.observables.showToastMessage({type:1,message:"Record not found for current week."})
                // this.api.send("insertDb",{
                //   table:"charts",
                //   data:{[`${this.datePipe.transform(this.formatedDate,'EE').toLowerCase()}_p1`]:value.p1,[`${this.datePipe.transform(this.formatedDate,'EE').toLowerCase()}_p2`]:value.p2,[`${this.datePipe.transform(this.formatedDate,'EE').toLowerCase()}_oc`]:value.ocResult},
                //   conditions:[obj]
                // }).then((res:any)=>{
                //   console.log("res==",res)
                //   if(res.data.affectedRows){
                //     //done
                //   }else{
                //     // show error
                //   }
                // })
              }
            })
   
    }else{
      //only op is there
      this.observables.showToastMessage({type:1,message:"Please Enter the Both Result...!"})
    }
    
  }
  SaveResult(value){
    // console.log("result form value=",value)
    if(value.ocResult == ''){
      this.observables.showToastMessage({type:1,message:"Please Enter the Result...!"})
    }else{
      if(confirm("Confirm to update Result ?")){
        this.loading.dataLoading(true);
        this.getSessionResult(true).then((res:any)=>{

          this.observables.submitResult({value:value,resultData:res});
        }).catch((err:any)=>{
          this.loading.dataLoading(false);
          this.observables.showToastMessage({type:1,message:"Can\'t save result...!"})
        });
      }
    }

  }
  calculateFinalResult(){
    // console.log("result==",this.resultForm.getRawValue())
    if(confirm("Are you sure to Calculate Market Result ?")){
      if(this.resultForm.value.p1 == "" || this.resultForm.value.opResult == "" || this.resultForm.value.p2 == "" ||
      this.resultForm.value.cpResult == "" || this.resultForm.value.ocResult == ""){
        this.observables.showToastMessage({type:1,message:"Result fields are empty..!"})
      }else{
        this.loading.dataLoading(true);
        this.getSessionResult(true).then((res:any)=>{
          // console.log("result resp=",res)
          if(res.length > 0){
            if(res[0].p1 =='' || res[0].p2=='' || res[0].cp =='' ||res[0].oc =='' ||res[0].op ==''){
              this.loading.dataLoading(false);
              this.observables.showToastMessage({type:1,message:"Please first submit both results to Calculate..!"})
            }else{

              this.api.send("findDb",{
                table:"calculations",
                fields:["COUNT(result_id) as calculations_Count"],
                conditions:[{result_id:res[0].id,"DATE(created)":this.formatedDate}]
              }).then((countRes:any)=>{
                // console.log("countRes==",countRes)
                if(countRes.data[0].calculations_Count > 0){

                  // this.observables.showToastMessage({type:0,message:"Update Calculation"})
                  this.observables.calculateResult({editStatus:true,result_id:res[0].id});
                }else{
                  //this.observables.showToastMessage({type:0,message:"Insert Calculation"})
                  this.observables.calculateResult({editStatus:false});
                }
              })
            }

          }else{
            this.loading.dataLoading(false);
            this.observables.showToastMessage({type:1,message:"Please first submit both results to Calculate..!"})
          }
        }).catch((err:any)=>{
          this.loading.dataLoading(false);
          this.observables.showToastMessage({type:1,message:"Calculation failed, Try again...!"})
        })

      }
      // this.observables.calculateResult();
    }
  }
  calculateSubCustomerFinalResult(){
    // console.log("result==",this.resultForm.getRawValue())
    if(confirm("Are you sure to Calculate Sub Customer Market Result ?")){
      if(this.resultForm.value.p1 == "" || this.resultForm.value.opResult == "" || this.resultForm.value.p2 == "" ||
      this.resultForm.value.cpResult == "" || this.resultForm.value.ocResult == ""){
        this.observables.showToastMessage({type:1,message:"Result fields are empty..!"})
      }else{
        this.loading.dataLoading(true);
        this.getSessionResult(true).then((res:any)=>{
          // console.log("result resp=",res)
          if(res.length > 0){
            if(res[0].p1 =='' || res[0].p2=='' || res[0].cp =='' ||res[0].oc =='' ||res[0].op ==''){
              this.loading.dataLoading(false);
              this.observables.showToastMessage({type:1,message:"Please first submit both results to Calculate..!"})
            }else{

              this.api.send("findDb",{
                table:"calculations",
                fields:["COUNT(result_id) as calculations_Count"],
                conditions:[{result_id:res[0].id,"DATE(created)":this.formatedDate}]
              }).then((countRes:any)=>{
                // console.log("countRes==",countRes)
                if(countRes.data[0].calculations_Count > 0){

                  // this.observables.showToastMessage({type:0,message:"Update Calculation"})
                  this.observables.calculateSubCustomerResult({editStatus:true,result_id:res[0].id});
                }else{
                  this.observables.showToastMessage({type:1,message:"Please Calculate Result Before Calculate Sub Customer Points.."})
                  // this.observables.calculateSubCustomerResult({editStatus:false});
                }
              })
            }

          }else{
            this.loading.dataLoading(false);
            this.observables.showToastMessage({type:1,message:"Please first submit both results to Calculate..!"})
          }
        }).catch((err:any)=>{
          this.loading.dataLoading(false);
          this.observables.showToastMessage({type:1,message:"Calculation failed, Try again...!"})
        })

      }
      // this.observables.calculateResult();
    }
  }
  calculateCustomerFinalResult(){
    // console.log("result==",this.resultForm.getRawValue())
    if(confirm("Are you sure to Calculate Customer Market Result ?")){
      if(this.resultForm.value.p1 == "" || this.resultForm.value.opResult == "" || this.resultForm.value.p2 == "" ||
      this.resultForm.value.cpResult == "" || this.resultForm.value.ocResult == ""){
        this.observables.showToastMessage({type:1,message:"Result fields are empty..!"})
      }else{
        this.loading.dataLoading(true);
        this.getSessionResult(true).then((res:any)=>{
          // console.log("result resp=",res)
          if(res.length > 0){
            if(res[0].p1 =='' || res[0].p2=='' || res[0].cp =='' ||res[0].oc =='' ||res[0].op ==''){
              this.loading.dataLoading(false);
              this.observables.showToastMessage({type:1,message:"Please first submit both results to Calculate..!"})
            }else{

              this.api.send("findDb",{
                table:"calculations",
                fields:["COUNT(result_id) as calculations_Count"],
                conditions:[{result_id:res[0].id,"DATE(created)":this.formatedDate}]
              }).then((countRes:any)=>{
                // console.log("countRes==",countRes)
                if(countRes.data[0].calculations_Count > 0){

                  // this.observables.showToastMessage({type:0,message:"Update Calculation"})
                  this.observables.calculateCustomerResult({editStatus:true,result_id:res[0].id});
                }else{
                  this.observables.showToastMessage({type:1,message:"Please Calculate Result Before Calculate Customer Points.."})
                  // this.observables.calculateSubCustomerResult({editStatus:false});
                }
              })
            }

          }else{
            this.loading.dataLoading(false);
            this.observables.showToastMessage({type:1,message:"Please first submit both results to Calculate..!"})
          }
        }).catch((err:any)=>{
          this.loading.dataLoading(false);
          this.observables.showToastMessage({type:1,message:"Calculation failed, Try again...!"})
        })

      }
      // this.observables.calculateResult();
    }
  }

  getSessionResult(status=false){
    return new Promise((resolve,reject)=>{
      // console.log("curent market=",this.currentMarket)
      this.api.send("findDb",{
        table:"results",
        conditions:[{market:this.currentMarket,"DATE(created)":this.formatedDate}]
      }).then((results:any)=>{
        this.resultData=[]
        // console.log("1st session result is=",results)
        if(results.data.length > 0){
          this.resultCalculatedStatus  = results.data[0].calculate_status;
          this.resultData = results.data;
          if(!status){

            this.resultForm.patchValue({p1:this.resultData[0].p1})
            this.calculateResult(this.resultData[0].p1,1);
            //if(this.resultData[0].session == 2){

              this.calculateResult(this.resultData[0].p2,2);
              this.resultForm.patchValue({p2:this.resultData[0].p2})
          //  }
          }

        }else{
          this.resultCalculatedStatus  = 0;
        }

        resolve(this.resultData);
      }).catch(error=>{
        console.log("1st session result error is=",error)
        reject(error)
      })
    })
  }

  calculateResult(number,session){
    // console.log("entered key=",number)
    // console.log("session key=",session)
    number = number.toString();
    if(number.length == 3){
      let calculateValues:number = parseInt(number.charAt(0))+parseInt(number.charAt(1))+parseInt(number.charAt(2));
      // console.log("calc=",calculateValues)
      if(session == 1){

        this.resultForm.patchValue({'opResult':parseInt(calculateValues.toString().split('').pop())})
       this.resultForm.patchValue({'ocResult':this.resultForm.get('opResult').value.toString()+this.resultForm.get('cpResult').value.toString()})
      }else{
        // console.log("cpResult=",calculateValues.toString().split('').pop())
        this.resultForm.patchValue({'cpResult':parseInt(calculateValues.toString().split('').pop())})
        this.resultForm.patchValue({'ocResult':this.resultForm.get('opResult').value.toString()+this.resultForm.get('cpResult').value.toString()})
      }
    }else{


      if(session == 1){

        this.resultForm.patchValue({'opResult':''})
        this.resultForm.patchValue({'ocResult':this.resultForm.get('cpResult').value.toString()})
      }else{
        this.resultForm.patchValue({'cpResult':''})
        this.resultForm.patchValue({'ocResult':this.resultForm.get('opResult').value.toString()})
      }
    }
  }

  closeOPModal(){
    this.opStatus = false
    this.activeBtn = 1;
    this.userOCData = []
    this.all_oc_total = 0
    this.userUniqueOCData = []
    this.unique_oc_total = 0
    this.userPoints = null
    this.myControl.reset()
  }

  
  gotoResultReport(){
    this.activeBtn = 10;
    this.observables.showPage({page:'resultReport',data:{}});
  }

  showOPModal(){
    this.opStatus = true
    this.activeBtn = 9;

    this.opForm.patchValue({pan:(this.resultData.length > 0)?this.resultData[0].p1:'',op:(this.resultData.length > 0)?this.resultData[0].op:''})

    // console.log("resultData==",this.resultData)
    if(this.opForm.valid){

      this.getCustomerData(this.opForm.getRawValue())
    }

    this.refreshClientList().then((res:any)=>{
      // this.usersData = res;
      this.options = res;
      this.filteredOptions1 = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
      // console.log("options==",this.options)
      // console.log("filteredOptions1==",this.filteredOptions1)
    });

    this.getExtraPoints()

  }

  private _filter(value: string): string[] {
    // console.log(value)
    if(value){

      const filterValue = value.toLowerCase();
      return this.options.filter(option => option.username.toLowerCase().includes(filterValue));
    }

  }

  onSelectionChanged(_event: any,userData) {

    if (_event.isUserInput) {

       this.selectedUserData= userData;

       // console.log("selected user-",this.selectedUserData)
       if(this.opForm.valid){

         this.getUserOCData(this.opForm.getRawValue())
       }else{
         this.observables.showToastMessage({status:1,message:"Enter Pan Field.."})
       }
    }

  }

  getUserOP(event){
    let number = event.target.value.toString();
    if(number.length == 3){
      let calculateValues:number = parseInt(number.charAt(0))+parseInt(number.charAt(1))+parseInt(number.charAt(2));

      this.op_no1 = parseInt(calculateValues.toString().split('').pop())
    }else{
      this.op_no1 = ''

    }
  }
  getUserOPData(value){
    this.getUserOCData(value)
  }

  getUserOCData(value){
    console.log('selectedUserData=',this.selectedUserData)
    this.userOCData = []
    this.all_oc_total = 0
    this.userUniqueOCData = []
    this.unique_oc_total = 0
    this.userPoints = null
    this.api.send('queryDb',{
      sql:`SELECT lot_no,point_no,session_no, number, points, P.user_id from points as P left join lots as L on(L.id = P.lot_id) where (number = ${value.pan}  OR number = '${value.op}' OR number >= '${value.op.toString() + '0'}' and number <= '${value.op.toString() + '9'}') and market = '${this.currentMarket}' and DATE(P.created) = '${this.formatedDate}' and P.user_id = ${this.selectedUserData.id} and P.session_no = 1 and P.consider_status = 1 order by number + 0`
    }).then((res:any)=>{
      console.log("user oc data==",res.data)
      this.userOCData = res.data
      this.all_oc_total = this.userOCData.reduce((a,c)=>a+c.points,0)
    })

    this.api.send('queryDb',{
      sql:`SELECT session_no, number, sum(points) as points, user_id from points where (number = ${value.pan}  OR number = '${value.op}' OR number >= '${value.op.toString() + '0'}' and number <= '${value.op.toString() + '9'}') and market = '${this.currentMarket}' and DATE(created) = '${this.formatedDate}' and user_id = ${this.selectedUserData.id}  and session_no = 1  and consider_status = 1 group by number order by number + 0`
    }).then((res:any)=>{
      // console.log("user oc data==",res.data)
      this.userUniqueOCData = res.data
      this.unique_oc_total = this.userUniqueOCData.reduce((a,c)=>a+c.points,0)
    })

    this.api.send('queryDb',{
      sql:`SELECT SUM(CASE WHEN session_no = 1 THEN points ELSE 0 END) as session1_amt, SUM(CASE WHEN session_no = 2 THEN points ELSE 0 END) as session2_amt, SUM(points) as total_session_amt, user_id from points where market = '${this.currentMarket}' and DATE(created) = '${this.formatedDate}' and user_id = ${this.selectedUserData.id} and consider_status = 1`
    }).then((res:any)=>{

      if(res.data.length > 0){

        this.userPoints = res.data[0]
      }else{
        this.userPoints = null
      }
    })
  }

  refreshClientList(){
    return new Promise((resolve,reject)=>{

      this.api.send("findDb",{
        table:"users",
        conditions:[{'role':'main-customer'}],
        order:"username ASC"
      }).then((res:any)=>{
        resolve(res.data);
      });
    })
  }

  calculateOP(number){
    // console.log("entered key=",number)
    // console.log("session key=",session)
    number = number.toString();
    if(number.length == 3){
      let calculateValues:number = parseInt(number.charAt(0))+parseInt(number.charAt(1))+parseInt(number.charAt(2));
      // console.log("calc=",calculateValues)


        this.opForm.patchValue({'op':parseInt(calculateValues.toString().split('').pop())})
    }else{


        this.opForm.patchValue({'op':''})

    }
  }

  getOP(event){
    let number = event.target.value.toString();
    if(number.length == 3){
      let calculateValues:number = parseInt(number.charAt(0))+parseInt(number.charAt(1))+parseInt(number.charAt(2));

      this.op_no = parseInt(calculateValues.toString().split('').pop())
    }else{
      this.op_no = ''

    }
  }





  getCustomerData(value){
    this.loading.dataLoading(true);
    this.opData = []
    this.op_total = 0
    this.pan_total = 0
    this.api.send("queryDb",{
      sql:`SELECT L.lot_no,P.point_no, U.id, U.username, SUM(CASE WHEN P.number = '${value.pan}' THEN P.points ELSE 0 END) as pan_amt,  SUM(CASE WHEN P.number = '${value.op}' THEN P.points ELSE 0 END) as op_amt from points as P left join lots as L on(L.id = P.lot_id) left join users as U on (U.id = P.user_id) WHERE P.market = '${this.currentMarket}' and DATE(P.created) = '${this.formatedDate}' and P.session_no = 1 and consider_status = 1 group By P.user_id order By username `

    }).then(async (res:any)=>{
      console.log("asd==",res.data)
      if(res.data.length > 0){

        this.opData = await res.data.filter((item:any)=>{
          if(item.pan_amt != 0 || item.op_amt != 0){
            this.op_total = this.op_total + item.op_amt
            this.pan_total = this.pan_total + item.pan_amt
            return item
          }
        })

        this.loading.dataLoading(false);
        // console.log("opData==",this.opData)
      }else{
        this.loading.dataLoading(false);
      }
    })
  }

  getTotal(type){
    if(type == 1){
      return this.all_points.reduce((a,c)=>a+c.points,0)
    }else if(type == 2){
      return this.gunule_points.reduce((a,c)=>a+c.points,0)
    }else if(type == 3){
      return this.ulta_gunule_points.reduce((a,c)=>a+c.points,0)
    }else if(type == 4){
      return this.sangam_points.reduce((a,c)=>a+c.points,0)
    }else if(type == 5){
      return this.adik_points.reduce((a,c)=>a+c.points,0)
    }else if(type == 6){
      return this.berij_points.reduce((a,c)=>a+c.points,0)
    }
  }


  getExtraPoints(){
    this.all_points = []
    this.api.send("queryDb",{
      sql:`SELECT P.*, U.username FROM points as P left join users as U on (U.id = P.user_id) where (gunule IS NOT NULL OR ulta_gunule IS NOT NULL  OR sangam IS NOT NULL OR adik IS NOT NULL OR berij IS NOT NULL)  AND P.market = '${this.currentMarket}' and DATE(P.created) = '${this.formatedDate}' AND gs_number IS NOT NULL ORDER BY username ASC`
    }).then((res:any)=>{
      // console.log("res.data==",res.data)
      let result:any = _.groupBy(res.data, "user_id")
      let userWise= _.values(result);
      // console.log("result==",result)
      // console.log("userWise==",userWise)
     
      for(let i=0;i<userWise.length;i++){
        // console.log("result=[]=",userWise[i])
        this.all_points = [...this.all_points, ...userWise[i]]
        this.all_points.push({username:userWise[i][0].username, points:0, points_total : userWise[i].reduce((a,c)=>a+c.points,0)})
      }
      // console.log("all_points==",this.all_points)
      // this.all_points = res.data
    })
    this.api.send("queryDb",{
      sql:`SELECT P.*, U.username FROM points as P left join users as U on (U.id = P.user_id) where gunule IS NOT NULL AND P.market = '${this.currentMarket}' and DATE(P.created) = '${this.formatedDate}' AND gs_number IS NOT NULL  ORDER BY username ASC`
    }).then((res:any)=>{
      this.gunule_points = res.data
    })
    this.api.send("queryDb",{
      sql:`SELECT P.*, U.username FROM points as P left join users as U on (U.id = P.user_id) where ulta_gunule IS NOT NULL AND P.market = '${this.currentMarket}' and DATE(P.created) = '${this.formatedDate}' AND gs_number IS NOT NULL  ORDER BY username ASC`
    }).then((res:any)=>{
      this.ulta_gunule_points = res.data
    })
    this.api.send("queryDb",{
      sql:`SELECT P.*, U.username FROM points as P left join users as U on (U.id = P.user_id) where sangam IS NOT NULL AND P.market = '${this.currentMarket}' and DATE(P.created) = '${this.formatedDate}' AND gs_number IS NOT NULL  ORDER BY username ASC`
    }).then((res:any)=>{
      this.sangam_points = res.data
    })
    this.api.send("queryDb",{
      sql:`SELECT P.*, U.username FROM points as P left join users as U on (U.id = P.user_id) where adik IS NOT NULL AND P.market = '${this.currentMarket}' and DATE(P.created) = '${this.formatedDate}' AND gs_number IS NOT NULL  ORDER BY username ASC`
    }).then((res:any)=>{
      this.adik_points = res.data
    })
    this.api.send("queryDb",{
      sql:`SELECT P.*, U.username FROM points as P left join users as U on (U.id = P.user_id) where berij IS NOT NULL AND P.market = '${this.currentMarket}' and DATE(P.created) = '${this.formatedDate}' AND gs_number IS NOT NULL  ORDER BY username ASC`
    }).then((res:any)=>{
      this.berij_points = res.data
    })
  }
  // getExtraPoints(){
  //   this.api.send("queryDb",{
  //     sql:`SELECT P.*, U.username FROM points as P left join users as U on (U.id = P.user_id) where ((gunule IS NOT NULL OR gunule_point_id IS NOT NULL) OR (ulta_gunule IS NOT NULL OR ulta_gunule_point_id IS NOT NULL) OR (sangam IS NOT NULL OR sangam_point_id IS NOT NULL) OR adik IS NOT NULL OR berij IS NOT NULL)  AND P.market = '${this.currentMarket}' and DATE(P.created) = '${this.formatedDate}' AND gs_number IS NOT NULL AND consider_status = 1`
  //   }).then((res:any)=>{
  //     this.all_points = res.data
  //   })
  //   this.api.send("queryDb",{
  //     sql:`SELECT P.*, U.username FROM points as P left join users as U on (U.id = P.user_id) where (gunule IS NOT NULL OR gunule_point_id IS NOT NULL) AND P.market = '${this.currentMarket}' and DATE(P.created) = '${this.formatedDate}' AND gs_number IS NOT NULL AND consider_status = 1`
  //   }).then((res:any)=>{
  //     this.gunule_points = res.data
  //   })
  //   this.api.send("queryDb",{
  //     sql:`SELECT P.*, U.username FROM points as P left join users as U on (U.id = P.user_id) where (ulta_gunule IS NOT NULL OR ulta_gunule_point_id IS NOT NULL) AND P.market = '${this.currentMarket}' and DATE(P.created) = '${this.formatedDate}' AND gs_number IS NOT NULL AND consider_status = 1`
  //   }).then((res:any)=>{
  //     this.ulta_gunule_points = res.data
  //   })
  //   this.api.send("queryDb",{
  //     sql:`SELECT P.*, U.username FROM points as P left join users as U on (U.id = P.user_id) where (sangam IS NOT NULL OR sangam_point_id IS NOT NULL) AND P.market = '${this.currentMarket}' and DATE(P.created) = '${this.formatedDate}' AND gs_number IS NOT NULL AND consider_status = 1`
  //   }).then((res:any)=>{
  //     this.sangam_points = res.data
  //   })
  //   this.api.send("queryDb",{
  //     sql:`SELECT P.*, U.username FROM points as P left join users as U on (U.id = P.user_id) where adik IS NOT NULL AND P.market = '${this.currentMarket}' and DATE(P.created) = '${this.formatedDate}' AND gs_number IS NOT NULL AND consider_status = 1`
  //   }).then((res:any)=>{
  //     this.adik_points = res.data
  //   })
  //   this.api.send("queryDb",{
  //     sql:`SELECT P.*, U.username FROM points as P left join users as U on (U.id = P.user_id) where berij IS NOT NULL AND P.market = '${this.currentMarket}' and DATE(P.created) = '${this.formatedDate}' AND gs_number IS NOT NULL AND consider_status = 1`
  //   }).then((res:any)=>{
  //     this.berij_points = res.data
  //   })
  // }

}
