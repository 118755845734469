import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {FormControl} from '@angular/forms';
import { FormBuilder, Validators, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { ApiService } from '../api.service'
import { SocketService } from '../socket.service';
import { LoadingService } from '../loading.service'
import { ObservablesService } from '../observables.service'
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { ErrorStateMatcher } from '@angular/material/core';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators'
import { environment } from 'src/environments/environment';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}
@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent implements OnInit {

  @Output() onUserPicked: EventEmitter<any> = new EventEmitter<any>();
  myControl = new FormControl();
  users: any=[];
  userData: any=[];
  options: any=[];
  pointsArr: any=[];
  filteredOptions: Observable<string[]>;
  type:any;
  status:boolean = false;
  lotViewStatus:boolean = false;
  typeViewStatus:boolean = false;
  weekResultViewStatus:boolean = false;
  lastWeekResultViewStatus:boolean = false;
  market:any
  activeBtn:any=0;
  showCurrentLotsStatusSubscription:any;
  activeStatus:boolean = true;
  viewLotStatus:boolean = false;
  messageBoxStatus:boolean = false;
  passwordBoxStatus:boolean = false;
  messageForm:FormGroup
  passwordForm:FormGroup
  messageString:string=''
  matcher = new MyErrorStateMatcher();
  constructor(private socket:SocketService,private fb:FormBuilder, private loading:LoadingService,private api:ApiService,private observables:ObservablesService) {
      this.messageForm = this.fb.group({
        message:[null,Validators.compose([Validators.required])]
      });
      this.passwordForm = this.fb.group({
        old_password:['',Validators.compose([Validators.required])],
        new_password:['',Validators.compose([Validators.required])],
        confirm_password:['']
      },{validator: this.checkPasswords });

   }

   checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.new_password.value;
    let confirmPass = group.controls.confirm_password.value;

    return pass === confirmPass ? null : { notSame: true }
  }

  openMenu(){
    this.observables.setAdminMenuStatus({menuState:'in'})
  }

  ngOnInit() {

    this.observables.setUserInHeaderStatus.subscribe((res:any)=>{
      this.status = res.status;
      this.activeStatus = res.activeStatus;

      if(this.status && this.activeStatus){
        this.lotViewStatus = false;
        this.typeViewStatus = false;
        this.weekResultViewStatus = false;
        this.lastWeekResultViewStatus = false;
      }
    })

    this.observables.setActiveTabStatus.subscribe(()=>{
      this.activeBtn = 1;
    })

    this.showCurrentLotsStatusSubscription = this.observables.showCurrentLotsStatus.subscribe(()=>{
      this.getCurrentMarket();
    })

    this.observables.showArrowStatus.subscribe(()=>{
      this.viewLotStatus = true;
    })
    this.observables.hideArrowStatus.subscribe(()=>{
      this.viewLotStatus = false;
    })

    this.refreshClientList().then((res:any)=>{
      this.options = res;
      this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

    });
  }

  goBack(){
    this.viewLotStatus = false;
    this.observables.hideArrowStatus.next()
  }


  refreshClientList(){
    return new Promise((resolve,reject)=>{

      this.api.send("findDb",{
        table:"users",
        conditions:[{'role':'main-customer'}]
      }).then((res:any)=>{
        // console.log("\nRefreshed users list",res);

        this.users = res.data;
        resolve(this.users);

      });

    })


  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.username.toLowerCase().includes(filterValue));
  }
onSelectionChanged(_event: any,userData) {

    if (_event.isUserInput) {
           //do something
           // console.log("this.lotViewStatus-",this.lotViewStatus)
           // console.log("this.typeViewStatus-",this.typeViewStatus)
           // console.log("this.weekResultViewStatus-",this.weekResultViewStatus)
           this.userData = userData;
           // console.log("this.userData-",this.userData)
           this.type = this.userData.type.split(',')[0];
           if(!this.lotViewStatus && !this.typeViewStatus && !this.weekResultViewStatus && !this.lastWeekResultViewStatus){

             this.onUserPicked.emit(this.userData);
           }


           if(this.lotViewStatus || this.typeViewStatus || this.weekResultViewStatus || this.lastWeekResultViewStatus){
            if(!this.lastWeekResultViewStatus){

              this.getCurrentMarket();
            }

             this.observables.sendUserDataStatus.next({data:this.userData,lotViewStatus:this.lotViewStatus,typeViewStatus:this.typeViewStatus,weekResultViewStatus:this.weekResultViewStatus, lastWeekResultViewStatus:this.lastWeekResultViewStatus})
           }
        }
  }

  getCurrentMarket(){
    this.loading.dataLoading(true)
    // console.log("getting current market")
    this.api.send("findDb",{
      table:"session_times",
      conditions:[{"curtime() >":"$start_time$","curtime() <":"$end_time$",pride_status:environment.prideStatus}]
    }).then((sessionTimeRes:any)=>{
      // console.log("current running session=",sessionTimeRes)
      if(sessionTimeRes.data.length>0){
        this.market = sessionTimeRes.data[0].market;
        this.getCurrentMarketLots().then((result:any)=>{
          // console.log("mine current market lots and points==",result);
          this.observables.showLotsStatus.next(result);
        });
      }else{
        this.loading.dataLoading(false)
      }
    }).catch(err=>{console.log("error while fetching session times",err)})
  }


  getCurrentMarketLots(){
    return new Promise((resolve,reject)=>{
      this.api.send("findDb",{
        table:"lots as L",
        fields:["L.*, L.id as lot_id"],
        conditions:[{'L.day_night':this.market,"DATE(L.created)":"$CURDATE()$",'L.user_id':this.userData.id}],
        groupBy:"L.id",
        order:"L.lot_no"
      }).then((lotResponse:any)=>{
        // console.log("current lots=",lotResponse)
         if(lotResponse.data.length > 0){
          resolve(this.getPoints(lotResponse.data));
        }else{
          resolve(lotResponse.data);
        }
      }).catch(error=>{console.log("error while fetching current lots=",error);reject(error)})
    })

  }

  getPoints(lotResponse){
    return new Promise((resolve,reject)=>{
      this.pointsArr=[];
      for(let i=0; i<lotResponse.length;i++){
        this.api.send("findDb",{
          table:"points",
          joins:[{type:"left",table:'type_variables as T',conditions:["T.type = points.type_no"]}],
          conditions:[{"lot_id":lotResponse[i].lot_id, consider_status:1}]
        }).then((pointResult:any)=>{
          // console.log('point result=',pointResult.data);
          lotResponse[i].points = pointResult.data;
          this.pointsArr.push({lot:lotResponse[i],points:pointResult.data});
          // console.log("final points==",this.pointsArr)
          if(i == lotResponse.length-1){
            resolve(this.pointsArr);
          }
        }).catch(pointsErr=>{console.log("Error while getting points=",pointsErr);reject(pointsErr)});
      }
    })
  }

  allUsersTotalPoints(){
    this.activeBtn = 4;
    this.observables.showPage({page:'userPoints',data:{}});
  }
  subAdminSummary(){
    this.activeBtn = 5;
    this.observables.showPage({page:'subAdmin',data:{}});
  }
  gotoChart(){
    this.activeBtn = 13;
    this.observables.showPage({page:'chart',data:{}});
  }

  cashEntry(){
    this.activeBtn = 6;
    this.observables.showPage({page:'cashEntry',data:{}});
  }

  loadingView(){
    this.activeBtn = 8;
    this.observables.showPage({page:'loading',data:{}});
  }
  dailyReport(){
    this.activeBtn = 10;
    this.observables.showPage({page:'dailyReport',data:{}});
  }
  cashDetails(){
    this.activeBtn = 11;
    this.observables.showPage({page:'cashDetails',data:{}});
  }
  digits(){
    this.activeBtn = 12;
    this.observables.showPage({page:'digits',data:{}});
  }

  currentLots(){
    this.activeBtn = 1;
    this.lotViewStatus = true;
    this.typeViewStatus = false;
    this.weekResultViewStatus = false;
    this.lastWeekResultViewStatus = false;
    this.observables.showPage({page:'reports',data:{lotViewStatus:true,reportViewStatus:false,typeViewStatus:false,weekResultViewStatus:false,lastWeekResultViewStatus:false}});
  }
  typeWiseReport(){
    this.activeBtn = 2;
    this.lotViewStatus = false;
    this.typeViewStatus = true;
    this.weekResultViewStatus = false;
     this.lastWeekResultViewStatus = false;

    this.observables.showPage({page:'reports',data:{lotViewStatus:false,reportViewStatus:false,typeViewStatus:true,weekResultViewStatus:false,lastWeekResultViewStatus:false}});
  }

  weekResult(){
    this.activeBtn = 3;
    this.lotViewStatus = false;
    this.typeViewStatus = false;
    this.weekResultViewStatus = true;
     this.lastWeekResultViewStatus = false;
    this.observables.showPage({page:'reports',data:{lotViewStatus:false,reportViewStatus:false,typeViewStatus:false,weekResultViewStatus:true,lastWeekResultViewStatus:false}});
  }
  lastWeekResult(){
    this.activeBtn = 4;
    this.lotViewStatus = false;
    this.typeViewStatus = false;
    this.weekResultViewStatus = false;
    this.lastWeekResultViewStatus = true;
    this.observables.showPage({page:'reports',data:{lotViewStatus:false,reportViewStatus:false,typeViewStatus:false,weekResultViewStatus:false,lastWeekResultViewStatus:true, weekStatus:2}});
  }

  // this.api.send("findDb",{
  //   table:"users"
  // }).then((res:any)=>{
  //   for(let i=0;i<res.data.length;i++){
  //     this.api.send("insertDb",{
  //       table:'user_ocb',
  //       data:{
  //         user_id:res.data[i].id,
  //         ocb:0,
  //         balance_cash:0
  //       }
  //     }).then((resp:any)=>{
  //       console.log("insert res",resp)
  //     })
  //   }
  // })
  addMessage(){

    this.api.send('findDb',{
      table:'messages',
      order:"id DESC"
    }).then((res:any)=>{
      if(res.data.length > 0){
        this.messageString = res.data[0].message;
        this.messageForm.patchValue({"message":res.data[0].message});
        this.activeBtn = 7;
        this.messageBoxStatus = true;
      }else{
        this.activeBtn = 7;
        this.messageBoxStatus = true;
      }
    })
  }

  closeModal(){
    this.activeBtn = 0;
    this.messageBoxStatus = false;
  }

  hideMessage(value){
    this.api.send('updateDb',{
      table:'messages',
      data:{
        message:value.message,
        show_status:0
      }
    }).then((res:any)=>{
      if(res.data.affectedRows){
        this.socket.socket.emit("updateMessage",{"data":"message","action":"hide"})
        this.observables.showToastMessage({type:0, message:"Message Updated And Hiding..!"})
        this.messageBoxStatus = false;
      }else{

        this.observables.showToastMessage({type:1, message:"Message Not updated, Try again...!"})
      }
    })
  }
  showMessage(value){

    if(this.messageString.length > 0){
      this.api.send('updateDb',{
        table:'messages',
        data:{
          message:value.message,
          show_status:1
        },
        where:[{"show_status":0}]
      }).then((res:any)=>{
        if(res.data.affectedRows){
          this.socket.socket.emit("updateMessage",{"data":"message","action":"show"})
          this.observables.showToastMessage({type:0, message:"Message Updated And Displaying..!"})
          this.messageBoxStatus = false;
        }else{

          this.observables.showToastMessage({type:1, message:"Message Not updated, Try again...!"})
        }
      })
    }else{
      this.api.send('insertDb',{
        table:'messages',
        data:{
          message:value.message,
          show_status:1
        }
      }).then((res:any)=>{
        if(res.data.affectedRows){
          this.observables.showToastMessage({type:0, message:"Message Saved And Displaying..!"})
          this.messageBoxStatus = false;
        }else{

          this.observables.showToastMessage({type:1, message:"Message Not saved, Try again...!"})
        }
      })
    }

  }

  changePassword(){
    this.activeBtn = 9;
    this.passwordBoxStatus = true;
  }

  updatePassword(value){
    // console.log("form value=",value)
    if(confirm("Are you sure,That you wanted to change Password?")){

      this.api.send("findDb",{
        table:"users",
        conditions:[{role:"admin",password:value.old_password}]
      }).then((res:any)=>{
        if(res.data.length > 0){
          this.api.send("updateDb",{
            table:"users",
            data:{
              password:value.confirm_password
            },
            conditions:[{role:"admin",password:value.old_password}]
          }).then((updateRes:any)=>{
            if(updateRes.data.affectedRows){
              this.passwordForm.reset();
              this.observables.showToastMessage({type:0,message:"Password Updated Successfully...!"})
            }else{
              this.observables.showToastMessage({type:1,message:"Can\'t Update Password, Try again...!"})
            }
          })
        }else{
          this.observables.showToastMessage({type:1,message:"Invalid Current Password...!"})
        }
      })
    }
  }

  closePasswordModal(){
    this.activeBtn = 0;
    this.passwordBoxStatus = false;
  }



}
