import { Component, OnInit, Input } from '@angular/core';
import { ApiService }  from '../api.service'
import * as _ from 'underscore';
@Component({
  selector: 'app-three-digits',
  templateUrl: './three-digits.component.html',
  styleUrls: ['./three-digits.component.scss']
})
export class ThreeDigitsComponent implements OnInit {

  numbers:any = []
  total:any = 0
  selectedData:any = null
  selectedType:any = 1
  _selectedUserData:any = null
  @Input() set _selectedData(_selectedData:any){
    console.log('selected data ==',_selectedData)
    this.selectedData = _selectedData;
  }
  @Input() set selectedUserData(selectedUserData:any){
    console.log('selected user data ==',selectedUserData)
   this._selectedUserData = selectedUserData;
   if(this._selectedUserData){

     this.getNumbers(true)
   }else{
     this.getNumbers()
   }
  }
  constructor(private api:ApiService) { }

  ngOnInit() {
  }

  getNumbers(userStatus = false){
    // SELECT T.number, sum(P.points) FROM two_digits as T left join points as P on (P.number = T.number) where group by T.number
    let conditions:any = []
    if(userStatus){
      conditions = [{"DATE(P.created)":this.selectedData.date,"P.market":this.selectedData.market,"P.session_no":this.selectedData.selectedType, "P.user_id":this._selectedUserData.id}]
    }else{
      conditions = [{"DATE(P.created)":this.selectedData.date,"P.market":this.selectedData.market,"P.session_no":this.selectedData.selectedType}]
    }

    this.api.send("findDb",{
      fields:["T.pan, T.number, SUM(P.points) as points"],
      table:"three_digits as T",
      joins:[{
        type:'left',
        table:'points as P',
        conditions:["P.number = T.number"]
      }],
      conditions:conditions,
      groupBy:"T.number"
    }).then((res:any)=>{
      // this.numbers = res.data



      let result:any = _.groupBy(res.data, "pan")

      this.numbers = _.values(result);
      // var items = values.map((item, index) => {
      //   return [item[index]]
      // });

      // console.log("result==",result)
      console.log("values==",this.numbers)
      // console.log("items==",items)
      this.getTotal(userStatus)
    })
  }
  getTotal(userStatus){
    let conditions:any = []
    if(userStatus){
      conditions = [{"DATE(P.created)":this.selectedData.date,"P.market":this.selectedData.market,"P.session_no":this.selectedData.selectedType, "P.user_id":this._selectedUserData.id}]
    }else{
      conditions = [{"DATE(P.created)":this.selectedData.date,"P.market":this.selectedData.market,"P.session_no":this.selectedData.selectedType}]
    }


    this.api.send("findDb",{
      fields:["SUM(P.points) as total"],
      table:"three_digits as T",
      joins:[{
        type:'left',
        table:'points as P',
        conditions:["P.number = T.number"]
      }],
      conditions:conditions
    }).then((res:any)=>{
      this.total = res.data[0].total
    })
  }
  TypeChanged(event){
    console.log("value==",event)
    this.getNumbers(this._selectedUserData?true:false)
    // this.selectedType = event.target.value
     // this._selectedData.selectedType = event.target.value;
    // this._selectedData = this._selectedData;
  }


}
