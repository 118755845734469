import { Component, HostListener, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, transition, animate} from '@angular/animations';
import { ObservablesService } from './observables.service';
import { LoadingService } from './loading.service';
import { SocketService } from './socket.service';
import { SocketObservablesService } from './socket-observables.service';
import { ApiService } from './api.service';
import { KeyboardEventService } from './keyboard-event.service';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: {'(window:keydown)': 'hotkeys($event)'},
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)',
      })),
      state('out', style({
        transform: 'translate3d(-100%, 0, 0)',
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
    trigger('adminSlideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)',
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)',
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class AppComponent {
  title = 'game';
  userLoggedInStatus = false;
  adminLoggedInStatus = false;
  subAdminLoggedInStatus = false;
  viewHeaderStatus = false;
  toastMessage='';
  messageStatus= false;
  menuState:string = 'out';
  adminMenuState:string = 'out';
  messageColor:any
  loggedInStatus:boolean = false
  t:any=null;
  type:any;
  userData:any;
  statusFreez:boolean = false;
  dataFreez:boolean = false;
  userKeyPadStatus:boolean = false;
  userSaveStatus:boolean = false;
  userAlertStatus:boolean = false;
  focusedElement:any;
  keyElements:any=[];
  fixedElements:any=[];
  fixedAddButtonElement:any=null;
  fixedSaveButtonElement:any=null;
  fixedElementsIndex:any= 0;
  editElements:any=[];
  editElementStatus:boolean = false;
  editStatus:boolean = false;
  editFixedStatus:boolean = false;
  sessionElements:any=[];
  typeElements:any=[];
  saveLotElement:any=[];
  ticketData:any=[];
  alertElements:any=[];
  editIndex:number = 0;
  toggleElement:ElementRef;
  mainContainer:ElementRef;
  loadingMessage:string = 'Please wait &hellip;';
  statusMessage:string = 'Please wait &hellip;'
  iconName:string = "hourglass-half"
  // listen for keyboardEvent
    @HostListener('document:keyup', ['$event'])
  // this function will call when ever key pressed
    onKeyUp(ev:KeyboardEvent) {
     // do something meaningful with it
     // console.log(`The user just pressed ${ev.key}!`);

   }

   // this function for setting shortcut keys.
    hotkeys(event){

      // console.log("event===",event)

      if(localStorage.getItem("adminLoggedInStatus") == "1"){
       if (event.altKey && event.keyCode == 83 ){
         if(this.editFixedStatus){
           this.fixedSaveButtonElement.click()
         }else{

           this.observables.savePointsStatus.next();
         }

       }else if(event.altKey && event.keyCode == 49){
        this.observables.moreSubOptionsStatus.next(0)
       }else if(event.altKey && event.keyCode == 50){
        this.observables.moreSubOptionsStatus.next(1)
       }else if(event.altKey && event.keyCode == 51){
        this.observables.moreSubOptionsStatus.next(2)
       }else if(event.altKey && event.keyCode == 52){
        this.observables.moreSubOptionsStatus.next(3)
       }else if(event.altKey && event.keyCode == 53){
        this.observables.moreSubOptionsStatus.next(4)
       }else if(event.altKey && event.keyCode == 54){
        this.observables.moreSubOptionsStatus.next(5)
       }else if(event.altKey && event.keyCode == 55){
        this.observables.moreSubOptionsStatus.next(6)
       }else if(event.altKey && event.keyCode == 56){
        this.observables.moreSubOptionsStatus.next(7)
       }else if(event.altKey && event.keyCode == 57){
        this.observables.moreSubOptionsStatus.next(8)
       }else if(event.altKey && event.keyCode == 67){

         this.observables.copyPointsStatus.next();
       }else if(event.altKey && event.keyCode == 76){

         this.observables.showEditLotsStatus.next();
        }else if(event.altKey && event.keyCode == 71){
          event.preventDefault()
          this.observables.importFileStatus.next();
        }else if(event.altKey && event.keyCode == 70){
         event.preventDefault()
         this.observables.fixedPointStatus.next();
       }else if(event.altKey && event.keyCode == 82){
         // console.log("show user points")
         this.observables.showPointsStatus.next();
       }else if(event.altKey && event.keyCode == 86){
         // console.log("show user points")
         this.observables.editPointsStatus.next();
       }else if(event.keyCode == 113){
         // console.log("show user points")
         this.observables.moreOptionsStatus.next();
       }else if(event.keyCode == 27){
         // console.log("show user points")


         if(this.editFixedStatus){
           this.editFixedStatus = false
           this.observables.fixedCloseStatus.next();
         }else{
           this.observables.moreOptionsCloseStatus.next();
         }
       }else if(event.altKey && event.keyCode == 65){
         // console.log("enabling the points")
         if(this.editElementStatus){

           this.observables.enableEntryBlock()
         }
         // this.observables.showPointsStatus.next();
       }
     }else if(localStorage.getItem("userLoggedInStatus") == "1" || localStorage.getItem("subAdminLoggedInStatus") == "1"){
       if (event.altKey && event.keyCode == 83 ){

         //if(this.ticketData.length > 0){

           this.observables.saveUserPointsStatus.next();
        // }else{

         //}

       }
     }

     this.handleKeyboardEvent(event.key);

   }

   setAdminMenuStatus(){
     this.adminMenuState = 'out'
     this.observables.hideDelete.next({status:this.adminMenuState})
   }

   ngAfterViewInit() {
     setTimeout(_ => this.dataFreez = false);

     this.loading.loadingStatus.subscribe(loadingRes=>{
       if(loadingRes.status){
         setTimeout(_ => this.dataFreez = true);
         // this.loadingMessage = loadingRes.message;
       }else{
         setTimeout(_ => this.dataFreez = false);

       }
     })
  }

  constructor(private socketObservables:SocketObservablesService,private api:ApiService, private elementRef:ElementRef, private key:KeyboardEventService, private socket: SocketService, private loading:LoadingService,  private changeDetect: ChangeDetectorRef, private router:Router,private observables:ObservablesService){

    //start socket.
    if(!this.socket.startSocket(loading)){
      //console.log("\nTrigger in appcomponent")
      this.t =setTimeout(()=>{

        this.statusFreez = true
        this.statusMessage = "Server is not runnig, please check with support person"

      },3000)
   }


   $(document).ready(()=> {
     console.log("ready")
     document.addEventListener(
      "visibilitychange"
      , () => {
        if (document.hidden) {
          // console.log("document is hidden");
        }else{
          // console.log("document is showing",this.observables);
          this.observables.checkSessionStatus.next()
        }
      }
    );
   });


    this.loading.status.subscribe(res=>{
      //console.log("\nLoading service called")
      if(res.status){

        //to avoid sharp flickering of loading page

        this.t =setTimeout(()=>{

          this.statusFreez = res.status
          this.statusMessage = res.message
          this.iconName = res.iconName

        },3000)

      }else{
        clearTimeout(this.t);
        this.statusFreez = res.status
        this.statusMessage = res.message
        this.iconName = res.iconName

      }

      this.changeDetect.detectChanges();

    })


    this.observables.changeHeaderStatus.subscribe((response)=>{
      if(response.status){

        this.viewHeaderStatus = true;
        this.type = response.data
      }else{
        this.viewHeaderStatus = false;

      }
    })

    this.observables.fixedCloseStatus.subscribe(()=>{
      this.editFixedStatus = false
    })




    this.observables.toastMessageStatus.subscribe((data:any)=>{

      if(data.userLoginStatus){
        this.userLoggedInStatus=data.userLoginStatus;
      }else if(data.adminLoginStatus){

        this.adminLoggedInStatus=data.adminLoginStatus;
      }else if(data.subAdminLoginStatus){

        this.subAdminLoggedInStatus=data.subAdminLoginStatus;
      }

      this.messageColor = data.type;
      this.toastMessage = data.message;
      this.messageStatus = true;
      let time:any = 3000
        if(data.hasOwnProperty('time')){
          time = data.time
      }

      setTimeout(()=>{ this.messageStatus = false }, time);
    })

    this.observables.menuStatus.subscribe((data:any)=>{
       this.menuState = data.menuState
    });
    this.observables.adminMenuStatus.subscribe((data:any)=>{
       this.adminMenuState = data.menuState
    });

    this.socketObservables.updateActiveStatus.subscribe((data:any)=>{
      // console.log("Disabled me")
      localStorage.clear();
      localStorage.setItem("logOutStatus",'1')
      this.observables.logout();
      this.router.navigate(['/home']);
    });


    //keyboard
    this.key.selectedElementStatus.subscribe((element:any)=>{
       this.focusedElement = element;
      //console.log("focused elements==",this.focusedElement)
    });
    this.key.getAllElementStatus.subscribe((elements:any)=>{
       this.keyElements = elements.mainElements;
       //console.log("elementssssssssssssssss=",this.keyElements)
        this.sessionElements = elements.sessionElements;
        this.typeElements = elements.typeElements;
       // this.toggleElement = elements.toggleElements;
       this.userKeyPadStatus = elements.userStatus;
       if(this.userKeyPadStatus){
         this.keyElements[0].focus();
       }

       // console.log("key elements are=",this.keyElements)
       //console.log("type elements are=",this.typeElements)
    });
    // this.key.getSaveElementStatus.subscribe((elements:any)=>{
    //
    //    this.saveLotElement = elements.saveLotElements;
    //    this.userSaveStatus = elements.userStatus;
    // });
    this.key.sendTicketDataStatus.subscribe((ticketData:any)=>{
      //console.log("ticket Data==",ticketData)
       this.ticketData = ticketData;
    });
    this.key.getAlertElementStatus.subscribe((elements:any)=>{
       this.alertElements = elements.alertElements;
       //console.log("alert elements=",elements)
       // setTimeout(()=>{
       //   this.elementRef.nativeElement.querySelectorAll('.app-main-container').fadeTo(200, 1);;
       //
       // },100)
       // this.alertElements[1].focus();
       this.userAlertStatus = elements.userStatus


    });

    this.key.getFixedElementsStatus.subscribe((fixedElements:any)=>{
      this.editFixedStatus = true
      this.fixedElements = fixedElements.focussableElement
      this.fixedElementsIndex = this.fixedElements.length
      this.fixedAddButtonElement = fixedElements.addBoxElement
      this.fixedSaveButtonElement = fixedElements.saveButtonElement
    })

    this.key.getEditElementsStatus.subscribe((editElements:any)=>{
      // console.log("Editable elements are=",editElements)
      this.editStatus = true;
      this.editElements = editElements;
      this.editElementStatus = this.editElements.editStatus;
      if(this.editElementStatus){

        this.editIndex = this.editElements.focussableElement.length - 5;
        this.editElements.focussableElement[this.editIndex].focus();
      }else{
        if(this.editElements.focussableElement.length > 0){

          this.editIndex = this.editElements.focussableElement.length - 6;
          this.editElements.focussableElement[this.editIndex].focus();
        }
      }
    })

    // this.observables.modalClosedStatus.subscribe(()=>{
    //   this.mainContainer.nativeElement.fadeOut(200);
    // })

    this.observables.logoutStatus.subscribe(()=>{
       this.userLoggedInStatus = false;
       this.adminLoggedInStatus = false;
       this.subAdminLoggedInStatus = false;
       this.viewHeaderStatus = false;
    });

    if(localStorage.getItem('userLoggedInStatus') =='1'){

      this.api.send("findDb",{
        table:"users",
        conditions:[{'id':localStorage.getItem("userId")}]
      }).then((userRes:any)=>{
        // console.log("user Response=",userRes)
        if(userRes.data.length > 0){

          if(userRes.data[0].active_status == 0){
            this.userLoggedInStatus = true;
            this.menuState = 'out';
            this.router.navigate(['/dashboard']);
          }else{
              localStorage.clear();
              localStorage.setItem("logOutStatus",'1')
              this.router.navigate(['/home']);
            }
        }
      })


    }else if(localStorage.getItem('adminLoggedInStatus') =='1'){
      this.adminLoggedInStatus = true;
      this.menuState = 'out';
      this.router.navigate(['/admin-dashboard']);
    }else if(localStorage.getItem('subAdminLoggedInStatus') =='1'){
      if(localStorage.getItem('selectedUserId')){
        localStorage.removeItem('selectedUserId')
      }

      this.subAdminLoggedInStatus = true;
      this.menuState = 'out';
      this.router.navigate(['/sub-admin-dashboard']);
    }else{
      //console.log("going to login")
      localStorage.setItem("logOutStatus",'1')
      this.router.navigate(['/home']);
    }
  }

  public UserSelected(userData: any) {
    // console.log('Picked User: ', userData);
    this.userData=userData;
    this.observables.getSelectedUserData(this.userData)
  }


  // function will handle keyboard events
    handleKeyboardEvent(keyCode){
      //console.log("keycode=",keyCode)

      switch (keyCode) {
        case "Enter":
        console.log("editFixedStatus==",this.editFixedStatus)
        if(this.editFixedStatus){
          // console.log("fixed elements==",this.fixedElements)
          this.fixedAddButtonElement.click()
          return
        }

        if(this.userKeyPadStatus){

          if(this.focusedElement.elementName == 'session' && this.focusedElement.status){

              this.keyElements[2].focus();

          }else if(this.focusedElement.elementName == 'type' && this.focusedElement.status){
              this.keyElements[1].focus();
          }else if(this.focusedElement.elementName == 'number' && this.focusedElement.status){
            if(this.keyElements[2].value.length > 0){
              this.keyElements[3].focus();
            }
          }else if(this.focusedElement.elementName == 'points' && this.focusedElement.status){
            if(this.keyElements[2].value.length > 0 && this.keyElements[3].value.length > 0){
              this.keyElements[4].click();
            }

          }

          if(this.userAlertStatus){
            this.alertElements[1].click();
          }


        }else{
          // console.log("element name=",this.focusedElement.elementName)
          // console.log("element status=",this.focusedElement.status)

         if(this.focusedElement.elementName == 'rate' && this.focusedElement.status){
           this.keyElements[2].focus();
          }else if(this.focusedElement.elementName == 'user' && this.focusedElement.status){
            if(this.keyElements[1].value.length > 0){

              this.keyElements[2].focus();
            }
          }else if(this.focusedElement.elementName == 'type' && this.focusedElement.status){
            if(this.keyElements[2].value.length > 0){

              this.keyElements[3].focus();
            }
          }else if(this.focusedElement.elementName == 'number' && this.focusedElement.status){
            if(this.keyElements[3].value.length > 0){
              this.keyElements[4].focus();
            }

          }else if(this.focusedElement.elementName == 'points' && this.focusedElement.status){
            if(this.keyElements[4].value.length > 0){
              this.keyElements[5].focus();
            // }else{
            //
            //   this.keyElements[6].focus();
            }
          }

            if(this.userAlertStatus){
              this.alertElements[1].click();
            }
        }

        break;

        case "1":
        if(this.userKeyPadStatus){
          if(this.focusedElement.elementName == 'session' && this.focusedElement.status){
              this.sessionElements[0].click();
          }
        }else{
        }

        break;
        case "2":
        if(this.userKeyPadStatus){
          if(this.focusedElement.elementName == 'session' && this.focusedElement.status){
              this.sessionElements[1].click();

          }
        }else{
        }

        break;
        case "8":
        if(this.userKeyPadStatus){
          if(this.focusedElement.elementName == 'type' && this.focusedElement.status){
              this.typeElements[0].click();
          }
        }else{

        }

        break;
        case "9":
        if(this.userKeyPadStatus){
          if(this.focusedElement.elementName == 'type' && this.focusedElement.status){
            if(this.typeElements.length > 1){

              this.typeElements[1].click();
            }

          }
        }else{

        }

        break;
        case "ArrowLeft":
        if(this.userKeyPadStatus){

          if(this.focusedElement.elementName == 'session' && this.focusedElement.status){

              this.keyElements[0].focus();

          }else if(this.focusedElement.elementName == 'type' && this.focusedElement.status){
              this.keyElements[3].focus();
          }else if(this.focusedElement.elementName == 'number' && this.focusedElement.status){
            //if(this.keyElements[2].value.length > 0){
              this.keyElements[1].focus();
            //}
          }else if(this.focusedElement.elementName == 'points' && this.focusedElement.status){
            //if(this.keyElements[2].value.length > 0 && this.keyElements[3].value.length > 0){
              this.keyElements[2].focus();
          //  }

          }



        }else{

          if(this.editElementStatus && this.editStatus){
            // console.log("edit elements==",this.editElements)
            this.editIndex = (this.editIndex - 1) % this.editElements.focussableElement.length;

            if(this.editIndex == -1){
              this.editIndex = this.editElements.focussableElement.length-1;
              this.editElements.focussableElement[this.editIndex].focus()
            }else{
              this.editElements.focussableElement[this.editIndex].focus();
            }
          }else if(!this.editElementStatus && this.editStatus){






            if(this.focusedElement.elementName == 'rate' && this.focusedElement.status){
              // this.keyElements[2].focus();
              this.editElements.focussableElement[4].focus()
             }else if(this.focusedElement.elementName == 'user' && this.focusedElement.status){
               if(this.keyElements[1].value.length > 0){

                 this.keyElements[0].focus();
               }
             }else if(this.focusedElement.elementName == 'type' && this.focusedElement.status){
               if(this.keyElements[2].value.length > 0){

                 this.keyElements[1].focus();
               }
             }else if(this.focusedElement.elementName == 'number' && this.focusedElement.status){
              // if(this.keyElements[3].value.length > 0){
                 this.keyElements[2].focus();
               //}

             }else if(this.focusedElement.elementName == 'points' && this.focusedElement.status){
               // if(this.keyElements[4].value.length > 0){
                 this.keyElements[3].focus();
               // }else{
               //   this.keyElements[6].focus();
               // }
             }else if(this.focusedElement.elementName == 'edit' && this.focusedElement.status){
               this.keyElements[4].focus();
             }











            // this.editIndex = (this.editIndex - 1) % this.editElements.focussableElement.length;
            //
            // if(this.editIndex == -1){
            //   this.editIndex = this.editElements.focussableElement.length-1;
            //   this.editElements.focussableElement[this.editIndex].focus()
            // }else{
            //   this.editElements.focussableElement[this.editIndex].focus();
            // }
          }


        }
        break;
        case "ArrowRight":
        if(this.userKeyPadStatus){
          if(this.focusedElement.elementName == 'session' && this.focusedElement.status){

              this.keyElements[2].focus();

          }else if(this.focusedElement.elementName == 'type' && this.focusedElement.status){
              this.keyElements[1].focus();
          }else if(this.focusedElement.elementName == 'number' && this.focusedElement.status){
            //if(this.keyElements[2].value.length > 0){
              this.keyElements[3].focus();
            //}
          }else if(this.focusedElement.elementName == 'points' && this.focusedElement.status){
            //if(this.keyElements[2].value.length > 0 && this.keyElements[3].value.length > 0){
              this.keyElements[0].focus();
          //  }

          }
        }else{

          if(this.editElementStatus && this.editStatus){
              if(this.editIndex == this.editElements.focussableElement.length -1){
                this.editIndex = ((this.editIndex+1) % (this.editElements.focussableElement.length-1))-1;

              }else{

                this.editIndex = (this.editIndex+1) % this.editElements.focussableElement.length;
              }

              this.editElements.focussableElement[this.editIndex].focus()

           }else if(!this.editElementStatus && this.editStatus){



             if(this.focusedElement.elementName == 'rate' && this.focusedElement.status){
               this.keyElements[1].focus();
              }else if(this.focusedElement.elementName == 'user' && this.focusedElement.status){
                if(this.keyElements[1].value.length > 0){

                  this.keyElements[2].focus();
                }
              }else if(this.focusedElement.elementName == 'type' && this.focusedElement.status){
                if(this.keyElements[2].value.length > 0){

                  this.keyElements[3].focus();
                }
              }else if(this.focusedElement.elementName == 'number' && this.focusedElement.status){
                if(this.keyElements[3].value.length > 0){
                  this.keyElements[4].focus();
                }

              }else if(this.focusedElement.elementName == 'points' && this.focusedElement.status){
                //if(this.keyElements[4].value.length > 0){
                //   this.keyElements[5].focus();
                // }else{
                  this.keyElements[6].focus();
                //}
              }else if(this.focusedElement.elementName == 'edit' && this.focusedElement.status){
                this.keyElements[0].focus();
              }









             // if(this.editIndex == this.editElements.focussableElement.length -1){
             //   this.editIndex = ((this.editIndex+1) % (this.editElements.focussableElement.length-1))-1;
             //
             // }else{
             //
             //   this.editIndex = (this.editIndex+1) % this.editElements.focussableElement.length;
             // }
             //
             // this.editElements.focussableElement[this.editIndex].focus()
           }

        }
        break;
        case "ArrowUp":
        if(this.editFixedStatus){
          if(this.fixedElementsIndex > 0){
            this.fixedElementsIndex = this.fixedElementsIndex - 1
            this.fixedElements[this.fixedElementsIndex].focus()
          }
        }else{





          // if(this.focusedElement.elementName == 'number' && this.focusedElement.status){
          //   this.keyElements[1].focus();
          // }else if(this.focusedElement.elementName == 'points' && this.focusedElement.status){
          //   this.keyElements[1].focus();
          // }else if(this.focusedElement.elementName == 'session' && this.focusedElement.status){
          //   this.keyElements[1].focus();
          // }
        }
        break;
        case "ArrowDown":
        if(this.editFixedStatus){
          if(this.fixedElementsIndex < this.fixedElements.length - 1){

            this.fixedElementsIndex = this.fixedElementsIndex + 1
            this.fixedElements[this.fixedElementsIndex].focus()
          }
        }else{
          //  if(this.focusedElement.elementName == 'type' && this.focusedElement.status){
          //   this.keyElements[2].focus();
          // }
        }
        break;
        case "PageUp":
          if(this.editElementStatus && this.editStatus){
            // console.log("before page up index=",this.editIndex)
            // this.editIndex = this.editElements.focussableElement.length % (this.editIndex + 5);
            //
            // console.log("after page up index=",this.editIndex)
            //   this.editElements.focussableElement[this.editIndex].focus();


              this.editIndex = (this.editIndex - 5) % this.editElements.focussableElement.length;
              // console.log("after page up index=",this.editIndex)
              if(this.editIndex == -5){
                this.editIndex = this.editElements.focussableElement.length - 5;
                this.editElements.focussableElement[this.editIndex].focus();
              }else{
                this.editElements.focussableElement[this.editIndex].focus();
              }
          }
        break;
        case "PageDown":
          // console.log("before page up index=",this.editIndex)
            if(this.editIndex == this.editElements.focussableElement.length - 5){
              if(this.editElements.focussableElement.length == 10){

                this.editIndex = ((this.editIndex+5) % (this.editElements.focussableElement.length-5));
              }else{

                this.editIndex = ((this.editIndex+5) % (this.editElements.focussableElement.length-5))-5;
              }

            }else{

              this.editIndex = (this.editIndex+5) % this.editElements.focussableElement.length;
            }

            // console.log("after page up index=",this.editIndex)
            this.editElements.focussableElement[this.editIndex].focus()


          break;

          case "Escape":
            if(this.userAlertStatus){
              this.alertElements[0].click();

            }

            break;


      }
    }


}
