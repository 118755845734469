import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class ApiService {
    host:string = environment.apiHost;
    // host:string = "http://localhost:3000/";
  constructor(private http:HttpClient) { }

  send(apiName,data){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    // console.log("Data==",data);
    let listnerAPI = "";
    switch(apiName){
      case "findDb":
       listnerAPI = "get-data"
      break;
      case "insertDb":
      listnerAPI = "insert-data"

      break;
      case "updateDb":
      listnerAPI = "update-data"

      break;
      case "deleteDb":
      listnerAPI = "delete-data"

      break;
      case "exportFile":
      listnerAPI = "send-data"

      break;
      case "writeFile":
      listnerAPI = "write-data"

      break;
      case "calculation":
      listnerAPI = "calculate-data"

      break;
      case "calculation-sub-customer":
      listnerAPI = "calculate-sub-customer-data"

      break;
      case "calculation-customer":
      listnerAPI = "calculate-customer-data"

      break;
      case "queryDb":
      listnerAPI = "query-data"

      break;
      case "scanImage":
      listnerAPI = "scan-image"

      break;
      break;
      case "winner-sms":
      listnerAPI = "send-winner-sms"

      break;
    }

    let url = this.host+listnerAPI;
    return new Promise((resolve,reject)=>{
      this.http.post(url,data,httpOptions).subscribe(res=>{

        resolve(res);
      },error=>{reject(error);})
    });
  }

  downloadReport(file){
    // Create url
    let url = this.host+"download-file";
    var body = { filename: file };

    return this.http.post(url, body, {
      responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "application/json")
    });
  }

  public upload(formData) {
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      // const options = {
      //     headers,
      //     reportProgress: true,
      //   };
      // console.log("formData==",formData)
  	// return this.http.post<any>('http://192.168.43.250:3002/api/upload-file', formData, {
  	return this.http.post<any>(this.host+'upload-file', formData, {
        reportProgress: true,
        observe: 'events'
      });
  }

  saveTransaction(data){
    this.send("insertDb",{
      table:'transactions',
      data:data
    })      
  }

}
