import { Component, OnInit } from '@angular/core';
import { ApiService } from  '../api.service'
import { ObservablesService } from  '../observables.service'
import { FormGroup, FormBuilder, Validators} from '@angular/forms'
@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {
  mainCustomers:any = []
  subCustomers:any = []
  allSubCustomers:any = []
  selectedCustomer:any = null
  userInfo:any = null
  permisssionStatus:boolean = false 
  mobileNoLotsStatus:boolean = false 
  maxAmountForm:FormGroup
  
  days:any=[{
    name:"Mon",
    full:"Monday",
    value:2
  },{
    name:"Tue",
    full:"Tuesday",
    value:3
  },{
    name:"Wed",
    full:"Wednesday",
    value:4
  },{
    name:"Thu",
    full:"Thursday",
    value:5
  },{
    name:"Fri",
    full:"Friday",
    value:6
  },{
    name:"Sat",
    full:"Saturday",
    value:7
  }];
  selectedDay:any=2
  tableData:any = []
  weekData:any = []
  mobile_nos:any = []
  userId:any = null
  lots:any = []
  selectedfilter:any = 0
  selectedMarket:any = 'D'
  dayLots:any = []
  nightLots:any =[]
  selectedRow:any = null
  selectedRows:any = []
  constructor(private fb:FormBuilder, private api:ApiService,private observables:ObservablesService) { }

  ngOnInit() {
    this.getMainCustomers()
  }

  getMainCustomers(){
    this.api.send("findDb",{
      table:"users",
      conditions:[{role:"main-customer"}],
      order:'username ASC'
    }).then(async (res:any)=>{
      this.mainCustomers = res.data;
      for(let i=0;i<this.mainCustomers.length;i++){

        await this.getSubCustomers(this.mainCustomers[i],i)
      }
    })
  }



  async getSubCustomers(mainCustomer,i=0){
    this.mainCustomers[i].sub_customers = []
    await this.api.send("findDb",{
      fields:["id,username,parent_id, parent_id as selected,role,active_status,copy_message_status,customer_daily_report_status,customer_chart_status,device_id"],
      table:"sub_customers",
      conditions:[{role:"sub_customer",parent_id:mainCustomer.id}],
      order:"username ASC"
    }).then((res:any)=>{
      this.mainCustomers[i].sub_customers = res.data
      this.subCustomers = res.data
    })
  }

  assignSubCustomer(mainCustomer){
    this.selectedCustomer = mainCustomer
    this.api.send("findDb",{
      fields:["id,username,parent_id, parent_id as selected,role,active_status"],
      table:"sub_customers",
      conditions:[{role:"sub_customer","parent_id IS NULL":""}]
      // conditions:[{role:"sub_customer","parent_id IS NULL":""},{role:"sub_customer","parent_id":mainCustomer.id}]
    }).then((res:any)=>{
      this.allSubCustomers = res.data
    })
  }

  saveData(){
    if(confirm("Are you sure to assign Sub customer under "+this.selectedCustomer.username+" ?")){
    // console.log("all sub customers==",this.allSubCustomers)
      let data:any = this.allSubCustomers.filter(item=>item.selected)
      let len = data.length
      // console.log("len==",len)

      if(len > 0){

        for(let i=0;i<len;i++){
          if(data[i].selected){

            this.api.send("updateDb",{
              table:"users",
              data:{
                parent_id:this.selectedCustomer.id
              },
              conditions:[{id:data[i].id}]
            }).then((res:any)=>{
              if(res.data.affectedRows){

                  this.selectedCustomer.sub_customers.push(data[i])

                if(i == len - 1){

                  //this.getSubCustomers(this.selectedCustomer)

                  this.observables.showToastMessage({type:0,message:"Sub customers assigned."})
                  this.selectedCustomer = null
                  this.allSubCustomers = []
                }
              }else{

                //this.getSubCustomers(this.selectedCustomer)
                this.observables.showToastMessage({type:1,message:"Problem occured, while assigning customers"})
                this.selectedCustomer = null
                this.allSubCustomers = []
              }
            })
          }
        }
      }else{
        this.observables.showToastMessage({type:1,message:"Select Customers to process.."})
      }
    }
  }

  removeSubCustomer(item,main){
    if(confirm("Are you sure to Remove from Sub customer list ?")){
      this.api.send("updateDb",{
        table:"users",
        data:{
          parent_id:null
        },
        conditions:[{id:item.id}]
      }).then((res:any)=>{
        if(res.data.affectedRows){
          main.sub_customers.map((data:any,index)=>{
            if(data.id == item.id){
              main.sub_customers.splice(index,1)
            }
          })

          if(this.allSubCustomers.length > 0 && this.selectedCustomer){
            item.selected = null
            this.allSubCustomers.unshift(item)
          }

          this.observables.showToastMessage({type:0,message:"Removed from Sub customers List."})
        }else{

          this.observables.showToastMessage({type:0,message:"Problem occured, while removing Sub customer."})
        }
      })
    }
  }


  removeDeviceID(event,element,item,i,j){

        if(event.checked){
          this.observables.showToastMessage({type:1, message:"Device ID is Empty"})
          element.checked = false

        }else{
          if(confirm(`Are you sure to Remove Device ID For ${item.username} ?`)){
            this.api.send("updateDb",{
              table:"sub_customers",
              data:{
                device_id:null
              },
              conditions:[{id:item.id}]
            }).then((res:any)=>{
              if(res.data.affectedRows){
                this.mainCustomers[i].sub_customers[j].device_id = null
                this.observables.showToastMessage({type:0, message:`Removed Device ID For ${item.username}.`})
              }else{

                this.observables.showToastMessage({type:1, message:"Can\'t Change the status, try again.."})
                element.checked = true
              }
            })
          }else{
            element.checked = true
          }



      }


      console.log("customers==",this.mainCustomers)

            // event.stopPropagation()

  }


  disableSubCustomer(event,element,item,i,j){

        if(event.checked){
          if(confirm(`Are you sure to Active subcustomer ${item.username} ?`)){
            this.api.send("updateDb",{
              table:"sub_customers",
              data:{
                active_status:0
              },
              conditions:[{id:item.id}]
            }).then((res:any)=>{
              if(res.data.affectedRows){
                this.mainCustomers[i].sub_customers[j].active_status = 0
                this.observables.showToastMessage({type:0, message:`${item.username} Sub customer active now.`})
              }else{

                this.observables.showToastMessage({type:1, message:"Can\'t Change the status, try again.."})
              }
            })
          }else{
            element.checked = false
          }

        }else{
          if(confirm(`Are you sure to Disable subcustomer ${item.username} ?`)){
            this.api.send("updateDb",{
              table:"sub_customers",
              data:{
                active_status:1
              },
              conditions:[{id:item.id}]
            }).then((res:any)=>{
              if(res.data.affectedRows){
                this.mainCustomers[i].sub_customers[j].active_status = 1
                this.observables.showToastMessage({type:0, message:`${item.username} Sub customer Disabled now.`})
              }else{

                this.observables.showToastMessage({type:1, message:"Can\'t Change the status, try again.."})
              }
            })
          }else{
            element.checked = true
          }



      }


      // console.log("customers==",this.mainCustomers)

            // event.stopPropagation()

  }

  showMobileNumberLots(customer, i){
    this.tableData = []
    this.weekData = []
    this.selectedRows = []
    this.userInfo = customer
    this.mobileNoLotsStatus = true
    this.getData()
  }

  closeLots(){

    this.mobileNoLotsStatus = false
  }

  showPermissions(customer,i){
    this.userInfo = customer
    this.maxAmountForm = this.fb.group({
      customer_max_amount:[this.userInfo.customer_max_amount, Validators.required],
      index:[i, Validators.required]
    })
    this.permisssionStatus = true
    // console.log(customer)
  }

  closePermission(){
    this.permisssionStatus = false
  }


  copyPasteOption(event,element){
    this.changeStatus(event,element, "Are you sure to Change Copy Paste Status ?","copy_message_status")
  }
  resultViewOption(event,element){
    this.changeStatus(event,element, "Are you sure to Change Result View Status ?","result_view_status")
  }
  dailyReportOption(event,element){
    this.changeStatus(event,element, "Are you sure to Change DailyReport Status ?","customer_daily_report_status")
  }
  createSubCustomerOption(event,element){
    this.changeStatus(event,element, "Are you sure to Change Create Customer Status ?","create_sub_customer_status")
  }
  chartOption(event,element){
    this.changeStatus(event,element, "Are you sure to Change Chart Status ?","customer_chart_status")
  }
  sendSMSOption(event,element){
    this.changeStatus(event,element, "Are you sure to give Send SMS Option ?","send_sms_status")
  }
  printerOption(event,element){
    this.changeStatus(event,element, "Are you sure to give Printer Option ?","print_status")
  }
  freeOption(event,element){
    this.changeStatus(event,element, "Are you sure to Change Free Option ?","free_status")
  }
  removeDeviceIdOption(event,element){
    if(event.checked){
      this.observables.showToastMessage({type:1, message:"Device ID is Empty"})
      element.checked = false
    }else{

      this.changeStatus(event,element, "Are you sure to Change Device Id Status ?","device_id")
    }

  }

  saveMaxAmount(value){
    if(confirm("Are you sure to Change Max Amount ?")){
      this.api.send("updateDb",{
        table:"users",
        data:{
          customer_max_amount:value.customer_max_amount
        },
        conditions:[{id:this.userInfo.id}]
      }).then((res:any)=>{
        if(res.data.affectedRows){
          this.mainCustomers[value.index].customer_max_amount = value.customer_max_amount
          this.userInfo.customer_max_amount = value.customer_max_amount
          this.observables.showToastMessage({type:0, message:`Max Amount Updated..`})
        }else{

          this.observables.showToastMessage({type:1, message:"Can\'t Change the Max Amount, try again.."})
        }
      })
    }
  }

  changeStatus(event,element,statusMessage,field){
      if(event.checked){
        if(confirm(`${statusMessage}`)){
          this.api.send("updateDb",{
            table:"users",
            data:{
              [field]:1
            },
            conditions:[{id:this.userInfo.id}]
          }).then((res:any)=>{
            if(res.data.affectedRows){
              this.observables.showToastMessage({type:0, message:`Status Updated..`})
            }else{

              this.observables.showToastMessage({type:1, message:"Can\'t Change the status, try again.."})
            }
          })
        }else{
          element.checked = false
        }

      }else{
        if(confirm(`${statusMessage} ?`)){
          this.api.send("updateDb",{
            table:"users",
            data:{
              [field]:(field == 'device_id')?null:0
            },
            conditions:[{id:this.userInfo.id}]
          }).then((res:any)=>{
            if(res.data.affectedRows){
              this.observables.showToastMessage({type:0, message:`Status Updated..`})
            }else{

              this.observables.showToastMessage({type:1, message:"Can\'t Change the status, try again.."})
            }
          })
        }else{
          element.checked = true
        }
    }
  }

  
  
  onDayChange(ev){
    // console.log("selected day=",event)
    this.selectedDay = ev.index + 2
    // this.filterTableData(this.selectedDay)
    this.getData()
  }

  getData(){
   
    this.tableData = []
    // SELECT COUNT(lot_mobile_no) as lotCount, lot_mobile_no, created from lots as L WHERE YEARWEEK(L.created, 1)= YEARWEEK(CURDATE(), 1) and user_id = 16 and lot_mobile_no IS NOT NULL GROUP BY lot_mobile_no
    this.api.send("queryDb",{
      sql:`SELECT l.created, l.lot_mobile_no,
            lot_count.lot_count AS lot_count,
            lot_count.day_market as day_market,
            lot_count.night_market as night_market,
            SUM(p.points) AS total_amount
              FROM
                lots l
              LEFT JOIN
                users u ON u.id = l.user_id
                LEFT JOIN (
              SELECT
              lot_mobile_no,
              COUNT(*) AS lot_count,
              SUM(CASE WHEN day_night = 'D' THEN 1 ELSE 0 END) AS day_market,
              SUM(CASE WHEN day_night = 'N' THEN 1 ELSE 0 END) AS night_market
            FROM
            lots
            WHERE YEAR(created) = YEAR(CURDATE())
            AND WEEK(created) = WEEK(CURDATE())
            AND DAYOFWEEK(created) = ${this.selectedDay} 
            AND lot_mobile_no IS NOT NULL
            GROUP BY
            lot_mobile_no
            ) AS lot_count ON l.lot_mobile_no = lot_count.lot_mobile_no
            LEFT JOIN
              points p ON l.id = p.lot_id
            WHERE  YEAR(l.created) = YEAR(CURDATE())
            AND WEEK(l.created) = WEEK(CURDATE())
            AND DAYOFWEEK(l.created) = ${this.selectedDay} and p.user_id = ${this.userInfo.id}  and l.lot_mobile_no is not null 
            GROUP BY
              l.lot_mobile_no 
            HAVING
            COUNT(l.id) > 0`
    }).then((res:any)=>{
      this.tableData = res.data
      // for(let i=0;i<res.data.length;i++){
      //   this.lots.push({day:new Date(res.data[i].created.split("T")[0]).getDay(),date:res.data[i].created,data:res.data[i]});
      // }
    }).catch((e:any)=>{
      console.log("Error==",e)
    })
   
  }


  onLinkClick(ev){
    this.selectedfilter = ev.index
    if(this.selectedfilter == 1){
      this.weekData = []
      this.api.send("queryDb",{
        sql:`SELECT l.created, l.lot_mobile_no,
            lot_count.lot_count AS lot_count,
            lot_count.day_market as day_market,
            lot_count.night_market as night_market,
            SUM(p.points) AS total_amount,
            false as expanded
              FROM
                lots l
              LEFT JOIN
                users u ON u.id = l.user_id
                LEFT JOIN (
              SELECT
              lot_mobile_no,
              COUNT(*) AS lot_count,
              SUM(CASE WHEN day_night = 'D' THEN 1 ELSE 0 END) AS day_market,
              SUM(CASE WHEN day_night = 'N' THEN 1 ELSE 0 END) AS night_market
              FROM
              lots
              WHERE YEAR(created) = YEAR(CURDATE())
              AND WEEK(created) = WEEK(CURDATE())
              AND
              lot_mobile_no IS NOT NULL
              GROUP BY
              lot_mobile_no
              ) AS lot_count ON l.lot_mobile_no = lot_count.lot_mobile_no
              LEFT JOIN
                points p ON l.id = p.lot_id
              WHERE YEAR(l.created) = YEAR(CURDATE())
              AND WEEK(l.created) = WEEK(CURDATE())
              AND p.user_id = ${this.userInfo.id}  and l.lot_mobile_no is not null 
              GROUP BY
                l.lot_mobile_no 
              HAVING
              COUNT(l.id) > 0`
      }).then((res:any)=>{
        this.weekData = res.data
        // for(let i=0;i<res.data.length;i++){
        //   this.lots.push({day:new Date(res.data[i].created.split("T")[0]).getDay(),date:res.data[i].created,data:res.data[i]});
        // }
      }).catch((e:any)=>{
        console.log("Error==",e)
      })
    }
  }

  async expandItem(item) {
    if (item.expanded) {
      item.expanded = false;

    } else {
      this.weekData.map(listItem => {
        if (item.lot_mobile_no == listItem.lot_mobile_no) {
          listItem.expanded = !listItem.expanded;

        } else {
          listItem.expanded = false;

        }
        return listItem;
      });
    }

    if(item.expanded){
      console.log("item==",item.expanded)
      this.selectedRow = item
      // this.api.presentLoading(" Please Wait...")
      this.api.send("queryDb",{
        // sql:`SELECT lot_mobile_no, DAYNAME(created) AS day_of_week, COUNT(*) AS total_lots FROM lots where lot_mobile_no = ${this.selectedRow.lot_mobile_no} and user_id = ${this.userId} GROUP BY lot_mobile_no, DAYOFWEEK(created)`
        sql:`SELECT days.day_name, COALESCE(lot_counts.lot_count, 0) AS lot_count FROM ( SELECT 'Mon'AS day_name, 2 AS day_number UNION SELECT 'Tue', 3 UNION SELECT 'Wed', 4 UNION SELECT 'Thu', 5 UNION SELECT 'Fri', 6 UNION SELECT 'Sat', 7 ) AS days LEFT JOIN ( SELECT DAYNAME(created) AS day_name, COUNT(*) AS lot_count FROM lots  where YEAR(created) = YEAR(CURDATE())
        AND WEEK(created) = WEEK(CURDATE())
        AND lot_mobile_no = ${this.selectedRow.lot_mobile_no} and user_id = ${this.userInfo.id} GROUP BY day_name ) AS lot_counts ON days.day_name = LEFT(lot_counts.day_name, 3) ORDER BY days.day_number`
      }).then((lotResponse:any)=>{
        this.selectedRows = lotResponse.data
        console.log("this.selected rows==",this.selectedRows)
      }).catch(error=>{console.log("error while fetching current lots=",error);})
      // this.api.dismissLoading()
    }
  }

 


}
