import { Component, OnInit, Input } from '@angular/core';
import { ApiService }  from '../api.service'
import * as _ from 'underscore';
@Component({
  selector: 'app-two-digits',
  templateUrl: './two-digits.component.html',
  styleUrls: ['./two-digits.component.scss']
})
export class TwoDigitsComponent implements OnInit {
  numbers:any = []
  selectedData:any = null
  total:any = 0
  _selectedUserData:any = null
  @Input() set _selectedData(_selectedData:any){
    console.log('selected data ==',_selectedData)
   this.selectedData = _selectedData;
  }
  @Input() set selectedUserData(selectedUserData:any){
    console.log('selected user data ==',selectedUserData)
   this._selectedUserData = selectedUserData;
   if(this._selectedUserData){

     this.getNumbers(true)
   }else{
     this.getNumbers()
   }
  }
  constructor(private api:ApiService) { }

  ngOnInit() {

  }

  getNumbers(userStatus = false){
    this.numbers = []
    // SELECT T.number, sum(P.points) FROM two_digits as T left join points as P on (P.number = T.number) where group by T.number
    let conditions:any = []
    if(userStatus){
        conditions = [{"DATE(P.created)":this.selectedData.date,"P.market":this.selectedData.market, "P.user_id":this._selectedUserData.id}]
    }else{
      conditions = [{"DATE(P.created)":this.selectedData.date,"P.market":this.selectedData.market}]
    }

    this.api.send("findDb",{
      fields:["T.number, SUM(P.points) as points, T.open"],
      table:"two_digits as T",
      joins:[{
        type:'left',
        table:'points as P',
        conditions:["P.number = T.number"]
      }],
      conditions:conditions,
      groupBy:"T.number"
    }).then((res:any)=>{
      // this.numbers = res.data
      let result:any = _.groupBy(res.data, "open")

      this.numbers = _.values(result);

      // for(let i= 10;i>0;i--){
      //   this.numbers.push(this.every_nth(res.data,10,i))
      // }

      // console.log("numbers==",this.numbers)
      this.getTotal(userStatus)
    })
  }


  // every_nth(arr, nth, num){
  //   return arr.filter((e, i) => i % nth === nth-num);
  // }


    getTotal(userStatus){
      let conditions:any = []
      if(userStatus){
          conditions = [{"DATE(P.created)":this.selectedData.date,"P.market":this.selectedData.market, "P.user_id":this._selectedUserData.id}]
      }else{
        conditions = [{"DATE(P.created)":this.selectedData.date,"P.market":this.selectedData.market}]
      }

      this.api.send("findDb",{
        fields:["SUM(P.points) as total"],
        table:"two_digits as T",
        joins:[{
          type:'left',
          table:'points as P',
          conditions:["P.number = T.number"]
        }],
        conditions:conditions
      }).then((res:any)=>{
        this.total = res.data[0].total
      })
    }



}
