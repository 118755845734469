import { Component, OnInit, Input } from '@angular/core';
import {ObservablesService } from '../observables.service'
import {ApiService } from '../api.service'
import { LoadingService } from '../loading.service'
@Component({
  selector: 'app-user-lot-info',
  templateUrl: './user-lot-info.component.html',
  styleUrls: ['./user-lot-info.component.scss']
})
export class UserLotInfoComponent implements OnInit {
  days:any=[];
  selectedDate:any;
  choosedDate:any;
  pointsArr:any=[]
  totalPoints:any=[]
  netPoints:any=0
  viewLotStatus:boolean = false;
  selectedData:any;
  selectedIndex: number = 0;
  selectedSubAdminData:any;
  AdminStatus:boolean = false;
  subAdminId:any;

  @Input() set selectedSubAdmin(selectedSubAdmin:any){
    if(selectedSubAdmin){


      this.AdminStatus = true;
      // console.log("sub admin data=",selectedSubAdmin)
      this.selectedSubAdminData=selectedSubAdmin;
      this.subAdminId = this.selectedSubAdminData.sub_admin_id;
      this.getDays();

      // let today = new Date().getDay();
      // this.selectedIndex = today - 1;
      if(this.selectedSubAdminData.selectedMarketData){
        for(let i=0;i<this.days.length;i++){
          if(new Date(this.selectedSubAdminData.selectedMarketData.date).getDate() == new Date(this.days[i].date).getDate()){
            this.onDayChange({index:i})
            break;
          }
        }
      }

    }
  }

  constructor(private loading:LoadingService,private api:ApiService, private observables:ObservablesService) { }

  ngOnInit() {
    if(!this.AdminStatus){
      this.subAdminId  = localStorage.getItem("subAdminId");
    }

    this.observables.hideArrowStatus.subscribe(()=>{
      this.viewLotStatus = false;
    })
    // this.getDays();
    // let today = new Date().getDay();
    // if(today == 0){
    //   this.selectedIndex = 5;
    // }else{
    //
    //   this.selectedIndex = today - 1;
    // }
    //
    //
    // this.onDayChange({index:this.selectedIndex})
    this.observables.hideArrowStatus.next()
  }

  getDays(){
  this.days=[];
   let curr = new Date();
   let first = curr.getDate() - curr.getDay();
   for (var i = 1; i < 7; i++) {
     var next = new Date(curr.getTime());
     next.setDate(first + i);
     // await this.days.filter((item:any)=>{
     //   if(item.value == next.getDay()){
         let dd:any = next.getDate();
         let mm:any = next.getMonth()+1; //January is 0!
         var yyyy = next.getFullYear();
         if(dd<10){dd='0'+dd} if(mm<10){mm='0'+mm}
         this.days.push({date:yyyy+"-"+mm+"-"+dd,value:next.getDay()});
     //   }
     // })
   }
 }

 onDayChange(event){
   // console.log("selected day=",event)
   this.selectedIndex = event.index;
   this.days.filter((item:any)=>{

     if((item.value - 1) == event.index){
       // console.log("selected date=",item.date)
       this.selectedDate = item.date
       this.choosedDate = new Date(item.date)
       this.refreshData();
       // this.selectedDay = item.value;
       // this.filterTableData(this.selectedDay);
     }
   })
 }

 refreshData(){
   this.loading.dataLoading(true);
   this.getNewTotalPoints();
 }


async getNewTotalPoints(){

  let points = 0;
  let count = 0;
  this.pointsArr = []
  this.totalPoints = [];
  // this.netPoints = 0;
  // for(let i=0;i<lotResponse.data.length;i++){
    // select SUM(P.points) as total_sum , COUNT(P.user_id) as total_counts from points as P where P.user_id = 3 and DATE(P.created) = CURDATE()
    //SELECT SUM(P.points) as total_sum , COUNT(P.user_id) as total_counts,U.username FROM `points` as P left join users as U on(U.id=P.user_id) WHERE P.`added_by` = 3 GROUP BY P.`user_id` ORDER BY P.`created` DESC
    await this.api.send("findDb",{
      table:"points as P",
      fields:[" SUM(P.points) as totalPoints , COUNT(P.lot_id) as totalEntries, U.username, U.id as user_id, P.market"],
      joins:[{
        type:"left",
        table:"users as U",
        conditions:["U.id = P.user_id"]
      }],
      conditions:[{"P.added_sub_admin_id":this.subAdminId,"DATE(P.created)":this.selectedDate}],
      groupBy:"P.user_id",
      order:"P.created DESC"
    }).then((pointsRes:any)=>{
      // console.log("points=",pointsRes)
      if(pointsRes.data.length > 0){

        this.totalPoints= pointsRes.data;//.push({market:pointsRes.data[].market,totalEntries:pointsRes.data[0].total_counts,totalPoints:pointsRes.data[0].total_sum,user_id:lotResponse.data[i].user_id,username:pointsRes.data[0].username})
        this.loading.dataLoading(false);
      }else{
        this.loading.dataLoading(false);
      }


    })

    // if(i == lotResponse.data.length -1){
    //
    // }


}

getTotal(){
  let total=0;
  // let i = 0
  // console.log("this.totalPoints==",this.totalPoints)
  this.totalPoints.filter((item:any)=>{
    // i++;
    total = total + item.totalPoints
    // if(i == this.totalPoints.length){
    // }
  })
  return total;
}

getMarketTotal(market){
  let total = 0;
  // console.log("this.totalPoints==",this.totalPoints)
  this.totalPoints.filter((item:any)=>{
    if(item.market == market){

      total = total + item.totalPoints
    }
  })
  return total;
}

viewLots(pointsData){
  // console.log("points data=",pointsData)
  this.viewLotStatus = true;
  this.observables.showArrowStatus.next()
  pointsData.index = this.selectedIndex;
  pointsData.subAdminId = this.subAdminId;
  pointsData.selectedMarketData = this.selectedSubAdminData.selectedMarketData
  this.selectedData = pointsData;
}


}
