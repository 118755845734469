import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service'
import { LoadingService } from '../loading.service'
import { ObservablesService } from '../observables.service'
@Component({
  selector: 'app-total-view',
  templateUrl: './total-view.component.html',
  styleUrls: ['./total-view.component.scss']
})
export class TotalViewComponent implements OnInit {
  // days:any=[{
  //   name:"Mon",
  //   full:"Monday",
  //   value:1
  // },{
  //   name:"Tue",
  //   full:"Tuesday",
  //   value:2
  // },{
  //   name:"Wed",
  //   full:"Wednesday",
  //   value:3
  // },{
  //   name:"Thu",
  //   full:"Thursday",
  //   value:4
  // },{
  //   name:"Fri",
  //   full:"Friday",
  //   value:5
  // },{
  //   name:"Sat",
  //   full:"Saturday",
  //   value:6
  // }];
  days:any=[]
  currentDay:any=new Date().getDay();
  userInfo:any=[]
  types:any=[]
  selectedType:any;
  selectedDay:any=1;
  selectedMarket:any="D";
  pointsArr:any=[];
  tableData:any=[];
  total:any;
  lostPercentage:any=0;
  Math: any;
  typeVariableData:any;
  weekResults:any=[]
  reportData:any=[]
  selectedIndex:number = 0
  selectedDate:any;
  choosedDate:any;
  constructor(private loading:LoadingService, private api:ApiService, private observables:ObservablesService) {
    this.Math = Math;
  }

  ngOnInit() {
    this.getLoggedUserInfo();
    this.getSessionResult()
  }

  getDays(){
  this.days=[];
   let curr = new Date();
   let first = curr.getDate() - curr.getDay();
   for (var i = 1; i < 7; i++) {
     var next = new Date(curr.getTime());
     next.setDate(first + i);
     // await this.days.filter((item:any)=>{
     //   if(item.value == next.getDay()){
         let dd:any = next.getDate();
         let mm:any = next.getMonth()+1; //January is 0!
         var yyyy = next.getFullYear();
         if(dd<10){dd='0'+dd} if(mm<10){mm='0'+mm}
         this.days.push({date:yyyy+"-"+mm+"-"+dd,value:next.getDay()});
     //   }
     // })
   }
 }
  getLoggedUserInfo(){

    this.api.send("findDb",{
      table:"users",
      conditions:[{id:localStorage.getItem('userId')}]
    }).then((userInfo:any)=>{
      // console.log("userInfo in type wise report=",userInfo);
      this.userInfo = userInfo.data[0];
      this.types = this.userInfo.type.split(',');
      // this.refreshTable(this.types[0]);
      this.getTotalReportData();

    }).catch(error=>{console.log("user info error",error)})
  }

  onTypeChange(event){
    // console.log("selected type=",event)
    if(event.index == 0){
      this.selectedType = this.types[0];
    }else{
      this.selectedType = this.types[1];
    }

    this.filterTableData(this.selectedType,this.selectedDate,this.selectedMarket);
    // this.refreshTable(this.selectedType);

  }
  onDayChange(event){
    // console.log("selected day=",event)


    this.selectedIndex = event.index;
    this.days.filter((item:any)=>{

      if((item.value - 1) == event.index){
        // console.log("selected date=",item.date)
        this.selectedDate = item.date
        this.choosedDate = new Date(item.date)
        this.filterTableData(this.selectedType,this.selectedDate,this.selectedMarket);
      }
    })
  }
  onMarketChange(event){
    // console.log("selected market=",event)
    if(event.index == 0){

      this.selectedMarket = "D";
    }else{
      this.selectedMarket = "N";
    }

    this.filterTableData(this.selectedType,this.selectedDate,this.selectedMarket);
  }

  async getVariableValues(type){
    await this.api.send("findDb",{
      table:"type_variables",
      conditions:[{type:type}]
    }).then((res:any)=>{

      // console.log("type variable response=",res)
      this.typeVariableData = res.data[0]

    }).catch((err:any)=>{
      console.log("err=",err)
    })
  }

  async refreshTable(type){
    this.loading.dataLoading(true);
    await this.getVariableValues(type)
    await this.getWeekData(type);
    //await this.filterTableData(type,this.selectedDay,this.selectedMarket);

  }

  getTotalReportData(){
     this.api.send("findDb",{
      table:"total_report",
      conditions:[{"YEARWEEK(date, 1)":"$YEARWEEK(CURDATE(), 1)$",user_id:this.userInfo.id}],
      order:"date ASC"
    }).then((response:any)=>{
      // console.log("total report==",response)
      this.reportData = response.data;
      this.getDays();
      // let today = new Date().getDay();
      // if(today == 0){
      //   this.selectedIndex = 5;
      // }else{
      //
      //   this.selectedIndex = today - 1;
      // }
      //
      // console.log("selected index=",this.selectedIndex)
      // this.onDayChange({index:this.selectedIndex})
    })
  }

  async getWeekData(type){

      await this.api.send("findDb",{
        table:"lots",
        conditions:[{"YEARWEEK(created, 1)":"$YEARWEEK(CURDATE(), 1)$",user_id:this.userInfo.id,type_no:type}],
        order:"created ASC"
      }).then((response:any)=>{
        // console.log("current week data=",response)
        if(response.data.length > 0){

          this.getPoints(response.data)
        }else{
          this.pointsArr=[];
          this.tableData=[];
          this.loading.dataLoading(false);
        }

      }).catch((err=>{console.log("error==",err)}))
  }

  async getPoints(lotResponse){
     this.pointsArr=[];
     let points = []
     // console.log("lot response in points=",lotResponse)
      for(let i=0; i<lotResponse.length;i++){

        await this.api.send("findDb",{
          table:"points",
          conditions:[{"lot_id":lotResponse[i].id}]
        }).then((pointResult:any)=>{
          lotResponse[i].points = pointResult.data;
          if(lotResponse[i].day_night == "D"){

            if(i > 0 && (lotResponse[i].created.split("T")[0] == lotResponse[i-1].created.split("T")[0]) && (lotResponse[i].day_night == lotResponse[i-1].day_night)){
              // console.log("inside ifff",i)
              points.push(pointResult.data)
              if(i == lotResponse.length - 1){
                this.pointsArr.push({day:new Date(lotResponse[i].created.split("T")[0]).getDay(),date:lotResponse[i].created,market:"D",lot:lotResponse[i],points:points});
              }else{
                if(i <= lotResponse.length){
                  if((lotResponse[i].day_night != lotResponse[i+1].day_night) || (lotResponse[i].created.split("T")[0] != lotResponse[i+1].created.split("T")[0])){
                    // console.log("if 2 index==",i)
                    this.pointsArr.push({day:new Date(lotResponse[i].created.split("T")[0]).getDay(),date:lotResponse[i].created,market:"D",lot:lotResponse[i],points:points});
                  }else{
                    // console.log("else 2 index==",i)
                  }
                }
              }
            }else{
              // console.log("inside elseee  ",i)
              points=[];
              points.push(pointResult.data);
              if(i == lotResponse.length - 1){
                this.pointsArr.push({day:new Date(lotResponse[i].created.split("T")[0]).getDay(),date:lotResponse[i].created,market:"D",lot:lotResponse[i],points:points});
              }else{

                if(i <= lotResponse.length){
                  if(lotResponse[i].created.split("T")[0] != lotResponse[i+1].created.split("T")[0] || lotResponse[i].day_night != lotResponse[i+1].day_night){

                    this.pointsArr.push({day:new Date(lotResponse[i].created.split("T")[0]).getDay(),date:lotResponse[i].created,market:"D",lot:lotResponse[i],points:points});
                  }
                }
              }
            }
          }else{

            if(i > 0 && (lotResponse[i].created.split("T")[0] == lotResponse[i-1].created.split("T")[0]) && (lotResponse[i].day_night == lotResponse[i-1].day_night)){
              // console.log("inside ifff",i)
              points.push(pointResult.data)
              if(i == lotResponse.length - 1){
                this.pointsArr.push({day:new Date(lotResponse[i].created.split("T")[0]).getDay(),date:lotResponse[i].created,market:"N",lot:lotResponse[i],points:points});
              }else{
                if(i <= lotResponse.length){
                  if((lotResponse[i].day_night != lotResponse[i+1].day_night) || (lotResponse[i].created.split("T")[0] != lotResponse[i+1].created.split("T")[0])){
                    // console.log("if 2 index==",i)
                    this.pointsArr.push({day:new Date(lotResponse[i].created.split("T")[0]).getDay(),date:lotResponse[i].created,market:"N",lot:lotResponse[i],points:points});
                  }else{
                    // console.log("else 2 index==",i)
                  }
                }
              }
            }else{
              // console.log("inside elseee  ",i)
              points=[];
              points.push(pointResult.data);
              if(i == lotResponse.length - 1){
                this.pointsArr.push({day:new Date(lotResponse[i].created.split("T")[0]).getDay(),date:lotResponse[i].created,market:"N",lot:lotResponse[i],points:points});
              }else{

                if(i <= lotResponse.length){
                  if(lotResponse[i].created.split("T")[0] != lotResponse[i+1].created.split("T")[0] || lotResponse[i].day_night != lotResponse[i+1].day_night){

                    this.pointsArr.push({day:new Date(lotResponse[i].created.split("T")[0]).getDay(),date:lotResponse[i].created,market:"N",lot:lotResponse[i],points:points});
                  }
                }
              }
            }

          }

          if(i == lotResponse.length-1){
            this.getPointsSum()
            this.filterTableData(this.selectedType,this.selectedDay,this.selectedMarket);
            // console.log("points array=",this.pointsArr);
          }
        }).catch(pointsErr=>{console.log("Error while getting points=",pointsErr)});
      }
  }


  async getPointsSum(){
    // await new Promise((resolve,reject)=>{


      for(let i=0;i<this.pointsArr.length;i++){

        await this.api.send("findDb",{
          table:"results",
          conditions:[{"DATE(created)":this.pointsArr[i].date.split("T")[0],market:this.pointsArr[i].market}]
        }).then((res:any)=>{

          // console.log("results==",res)
          let opPoints = 0;
          let cpPoints = 0;
          let ocPoints = 0;
          let p1Points = 0;
          let p2Points = 0;
          let opCal = 0;
          let cpCal = 0;
          let ocCal = 0;
          let p1Cal = 0;
          let p2Cal = 0;
          this.total = 0;
          if(res.data.length > 0){

            if((res.data[0].market == this.pointsArr[i].market) && (res.data[0].created.split("T")[0] == this.pointsArr[i].date.split("T")[0])){
              for(let j=0; j<this.pointsArr[i].points.length;j++){
                for(let k=0;k<this.pointsArr[i].points[j].length;k++){

                  if(res.data[0].op == this.pointsArr[i].points[j][k].number && this.pointsArr[i].points[j][k].session_no == 1){

                    opPoints = opPoints + this.pointsArr[i].points[j][k].points;

                  }

                  if(res.data[0].cp == this.pointsArr[i].points[j][k].number && this.pointsArr[i].points[j][k].session_no == 2){
                    cpPoints = cpPoints + this.pointsArr[i].points[j][k].points;
                  }

                  if(res.data[0].oc == this.pointsArr[i].points[j][k].number){
                    ocPoints = ocPoints + parseInt(this.pointsArr[i].points[j][k].points);
                  }

                  if(res.data[0].p1 == this.pointsArr[i].points[j][k].number && this.pointsArr[i].points[j][k].session_no == 1){
                    p1Points = p1Points + parseInt(this.pointsArr[i].points[j][k].points);
                  }

                  if(res.data[0].p2 == this.pointsArr[i].points[j][k].number && this.pointsArr[i].points[j][k].session_no == 2){
                    p2Points = p2Points + this.pointsArr[i].points[j][k].points;
                  }


                  this.pointsArr[i].op = opPoints;
                  this.pointsArr[i].cp = cpPoints;
                  this.pointsArr[i].oc = ocPoints;
                  this.pointsArr[i].p1 = p1Points;
                  this.pointsArr[i].p2 = p2Points;
                }

                opCal = (this.pointsArr[i].op * this.typeVariableData.single_digit)
                cpCal = (this.pointsArr[i].cp * this.typeVariableData.single_digit)
                ocCal = (this.pointsArr[i].oc * this.typeVariableData.double_digit)
                p1Cal = (this.pointsArr[i].p1 * this.typeVariableData.triple_digit)
                p2Cal = (this.pointsArr[i].p2 * this.typeVariableData.triple_digit)
                this.getTotal(this.pointsArr[i].points[j]).then((points:any)=>{
                   this.total =  this.total + points
                  this.pointsArr[i].total = this.total;
                  this.pointsArr[i].comm = this.total*(1 - this.typeVariableData.lost)
                  if(this.total - (opCal+cpCal+ocCal+p1Cal+p2Cal+this.pointsArr[i].comm) > 0){
                    this.pointsArr[i].debit = this.total - (opCal+cpCal+ocCal+p1Cal+p2Cal+this.pointsArr[i].comm)
                    this.pointsArr[i].credit = 0;
                  }else{
                    this.pointsArr[i].credit = this.total - (opCal+cpCal+ocCal+p1Cal+p2Cal+this.pointsArr[i].comm)
                    this.pointsArr[i].debit = 0;
                  }
                })
                // if((i==this.pointsArr.length-1) && (j== this.pointsArr[i].points.length-1)){
                //   return (this.pointsArr);
                // }
              }
            }else{
              for(let j=0; j<this.pointsArr[i].points.length;j++){

                // this.total =  this.total + this.getTotal(this.pointsArr[i].points[j] );
                this.pointsArr[i].op = opPoints;
                this.pointsArr[i].cp = cpPoints;
                this.pointsArr[i].oc = ocPoints;
                this.pointsArr[i].p1 = p1Points;
                this.pointsArr[i].p2 = p2Points;
                this.pointsArr[i].total = this.total;
                opCal = (this.pointsArr[i].op * this.typeVariableData.single_digit)
                cpCal = (this.pointsArr[i].cp * this.typeVariableData.single_digit)
                ocCal = (this.pointsArr[i].oc * this.typeVariableData.double_digit)
                p1Cal = (this.pointsArr[i].p1 * this.typeVariableData.triple_digit)
                p2Cal = (this.pointsArr[i].p2 * this.typeVariableData.triple_digit)
                this.getTotal(this.pointsArr[i].points[j]).then((points:any)=>{
                 this.total =  this.total + points
                  this.pointsArr[i].comm = this.total*(1 - this.typeVariableData.lost)
                  if(this.total - (opCal+cpCal+ocCal+p1Cal+p2Cal+this.pointsArr[i].comm) > 0){
                    this.pointsArr[i].debit = this.total - (opCal+cpCal+ocCal+p1Cal+p2Cal+this.pointsArr[i].comm)
                    this.pointsArr[i].credit = 0;
                  }else{
                    this.pointsArr[i].credit = this.total - (opCal+cpCal+ocCal+p1Cal+p2Cal+this.pointsArr[i].comm)
                    this.pointsArr[i].debit = 0;
                  }
                })
              }
            }
          }else{
            // console.log("result not available",this.pointsArr[i])
            for(let j=0; j<this.pointsArr[i].points.length;j++){
              // this.total =  this.total + this.getTotal(this.pointsArr[i].points[j] );
              this.pointsArr[i].op = opPoints;
              this.pointsArr[i].cp = cpPoints;
              this.pointsArr[i].oc = ocPoints;
              this.pointsArr[i].p1 = p1Points;
              this.pointsArr[i].p2 = p2Points;
              this.pointsArr[i].total = this.total;
              opCal = (this.pointsArr[i].op * this.typeVariableData.single_digit)
              cpCal = (this.pointsArr[i].cp * this.typeVariableData.single_digit)
              ocCal = (this.pointsArr[i].oc * this.typeVariableData.double_digit)
              p1Cal = (this.pointsArr[i].p1 * this.typeVariableData.triple_digit)
              p2Cal = (this.pointsArr[i].p2 * this.typeVariableData.triple_digit)
              this.getTotal(this.pointsArr[i].points[j]).then((points:any)=>{
               this.total =  this.total + points
                this.pointsArr[i].comm = this.total*(1 - this.typeVariableData.lost)
                if(this.total - (opCal+cpCal+ocCal+p1Cal+p2Cal+this.pointsArr[i].comm) > 0){
                  this.pointsArr[i].debit = this.total - (opCal+cpCal+ocCal+p1Cal+p2Cal+this.pointsArr[i].comm)
                  this.pointsArr[i].credit = 0;
                }else{
                  this.pointsArr[i].credit = this.total - (opCal+cpCal+ocCal+p1Cal+p2Cal+this.pointsArr[i].comm)
                  this.pointsArr[i].debit = 0;
                }
              })
            }
          }

        })

        if(i == this.pointsArr.length - 1){
          this.loading.dataLoading(false);
        }
      }
    // })
  }

  async getTotal(arr){
     return new Promise((resolve,reject)=>{

       let points = 0;
       let i = 0 ;
        arr.filter((item:any)=>{
         i++;
         points = points + item.points;
         if(i == arr.length){
           // console.log("resolving points="+i+"=",points)
           resolve(points);
         }
       })
     })
   }
  //   let points=0
  //   for(let i=0;i<arr.length;i++){
  //     points = points + arr[i].points;
  //     if(i== arr.length -1){
  //       return points;
  //     }
  //   }
  // }


  async getSessionResult(){


      this.api.send("findDb",{
        table:"results",
        conditions:[{"YEARWEEK(created, 1)":"$YEARWEEK(CURDATE(), 1)$"}]
      }).then((result:any)=>{
        // console.log("session result is=",result)

        this.weekResults = result.data;
        // for(let i=0;i<result.data.length;i++){
        //   this.weekResults.push({day:new Date(result.data[i].created.split("T")[0]).getDay(),date:result.data[i].created,market:result.data[i].market,data:result.data[i]});
        // }
      }).catch(error=>{

        console.log(" session result error is=",error)
      })
  }


  async filterTableData(type,date,market){
    // console.log("type=",type)
    // console.log("day=",date)
    // console.log("market=",market)
    // console.log("reportData in filter=",this.reportData)
    // console.log("result in filter=",this.weekResults)
    this.tableData = []
    await this.reportData.filter((item:any)=>{
      if(date==item.date.split("T")[0] && market == item.market && type == item.rate){
        this.tableData.push(item);

      }
    })

    // console.log("this.tableData === ",this.tableData)

    let matchedStatus = false;
    await this.weekResults.filter((item:any)=>{
      if(date==item.created.split("T")[0] && market == item.market){
        // console.log("result=",this.result1)
        matchedStatus = true;
        this.observables.setViewHeaderResultStatus.next({p1:item.p1,p2:item.p2,oc:item.oc,cp:item.cp,op:item.op});
      }else{
        if(!matchedStatus){

          this.observables.setViewHeaderResultStatus.next({p1:"",p2:"",oc:"",cp:"",op:""});
        }
      }
    })

    // console.log("table data==",this.tableData)

  }

  getTotalPointsSum(title){
    let total_points:number=0;
    if(title=="ocb"){
      this.reportData.filter((item:any)=>{
        total_points= total_points + item.ocb;
      })
      return total_points;
    }else if(title == "op"){
      this.reportData.filter((item:any)=>{
        total_points= total_points + item.op;
      })
      return total_points;

    }else if(title == "oc"){
      this.reportData.filter((item:any)=>{
        total_points= total_points + item.oc;
      })
      return total_points;

    }else if(title == "c"){
      this.reportData.filter((item:any)=>{
        total_points= total_points + item.c;
      })
      return total_points;

    }else if(title == "pan1"){
      this.reportData.filter((item:any)=>{
        total_points= total_points + item.pan1;
      })
      return total_points;

    }else if(title == "pan2"){
      this.reportData.filter((item:any)=>{
        total_points= total_points + item.pan2;
      })
      return total_points;

    }else if(title == 'comm'){
      this.reportData.filter((item:any)=>{
        total_points= total_points + this.getAbsuluteData(item.comm);
      })
      return total_points;
    }else if(title == 'debit'){
      this.reportData.filter((item:any)=>{
        total_points= total_points + this.getAbsuluteData(item.debit);
      })
      return total_points;
    }else if(title == 'credit'){
      this.reportData.filter((item:any)=>{
        total_points= total_points + this.getAbsuluteData(item.credit);
      })
      return total_points;
    }
  }

  getAbsuluteData(number){
    return parseFloat((Math.round(number * 100) / 100).toFixed(2))
  }


}
