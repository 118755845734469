import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router,private api:ApiService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if((localStorage.getItem('userLoggedInStatus') == "1") && (localStorage.getItem('adminLoggedInStatus') == "0") && (localStorage.getItem('subAdminLoggedInStatus') == "0")){
           // logged in so return true
           // this.api.send("findDb",{
           //   table:"users",
           //   conditions:[{'id':localStorage.getItem("userId")}]
           // }).then((userRes:any)=>{
           //   console.log("in guard user data=",userRes)
           //   if(userRes.data.length > 0){
           //
           //     if(userRes.data[0].active_status == 0){
                 return true;
           //     }else{
           //       localStorage.clear();
           //       localStorage.setItem("logOutStatus",'1')
           //       this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
           //       return false;
           //     }
           //   }
           // })

       }

       // not logged in so redirect to login page with the return url
       this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
       return false;
  }
}
