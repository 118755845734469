import { Component, OnInit,Input, ViewChild, ElementRef } from '@angular/core';
import { ObservablesService } from '../observables.service';
import { ApiService } from '../api.service';
import { Subject } from 'rxjs';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { ImageCroppedEvent, base64ToFile } from 'ngx-image-cropper';
declare var wheelzoom:any;
import {trigger, state, style, animate, transition} from '@angular/animations';
@Component({
  selector: 'app-scan-image',
  templateUrl: './scan-image.component.html',
  styleUrls: ['./scan-image.component.scss'],
  animations: [
         // Each unique animation requires its own trigger. The first argument of the trigger function is the name
         trigger('rotatedState', [
           state('default', style({ transform: 'rotate(0)' })),
           state('rotated', style({ transform: 'rotate(-180deg)' })),
           transition('rotated => default', animate('1500ms ease-out')),
           transition('default => rotated', animate('400ms ease-in'))
       ])
   ]
})
export class ScanImageComponent implements OnInit {
  session:any;
  _selectedType:any=1
  @ViewChild('fileInput',{static:false}) fileInput:any;
  @Input() set selectedType(selectedType:any){
    this.session = selectedType;
    this._selectedType = selectedType
    // console.log("type==",selectedType)
  }
  files  = [];
  images:any=[];
  uploadSubscribeVariable:any;
  iterationObserver:any = new Subject<any>();
  imageChangedEvent: any = '';
  croppedImage: any = '';
  croppedfile:any = null
  showCropperStatus:boolean = false
  zoomStatus:boolean = false
  rotationAmount:any = 0
  @ViewChild('myImg',{static:false}) img: ElementRef;
  @ViewChild('myModal',{static:false}) modal: ElementRef;
  @ViewChild('img01',{static:false}) modalImg: ElementRef;
  @ViewChild('caption',{static:false}) captionText: ElementRef;
  constructor(private ele:ElementRef, private api:ApiService,private observables:ObservablesService) { }

  ngOnInit() {
  }

  resetFile(){
    this.fileInput.nativeElement.value = null;
  }


  onFileChange(evt: any) {
    this.zoomStatus = false

    this.imageChangedEvent = event;
    const fileUpload = this.fileInput.nativeElement;
      // console.log("fileUpload.files=",fileUpload.files)
      for (let index = 0; index < fileUpload.files.length; index++)
      {
        if(fileUpload.files[index].type == 'image/jpg' || fileUpload.files[index].type == 'image/jpeg'||
            fileUpload.files[index].type == 'image/png'){
             const file = fileUpload.files[index];
             this.files.push({ data: file, inProgress: false, progress: 0});

           let reader = new FileReader();
            reader.onload = (_event) => {
              this.images.push(reader.result);
            }
            reader.readAsDataURL(fileUpload.files[index]);
            // console.log(this.images)
         }else{
           this.resetFile()
           this.observables.showToastMessage({type:1,message:"Only Image files accepted..!"});
         }
      }

  }

  save(){
    // if(this.files.length > 0){
    //     this.uploadFile(this.files[0]).then((resp:any)=>{
    //       console.log("resp===",resp)
    //       if(resp){
    //       }
    //     })
    // }
    if(this.croppedfile){
      this.uploadFile({data:new File([this.croppedfile],'demo.jpeg')}).then((resp:any)=>{
        console.log("resp===",resp)
        if(resp){
        }
      })
    }
  }


  // uploadFiles(ledger_id,transaction_id,updateStatus=false){
  //   // console.log("uploading file==",this.files);
  //   return new Promise((resolve)=>{
  //
  //     let k = 0;
  //     this.fileInput.nativeElement.value = '';
  //     this.uploadSubscribeVariable = this.iterationObserver.subscribe((k)=>{
  //       // console.log("count and filelist length=",k+" And "+this.fileData.length)
  //       if(k>=this.files.length){
  //
  //         this.uploadSubscribeVariable.unsubscribe();
  //
  //           }else{
  //
  //             this.uploadFile(this.files[k],updateStatus).then((res:any)=>{
  //               if(res){
  //                 this.iterationObserver.next(k+1);
  //
  //               }else{
  //                 this.observables.showToastMessage({type:1, message:"Product saved but Upload image failed..!"});
  //                 this.iterationObserver.next(k+1);
  //               }
  //
  //
  //             })
  //             // }
  //
  //
  //           }
  //         })
  //
  //         this.iterationObserver.next(k);
  //   })
  //
  // }



  uploadFile(file,updateStatus=false) {
    return new Promise((resolve,reject)=>{

      const formData = new FormData();
      // formData.append("business_id",this.loggedUserInfo.business_id)
      formData.append("type","scan")
      formData.append("single_status", "1");
      // formData.append("product_status", "1");
      if(updateStatus){
        formData.append("update_status", "1");
      }else{
        formData.append("update_status", "0");
      }
      formData.append('file', file.data);


        file.inProgress = true;
        this.api.upload(formData).pipe(
          map(event => {

            switch (event.type) {
              case HttpEventType.UploadProgress:
              file.progress = Math.round(event.loaded * 100 / event.total);
              break;
              case HttpEventType.Response:
              return event;
            }

            // console.log("event==",event)
            // console.log("HttpEventType.UploadProgress==",HttpEventType.UploadProgress)
            // console.log("HttpEventType.UploadProgress==",HttpEventType)

          }),
          catchError((error: HttpErrorResponse) => {
            file.inProgress = false;
            return of(`${file.data.name} upload failed.`);
          })).subscribe((event: any) => {
            // console.log("events==",event)
            if (typeof (event) === 'object') {
              // console.log(event.body);
                resolve(event.body)
            }
          });
        })
    }
    // fileChangeEvent(event: any): void {
    //     this.imageChangedEvent = event;
    // }
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.croppedfile = base64ToFile(this.croppedImage);

    }

    imageLoaded() {
        // show cropper
    }
    cropperReady() {
        // cropper ready
    }
    loadImageFailed() {
        // show message
    }

    largeView(){
      // console.log("fileData",fileData)
       // let path = this.socketHost+"chat_files/"+fileData.group_id+"/"+fileData.chat_id+"/"+fileData.file_name
        // this.modal.nativeElement.style.display = "block";
        // this.modalImg.nativeElement.src = this.croppedImage;
        // this.captionText.nativeElement.innerHTML = this.img.nativeElement.alt;
        this.showCropperStatus = !this.showCropperStatus
        if(this.showCropperStatus){
          this.ele.nativeElement.querySelector('.cropper').style.visibility='hidden';
          if(!this.zoomStatus){
            this.zoomStatus = true

            wheelzoom(this.ele.nativeElement.querySelector('.source-image'));
          }
        }else{

          this.ele.nativeElement.querySelector('.cropper').style.visibility='visible';
        }


    }

    rotate(direction){
      this.rotationAmount += direction == 'left' ? -90 : 90;

      this.ele.nativeElement.querySelector('.source-image').style.transform = `rotate(${this.rotationAmount}deg)`;
      this.ele.nativeElement.querySelector('.source-image').style.transition = `400ms ease-in`;
    }

    close(){
      this.modal.nativeElement.style.display = "none";
    }

}
