import { Component, OnInit } from '@angular/core';
import { ApiService } from  '../api.service'
import { ObservablesService } from  '../observables.service'

@Component({
  selector: 'app-sub-admins',
  templateUrl: './sub-admins.component.html',
  styleUrls: ['./sub-admins.component.scss']
})
export class SubAdminsComponent implements OnInit {

    subAdmins:any = []
    subCustomers:any = []
    allSubCustomers:any = []
    allSubCustomers_list:any = []
    selectedCustomer:any = null
    subAdminInfo:any = null
    permisssionStatus:boolean = false
    constructor(private api:ApiService,private observables:ObservablesService) { }

    ngOnInit() {
      this.getSubAdmins()
    }

    getSubAdmins(){
      this.api.send("findDb",{
        table:"users",
        conditions:[{role:"subadmin"}],
        order:'username ASC'
      }).then(async (res:any)=>{
        this.subAdmins = res.data;
        for(let i=0;i<this.subAdmins.length;i++){

          await this.getSubCustomers(this.subAdmins[i],i)
        }
      })
    }

    showPermissions(subadmin,i){
      this.subAdminInfo = subadmin
     
      this.permisssionStatus = true
      // console.log(customer)
    }
  
    closePermission(){
      this.permisssionStatus = false
      this.subAdminInfo = null
    }

    sendSMSOption(event,element){
      this.changeStatus(event,element, "Are you sure to give Send SMS Option ?","send_sms_status")
    }
    printerOption(event,element){
      this.changeStatus(event,element, "Are you sure to give Printer Option ?","print_status")
    }

    
  changeStatus(event,element,statusMessage,field){
    if(event.checked){
      if(confirm(`${statusMessage}`)){
        this.api.send("updateDb",{
          table:"users",
          data:{
            [field]:1
          },
          conditions:[{id:this.subAdminInfo.id}]
        }).then((res:any)=>{
          if(res.data.affectedRows){
            this.observables.showToastMessage({type:0, message:`Status Updated..`})
          }else{

            this.observables.showToastMessage({type:1, message:"Can\'t Change the status, try again.."})
          }
        })
      }else{
        element.checked = false
      }

    }else{
      if(confirm(`${statusMessage} ?`)){
        this.api.send("updateDb",{
          table:"users",
          data:{
            [field]:(field == 'device_id')?null:0
          },
          conditions:[{id:this.subAdminInfo.id}]
        }).then((res:any)=>{
          if(res.data.affectedRows){
            this.observables.showToastMessage({type:0, message:`Status Updated..`})
          }else{

            this.observables.showToastMessage({type:1, message:"Can\'t Change the status, try again.."})
          }
        })
      }else{
        element.checked = true
      }
  }
}



    async getSubCustomers(subAdmins,i=0){
      this.subAdmins[i].sub_customers = []
      await this.api.send("findDb",{
        fields:["id,username,parent_id, parent_id as selected,role"],
        table:"users",
        conditions:[{role:"main-customer",parent_id:subAdmins.id}],
        order:"username ASC"
      }).then((res:any)=>{
        this.subAdmins[i].sub_customers = res.data
        this.subCustomers = res.data
      })
    }

    assignSubCustomer(mainCustomer,index){
      this.selectedCustomer = mainCustomer
      this.selectedCustomer.index = index
      // console.log("selectedCustomer==",this.selectedCustomer)
      this.api.send("findDb",{
        fields:["id,username,parent_id, parent_id as selected,role"],
        table:"users",
        conditions:[{role:"main-customer","parent_id IS NULL":""}]
        // conditions:[{role:"sub_customer","parent_id IS NULL":""},{role:"sub_customer","parent_id":mainCustomer.id}]
      }).then((res:any)=>{
        this.allSubCustomers = res.data
        this.allSubCustomers_list = res.data
      })
    }

    saveData(){
      if(confirm("Are you sure to assign customer under "+this.selectedCustomer.username+" ?")){
      // console.log("all sub customers==",this.allSubCustomers)
        let data:any = this.allSubCustomers.filter(item=>item.selected)
        let len = data.length
        // console.log("len==",len)

        if(len > 0){

          for(let i=0;i<len;i++){
            if(data[i].selected){

              this.api.send("updateDb",{
                table:"users",
                data:{
                  parent_id:this.selectedCustomer.id
                },
                conditions:[{id:data[i].id}]
              }).then((res:any)=>{
                if(res.data.affectedRows){
                    // console.log("llll==",res)
                    this.selectedCustomer.sub_customers.push(data[i])

                  if(i == len - 1){

                    //this.getSubCustomers(this.selectedCustomer)

                    this.observables.showToastMessage({type:0,message:"Customers assigned."})
                    this.selectedCustomer = null
                    this.allSubCustomers = []
                    this.allSubCustomers_list = []
                  }
                }else{

                  //this.getSubCustomers(this.selectedCustomer)
                  this.observables.showToastMessage({type:1,message:"Problem occured, while assigning customers"})
                  this.selectedCustomer = null
                  this.allSubCustomers = []
                  this.allSubCustomers_list = []
                }
              })
            }
          }
        }else{
          this.observables.showToastMessage({type:1,message:"Select Customers to process.."})
        }
      }
    }

    removeSubCustomer(item,main){
      if(confirm("Are you sure to Remove from customer list ?")){
        this.api.send("updateDb",{
          table:"users",
          data:{
            parent_id:null
          },
          conditions:[{id:item.id}]
        }).then((res:any)=>{
          if(res.data.affectedRows){
            main.sub_customers.map((data:any,index)=>{
              if(data.id == item.id){
                main.sub_customers.splice(index,1)
              }
            })

            if(this.allSubCustomers.length > 0 && this.selectedCustomer){
              item.selected = null
              this.allSubCustomers.unshift(item)
              this.allSubCustomers_list.unshift(item)
            }

            this.observables.showToastMessage({type:0,message:"Removed from Sub customers List."})
          }else{

            this.observables.showToastMessage({type:0,message:"Problem occured, while removing Sub customer."})
          }
        })
      }
    }


    searchCustomer(event){
      // console.log(this.customers_list)
        let searchTerm = event.target.value;
        // console.log("searchTerm=",searchTerm)
        if(searchTerm.length>0){

          this.allSubCustomers = this.allSubCustomers_list.filter((item)=>{
            return (item.username.toLowerCase().indexOf(searchTerm.toLowerCase())> -1 );

          });


        }else{
          this.allSubCustomers = this.allSubCustomers_list;

        }
    }


    assignAll(event,element){

          if(event.checked){
            if(confirm("Are you sure to Assign Default All Customers?")){
              this.api.send("updateDb",{
                table:"users",
                data:{
                  all_customer_status:1
                },
                conditions:[{id:this.selectedCustomer.id}]
              }).then((res:any)=>{
                if(res.data.affectedRows){

                  this.observables.showToastMessage({type:0, message:"Default All Customer assigned.."})
                  this.subAdmins[this.selectedCustomer.index].all_customer_status = 1
                }else{

                  this.observables.showToastMessage({type:1, message:"Can\'t assign, try again.."})
                }
              })
            }else{
              element.checked = false
            }

          }else{
            if(confirm("Are you sure to Remove Default All Customers?")){
              this.api.send("updateDb",{
                table:"users",
                data:{
                  all_customer_status:0
                },
                conditions:[{id:this.selectedCustomer.id}]
              }).then((res:any)=>{
                if(res.data.affectedRows){

                  this.observables.showToastMessage({type:0, message:"Removed Default All Customers.."})
                  this.subAdmins[this.selectedCustomer.index].all_customer_status = 0
                }else{

                  this.observables.showToastMessage({type:1, message:"Can't Remove, try again.."})
                }
              })
            }else{
              element.checked = true
            }



        }

              // event.stopPropagation()

    }

    removeAll(item){
      if(confirm("Are you Sure to remove All assigned customers?")){
        this.api.send("updateDb",{
          table:'users',
          data:{
            parent_id:null
          },
          conditions:[{parent_id:item.id}]
        }).then((res:any)=>{
          if(res.data.affectedRows){
            this.observables.showToastMessage({status:0,message:"All assigned customers removed.."})
            item.sub_customers = []
          }else{
            this.observables.showToastMessage({status:1,message:"Can\'t remove assigned customers, Try again.."})

          }
        })
      }
    }


    removeDeviceID(event,element){

          if(event.checked){
            this.observables.showToastMessage({type:1, message:"Device ID is Empty"})
            element.checked = false

          }else{
            if(confirm(`Are you sure to Remove Device ID For ${this.selectedCustomer.username} ?`)){
              this.api.send("updateDb",{
                table:"users",
                data:{
                  device_id:null
                },
                conditions:[{id:this.selectedCustomer.id}]
              }).then((res:any)=>{
                if(res.data.affectedRows){
                  this.selectedCustomer.device_id = null
                  this.observables.showToastMessage({type:0, message:`Removed Device ID For ${this.selectedCustomer.username}.`})
                }else{

                  this.observables.showToastMessage({type:1, message:"Can\'t Change the status, try again.."})
                  element.checked = true
                }
              })
            }else{
              element.checked = true
            }
        }
    }


    changeFreeStatus(event,element){
        if(event.checked){
          if(confirm(`Are you sure to change Free Status ?`)){
            this.api.send("updateDb",{
              table:"users",
              data:{
                free_status:1
              },
              conditions:[{id:this.selectedCustomer.id}]
            }).then((res:any)=>{
              if(res.data.affectedRows){
                this.observables.showToastMessage({type:0, message:`Status Updated..`})
              }else{

                this.observables.showToastMessage({type:1, message:"Can\'t Change the status, try again.."})
              }
            })
          }else{
            element.checked = false
          }

        }else{
          if(confirm(`Are you sure to change Free Status ?`)){
            this.api.send("updateDb",{
              table:"users",
              data:{
                free_status:0
              },
              conditions:[{id:this.selectedCustomer.id}]
            }).then((res:any)=>{
              if(res.data.affectedRows){
                this.observables.showToastMessage({type:0, message:`Status Updated..`})
              }else{

                this.observables.showToastMessage({type:1, message:"Can\'t Change the status, try again.."})
              }
            })
          }else{
            element.checked = true
          }



      }
    }


}
