import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { SubAdminDashboardComponent } from './sub-admin-dashboard/sub-admin-dashboard.component';
import { LotViewComponent } from './lot-view/lot-view.component';
import { SubAdminLotViewComponent } from './sub-admin-lot-view/sub-admin-lot-view.component';
import { ResultViewComponent } from './result-view/result-view.component';
import { TypeWiseReportComponent } from './type-wise-report/type-wise-report.component';
import { TotalViewComponent } from './total-view/total-view.component';
import { TransactionViewComponent } from './transaction-view/transaction-view.component';
import { UserLotInfoComponent } from './user-lot-info/user-lot-info.component';
import { CustomersComponent } from './customers/customers.component';
import { SubAdminsComponent } from './sub-admins/sub-admins.component';
import { TwoDigitsComponent } from './two-digits/two-digits.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthGuard } from './auth.guard';
import { AdminGuard } from './admin.guard';
import { SubAdminGuard } from './sub-admin.guard';
import { AnonymousGuard } from './anonymous.guard';
import { ChartsComponent } from './charts/charts.component';
import { ResultReportComponent } from './result-report/result-report.component';
const routes: Routes = [
  { path: "", component:LoginComponent, pathMatch: 'full', canActivate: [AnonymousGuard]},
  { path: "login", component:LoginComponent, canActivate: [AnonymousGuard]},
  { path: "home", component:HomeComponent, canActivate: [AnonymousGuard]},
  { path: "dashboard", component:DashboardComponent, canActivate: [AuthGuard]},
  { path: "admin-dashboard", component:AdminDashboardComponent, canActivate: [AdminGuard]},
  { path: "customers", component:CustomersComponent, canActivate: [AdminGuard]},
  { path: "sub-admins", component:SubAdminsComponent, canActivate: [AdminGuard]},
  { path: "sub-admin-dashboard", component:SubAdminDashboardComponent, canActivate: [SubAdminGuard]},
  { path: "sub-admin-lot-view", component:SubAdminLotViewComponent, canActivate: [SubAdminGuard]},
  { path: "user-lot-view", component:UserLotInfoComponent, canActivate: [SubAdminGuard]},
  { path: "lot-view", component:LotViewComponent, canActivate: [AuthGuard]},
  { path: "two-digits", component:TwoDigitsComponent, canActivate: [AdminGuard]},
  { path: "result-view", component:ResultViewComponent, canActivate: [AuthGuard]},
  { path: "total-review", component:TotalViewComponent, canActivate: [AuthGuard]},
  { path: "transaction-view", component:TransactionViewComponent, canActivate: [AuthGuard]},
  { path: "charts", component:ChartsComponent, canActivate: [AdminGuard]},
  { path: "type-wise-report", component:TypeWiseReportComponent, canActivate: [AuthGuard]},
  { path: "result-report", component:ResultReportComponent, canActivate: [AuthGuard]},
  { path: "**", component:PageNotFoundComponent, canActivate: [AuthGuard] }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
