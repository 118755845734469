import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'
@Injectable({
  providedIn: 'root'
})
export class SocketObservablesService {
  updateResultStatus: any = new Subject<any>();
  updateLotStatus: any = new Subject<any>();
  updateAmountStatus: any = new Subject<any>();
  updateActiveStatus: any = new Subject<any>();
  updateMessageStatus: any = new Subject<any>();
  updateProgressBarStatus: any = new Subject<any>();
  constructor() { }

  refreshResult(data){
    // console.log('refreshing results',data);
    this.updateResultStatus.next(data);
  }
  // refreshResult(data){
  //   console.log('refreshing results',data);
  //   this.updateResultStatus.next(data);
  // }
  refreshLots(data){
    // console.log('refreshing lots',data);
    this.updateLotStatus.next(data);
  }
  refreshAmount(data){
    // console.log('refreshing lots',data);
    this.updateAmountStatus.next(data);
  }
  refreshActiveStatus(data){
    // console.log('refreshing lots',data);
    this.updateActiveStatus.next(data);
  }
  refreshMessage(data){
    // console.log('refreshing lots',data);
    this.updateMessageStatus.next(data);
  }
  refreshProgressBar(data){
    // console.log('refreshing lots',data);
    this.updateProgressBarStatus.next(data);
  }

}
