import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service'
import { LoadingService } from '../loading.service'
import { ObservablesService } from '../observables.service'
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  pointsArr:any=[];
  total:any;
  lostPercentage:any=0;
  types:any=[8,9]
  userInfo:any=[]
  Math: any;
  typeVariableData:any;
  tableArr:any=[];
  reportData:any=[]
  constructor(private loading:LoadingService,private api:ApiService,private observable:ObservablesService) {
    this.Math = Math;
  }

  ngOnInit() {

    this.observable.getSelectedUserStatus.subscribe((userInfo:any)=>{
      // console.log("userInfo in type wise report=",userInfo);
      this.userInfo = userInfo;
      this.types=[];
      this.types = this.userInfo.type.split(',');
      this.getTotalReportData();
      // this.refreshTable();
    })
  }

  getTotalReportData(){
     this.api.send("findDb",{
      table:"total_report",
      conditions:[{"YEARWEEK(date, 1)":"$YEARWEEK(CURDATE(), 1)$",user_id:this.userInfo.id}],
      order:"date ASC"
    }).then((response:any)=>{
      // console.log("total report==",response)
      this.reportData = response.data;
      this.onLinkClick({index:0})
    })
  }

  async onLinkClick(event){
    // console.log("selected tab=",event)
      if(event.index == 0){
        // await this.getVariableValues(this.types[0])
        this.filterData(this.types[0])
      }else if(event.index == 1){
        // await this.getVariableValues(this.types[1])
        this.filterData(this.types[1])
      }
  }

  async filterData(type){
    // console.log("point arr = =",this.pointsArr)
    // console.log("selected type=",type)
    this.tableArr = [];
    let i = 0;
    await this.reportData.filter((item:any)=>{
      i++;
      if(item.rate == type){
        this.tableArr.push(item);
      }

  
    })

    // console.log("tableArr =",this.tableArr)
  }

  getTotalPointsSum(title){
    let total_points:number=0;
    if(title=="ocb"){
      this.tableArr.filter((item:any)=>{
        total_points= total_points + item.ocb;
      })
      return total_points;
    }else if(title == "op"){
      this.tableArr.filter((item:any)=>{
        total_points= total_points + item.op;
      })
      return total_points;

    }else if(title == "oc"){
      this.tableArr.filter((item:any)=>{
        total_points= total_points + item.oc;
      })
      return total_points;

    }else if(title == "c"){
      this.tableArr.filter((item:any)=>{
        total_points= total_points + item.c;
      })
      return total_points;

    }else if(title == "pan1"){
      this.tableArr.filter((item:any)=>{
        total_points= total_points + item.pan1;
      })
      return total_points;

    }else if(title == "pan2"){
      this.tableArr.filter((item:any)=>{
        total_points= total_points + item.pan2;
      })
      return total_points;

    }else if(title == 'comm'){
      this.tableArr.filter((item:any)=>{
        total_points= total_points + this.getAbsuluteData(item.comm);
      })
      return total_points;
    }else if(title == 'debit'){
      this.tableArr.filter((item:any)=>{
        total_points= total_points + this.getAbsuluteData(item.debit);
      })
      return total_points;
    }else if(title == 'credit'){
      this.tableArr.filter((item:any)=>{
        total_points= total_points + this.getAbsuluteData(item.credit);
      })
      return total_points;
    }
  }

  getAbsuluteData(number){
    return parseFloat((Math.round(number * 100) / 100).toFixed(2))
  }


}
