import { Component, OnInit, OnDestroy, Input} from '@angular/core';
import { Router } from '@angular/router';
import { ObservablesService } from '../observables.service'
import { SocketObservablesService } from '../socket-observables.service'
import { ApiService } from '../api.service'

@Component({
  selector: 'app-view-header',
  templateUrl: './view-header.component.html',
  styleUrls: ['./view-header.component.scss']
})
export class ViewHeaderComponent implements OnInit {
  // @Input() set type(type:any){
  //   this.dayType = type.type;
  //   console.log("day type==",type);
  //   this.resultBarStatus = type.resultBarStatus
  //   if(this.dayType=="single"){
  //     this.weekStatus = false;
  //     this.result1='';
  //     this.result2='';
  //     this.middle_result='';
  //     this.today = Date.now();
  //   }else if(this.dayType == "week"){
  //     this.weekStatus = true;
  //   }
  //
  //
  // }

  // days:any=[{
  //   name:"Mon",
  //   value:1
  // },{
  //   name:"Tues",
  //   value:2
  // },{
  //   name:"Wed",
  //   value:3
  // },{
  //   name:"Thur",
  //   value:4
  // },{
  //   name:"Fri",
  //   value:5
  // },{
  //   name:"Sat",
  //   value:6
  // }];

  // resultBarStatus:boolean = true;
  // dayType:any;
  // weekStatus:boolean = false;
  // today: number = Date.now();
  // hours = (new Date()).getHours();
  p1:any='';
  p2:any='';
  op:any='';
  cp:any='';
  oc:any='';

  constructor(private socketObservables:SocketObservablesService, public router:Router,public observables:ObservablesService, private api:ApiService) { }

  ngOnInit() {

    this.observables.setViewHeaderResultStatus.subscribe((result:any)=>{
      // console.log("settinf in header",result)
      this.p1 = result.p1;
      this.p2 = result.p2;
      this.oc = result.oc;
      this.op = result.op;
      this.cp = result.cp;
    })
  }



  ngOnDestroy(){
    // console.log("ha ha ha, called me in view header")
  }

  openMenu(){
    //console.log(this.observables.menuStatus);

    this.observables.setMenuStatus({menuState:'in'})
  }

}
