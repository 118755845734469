import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { FormArray, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ObservablesService } from '../observables.service'

@Component({
  selector: 'app-more-options',
  templateUrl: './more-options.component.html',
  styleUrls: ['./more-options.component.scss']
})
export class MoreOptionsComponent implements OnInit {
  tab_index:number = 0;
  cycleStatus:boolean = true;
  singleStatus:boolean = false;
  doubleStatus:boolean = false;
  choucutStatus:boolean = false;
  astakStatus:boolean = false;
  gunuleStatus:boolean = false;
  singleGunuleStatus:boolean = true;
  multiGunuleStatus:boolean = false;
  ultaGunuleStatus:boolean = false;
  singleUltaGunuleStatus:boolean = true;
  multiUltaGunuleStatus:boolean = false;
  sangamStatus:boolean = false;
  singleSangamStatus:boolean = true;
  multiSangamStatus:boolean = false;
  abStatus:boolean = false;
  pointsReadyStatus:boolean = false;
  astakPointsReadyStatus:boolean = false;
  errorMessageStatus:boolean = false;
  pointsForm:FormGroup
  spForm:FormGroup
  dpForm:FormGroup
  choucutForm:FormGroup
  astakForm:FormGroup
  gunuleForm:FormGroup
  ultaGunuleForm:FormGroup
  sangamForm:FormGroup
  multiGunuleForm:FormGroup
  multiUltaGunuleForm:FormGroup
  multiSangamForm:FormGroup
  abForm:FormGroup
  abForm1:FormGroup
  numberPattern1:any = /^(?=\d{1,3}$)1*2*3*4*5*6*7*8*9*0*$/;
  numberPattern:any = /^(?!.*?(\w)\1).*$/
  mainABForm: FormGroup;
  selectedIndex:number = 0
   // @Input() set tabIndex(tabIndex:any){
   //   console.log("selected Tab index==",tabIndex)
   //   this.tab_index = tabIndex;
   //   if(this.tabIndex = 0){
   //     this.cycleStatus = true;
   //     this.choucutStatus = false;
   //     this.astakStatus = false;
   //   }else if(this.tabIndex = 1){
   //     this.cycleStatus = false;
   //     this.choucutStatus = true;
   //     this.astakStatus = false;
   //   }else{
   //     this.cycleStatus = false;
   //     this.choucutStatus = false;
   //     this.astakStatus = true;
   //   }
   // }
  //  @ViewChild('numberInput1', { static: false }) numberInput1:ElementRef;
  //  @ViewChild('numberInput2', { static: false }) numberInput2:ElementRef;
  //  @ViewChild('numberInput3', { static: false }) numberInput3:ElementRef;
  //  @ViewChild('numberInput4', { static: false }) numberInput4:ElementRef;
  //  @ViewChild('numberInput5', { static: false }) numberInput5:ElementRef;
  //  @ViewChild('numberInput6', { static: false }) numberInput6:ElementRef;
  //  @ViewChild('numberInput7', { static: false }) numberInput7:ElementRef;
  //  @ViewChild('numberInput8', { static: false }) numberInput8:ElementRef;
  //  @ViewChild('numberInput9', { static: false }) numberInput9:ElementRef;
  //  @ViewChild('numberInput10', { static: false }) numberInput10:ElementRef;
  //  @ViewChild('numberInput11', { static: false }) numberInput11:ElementRef;
  //  @ViewChild('numberInput12', { static: false }) numberInput12:ElementRef;
  constructor(private renderer:Renderer2 ,private cd:ChangeDetectorRef,private observables:ObservablesService, private fb:FormBuilder,private observable:ObservablesService) {
    this.pointsForm = this.fb.group({
        upper_number:[null,Validators.compose([Validators.required])],
        lower_number:[null,Validators.compose([Validators.required])],
        amount:[null,Validators.compose([Validators.required])]
      });
    this.spForm = this.fb.group({
        sp_number:[null,Validators.compose([Validators.required])],
        amount:[null,Validators.compose([Validators.required])]
      });
    this.dpForm = this.fb.group({
        dp_number:[null,Validators.compose([Validators.required])],
        amount:[null,Validators.compose([Validators.required])]
      });
    // let ultaGunuleItems = {
    //   pan:[null,Validators.compose([Validators.required, Validators.minLength(3),Validators.maxLength(3),Validators.pattern(this.numberPattern1)])],
    //   single:[null,Validators.compose([Validators.required, Validators.minLength(1),Validators.maxLength(1),Validators.pattern(/^[0-9]{1}$/)])],
    //   amount:[null,Validators.compose([Validators.required])]
    // }

    this.gunuleForm = this.fb.group({
      pan:[null,Validators.compose([Validators.required, Validators.minLength(3),Validators.maxLength(3),Validators.pattern(this.numberPattern1)])],
      items: this.fb.array([]),
      amount:[null,Validators.compose([Validators.required])]
    });
    this.multiGunuleForm = this.fb.group({
      items: this.fb.array([]),
    });
    this.multiUltaGunuleForm = this.fb.group({
      items: this.fb.array([]),
    });
    this.multiSangamForm = this.fb.group({
      items: this.fb.array([]),
    });
    this.ultaGunuleForm = this.fb.group({
      pan:[null,Validators.compose([Validators.required, Validators.minLength(3),Validators.maxLength(3),Validators.pattern(this.numberPattern1)])],
      items: this.fb.array([]),
      amount:[null,Validators.compose([Validators.required])]
    });


    // this.ultaGunuleForm = this.fb.group({
    //     pan:[null,Validators.compose([Validators.required, Validators.minLength(3),Validators.maxLength(3),Validators.pattern(this.numberPattern1)])],
    //     single:[null,Validators.compose([Validators.required, Validators.minLength(1),Validators.maxLength(1),Validators.pattern(/^[0-9]{1}$/)])],
    //     amount:[null,Validators.compose([Validators.required])]
    //   });
    this.sangamForm = this.fb.group({
        pan1:[null,Validators.compose([Validators.required, Validators.minLength(3),Validators.maxLength(3),Validators.pattern(this.numberPattern1)])],
        pan2:[null,Validators.compose([Validators.required, Validators.minLength(3),Validators.maxLength(3),Validators.pattern(this.numberPattern1)])],
        amount:[null,Validators.compose([Validators.required])]
      });
    this.abForm = this.fb.group({
        items: this.fb.array([]),
        // number:[null,Validators.compose([Validators.required, Validators.minLength(1),Validators.maxLength(1),Validators.pattern(/^[0-9]{1}$/)])],
        operation:[{value:'A',disabled:true},Validators.compose([Validators.required])],
        amount:[null]
      });
    this.abForm1 = this.fb.group({
        items: this.fb.array([]),
        // number:[null,Validators.compose([Validators.required, Validators.minLength(1),Validators.maxLength(1),Validators.pattern(/^[0-9]{1}$/)])],
        operation:[{value:'B',disabled:true},Validators.compose([Validators.required])],
        amount:[null]
      });

      this.mainABForm = this.fb.group({
        abForm: this.abForm,
        abForm1: this.abForm1
      });
  
    this.choucutForm = this.fb.group({
        first:[null,Validators.compose([Validators.required])],
        second:[{value:null, disabled: true},Validators.compose([Validators.required])],
        third:[{value:null, disabled: true},Validators.compose([Validators.required])],
        fourth:[{value:null, disabled: true},Validators.compose([Validators.required])],
        amount:[null,Validators.compose([Validators.required])]
      });
    this.astakForm = this.fb.group({
        first:[null,Validators.compose([Validators.required,Validators.pattern(this.numberPattern)])],
        second:[{value:null, disabled: true},Validators.compose([Validators.required])],
        third:[{value:null, disabled: true},Validators.compose([Validators.required])],
        fourth:[{value:null, disabled: true},Validators.compose([Validators.required])],
        fifth:[{value:null, disabled: true},Validators.compose([Validators.required])],
        sixth:[{value:null, disabled: true},Validators.compose([Validators.required])],
        seventh:[{value:null, disabled: true},Validators.compose([Validators.required])],
        eighth:[{value:null, disabled: true},Validators.compose([Validators.required])],
        amount:[null,Validators.compose([Validators.required])]
      });
   }

  ngOnInit() {

    this.observables.moreSubOptionsStatus.subscribe((data)=>{
      // console.log("pressed key==",data)
      this.selectedIndex = data
      setTimeout(()=>{

        var elem:any;

      if(this.selectedIndex == 0){
        elem = this.renderer.selectRootElement('#numberInput1');
        // this.numberInput1.nativeElement.focus()
      }else if(this.selectedIndex == 1){
        elem = this.renderer.selectRootElement('#numberInput2');
        // this.numberInput2.nativeElement.focus()
      }else if(this.selectedIndex == 2){
        elem = this.renderer.selectRootElement('#numberInput3');
        // this.numberInput3.nativeElement.focus()
      }else if(this.selectedIndex == 3){
        elem = this.renderer.selectRootElement('#numberInput4');
        // this.numberInput4.nativeElement.focus()
      }else if(this.selectedIndex == 4){
        elem = this.renderer.selectRootElement('#numberInput5');
        // this.numberInput5.nativeElement.focus()
      }else if(this.selectedIndex == 5){
        elem = this.renderer.selectRootElement('#numberInput6');
        // this.numberInput6.nativeElement.focus()
      }else if(this.selectedIndex == 6){
        elem = this.renderer.selectRootElement('#numberInput8');
        // this.numberInput8.nativeElement.focus()
      }else if(this.selectedIndex == 7){
        elem = this.renderer.selectRootElement('#numberInput10');
        // this.numberInput10.nativeElement.focus()
      }else if(this.selectedIndex == 8){
        elem = this.renderer.selectRootElement('#numberInput12');
        // this.numberInput12.nativeElement.focus()
      }

      elem.focus()
      this.cd.detectChanges()
    },1000)
        
        
        
        
        
        
        
        
      // numberInput7
      // numberInput9
      // numberInput11
     
    })

    // this.abForm.get('items').valueChanges.subscribe((items) => {
    //   const pointsControl = this.abForm.get('amount');
    //   if (items.some(item => item.number)) {
    //     pointsControl.setValidators([Validators.required]);
    //   } else {
    //     pointsControl.clearValidators();
    //   }
    //   pointsControl.updateValueAndValidity();
    // });
    // this.abForm1.get('items').valueChanges.subscribe((items) => {
    //   const pointsControl = this.abForm1.get('amount');
    //   if (items.some(item => item.number)) {
    //     pointsControl.setValidators([Validators.required]);
    //   } else {
    //     pointsControl.clearValidators();
    //   }
    //   pointsControl.updateValueAndValidity();
    // });
  }

  
  ngAfterViewInit(){
   var elem = this.renderer.selectRootElement('#numberInput1');

   elem.focus()
    // this.numberInput.nativeElement.focus()
  }

    get num1() {
      return this.abForm1.controls["items"] as FormArray;
    }
    get num() {
      if(this.gunuleStatus){
        if(this.singleGunuleStatus){

          return this.gunuleForm.controls["items"] as FormArray;
        }else{
          return this.multiGunuleForm.controls["items"] as FormArray;

        }
      }else if(this.ultaGunuleStatus){
        if(this.singleUltaGunuleStatus){
          return this.ultaGunuleForm.controls["items"] as FormArray;
        }else{
          return this.multiUltaGunuleForm.controls["items"] as FormArray;

        }

      }else if(this.sangamStatus){
        if(this.multiSangamStatus){
        //   return this.ultaGunuleForm.controls["items"] as FormArray;
        // }else{
          return this.multiSangamForm.controls["items"] as FormArray;

        }
        
      }else if(this.abStatus){
        return this.abForm.controls["items"] as FormArray;

      }
    }

    isFormValid(): boolean {
      const formArray = this.multiGunuleForm.get('items') as FormArray;
  
      return formArray.controls.every((group: FormGroup) => {
        return Object.values(group.controls).every(control => control.valid && control.value !== '');
      });
    }

    addItems() {
      if(this.gunuleStatus){
        if(this.singleGunuleStatus){
          
          const gunuleFrm = this.fb.group({
            single:[null,Validators.compose([Validators.minLength(1),Validators.maxLength(1),Validators.pattern(/^[0-9]{1}$/)])],
          });
          this.num.push(gunuleFrm);
        }else if(this.multiGunuleStatus){

          const mutiGunuleFrm = this.fb.group({
            pan:[null,Validators.compose([ Validators.minLength(3),Validators.maxLength(3),Validators.pattern(this.numberPattern1)])],
            single:[null,Validators.compose([Validators.minLength(1),Validators.maxLength(1),Validators.pattern(/^[0-9]{1}$/)])],
            amount:[null,Validators.compose([Validators.minLength(1),Validators.maxLength(5),Validators.pattern(/^[1-9]\d*$/)])]
          });
          this.num.push(mutiGunuleFrm);
        }



      }else if(this.ultaGunuleStatus){

        if(this.singleUltaGunuleStatus){
          
          const ultaGunuleFrm = this.fb.group({
            single:[null,Validators.compose([Validators.minLength(1),Validators.maxLength(1),Validators.pattern(/^[0-9]{1}$/)])],
          });
          this.num.push(ultaGunuleFrm);
        }else if(this.multiUltaGunuleStatus){

          const mutiUltaGunuleFrm = this.fb.group({
            single:[null,Validators.compose([Validators.minLength(1),Validators.maxLength(1),Validators.pattern(/^[0-9]{1}$/)])],
            pan:[null,Validators.compose([ Validators.minLength(3),Validators.maxLength(3),Validators.pattern(this.numberPattern1)])],
            amount:[null,Validators.compose([Validators.minLength(1),Validators.maxLength(5),Validators.pattern(/^[1-9]\d*$/)])]
          });
          this.num.push(mutiUltaGunuleFrm);
        }

      }else if(this.sangamStatus){

        if(this.multiSangamStatus){

          const mutiSangamFrm = this.fb.group({
            pan1:[null,Validators.compose([ Validators.minLength(3),Validators.maxLength(3),Validators.pattern(this.numberPattern1)])],
            pan2:[null,Validators.compose([ Validators.minLength(3),Validators.maxLength(3),Validators.pattern(this.numberPattern1)])],
            amount:[null,Validators.compose([Validators.minLength(1),Validators.maxLength(5),Validators.pattern(/^[1-9]\d*$/)])]
          });
          this.num.push(mutiSangamFrm);
        }

        // const ultaGunuleFrm = this.fb.group({
        //   single:[null,Validators.compose([Validators.minLength(1),Validators.maxLength(1),Validators.pattern(/^[0-9]{1}$/)])],
        // });

        // this.num.push(ultaGunuleFrm);

      }else if(this.abStatus){
        const abFrm = this.fb.group({
          number:[null,Validators.compose([Validators.maxLength(1),Validators.pattern(/^[0-9]{1}$/)])],
        });

        const abFrm1 = this.fb.group({
          number:[null,Validators.compose([Validators.maxLength(1),Validators.pattern(/^[0-9]{1}$/)])],
        });
        this.num.push(abFrm);
        this.num1.push(abFrm1)
      }
    }

    onTabChange1(event){
      this.gunuleForm.setControl('items', this.fb.array([]));
      this.multiGunuleForm.setControl('items', this.fb.array([]));
      this.ultaGunuleForm.setControl('items', this.fb.array([]));
      this.multiUltaGunuleForm.setControl('items', this.fb.array([]));
      this.multiSangamForm.setControl('items', this.fb.array([]));
      if(this.gunuleStatus){
        if(event.index == 0){
          this.singleGunuleStatus = true
          this.multiGunuleStatus = false
           
          for(let i=0;i<10;i++){
            this.addItems()
          }
        }else{
          this.singleGunuleStatus = false
          this.multiGunuleStatus = true
           
          for(let i=0;i<10;i++){
            this.addItems()
          }
        }
      }else if(this.ultaGunuleStatus){
         if(event.index == 0){
          this.singleUltaGunuleStatus = true
          this.multiUltaGunuleStatus = false
           
          for(let i=0;i<10;i++){
            this.addItems()
          }
        }else{
          this.singleUltaGunuleStatus = false
          this.multiUltaGunuleStatus = true
           
          for(let i=0;i<10;i++){
            this.addItems()
          }
        }
      }else if(this.sangamStatus){
         if(event.index == 0){
          this.singleSangamStatus = true
          this.multiSangamStatus = false
        }else{
          this.singleSangamStatus = false
          this.multiSangamStatus = true
           
          for(let i=0;i<10;i++){
            this.addItems()
          }
        }
      }
    }

  onTabChange(event){
    this.selectedIndex = event.index
    this.gunuleForm.setControl('items', this.fb.array([]));
      this.multiGunuleForm.setControl('items', this.fb.array([]));
      this.ultaGunuleForm.setControl('items', this.fb.array([]));
      this.multiUltaGunuleForm.setControl('items', this.fb.array([]));
      this.multiSangamForm.setControl('items', this.fb.array([]));
    if(event.index == 0){
     this.cycleStatus = true;
     this.singleStatus = false;
     this.doubleStatus = false;
     this.choucutStatus = false;
     this.astakStatus = false;
     this.gunuleStatus = false;
     this.ultaGunuleStatus = false;
     this.sangamStatus = false;
     this.abStatus = false
   }else if(event.index == 1){
    this.cycleStatus = false;
     this.singleStatus = true;
     this.doubleStatus = false;
     this.choucutStatus = false;
     this.astakStatus = false;
     this.gunuleStatus = false;
     this.ultaGunuleStatus = false;
     this.sangamStatus = false;
     this.abStatus = false
   }else if(event.index == 2){
    this.cycleStatus = false;
     this.singleStatus = false;
     this.doubleStatus = true;
     this.choucutStatus = false;
     this.astakStatus = false;
     this.gunuleStatus = false;
     this.ultaGunuleStatus = false;
     this.sangamStatus = false;
     this.abStatus = false
   }else if(event.index == 3){
     this.cycleStatus = false;
     this.singleStatus = false;
     this.doubleStatus = false;
     this.choucutStatus = true;
     this.astakStatus = false;
     this.gunuleStatus = false;
     this.ultaGunuleStatus = false;
     this.sangamStatus = false;
     this.abStatus = false
   }else if(event.index == 4){
     this.cycleStatus = false;
     this.singleStatus = false;
     this.doubleStatus = false;
     this.choucutStatus = false;
     this.gunuleStatus = false;
     this.astakStatus = true;
     this.ultaGunuleStatus = false;
     this.sangamStatus = false;
     this.abStatus = false
   }else if(event.index == 5){
     this.cycleStatus = false;
     this.singleStatus = false;
     this.doubleStatus = false;
     this.choucutStatus = false;
     this.astakStatus = false;
     this.gunuleStatus = true;
     this.ultaGunuleStatus = false;
     this.sangamStatus = false;
     this.abStatus = false
     for(let i=0;i<10;i++){
       this.addItems()
     }
   }else if(event.index == 6){
     this.cycleStatus = false;
     this.singleStatus = false;
     this.doubleStatus = false;
     this.choucutStatus = false;
     this.astakStatus = false;
     this.gunuleStatus = false;
     this.ultaGunuleStatus = true;
     this.sangamStatus = false;
     this.abStatus = false
     for(let i=0;i<10;i++){
       this.addItems()
     }
   }else if(event.index == 7){
     this.cycleStatus = false;
     this.singleStatus = false;
     this.doubleStatus = false;
     this.choucutStatus = false;
     this.astakStatus = false;
     this.gunuleStatus = false;
     this.ultaGunuleStatus = false;
     this.sangamStatus = true;
     this.abStatus = false
   }else if(event.index == 8){
     this.cycleStatus = false;
     this.singleStatus = false;
     this.doubleStatus = false;
     this.choucutStatus = false;
     this.astakStatus = false;
     this.gunuleStatus = false;
     this.ultaGunuleStatus = false;
     this.sangamStatus = false;
     this.abStatus = true
     for(let i=0;i<10;i++){
       this.addItems()
     }
   }
  }

  submitCycleData(values){
    // console.log("values==",values)
    let upperValues:any=values.upper_number.toString().split('');
    let lowerValues:any=values.lower_number.toString().split('');
    let combinations:any=[];

    for(let i=0;i<upperValues.length;i++){
      for(let j=0;j<lowerValues.length;j++){
        combinations.push({number:upperValues[i]+""+lowerValues[j],points:values.amount});
      }
    }

    // console.log("combinations are==",combinations)
    this.observable.addPoints(combinations);
  }

  customSort = (a:any, b:any)=> {
    if (a === '0') {
      return 1; // Treat 0 as the largest number
    } else if (b === '0') {
      return -1; // Treat 0 as the largest number
    } else {
      return a.localeCompare(b); // Normal comparison for other digits
    }
  };

  submitSPData(values){
    const numStr = values.sp_number.toString().split('').sort(this.customSort).join('')
    const digits = Array.from(numStr, Number);
    const numDigits = digits.length;
    let combinations = []
    for (let i = 0; i < numDigits; i++) {
        for (let j = i + 1; j < numDigits; j++) {
            for (let k = j + 1; k < numDigits; k++) {
                combinations.push({number:`${digits[i]}${digits[j]}${digits[k]}`,points:values.amount});
            }
        }
    }

    // console.log("combinations are==",combinations)
    this.observable.addPoints(combinations);
  }

  getNumber(number){
    let combination = []
      for(let i=0;i<number.length;i++){
          for(let j=i+1;j<number.length;j++){
              combination.push(`${number[i]}${number[i]}${number[j]}`)
              combination.push(`${number[i]}${number[j]}${number[j]}`)
          }
      }

    return combination
}

  
  submitDPData(values){
    const numStr = values.dp_number.toString().split('').sort(this.customSort).join('')
    const number = Array.from(numStr, Number);
    const numDigits = number.length;
    let combinations = []
    for(let i=0;i<numDigits;i++){
      for(let j=i+1;j<numDigits;j++){
        combinations.push({number:`${number[i]}${number[i]}${number[j]}`,points:values.amount});
        combinations.push({number:`${number[i]}${number[j]}${number[j]}`,points:values.amount});
   
      }
     }
           
     combinations.sort((a:any,b:any)=>a.number-b.number)
    // console.log("combinations are==",combinations)
    this.observable.addPoints(combinations);
  }

  calculateOtherPoints(event){
    if(event.target.value.length == 2){
      // console.log("event data=",event.target.value)
      let value:string=event.target.value.toString();
      let firstDigit:any = parseInt(value.split('')[0]);
      let secondDigit:any = parseInt(value.split('')[1]);
      this.pointsReadyStatus = true;
      let second:any = this.get2And4Numbers(value,firstDigit,secondDigit);
      // console.log("second value=",second)
      firstDigit = second.toString().split('')[0];
      secondDigit = second.toString().split('')[1];

      let third:string;

      firstDigit = this.get3FirstNumber(parseInt(firstDigit));
      secondDigit = this.get3SecondNumber(parseInt(secondDigit));
      // console.log("first digit for 3==",firstDigit)
      // console.log("second digit for 3==",secondDigit)
      third = firstDigit.toString() + secondDigit.toString();
      // console.log("third value=",third)
      let fourth:number = this.get2And4Numbers(third,firstDigit,secondDigit);
      // console.log("fourth values==",fourth)
      this.choucutForm.patchValue({second:second,third:third,fourth:fourth})
    }else{
      this.choucutForm.patchValue({second:null,third:null,fourth:null})
    }
  }

  get2And4Numbers(value,firstDigit,secondDigit){
    // console.log("fir==",firstDigit)
    // console.log("sec==",secondDigit)
    if(secondDigit < 5 && firstDigit != 0){
      return parseInt(value) + 5;
    }else if(secondDigit > 4 && firstDigit != 0){
      return parseInt(value) - 5;
    }else if(firstDigit == 0 && secondDigit < 5){
      return firstDigit.toString()+(secondDigit + 5).toString()
    }else if(firstDigit == 0 && secondDigit > 4){
      // console.log("in if==",firstDigit.toString()+(secondDigit - 5).toString())
      return firstDigit.toString()+(secondDigit - 5).toString()
    }
  }

  get3FirstNumber(firstDigit){

    if(firstDigit < 5){
      return firstDigit + 5;
    }else if(firstDigit > 4){
      return firstDigit - 5;
    }
  }
  get3SecondNumber(secondDigit){

    if(secondDigit < 5){
      return secondDigit + 5;
    }else if(secondDigit > 4){
      return secondDigit - 5;
    }
  }

  submitChoucutData(){
    let values:any=this.choucutForm.getRawValue();
    // console.log("choucut values=",this.choucutForm.getRawValue())
    let combinations = [];

    combinations.push({number:values.first,points:values.amount,typeStatus:false});
    combinations.push({number:values.second,points:values.amount,typeStatus:false});
    combinations.push({number:values.third,points:values.amount,typeStatus:false});
    combinations.push({number:values.fourth,points:values.amount,typeStatus:false});


    // console.log("choucut combinations are==",combinations)
    this.observable.addPoints(combinations);
  }

  calculateOtherAstakPoints(event){
    if(event.target.value.length == 2){
      // console.log("event data=",event.target.value)
      let value:string=event.target.value.toString();
      if( value == "16" || value == "27" || value == "38" || value == "49" || value == "50" || value == "61" || value == "72" || value == "83" || value == "94" || value == "16" || value == "05"){
        this.astakPointsReadyStatus = false;
        this.errorMessageStatus = true;
      }else{
        this.errorMessageStatus = false;
        // console.log("form=",this.astakForm)
        if(this.astakForm.controls["first"].valid){

          let firstDigit:any = parseInt(value.split('')[0]);
          let secondDigit:any = parseInt(value.split('')[1]);
          this.astakPointsReadyStatus = true;
          let second:any = this.get2And4Numbers(value,firstDigit,secondDigit);
          // console.log("second value=",second)
          firstDigit = second.toString().split('')[0];
          secondDigit = second.toString().split('')[1];

          let third:string;

          firstDigit = this.get3FirstNumber(parseInt(firstDigit));
          secondDigit = this.get3SecondNumber(parseInt(secondDigit));
          // console.log("first digit for 3==",firstDigit)
          // console.log("second digit for 3==",secondDigit)
          third = firstDigit.toString() + secondDigit.toString();
          // console.log("third value=",third)
          let fourth:any = this.get2And4Numbers(third,firstDigit,secondDigit);
          // console.log("fourth values==",fourth)

          let fifth:any = value.split("").reverse().join("");
          let sixth:any = second.toString().split("").reverse().join("");
          let seventh:any = third.split("").reverse().join("");
          let eighth:any = fourth.toString().split("").reverse().join("");
          this.astakForm.patchValue({second:second,third:third,fourth:fourth,fifth:fifth,sixth:sixth,seventh:seventh,eighth:eighth})
        }else{
          this.astakPointsReadyStatus = false;
          this.astakForm.patchValue({second:null,third:null,fourth:null,fifth:null,sixth:null,seventh:null,eighth:null})
        }
      }
    }else{
      this.astakPointsReadyStatus = false;
      this.astakForm.patchValue({second:null,third:null,fourth:null,fifth:null,sixth:null,seventh:null,eighth:null})
    }
  }

  submitAstakData(){
    let values:any=this.astakForm.getRawValue();
    // console.log("choucut values=",values)
    let combinations = [];

    combinations.push({number:values.first,points:values.amount});
    combinations.push({number:values.second,points:values.amount});
    combinations.push({number:values.third,points:values.amount});
    combinations.push({number:values.fourth,points:values.amount});
    combinations.push({number:values.fifth,points:values.amount});
    combinations.push({number:values.sixth,points:values.amount});
    combinations.push({number:values.seventh,points:values.amount});
    combinations.push({number:values.eighth,points:values.amount});


    // console.log("choucut combinations are==",combinations)
    this.observable.addPoints(combinations);
  }

  // calculateOP(number){
  //   // console.log("entered key=",number)
  //   // console.log("session key=",session)
  //   number = number.toString();
  //   if(number.length == 3){
  //     let :number = parseInt(number.charAt(0))+parseInt(number.charAt(1))+parseInt(number.charAt(2));
  //     // console.log("calc=",calculateValues)
  //
  //   }else{
  //
  //   }
  // }
  submitGunuleData(formType){ 
    let Formvalues:any = (formType == 1)?this.gunuleForm.getRawValue():this.multiGunuleForm.getRawValue();
    // console.log("Formvalues==",Formvalues)
    let values:any = Formvalues.items.filter(obj =>
      Object.values(obj).every(value => value !== null)
    );

    // console.log("values==",values)
    let combinations = [];
    if(this.singleGunuleStatus){

      const { amount,pan } = Formvalues;
      values = {amount, pan, items:values}
    for(let i=0;i<values.items.length;i++){
        if(values.pan && values.items[i].single !== null && values.amount){
        

            combinations.push({number:values.pan,points:values.amount,gunule:values.items[i].single,relative_number:values.items[i].single});
            combinations.push({number:values.items[i].single,points:values.amount,gunule:null,gunuleStatus:1,relative_number:values.pan});
          
            
        }

        if(i == values.items.length - 1){

          this.observable.addPoints(combinations);
        }
      }
    }else if(this.multiGunuleStatus){
      for(let i=0;i<values.length;i++){
        if(values[i].pan && values[i].single !== null && values[i].amount){
        

          combinations.push({number:values[i].pan,points:values[i].amount,gunule:values[i].single,relative_number:values[i].single});
          combinations.push({number:values[i].single,points:values[i].amount,gunule:null,gunuleStatus:1,relative_number:values[i].pan});
        
          
        }

        if(i == values.length - 1){

          this.observable.addPoints(combinations);
        }
      }
     
    }
  }
  submitUltaGunuleData(formType){
    let Formvalues:any = (formType == 1)?this.ultaGunuleForm.getRawValue():this.multiUltaGunuleForm.getRawValue();
    // console.log("Formvalues==",Formvalues)
    let values:any = Formvalues.items.filter(obj =>
      Object.values(obj).every(value => value !== null)
    );

    // console.log("values==",values)
    let combinations = [];
    if(this.singleUltaGunuleStatus){
      const { amount,pan } = Formvalues;
      values = {amount, pan, items:values}
    for(let i=0;i<values.items.length;i++){
        if(values.pan && values.items[i].single !== null && values.amount){

          combinations.push({number:values.items[i].single,points:values.amount,ulta_gunule:values.pan,relative_number:values.pan});
          combinations.push({number:values.pan,points:values.amount,ulta_gunule:null,ultaGunuleStatus:1,relative_number:values.items[i].single});
        }////check here
      
        if(i == values.items.length - 1){

          this.observable.addPoints(combinations);
        }
      }
      
    }else if(this.multiUltaGunuleStatus){
      for(let i=0;i<values.length;i++){
        if(values[i].pan && values[i].single !== null && values[i].amount){
        

          combinations.push({number:values[i].single,points:values[i].amount,ulta_gunule:values[i].pan,relative_number:values[i].pan});
          combinations.push({number:values[i].pan,points:values[i].amount,ulta_gunule:null,ultaGunuleStatus:1,relative_number:values[i].single});
        
          
        }

        if(i == values.length - 1){

          this.observable.addPoints(combinations);
        }
      }
      
    }
  }
  
  // (){
  //   let values:any=this.ultaGunuleForm.getRawValue();
  //   console.log("values==",values)
  //   let combinations = [];

  //   // combinations.push({number:values.single,points:values.amount,ulta_gunule:values.pan,relative_number:values.pan});
  //   // combinations.push({number:values.pan,points:values.amount,ulta_gunule:null,ultaGunuleStatus:1,relative_number:values.single});
  //   // this.observable.addPoints(combinations);
  //   for(let i=0;i<values.items.length;i++){
  //     if(values.pan && values.items[i].single !== null && values.amount){

  //       combinations.push({number:values.items[i].single,points:values.amount,ulta_gunule:values.pan,relative_number:values.pan});
  //       combinations.push({number:values.pan,points:values.amount,ulta_gunule:null,ultaGunuleStatus:1,relative_number:values.items[i].single});
  //     }
  //     if(i == values.items.length - 1){

  //       this.observable.addPoints(combinations);
  //     }
  //   }
  // }
  submitSangamData(formType){
    let Formvalues:any = (formType == 1)?this.sangamForm.getRawValue():this.multiSangamForm.getRawValue();
    // console.log("Formvalues==",Formvalues)
    

    // console.log("values==",values)
    let combinations = [];
    if(this.singleSangamStatus){
      let values = Formvalues
      combinations.push({number:values.pan1,points:values.amount,sangam:values.pan2,relative_number:values.pan2});
      combinations.push({number:values.pan2,points:values.amount,sangam:null,sangamStatus:1,relative_number:values.pan1});
      this.observable.addPoints(combinations);
    }else if(this.multiSangamStatus){
      let values:any = Formvalues.items.filter(obj =>
        Object.values(obj).every(value => value !== null)
      );

      for(let i=0;i<values.length;i++){
        // if(values[i].pan1 && values[i].pan2 !== null && values[i].amount){
        
          // console.log("values=======",values)
          combinations.push({number:values[i].pan1,points:values[i].amount,sangam:values[i].pan2,relative_number:values[i].pan2});
          combinations.push({number:values[i].pan2,points:values[i].amount,sangam:null,sangamStatus:1,relative_number:values[i].pan1});
        
        // }

        // console.log("combinations==",combinations)

        if(i == values.length - 1){

          this.observable.addPoints(combinations);
        }
      }
    }
   
      
  }
  submitABData(){
   
    console.log("form array==", this.mainABForm.getRawValue())

    let values:any=this.abForm.getRawValue();
    let values1:any=this.abForm1.getRawValue();
    // console.log("values==",values)
    // console.log("values1==",values1)
   
    let combinations = [];
    for(let i=0;i<values.items.length;i++){
      if(values.items[i].number !== null  && values.items[i].number !== ''  && values.amount){
        // console.log("values==",values.items[i])
        combinations.push({number:values.items[i].number,points:values.amount,operation:values.operation,relative_number:values.items[i].number});
      }
    
    }

    for(let i=0;i<values1.items.length;i++){
      if(values1.items[i].number !== null && values1.items[i].number !== '' && values1.amount){
        // console.log("values1==",values1.items[i])
        combinations.push({number:values1.items[i].number,points:values1.amount,operation:values1.operation,relative_number:values1.items[i].number});
      }
    }

    // console.log("combination===",combinations)
   this.observable.addPoints(combinations);
  }
}
