import { Injectable } from '@angular/core';
import { LoadingService } from './loading.service';
import { SocketObservablesService } from './socket-observables.service';
import { environment } from '../environments/environment'
declare var io
@Injectable({
  providedIn: 'root'
})
export class SocketService {
  socket:any;
  constructor(private loading:LoadingService,private socketObservables:SocketObservablesService) { }

  startSocket(loading2:LoadingService){
    if(typeof io === 'undefined'){
      // console.log("\nServer needs to be running")
     loading2.loading(true,"hourglass-half","Server is not runnig, please check with support person")
      return false
    }

   //  //constructor has started the io initialization
   //  var connectionOptions =  {
   //              "force new connection" : true,
   //              "reconnectionAttempts": "Infinity", //avoid having user reconnect manually in order to prevent dead clients after a server restart
   //              "timeout" : 10000,                  //before connect_error and connect_timeout are emitted.
   //              "transports" : ["websocket"]
   //          };
   //
   // this.socket = io.connect(environment.apiHost,connectionOptions)
   var connectionOptions =  {
               "force new connection" : true,
                "reconnectionAttempts": "Infinity", //avoid having user reconnect manually in order to prevent dead clients after a server restart
                "timeout" : 10000,                  //before connect_error and connect_timeout are emitted.
                "transports" : ["websocket"],
                secure: true,
                rejectUnauthorized: false,
                path: '/chat/socket.io'
           };



  this.socket = io.connect(environment.socket,connectionOptions)
    this.socket.on("disconnect",()=>{
      console.log("Disconnected")
      this.loading.loading(true,'exclamation-triangle','Check internet connection<br /> OR <br />Server might be down for maintainence and fixes,<br /> Please contact the support team')
    })

    this.socket.on("connect",()=>{
      console.log("\n\nReconnected")
      this.loading.loading(false)
    })

    this.socket.emit("fired",{status:"Fired Call"});

    this.resultUpdate();
    this.lotUpdate();
    this.amountUpdate();
    this.updateActiveStatus();
    this.messageUpdate();
    this.progressBarUpdate();
    return true
  }


  resultUpdate(){
    this.socket.on("updateResult",(data)=>{
      // console.log("\n\nUpdate Result fired",data)

      this.socketObservables.refreshResult(data);
    })
  }
  lotUpdate(){
    this.socket.on("insertLot",(data)=>{
      // console.log("\n\nUpdate Lots fired",data)

      this.socketObservables.refreshLots(data);
    })
  }
  amountUpdate(){

    this.socket.on("updateAmount"+localStorage.getItem("userId"),(data)=>{
      // console.log("\n\nUpdate Lots fired",data)

      this.socketObservables.refreshAmount(data);
    })
  }
  updateActiveStatus(){

    this.socket.on("updateActiveStatus"+localStorage.getItem("userId"),(data)=>{
      // console.log("\n\nUpdate Lots fired",data)

      this.socketObservables.refreshActiveStatus(data);
    })
  }
  messageUpdate(){

    this.socket.on("updateMessage",(data)=>{
      // console.log("\n\nUpdate Lots fired",data)

      this.socketObservables.refreshMessage(data);
    })
  }
  progressBarUpdate(){

    this.socket.on("updateProgressBar",(data)=>{
      // console.log("\n\nUpdate Lots fired",data)

      this.socketObservables.refreshProgressBar(data);
    })
  }




 listen(listner){
   return new Promise((resolve,reject)=>{
     this.socket.on(listner,(data)=>{
       resolve(data)
     })
   })
 }

 send(listnerName,data){
     // console.log("listnerName=",listnerName)
     this.socket.emit("fired",{status:"Fired Call"});

     let t = Date.now()+"-"+Math.random()*9999

     return new Promise((resolve,reject)=>{

       this.socket.once(listnerName+t+"Response",(response)=>{
         if(!response.status){

           reject(response)
         }

          resolve(response)
       })


         this.socket.emit(listnerName,{t:t,arg:data})


       // console.log("\n\n Send fired for: ",listnerName)
       // console.log("\n\n With Time: ",t)

     })
   }



}
