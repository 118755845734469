import { FormBuilder, Validators, FormGroup, ValidatorFn,ValidationErrors, AbstractControl, FormArray, FormControl } from '@angular/forms'
import * as moment from 'moment'

export const sameDateValidator = (val: any): ValidatorFn => {

  return (control: AbstractControl): ValidationErrors | null => {

    let v: any = control.value;

    if (!moment(v).isSame(val)) {
      return { 'dateMismatch': true, 'requiredValue': val }
    }else{

      return null;
    }


  }

}

export const sameMarketValidator = (val: any): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {

    let v: any = control.value;

    if (v != val) {
      return { 'marketMismatch': true, 'requiredValue': val, market:'Mismatch' }
    }else{
        return null;
    }



  }

}
