import { Component, OnInit, OnDestroy, ElementRef} from '@angular/core';
import { Router } from '@angular/router';
import { ObservablesService } from '../observables.service'
import { SocketObservablesService } from '../socket-observables.service'
import { ApiService } from '../api.service'
import { LoadingService } from '../loading.service'
import { KeyboardEventService } from '../keyboard-event.service'
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  today: number = Date.now();
  hours = (new Date()).getHours();
  result1:any='';
  result2:any='';
  middle_result:any='';
  market:any='';
  current_market_lots:any=[];
  pointsArr:any=[];
  showCurrentLotsStatusSubscription:any;
  updateResultStatusSubscription:any;
  refreshSessionStatusSubscription:any;
  saveUserPointsStatusSubscription:any;
  keyPadElements:any=[]
  saveLotStatus:boolean=false;
  alertElements:any=[]
  ticketData:any=[]
  sessionData:any=[]
  timer:any= 0;
  messageString:string=''
  showMessageStatus:any=0;
  exportSaturdayStatus:boolean=false;
  exportSundayStatus:boolean=false;
  constructor(private loading:LoadingService,private key:KeyboardEventService, private elementRef:ElementRef, private socketObservables:SocketObservablesService, public router:Router,public observables:ObservablesService, private api:ApiService) { }

  ngOnInit() {


    var d2 = new Date();
    if(d2.getDay() == 6){
      this.exportSaturdayStatus = true;
    }else{
      this.exportSaturdayStatus = false;
    }

    if(d2.getDay() == 0){
      this.exportSundayStatus = true;
    }else{
      this.exportSundayStatus = false;
    }

    this.getMessage();

    this.getCurrentMarket();

    this.showCurrentLotsStatusSubscription = this.observables.showCurrentLotsStatus.subscribe(()=>{
      this.loading.dataLoading(true)
      // console.log("mine current market lots and points==",result);
      this.getCurrentMarketLots().then((result:any)=>{
        this.observables.showLotsStatus.next(result);
      });
    })
    this.updateResultStatusSubscription = this.socketObservables.updateResultStatus.subscribe(()=>{
      this.getSessionResult();
    })

    this.refreshSessionStatusSubscription = this.observables.refreshSessionStatus.subscribe(()=>{
      this.getCurrentMarket();
    });

    this.saveUserPointsStatusSubscription = this.observables.saveUserPointsStatus.subscribe(()=>{
      this.saveLot();
    });

    this.key.sendTicketDataStatus.subscribe((ticketData:any)=>{
      //console.log("ticket Data==",ticketData)
       this.ticketData = ticketData;
    });

    this.socketObservables.updateMessageStatus.subscribe((data)=>{
      // console.log("amount updated",data);
      if(data.data.action == 'show'){
        this.getMessage()
      }else{
        this.getMessage()
      }
    })

  }

  ngOnDestroy(){
    this.updateResultStatusSubscription.unsubscribe();
    this.refreshSessionStatusSubscription.unsubscribe();
    this.saveUserPointsStatusSubscription.unsubscribe();
  }

  getMessage(){
    this.api.send('findDb',{
      table:'messages'
    }).then((res:any)=>{
      // console.log("message data=",res)
      if(res.data.length > 0){
        this.showMessageStatus = res.data[0].show_status;
        this.messageString = res.data[0].message;
      }else{
        this.showMessageStatus = 0
      }
    })
  }

  getSessionResult(){

    this.api.send("findDb",{
      table:"results",
      conditions:[{market:this.market,"DATE(created)":"$CURDATE()$"}]
    }).then((results:any)=>{
      // console.log("1st session result is=",results)
      if(results.data.length > 0){
        this.result1 = results.data[0].p1;
        this.result2 = results.data[0].p2;
        this.middle_result = results.data[0].oc;

      }
    }).catch(error=>{

      console.log(" session result error is=",error)
    })
  }

  openMenu(){
    //console.log(this.observables.menuStatus);

    this.observables.setMenuStatus({menuState:'in'})
  }

  checkUserDisableSastus(){
    return new Promise((resolve,reject)=>{
      this.api.send('findDb',{
        table:"users",
        conditions:[{active_status:"0",id:localStorage.getItem("userId")}]
      }).then((response:any)=>{
        // console.log("resonse",response)
        if(response.data.length > 0 && response.status){
          if(response.data[0].id == localStorage.getItem("userId")){
            resolve(true);
          }else{
            resolve(false);
          }
        }else{
          resolve(false);
        }
      })
    })
  }


  saveLot(){
    // console.log("session data=",this.sessionData)
    // console.log("timer==",this.timer)

    if(this.timer < 2){
      this.observables.showToastMessage({type:1,message:'Session and Market closed..Time out...!'});
      return
    }

    if(this.exportSaturdayStatus && this.market == 'N'){
        this.observables.showToastMessage({type:1,message:'Saturday Night Market Not Available...!'});
        return
    }

    if(this.exportSundayStatus){
        this.observables.showToastMessage({type:1,message:'Sunday Market Not Available...!'});
        return
    }

    this.checkUserDisableSastus().then((disableStatus:any)=>{
      if(disableStatus){

        this.getCurrentMarket().then((sessionData:any)=>{
          if(sessionData.data.length > 0){
            let currentDate = new Date()

            let endDate = new Date(currentDate.getTime());
            endDate.setHours(this.sessionData.end_time.split(":")[0]);
            endDate.setMinutes(this.sessionData.end_time.split(":")[1]);
            endDate.setSeconds(this.sessionData.end_time.split(":")[2]);
            // endDate > currentDate
            if(this.sessionData.session == 2 &&  currentDate > endDate){
              this.observables.showToastMessage({type:1,message:'Market closed, can\'t save Lot...!'});
            }else{

              if(this.ticketData.length > 0){
                if(confirm("Please Confirm Lot Entry..")){
                  this.observables.getLots(this.market);
                }else{
                  this.observables.cancelLotEntry();
                }

                }else{
                  this.observables.showToastMessage({type:1,message:'Points Not added yet...!'});
                }
              }
            }else{
              this.observables.showToastMessage({type:1,message:'Market closed, can\'t save Lot...!'});
            }
          })
      }else{
        this.observables.showToastMessage({type:1,message:'Can\'t save Lot...!, Please Contact Owner'});
      }
    })

  }

  saveLotConfirmation(){

      this.saveLotStatus=false;
      this.observables.getLots(this.market);

  }

  closeModal(){
    this.saveLotStatus=false;
    this.observables.cancelLotEntry();
  }

  clearAll(){
    if(confirm("Are you sure to clear all Points..?")){

      this.observables.clearAll();
    }
  }


    getLoggedUserInfo(){
      return new Promise((resolve,reject)=>{

        this.api.send("findDb",{
          table:"users",
          conditions:[{id:localStorage.getItem('userId')}]
        }).then((userInfo:any)=>{
          // console.log("userInfo=",userInfo);
          resolve(userInfo.data[0])

        }).catch(error=>{console.log("user info error",error)})
      })
    }


  getCurrentMarket(){
    // console.log("getting current market")
    return new Promise((resolve,reject)=>{

      this.api.send("findDb",{
        table:"session_times",
        conditions:[{"curtime() >":"$start_time$","curtime() <":"$end_time$",pride_status:environment.prideStatus}]
      }).then((sessionTimeRes:any)=>{
        // console.log("current running session=",sessionTimeRes)
        if(sessionTimeRes.data.length>0){
          this.sessionData = sessionTimeRes.data[0];
          this.market = sessionTimeRes.data[0].market;
          this.getSessionResult();
          this.observables.sendMarketData(sessionTimeRes.data[0]);
          // console.log("session data=",this.sessionData)
          let end_time = this.sessionData.end_time.split(":")
          this.getLoggedUserInfo().then((userInfo:any)=>{

            // console.log("extra_time==",userInfo.extra_time)
            // console.log('before end_time==',end_time)
            if(userInfo.extra_time){
              let min = userInfo.extra_time.split(':')[1]
              let sec = userInfo.extra_time.split(':')[2]
              end_time[1] = parseInt(end_time[1]) + parseInt(min)
              end_time[2] = parseInt(end_time[2]) + parseInt(sec)
            }

            // console.log('After end_time==',end_time)

            let end_date_time = new Date(new Date().setHours(end_time[0],end_time[1],end_time[2]))
            this.timer = 0
            let refreshInterval = setInterval(() => {

              let now = new Date();
              let timeDifference = end_date_time.getTime() - now.getTime();
              this.timer = timeDifference / 1000;
              // console.log("seconds=",this.timer)
              if(this.timer < 1){

                clearInterval(refreshInterval);
              }
            }, 1000);
            resolve(sessionTimeRes)
          })


        }else{
          resolve(sessionTimeRes)
        }
      }).catch(err=>{console.log("error while fetching session times",err)})
    })
  }







  getCurrentMarketLots(){
    return new Promise((resolve,reject)=>{
      this.api.send("findDb",{
        table:"lots as L",
        fields:["L.*, L.id as lot_id"],
        conditions:[{'L.day_night':this.market,"DATE(L.created)":"$CURDATE()$",'L.user_id':localStorage.getItem('userId')}],
        groupBy:"L.id",
        order:"L.lot_no"
      }).then((lotResponse:any)=>{
        // console.log("current lots=",lotResponse)
        if(lotResponse.data.length > 0){
        //   this.current_market_lots = lotResponse.data;
          resolve(this.getPoints(lotResponse.data));
        }else{
          this.loading.dataLoading(false);
        }
      }).catch(error=>{console.log("error while fetching current lots=",error);reject(error)})
    })

  }

  getPoints(lotResponse){
    return new Promise((resolve,reject)=>{
      this.pointsArr=[];
      for(let i=0; i<lotResponse.length;i++){
        this.api.send("findDb",{
          table:"points",
          conditions:[{"lot_id":lotResponse[i].lot_id}]
        }).then((pointResult:any)=>{
          // console.log('point result=',pointResult.data);
          lotResponse[i].points = pointResult.data;
          this.pointsArr.push({lot:lotResponse[i],points:pointResult.data});
          // console.log("final points==",this.pointsArr)
          if(i == lotResponse.length-1){
            resolve(this.pointsArr);
          }
        }).catch(pointsErr=>{console.log("Error while getting points=",pointsErr);reject(pointsErr)});
      }
    })
  }


}
