import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ObservablesService } from '../observables.service'
import { ApiService } from '../api.service';
import {FormControl} from '@angular/forms';
import { LoadingService } from '../loading.service';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators'
import * as _ from 'underscore';
import {Subject} from 'rxjs';
@Component({
  selector: 'app-loading-view',
  templateUrl: './loading-view.component.html',
  styleUrls: ['./loading-view.component.scss']
})

export class LoadingViewComponent implements OnInit {
  single_digit:any=[]
  double_digit:any=[]
  oc_data:any=[]
  op_data:any=[]
  c_data:any=[]
  pan1_data:any=[]
  pan2_data:any=[]
  thriple_digit:any=[]
  myControl = new FormControl();
  users: any=[];
  options: any=[];
  filteredOptions: Observable<string[]>;
  userStatus:boolean = false;
  SortStatus:boolean = false;
  showDataStatus:boolean = false;
  userData:any=[];
  selectedMarket:string="A";
  selectedDate:any;
  cTotal:any=0;
  ocTotal:any=0;
  opTotal:any=0;
  pan1Total:any=0;
  pan2Total:any=0;
  opSortedData:any=[]
  ocSortedData:any=[]
  cSortedData:any=[]
  pan1SortedData:any=[]
  pan2SortedData:any=[]
  opVariable:any;
  cVariable:any;
  ocVariable:any;
  pan1Variable:any;
  pan2Variable:any;
  opObserver:any = new Subject<any>();
  cObserver:any = new Subject<any>();
  ocObserver:any = new Subject<any>();
  pan1Observer:any = new Subject<any>();
  pan2Observer:any = new Subject<any>();
  showUsersPopupStatus:boolean = false;
  numberDetails:any =[]
  totalPoints:any = 0;
  selectedNumber:any = null
  op_diff:any = 0
  oc_diff:any = 0
  pan1_diff:any = 0
  c_diff:any = 0
  pan2_diff:any = 0
  @Input() set selectedData(selectedData:any){
    this.selectedDate = selectedData.date;
    this.selectedMarket = selectedData.market;
    this.getAllData();
  }
  @ViewChild('amt', { static: false }) amt;
  @ViewChild('rate', { static: false }) rate;
  @ViewChild('amt1', { static: false }) amt1;
  @ViewChild('rate1', { static: false }) rate1;
  @ViewChild('amt2', { static: false }) amt2;
  @ViewChild('rate2', { static: false }) rate2;
  @ViewChild('amt3', { static: false }) amt3;
  @ViewChild('rate3', { static: false }) rate3;
  @ViewChild('amt4', { static: false }) amt4;
  @ViewChild('rate4', { static: false }) rate4;
  constructor(private loading:LoadingService, private observables:ObservablesService, private api:ApiService) {

   }

   SortData(){
     if(this.SortStatus){
        this.SortStatus = false;
        this.op_data.sort((a, b) => parseFloat(a.number) - parseFloat(b.number));
        this.oc_data.sort((a, b) => parseFloat(a.number) - parseFloat(b.number));
        this.c_data.sort((a, b) => parseFloat(a.number) - parseFloat(b.number));
        this.pan1_data.sort((a, b) => parseFloat(a.number) - parseFloat(b.number));
        this.pan2_data.sort((a, b) => parseFloat(a.number) - parseFloat(b.number));
     }else{

       this.SortStatus = true;
       this.op_data.sort((a, b) => parseFloat(b.points) - parseFloat(a.points));
       this.oc_data.sort((a, b) => parseFloat(b.points) - parseFloat(a.points));
       this.c_data.sort((a, b) => parseFloat(b.points) - parseFloat(a.points));
       this.pan1_data.sort((a, b) => parseFloat(b.points) - parseFloat(a.points));
       this.pan2_data.sort((a, b) => parseFloat(b.points) - parseFloat(a.points));
     }
   }


  ngOnInit(){

    this.refreshClientList().then((res:any)=>{
      this.options = res;
      this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

    });
  }

  refreshClientList(){
    return new Promise((resolve,reject)=>{

      this.api.send("findDb",{
        table:"users",
        conditions:[{'role':'main-customer'}]
      }).then((res:any)=>{
        // console.log("\nRefreshed users list",res);

        this.users = res.data;
        resolve(this.users);

      });

    })


  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.username.toLowerCase().includes(filterValue));
  }

  onSelectionChanged(_event: any,userData) {

    if (_event.isUserInput) {
      this.SortStatus = false;
      this.single_digit = [];
      this.double_digit = [];
      this.thriple_digit = [];
      this.userData = userData;
      this.getAllData()
    }
  }

  async getDoubleDigit(){

      let result:any = _.groupBy(this.double_digit, "number")
      let doubleDigitResult= _.values(result);
      this.getOcData(doubleDigitResult)
  }

  async getOcData(doubleDigitResult){
    this.oc_data=[];
    // let i = 0;
    let k = 0;
    this.ocVariable = this.ocObserver.subscribe((k)=>{
      if(k>=doubleDigitResult.length){

          this.ocVariable.unsubscribe();

       }else{

         this.oc_data.push({number:doubleDigitResult[k][0].number,points: doubleDigitResult[k].reduce(function (acc, obj) { return acc + obj.points; }, 0)})

         if(k+1 == doubleDigitResult.length){
           setTimeout(()=>{
             this.showDataStatus = true;
             this.loading.dataLoading(false);
           },5000)

         }

         this.ocObserver.next(k+1);
       }
     })
     this.ocObserver.next(k);

  }
  async getOpData(singleDigitResult){
    // console.log("doubleDigitResult==",doubleDigitResult)
    this.op_data=[];
    let k = 0;
    this.opVariable = this.opObserver.subscribe((k)=>{
      if(k>= singleDigitResult.length){

          this.opVariable.unsubscribe();

       }else{

         this.op_data.push({number:singleDigitResult[k][0].number,points: singleDigitResult[k].reduce(function (acc, obj) { return acc + obj.points; }, 0),ultaGunuleStatus:(singleDigitResult[k].map((item)=>{return (item.ulta_gunule !== null)?1:0})).reduce(function (acc, obj) { return acc + obj; }, 0),gunuleStatus:(singleDigitResult[k].map((item)=>{return (item.gunule_point_id !== null)?1:0})).reduce(function (acc, obj) { return acc + obj; }, 0),gs_number:singleDigitResult[k].map((obj)=>{ return (obj.ulta_gunule_point_id !== null || obj.ulta_gunule !== null)?{type:'ulta_gunule',gs_number:obj.gs_number}:null})})

         if(k+1 == singleDigitResult.length){
           setTimeout(()=>{
             this.showDataStatus = true;
             this.loading.dataLoading(false);
           },5000)
         }

         this.opObserver.next(k+1);
       }
     })
     this.opObserver.next(k);

  }
  async getCData(singleDigitResult){


    // console.log("doubleDigitResult==",doubleDigitResult)
    this.c_data=[];

    let k = 0;
    this.cVariable = this.cObserver.subscribe((k)=>{
      if(k>= singleDigitResult.length){

          this.cVariable.unsubscribe();

       }else{
         // console.log("singleDigitResult[k]==",singleDigitResult[k])

         // this.c_data.push({number:singleDigitResult[k][0].number,points: singleDigitResult[k].reduce(function (acc, obj) { return acc + (obj.gunule_point_id !== null)?(obj.points/obj.triple_digit):obj.points; }, 0),gunuleStatus:(singleDigitResult[k].map((item)=>{return (item.gunule_point_id !== null)?1:0})).reduce(function (acc, obj) { return acc + obj; }, 0)})
         this.c_data.push({number:singleDigitResult[k][0].number,points: singleDigitResult[k].reduce(function (acc, obj) { return acc + obj.points; }, 0),gunuleStatus:(singleDigitResult[k].map((item)=>{return (item.gunule_point_id !== null)?1:0})).reduce(function (acc, obj) { return acc + obj; }, 0),adikStatus:(singleDigitResult[k].map((item)=>{return (item.adik !== null)?1:0})).reduce(function (acc, obj) { return acc + obj; }, 0),berijStatus:(singleDigitResult[k].map((item)=>{return (item.berij !== null)?1:0})).reduce(function (acc, obj) { return acc + obj; }, 0),gs_number:singleDigitResult[k].map((obj)=>{ return (obj.gunule_point_id !== null || obj.gunule !== null)?{type:'gunule',gs_number:obj.gs_number}:null})})

         if(k+1 == singleDigitResult.length){
           // console.log('c_data=',this.c_data)
           setTimeout(()=>{
             this.showDataStatus = true;
             this.loading.dataLoading(false);
           },5000)
         }

         this.cObserver.next(k+1);
       }
     })
     this.cObserver.next(k);

  }
  async getPan1Data(tripleDigitResult){
    // console.log("tripleDigitResult==",tripleDigitResult)
    this.pan1_data=[];

    let k = 0;
    this.pan1Variable = this.pan1Observer.subscribe((k)=>{
      if(k>= tripleDigitResult.length){

          this.pan1Variable.unsubscribe();

       }else{

         this.pan1_data.push({number:tripleDigitResult[k][0].number,points: tripleDigitResult[k].reduce(function (acc, obj) { return acc + obj.points; }, 0),gunuleStatus:(tripleDigitResult[k].map((item)=>{return (item.gunule !== null)?1:0})).reduce(function (acc, obj) { return acc + obj; }, 0),sangamStatus:(tripleDigitResult[k].map((item)=>{return (item.sangam !== null)?1:0})).reduce(function (acc, obj) { return acc + obj; }, 0),gs_number:tripleDigitResult[k].map((obj)=>{ return (obj.gunule_point_id !== null || obj.gunule !== null)?{type:'gunule',gs_number:obj.gs_number}:((obj.sangam_point_id !== null || obj.sangam !== null)?{type:'sangam',gs_number:obj.gs_number}:null)})})
         if(k+1 == tripleDigitResult.length){
           setTimeout(()=>{
             this.showDataStatus = true;
             this.loading.dataLoading(false);
           },5000)
         }

         this.pan1Observer.next(k+1);
       }
     })
     this.pan1Observer.next(k);
  }
  async getPan2Data(tripleDigitResult){
    // console.log("doubleDigitResult==",doubleDigitResult)
    this.pan2_data=[];
    let k = 0;
    this.pan2Variable = this.pan2Observer.subscribe((k)=>{
      if(k>= tripleDigitResult.length){

          this.pan2Variable.unsubscribe();

       }else{

         // this.pan2_data.push({number:tripleDigitResult[k][0].number,points: tripleDigitResult[k].reduce(function (acc, obj) { return acc + (obj.ulta_gunule_point_id !== null)?(obj.points/obj.single_digit):((obj.sangam_point_id !== null)?(obj.points/obj.triple_digit):obj.points); }, 0),ultaGunuleStatus:(tripleDigitResult[k].map((item)=>{return (item.ulta_gunule_point_id !== null)?1:0})).reduce(function (acc, obj) { return acc + obj; }, 0),sangamStatus:(tripleDigitResult[k].map((item)=>{return (item.sangam_point_id !== null)?1:0})).reduce(function (acc, obj) { return acc + obj; }, 0)})
         this.pan2_data.push({number:tripleDigitResult[k][0].number,points: tripleDigitResult[k].reduce(function (acc, obj) { return acc + obj.points; }, 0),ultaGunuleStatus:(tripleDigitResult[k].map((item)=>{return (item.ulta_gunule_point_id !== null)?1:0})).reduce(function (acc, obj) { return acc + obj; }, 0),sangamStatus:(tripleDigitResult[k].map((item)=>{return (item.sangam_point_id !== null)?1:0})).reduce(function (acc, obj) { return acc + obj; }, 0),gs_number:tripleDigitResult[k].map((obj)=>{ return (obj.ulta_gunule_point_id !== null || obj.ulta_gunule !== null)?{type:'ulta_gunule',gs_number:obj.gs_number}:((obj.sangam_point_id !== null || obj.sangam !== null)?{type:'sangam',gs_number:obj.gs_number}:null)})})
         if(k+1 == tripleDigitResult.length){
           console.log("pan2_data==",this.pan2_data)
           setTimeout(()=>{
             this.showDataStatus = true;
             this.loading.dataLoading(false);
           },5000)
         }

         this.pan2Observer.next(k+1);
       }
     })
     this.pan2Observer.next(k);
  }

  async getSingleDigit(){

    let sessionWise = _.groupBy(this.single_digit, "session_no")
    let sessionWiseResult= _.values(sessionWise);
    for(let i = 0;i<sessionWiseResult.length;i++){
      if(sessionWiseResult[i][0].session_no == 1){
        let opResult:any = _.groupBy(sessionWiseResult[i], "number")
        let opWiseResult= _.values(opResult);
        // this.op_data = opWiseResult
        await this.getOpData(opWiseResult)
      }else{
        let cResult:any = _.groupBy(sessionWiseResult[i], "number")
        let cWiseResult= _.values(cResult);
        // this.c_data = cWiseResult
        await this.getCData(cWiseResult)
      }


      // console.log("c==",this.c_data)
    }



  }
  async getTripleDigit(){
    let sessionWise = _.groupBy(this.thriple_digit, "session_no")
    let sessionWiseResult= _.values(sessionWise);
    for(let i = 0;i<sessionWiseResult.length;i++){
      if(sessionWiseResult[i][0].session_no == 1){
        let pan1Result:any = _.groupBy(sessionWiseResult[i], "number")
        let pan1WiseResult= _.values(pan1Result);
        // this.pan1_data = pan1WiseResult
        await this.getPan1Data(pan1WiseResult)
      }else{
        let pan2Result:any = _.groupBy(sessionWiseResult[i], "number")
        let pan2WiseResult= _.values(pan2Result);
        // this.pan2_data = pan2WiseResult
        await this.getPan2Data(pan2WiseResult)
      }

      // console.log("opan1 ==",this.pan1_data )
      // console.log("pan2==",this.pan2_data)
    }

  }

  getTotal(number,index,type){
    let tempData:any;
    if(type == 'c'){
      tempData = this.c_data;
    }else if(type == 'oc'){
      tempData = this.oc_data;
    }else if(type == 'op'){
      tempData = this.op_data;
    }else if(type == 'pan1'){
      tempData = this.pan1_data;
    }else if(type == 'pan2'){
      tempData = this.pan2_data;
    }


    let total:any = 0;
    for(let i=0;i<tempData[index].length;i++){
      total = total+tempData[index][i].points;

      if(i == tempData[index].length - 1){

        return total;
      }
    }

  }

  getTotalPoints(type){
    let tempData:any;
    if(type == 'c'){
      if(this.c_data.length > 0){

        tempData = this.c_data;
      }else{
        this.cTotal = 0;
      }
    }else if(type == 'oc'){
      if(this.oc_data.length > 0){

        tempData = this.oc_data;
      }else{
        this.ocTotal = 0;
      }
    }else if(type == 'op'){
      if(this.op_data.length > 0){

        tempData = this.op_data;
      }else{
        this.opTotal = 0;
      }
    }else if(type == 'pan1'){
      if(this.pan1_data.length > 0){

        tempData = this.pan1_data;
      }else{
        this.pan1Total = 0;
      }
    }else if(type == 'pan2'){
      if(this.pan2_data.length > 0){

        tempData = this.pan2_data;
      }else{
        this.pan2Total = 0;
      }
      tempData = this.pan2_data;
    }


    let total:any = 0;

      if(tempData){

        for(let i=0;i<tempData.length;i++){
          // for(let j=0;j<tempData[i].length;j++){

            total = total+tempData[i].points;
            if(i == tempData.length - 1){
              if(type == 'c'){
                this.cTotal = total;
                return total;
              }else if(type == 'oc'){
                this.ocTotal = total;
                return total;
              }else if(type == 'op'){
                this.opTotal = total;
                return total;
              }else if(type == 'pan1'){
                this.pan1Total = total;
                return total;
              }else if(type == 'pan2'){
                this.pan2Total = total;
                return total;
              }

            }
          // }

        }
      }

  }

  getGradTotal(){
    return (this.cTotal + this.ocTotal + this.opTotal + this.pan1Total + this.pan2Total)
  }

  getPoints(){
    let conditions:any;
    if(this.selectedMarket == "A"){

      if(this.userStatus){
        conditions = [{"DATE(created)":this.selectedDate,user_id:this.userData.id,consider_status:1}]
      }else{
        conditions = [{"DATE(created)":this.selectedDate, consider_status:1}];
      }
    }else{
      if(this.userStatus){
        conditions = [{"DATE(created)":this.selectedDate,user_id:this.userData.id,market:this.selectedMarket,consider_status:1}]
      }else{
        conditions = [{"DATE(created)":this.selectedDate,market:this.selectedMarket,consider_status:1}];
      }
    }
    return new Promise((resolve,reject)=>{
      this.api.send('findDb',{
        fields:["P.*, T.*, T.id as id"],
        table:'points as P',
        joins:[{type:"left",table:'type_variables as T',conditions:["T.type = P.type_no"]}],
        conditions:conditions
      }).then((res:any)=>{
        console.log("points==res==",res.data)
        resolve(res)
      })
    })
  }

  onTypeChange(type){
    // console.log("selected type=",type)
    this.SortStatus = false;
    if(type==2){
      this.userStatus = true;
    }else{
      this.userStatus = false;
      this.getAllData()
    }
  }

  getAllData(){

    this.loading.dataLoading(true)
    this.getPoints().then((res:any)=>{
      // console.log("points==",res)
      if(res.data.length > 0){
        this.single_digit = [];
        this.double_digit = [];
        this.thriple_digit = [];
        this.oc_data = [];
        this.op_data = [];
        this.c_data = [];
        this.pan1_data = [];
        this.pan2_data = [];

        for(let i=0;i<res.data.length;i++){
          if(res.data[i].number.length  == 1){
            this.single_digit.push(res.data[i]);
          }else if(res.data[i].number.length  == 2){
            this.double_digit.push(res.data[i]);
          }else{
            this.thriple_digit.push(res.data[i]);
          }
        }

        // console.log("single digits==",this.single_digit)
        // console.log("double digits==",this.double_digit)
        // console.log("thriple digits==",this.thriple_digit)
        this.getSingleDigit()
        this.getTripleDigit()
        this.getDoubleDigit()

      }else{
        this.single_digit = [];
        this.double_digit = [];
        this.thriple_digit = [];
        this.oc_data = [];
        this.op_data = [];
        this.c_data = [];
        this.pan1_data = [];
        this.pan2_data = [];
        this.showDataStatus = true;
        this.loading.dataLoading(false);
      }
    })
  }

  onMarketChange(market){
    this.SortStatus = false;
    this.selectedMarket = market;
    this.getAllData()
  }

  showUsersPopup(number, points, type, gunuleCount = 0, ultaGunuleCount = 0, sangamCount = 0, adikCount = 0, berijCount = 0){
    console.log('ultaGunuleCount==',ultaGunuleCount)
    console.log('sangamCount==',sangamCount)
    this.showUsersPopupStatus = true
    this.numberDetails = []
    this.totalPoints = points
    this.selectedNumber = number
    this.getNumberDetails(number,type, gunuleCount, ultaGunuleCount, sangamCount, adikCount, berijCount).then((res:any)=>{
      console.log("users==",res.data)
      this.numberDetails = res.data
      this.numberDetails.sort((a, b) => parseFloat(b.points) - parseFloat(a.points));
    })
  }

  close(){this.showUsersPopupStatus = false}

  getNumberDetails(number,type,gunuleCount = 0, ultaGunuleCount = 0, sangamCount = 0, adikCount = 0, berijCount = 0){
    let conditions:any = [{"P.number":number,"DATE(P.created)":this.selectedDate,"P.consider_status":1}];
    let conditions1:any;
    console.log('this.selectedMarket==',this.selectedMarket)
    if(this.selectedMarket == "A"){
      if(type == "op" || type == 'pan1'){
        conditions[0]["P.session_no"] = 1
        if(gunuleCount > 0){
          conditions[0]["P.gunule IS NULL"] =''
        }

        if(ultaGunuleCount > 0){
          conditions[0]["P.ulta_gunule_point_id IS NULL"] =''
        }

        if(sangamCount > 0){
          conditions[0]["P.sangam_point_id IS NULL"] =''
        }
          // if(gunuleCount > 0 && ultaGunuleCount > 0 && sangamCount > 0){
          //
          //   conditions = [{"P.number":number,"DATE(P.created)":this.selectedDate,"P.session_no":1,"P.gunule IS NULL":'',"P.ulta_gunule_point_id IS NULL":'', "P.sangam_point_id IS NULL":'',"P.consider_status":1}];
          // }else if(gunuleCount > 0 && ultaGunuleCount == 0 && sangamCount == 0){
          //
          //   conditions = [{"P.number":number,"DATE(P.created)":this.selectedDate,"P.session_no":1,"P.gunule IS NULL":'',"P.consider_status":1}];
          // }else if(gunuleCount == 0 && ultaGunuleCount > 0 && sangamCount == 0){
          //
          //   conditions = [{"P.number":number,"DATE(P.created)":this.selectedDate,"P.session_no":1,"P.ulta_gunule_point_id IS NULL":'',"P.consider_status":1}];
          // }else if(gunuleCount == 0 && ultaGunuleCount == 0 && sangamCount > 0){
          //
          //   conditions = [{"P.number":number,"DATE(P.created)":this.selectedDate,"P.session_no":1,"P.sangam_point_id IS NULL":'',"P.consider_status":1}];
          //
          // }else{
          //   conditions = [{"P.number":number,"DATE(P.created)":this.selectedDate,"P.session_no":1,"P.consider_status":1}];
          //
          // }
      }else if(type == 'c' || type == 'pan2'){
        conditions[0]["P.session_no"] = 2
        if(gunuleCount > 0){
          conditions[0]["P.gunule IS NULL"] =''
        }

        if(ultaGunuleCount > 0){
          conditions[0]["P.ulta_gunule_point_id IS NULL"] =''
        }

        if(sangamCount > 0){
          conditions[0]["P.sangam_point_id IS NULL"] =''
        }

        if(adikCount > 0){

        }

        if(berijCount > 0){

        }
        // if(type == 'c'){
          if(gunuleCount > 0 && ultaGunuleCount > 0 && sangamCount > 0 && adikCount > 0 && berijCount > 0){
            conditions = [{"P.number":number,"DATE(P.created)":this.selectedDate,"P.session_no":2,"P.ulta_gunule IS NULL":'',"P.gunule_point_id IS NULL":'' ,"P.sagam IS NULL":'', "P.consider_status":1},{"P.number":number,"DATE(P.created)":this.selectedDate,"P.session_no":2,"P.adik IS NULL":'',"P.berij IS NULL":'', "P.consider_status":1}];
          }else if(gunuleCount > 0 && ultaGunuleCount == 0 && sangamCount == 0 && adikCount == 0 && berijCount == 0){
            conditions = [{"P.number":number,"DATE(P.created)":this.selectedDate,"P.session_no":2,"P.gunule_point_id IS NULL":'',"P.consider_status":1}];
          }else if(gunuleCount == 0 && ultaGunuleCount > 0 && sangamCount == 0  && adikCount == 0 && berijCount == 0){
            conditions = [{"P.number":number,"DATE(P.created)":this.selectedDate,"P.session_no":2,"P.ulta_gunule IS NULL":'',"P.consider_status":1}];
          }else if(gunuleCount == 0 && ultaGunuleCount == 0 && sangamCount > 0  && adikCount == 0 && berijCount == 0){
            conditions = [{"P.number":number,"DATE(P.created)":this.selectedDate,"P.session_no":2,"P.sangam IS NULL":'',"P.consider_status":1}];
          }else if(gunuleCount == 0 && ultaGunuleCount == 0 && sangamCount == 0  && adikCount > 0 && berijCount == 0){
            conditions = [{"P.number":number,"DATE(P.created)":this.selectedDate,"P.session_no":2,"P.adik IS NULL":'',"P.consider_status":1}];
          }else if(gunuleCount == 0 && ultaGunuleCount == 0 && sangamCount == 0  && adikCount == 0 && berijCount > 0){
            conditions = [{"P.number":number,"DATE(P.created)":this.selectedDate,"P.session_no":2,"P.berij IS NULL":'',"P.consider_status":1}];
          }else{
            conditions = [{"P.number":number,"DATE(P.created)":this.selectedDate,"P.session_no":2,"P.consider_status":1}];

          }
        // }else{
        //
        //   conditions = [{"P.number":number,"DATE(P.created)":this.selectedDate,"P.session_no":2}];
        // }
      }else if(type == 'oc'){
        conditions = [{"P.number":number,"DATE(P.created)":this.selectedDate,"P.consider_status":1}];
      }

    }else{
      console.log("market==",this.selectedMarket)
      if(type == "op" || type == 'pan1'){
        conditions = [{"P.number":number,"DATE(P.created)":this.selectedDate,'P.market':this.selectedMarket,"P.session_no":1,"P.gunule IS NULL":'',"P.ulta_gunule IS NULL":'',"P.sangam IS NULL":'',"P.gunule_point_id IS NULL":'',"P.ulta_gunule_point_id IS NULL":'',"P.sangam_point_id IS NULL":'',"P.consider_status":1}];
      }else if(type == 'c' || type == 'pan2'){
        conditions = [{"P.number":number,"DATE(P.created)":this.selectedDate,'P.market':this.selectedMarket,"P.session_no":2,"P.gunule IS NULL":'',"P.ulta_gunule IS NULL":'',"P.sangam IS NULL":'',"P.gunule_point_id IS NULL":'',"P.ulta_gunule_point_id IS NULL":'',"P.sangam_point_id IS NULL":'', "P.adik IS NULL":'',"P.berij IS NULL":'',"P.consider_status":1}];
      }else if(type == 'oc'){
        conditions = [{"P.number":number,"DATE(P.created)":this.selectedDate,'P.market':this.selectedMarket,"P.consider_status":1}];
      }

    }

    return new Promise((resolve,reject)=>{
      this.api.send('findDb',{
        fields:["P.number, SUM(P.points) as points, U.username, P.gs_number, P.gunule_point_id,P.gunule, P.ulta_gunule_point_id, P.ulta_gunule, P.sangam, P.sangam_point_id, P.adik, P.berij"],
        table:'points as P',
        joins:[{
          type:'left',
          table:'users as U',
          conditions:["U.id = P.user_id"]
        }],
        conditions:conditions,
        groupBy:"P.user_id"
      }).then((res:any)=>{
        if((type == 'c' || type =='pan1' || type == 'op' || type == "pan2") && (gunuleCount > 0 || ultaGunuleCount > 0 || sangamCount > 0 || adikCount > 0 || berijCount > 0)){
          if(this.selectedMarket == "A"){
            if(type == 'c'){
              conditions1 = [{"P.number":number,"DATE(P.created)":this.selectedDate,"P.session_no":2,"P.gunule_point_id IS NOT NULL":'',"P.consider_status":1},{"P.number":number,"DATE(P.created)":this.selectedDate,"P.session_no":2,"P.adik IS NOT NULL":'',"P.consider_status":1},{"P.number":number,"DATE(P.created)":this.selectedDate,"P.session_no":2,"P.berij IS NOT NULL":'',"P.consider_status":1}];
            }else if(type == 'op'){
              conditions1 = [{"P.number":number,"DATE(P.created)":this.selectedDate,"P.session_no":1,"P.ulta_gunule IS NOT NULL":'',"P.consider_status":1}];
            }else if(type == 'pan1'){
              if(gunuleCount > 0){
                conditions1 = [{"P.number":number,"DATE(P.created)":this.selectedDate,"P.session_no":1,"P.gunule IS NOT NULL":'',"P.consider_status":1}];
              }else if(sangamCount > 0){

                conditions1 = [{"P.number":number,"DATE(P.created)":this.selectedDate,"P.session_no":1,"P.sangam_point_id IS NOT NULL":'',"P.consider_status":1}];
              }
            }else if(type == 'pan2'){
              if(ultaGunuleCount > 0){

                conditions1 = [{"P.number":number,"DATE(P.created)":this.selectedDate,"P.session_no":2,"P.ulta_gunule IS NOT NULL":'',"P.consider_status":1}];
              }else if(sangamCount > 0){

                conditions1 = [{"P.number":number,"DATE(P.created)":this.selectedDate,"P.session_no":2,"P.sangam_point_id IS NOT NULL":'',"P.consider_status":1}];
              }
            }

          }else{
            if(type == 'c'){
              conditions1 = [{"P.number":number,"P.market":this.selectedMarket,"DATE(P.created)":this.selectedDate,"P.session_no":2,"P.gunule_point_id IS NOT NULL":'',"P.consider_status":1},
                {"P.number":number,"P.market":this.selectedMarket,"DATE(P.created)":this.selectedDate,"P.session_no":2,"P.adik IS NOT NULL":'',"P.consider_status":1},
                {"P.number":number,"P.market":this.selectedMarket,"DATE(P.created)":this.selectedDate,"P.session_no":2,"P.berij IS NOT NULL":'',"P.consider_status":1}
            ];
            }else if(type == 'op'){
              conditions1 = [{"P.number":number,"P.market":this.selectedMarket,"DATE(P.created)":this.selectedDate,"P.session_no":1,"P.ulta_gunule IS NOT NULL":'',"P.consider_status":1}];
            }else if(type == 'pan1'){
              conditions1 = [{"P.number":number,"P.market":this.selectedMarket,"DATE(P.created)":this.selectedDate,"P.session_no":1,"P.gunule IS NOT NULL":'',"P.consider_status":1},{"P.number":number,"P.market":this.selectedMarket,"DATE(P.created)":this.selectedDate,"P.session_no":1,"P.sangam IS NOT NULL":'',"P.consider_status":1}];
            }else if(type == 'pan2'){
              conditions1 = [{"P.number":number,"P.market":this.selectedMarket,"DATE(P.created)":this.selectedDate,"P.session_no":2,"P.ulta_gunule IS NOT NULL":'',"P.consider_status":1},{"P.number":number,"P.market":this.selectedMarket,"DATE(P.created)":this.selectedDate,"P.session_no":2,"P.sangam_point_id IS NOT NULL":'',"P.consider_status":1}];
            }
          }
          this.api.send("findDb",{
            fields:["P.number, P.points, U.username, P.gs_number, P.gunule_point_id,P.gunule, P.ulta_gunule_point_id, P.ulta_gunule, P.sangam, P.sangam_point_id, P.adik, P.berij"],
            table:'points as P',
            joins:[{
              type:'left',
              table:'users as U',
              conditions:["U.id = P.user_id"]
            }],
            conditions:conditions1
          }).then((res1:any)=>{
            if(res1.data.length > 0){
              console.log("res==",res.data)
              console.log("res1==",res1.data)
              resolve({data:[...res.data,...res1.data]})
            }else{
              resolve(res)
            }
          })
        }else{

          resolve(res)
        }

      })
    })
  }

  calc(rate,amt,type){
    if(type=='op'){
      let op_diff = (this.getTotalPoints(type) - (this.getTotalPoints(type)*((rate == 8)?20:10)/100))
      let op_diff1 = op_diff+parseFloat(amt)
      this.op_diff = op_diff1/rate

    }else if(type == 'oc'){
      let oc_diff = (this.getTotalPoints(type) - (this.getTotalPoints(type)*((rate == 80)?20:10)/100))
      let oc_diff1 = oc_diff+parseFloat(amt)
      this.oc_diff = oc_diff1/rate
    }else if(type == 'pan1'){
      let pan1_diff = (this.getTotalPoints(type) - (this.getTotalPoints(type)*((rate == 10)?20:10)/100))
      let pan1_diff1 = pan1_diff+parseFloat(amt)
      this.pan1_diff = pan1_diff1/rate
    }else if(type == 'c'){
      let c_diff = (this.getTotalPoints(type) - (this.getTotalPoints(type)*((rate == 8)?20:10)/100))
      let c_diff1 = c_diff+parseFloat(amt)
      this.c_diff = c_diff1/rate
    }else if(type == 'pan2'){
      let pan2_diff = (this.getTotalPoints(type) - (this.getTotalPoints(type)*((rate == 10)?20:10)/100))
      let pan2_diff1 = pan2_diff+parseFloat(amt)
      this.pan2_diff = pan2_diff1/rate
    }else{
      this.op_diff = 0
      this.oc_diff = 0
      this.pan1_diff = 0
      this.c_diff = 0
      this.pan2_diff = 0
    }

  }

  calcAll(){
    if(this.amt.nativeElement.value != ""){
      this.calc(this.rate.nativeElement.value,this.amt.nativeElement.value,'op')
    }
    if(this.amt1.nativeElement.value != ""){
      this.calc(this.rate1.nativeElement.value,this.amt1.nativeElement.value,'oc')
    }
    if(this.amt2.nativeElement.value != ""){
      this.calc(this.rate2.nativeElement.value,this.amt2.nativeElement.value,'pan1')
    }
    if(this.amt3.nativeElement.value != ""){
      this.calc(this.rate3.nativeElement.value,this.amt3.nativeElement.value,'c')
    }
    if(this.amt4.nativeElement.value != ""){
      this.calc(this.rate4.nativeElement.value,this.amt4.nativeElement.value,'pan2')
    }
  }

}
