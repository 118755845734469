// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  // apiHost : 'http://192.168.0.103:3333/endpoint/',
  apiHost : 'https://pride.fraganance.com/pride-endpoint/',
  socket : 'https://pride.fraganance.com/',
  downloadApiHost:'https://pride.fraganance.com/pride-endpoint/exports/',
  prideStatus:1

};
