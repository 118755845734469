import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../api.service';
import { LoadingService } from '../loading.service';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as _ from 'underscore';
@Component({
  selector: 'app-all-user-daily-report',
  templateUrl: './all-user-daily-report.component.html',
  styleUrls: ['./all-user-daily-report.component.scss']
})
export class AllUserDailyReportComponent implements OnInit {
  data:any;
  dailyData:any=[];
  Math: any;
  selectedDate: any;
  selectedDay: any;
  totalDebit: any;
  totalCredit: any;
  netCredit: any;
  weekStatus:boolean = false;
  weekData:any=[]
  @Input() set selectedData(selectedData:any){
    this.data = selectedData;
    // console.log("selected data==",this.data)
    this.selectedDate = new Date(this.data.date);
    this.selectedDay = this.selectedDate.getDay();
    if(this.weekStatus){

      this.getUserWeeklyReport();
    }else{

      this.getUserDailyReport();
    }
  }
  constructor(private api:ApiService,private loading:LoadingService) {
    this.Math = Math;
   }

  ngOnInit() {

  }

  getUserDailyReport(){
    this.loading.dataLoading(true);
    this.api.send("findDb",{
      table:"total_report as T",
      joins:[{
        type:"left",
        table:"users as U",
        conditions:["U.id = T.user_id"]
      }],
      conditions:[{"DATE(T.date)":this.data.date,market:this.data.market}]
    }).then((res:any)=>{
      // console.log("resport==",res)
      this.dailyData = res.data;

      this.totalCredit = this.dailyData.reduce(function (acc, obj) { return acc + obj.credit; }, 0)
      this.totalDebit = this.dailyData.reduce(function (acc, obj) { return acc + obj.debit; }, 0)
      this.loading.dataLoading(false);
    })
  }

  getUserWeeklyReport(){
    this.loading.dataLoading(true);
    this.weekData = [];
    this.api.send("findDb",{
      table:"total_report as T",
      fields:["SUM(T.ocb) as sum_ocb, SUM(T.op) as sum_op,SUM(T.oc) as sum_oc, SUM(T.c) as sum_c,SUM(T.pan1) as sum_pan1, SUM(T.pan2) as sum_pan2,SUM(T.comm) as sum_comm, SUM(T.debit) as sum_debit, SUM(T.credit) as sum_credit, T.*,U.username"],
      joins:[{
        type:"left",
        table:"users as U",
        conditions:["U.id = T.user_id"]
      }],
      conditions:[{"YEARWEEK(T.date, 1)":"$YEARWEEK('"+this.data.date+"', 1)$"}],
      groupBy:"T.user_id,T.rate",
      order:"user_id ASC"
    }).then((res:any)=>{
      // console.log("week resport==",res)
      if(res.data.length > 0){

        let result:any = _.groupBy(res.data, "user_id")
        let userWise= _.values(result);
        // console.log("user wise=",userWise)

        for(let i=0;i<userWise.length;i++){
          if(userWise[i].length > 1){
            for(let j=0;j<userWise[i].length;j++){

              this.weekData.push(userWise[i][j])

              if(j == userWise[i].length - 1){
                let totalSumDebit =  userWise[i][0].sum_debit + userWise[i][1].sum_debit;
                let totalSumCredit =  userWise[i][0].sum_credit + userWise[i][1].sum_credit;
                let netTotal = totalSumDebit - totalSumCredit;
                this.weekData.push({username:"SUB TOTAL",dr_net:(netTotal >= 0)?Math.abs(netTotal):0,cr_net:(netTotal < 0)?Math.abs(netTotal):0})
              }
            }
          }else{
            let netTotal = userWise[i][0].sum_debit - userWise[i][0].sum_credit;
            userWise[i][0].dr_net = (netTotal >= 0)?Math.abs(netTotal):0;
            userWise[i][0].cr_net = (netTotal < 0)?Math.abs(netTotal):0;
            // userWise[i][0].tag =(netTotal >= 0)?"DR":"CR";
            this.weekData.push(userWise[i][0])
          }

          if(i == userWise.length - 1){
            // console.log("week data==",this.weekData)
            this.loading.dataLoading(false);

          }
        }
      }else{
        this.loading.dataLoading(false);
      }
      // this.weekData = userWise;
    // SELECT SUM(T.ocb) as ocb, SUM(T.op) as op, T.*,U.username from total_report as T left JOIN users as U ON (U.id = T.user_id)  WHERE YEARWEEK(T.date,1)=YEARWEEK(CURDATE(), 1) AND T.market= 'D' GROUP BY T.user_id,T.rate ORDER BY `user_id` ASC
      // this.dailyData = res.data;
      //
      // this.totalCredit = this.dailyData.reduce(function (acc, obj) { return acc + obj.credit; }, 0)
      // this.totalDebit = this.dailyData.reduce(function (acc, obj) { return acc + obj.debit; }, 0)
      // this.loading.dataLoading(false);

    })
  }

  getTotalNetDebit(){
    let dr_net= 0;

    for(let i=0;i< this.weekData.length;i++){
      if(this.weekData[i].dr_net){
        dr_net = dr_net + this.weekData[i].dr_net;
      }

      if(i == this.weekData.length - 1){
        return dr_net;
      }
    }

  }
  getTotalNetCredit(){
    let cr_net= 0;
    for(let i=0;i< this.weekData.length;i++){
      if(this.weekData[i].cr_net){
        cr_net = cr_net + this.weekData[i].cr_net;
      }

      if(i == this.weekData.length - 1){
        return cr_net;
      }
    }

  }


  getAbsuluteData(number){
    return parseFloat((Math.round(number * 100) / 100).toFixed(2))
  }

  getPdf(){
    this.loading.dataLoading(true);
    if(!this.weekStatus){

      var footer = document.getElementById('footer');
      footer.classList.remove("table-footer");
    }

    var data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 200;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 3;
      pdf.addImage(contentDataURL, 'PNG', 3, position, imgWidth, imgHeight)
      pdf.save('MYPdf.pdf'); // Generated PDF
      if(!this.weekStatus){
        footer.classList.add("table-footer");
      }

      this.loading.dataLoading(false);
    });
  }

  onTabChange(event){
    if(event.index == 0){
      this.weekStatus = false;
      this.getUserDailyReport()
    }else{
      this.weekStatus = true;
      this.getUserWeeklyReport()
    }
  }


  getMonday(d){
    d = new Date(d);
    var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

}
