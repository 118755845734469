import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ObservablesService } from '../observables.service'
import { ApiService } from '../api.service'
import { KeyboardEventService } from '../keyboard-event.service'

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  ticketData:any=[]
  sideNavStatus:boolean = false;
  loggedUserInfo:any=[]
  sendTicketDataSubscriber:any
  setSideNavSubscriber:any
  constructor(private key: KeyboardEventService, private api:ApiService, private observables:ObservablesService,private router:Router) { }
  ngOnDestroy(){
    // console.log("destroying")
    this.sendTicketDataSubscriber.unsubscribe()
    this.setSideNavSubscriber.unsubscribe()
  }
  ngOnInit() {
    this.sendTicketDataSubscriber = this.key.sendTicketDataStatus.subscribe((ticketData:any)=>{
      //console.log("ticket Data==",ticketData)
       this.ticketData = ticketData;
    });

    this.setSideNavSubscriber = this.observables.setSideNavStatus.subscribe((res:any)=>{
      this.sideNavStatus = res.status;
    })

    if(localStorage.getItem('subAdminLoggedInStatus') =='1'){
      this.sideNavStatus = true;
    }else if(localStorage.getItem('userLoggedInStatus') =='1'){
      this.sideNavStatus = false;
    }



    this.getLoggedUserInfo().then((res:any)=>{
      this.loggedUserInfo = res
    })
  }

  getLoggedUserInfo(){
    return new Promise((resolve,reject)=>{

      this.api.send("findDb",{
        table:"users",
        conditions:[{id:localStorage.getItem('userId')}]
      }).then((userInfo:any)=>{
        // console.log("userInfo=",userInfo);
        resolve(userInfo.data[0])

      }).catch(error=>{console.log("user info error",error)})
    })
  }

  closeMenu(){

    this.observables.setMenuStatus({menuState:'out'})
  }

  showCurrentLots(){
    if(this.router.url=='/dashboard' && this.ticketData.length > 0){
      if(confirm("You have unsaved Points; are you sure you want to leave this page?")){
        this.closeMenu();
        this.observables.changeHeader({status:false});
        this.observables.showCurrentLots();
        this.router.navigate(['/lot-view']);
      }else{
        this.closeMenu();

      }
    }else{
      this.closeMenu();
      this.observables.changeHeader({status:false});
      this.observables.showCurrentLots();
      this.router.navigate(['/lot-view']);
    }

  }

  gotoHome(){
    this.closeMenu();
    this.observables.changeHeader({status:false});
    this.observables.refreshSession();
    this.router.navigate(['/dashboard']);
  }

  // showResult(){
  //   this.closeMenu();
  //   this.observables.changeHeader({status:true,data:{type:"single",resultBarStatus:true}});
  //   this.router.navigate(['/result-view']);
  // }

  showWeekResult(){
    if(this.router.url=='/dashboard' && this.ticketData.length > 0){
      if(confirm("You have unsaved Points; are you sure you want to leave this page?")){
        this.closeMenu();
        this.observables.changeHeader({status:true,data:{type:"week",resultBarStatus:true}});
        this.router.navigate(['/result-view']);
      }else{
          this.closeMenu();

        }
      }else{
        this.closeMenu();
        this.observables.changeHeader({status:true,data:{type:"week",resultBarStatus:true}});
        this.router.navigate(['/result-view']);
      }
  }

  showWeekReview(){
    if(this.router.url=='/dashboard' && this.ticketData.length > 0){
      if(confirm("You have unsaved Points; are you sure you want to leave this page?")){
        this.closeMenu();
        this.observables.changeHeader({status:true,data:{type:"single",resultBarStatus:false}});
        this.router.navigate(['/week-review']);
      }else{
          this.closeMenu();

        }
      }else{
        this.closeMenu();
        this.observables.changeHeader({status:true,data:{type:"single",resultBarStatus:false}});
        this.router.navigate(['/week-review']);
      }
  }
  typeWiseReport(){
    if(this.router.url=='/dashboard' && this.ticketData.length > 0){
      if(confirm("You have unsaved Points; are you sure you want to leave this page?")){
        this.closeMenu();
        this.observables.changeHeader({status:true,data:{type:"single",resultBarStatus:false}});
        // this.observables.showCurrentLots();
        this.router.navigate(['/type-wise-report']);
      }else{
          this.closeMenu();

        }
    }else{
      this.closeMenu();
      this.observables.changeHeader({status:true,data:{type:"single",resultBarStatus:false}});
      // this.observables.showCurrentLots();
      this.router.navigate(['/type-wise-report']);
    }
  }

  totalWeekReview(){
    if(this.router.url=='/dashboard' && this.ticketData.length > 0){
      if(confirm("You have unsaved Points; are you sure you want to leave this page?")){
        this.closeMenu();
        this.observables.changeHeader({status:true,data:{type:"single",resultBarStatus:false}});
        this.router.navigate(['/total-review']);
      }else{
          this.closeMenu();

        }
      }else{
        this.closeMenu();
        this.observables.changeHeader({status:true,data:{type:"single",resultBarStatus:false}});
        this.router.navigate(['/total-review']);
      }
  }
  transactionView(){
    if(this.router.url=='/dashboard' && this.ticketData.length > 0){
      if(confirm("You have unsaved Points; are you sure you want to leave this page?")){
        this.closeMenu();
        this.observables.changeHeader({status:true,data:{type:"single",resultBarStatus:false}});
        this.router.navigate(['/transaction-view']);
      }else{
          this.closeMenu();

        }
      }else{
        this.closeMenu();
        this.observables.changeHeader({status:true,data:{type:"single",resultBarStatus:false}});
        this.router.navigate(['/transaction-view']);
      }
  }

  adminLogout(){
    if(this.router.url=='/sub-admin-dashboard' && this.ticketData.length > 0){
        if(confirm("You have unsaved Points; are you sure you want to leave this page?")){
          this.api.send("updateDb",{
            table:"users",
            data:{
              "login_status":0
            },
            conditions:[{"id":localStorage.getItem('userId')}]
          }).then((res:any)=>{
            // console.log("Enabled User res",res)

              this.closeMenu();
              localStorage.clear();
              localStorage.setItem("logOutStatus",'1')
              this.observables.logout();
              this.router.navigate(['/home']);

          })

        }else{
          this.closeMenu();
        }
    }else{
      this.api.send("updateDb",{
        table:"users",
        data:{
          "login_status":0
        },
        conditions:[{"id":localStorage.getItem('userId')}]
      }).then((res:any)=>{
        // console.log("Enabled User res",res)


          this.closeMenu();
          localStorage.clear();
          localStorage.setItem("logOutStatus",'1')
          this.observables.logout();
          this.router.navigate(['/home']);

      })
    }
  }

  adminHome(){
    this.closeMenu();
    this.observables.refreshSession();
    this.router.navigate(['/sub-admin-dashboard']);
  }
  currentLots(){
    if(this.router.url=='/sub-admin-dashboard' && this.ticketData.length > 0){
      if(confirm("You have unsaved Points; are you sure you want to leave this page?")){
        this.closeMenu();
        this.observables.showUserCurrentLots();
        this.router.navigate(['/sub-admin-lot-view']);
      }else{
        this.closeMenu();

      }
    }else{
      this.closeMenu();
      this.observables.showUserCurrentLots();
      this.router.navigate(['/sub-admin-lot-view']);
    }
  }

  usersTotalPoints(){
    if(this.router.url=='/sub-admin-dashboard' && this.ticketData.length > 0){
      if(confirm("You have unsaved Points; are you sure you want to leave this page?")){
        this.closeMenu();
        this.router.navigate(['/user-lot-view']);
      }else{
        this.closeMenu();

      }
    }else{
      this.closeMenu();
      // this.observables.showUserCurrentLots();
      this.router.navigate(['/user-lot-view']);
    }
  }

  logout(){
    if(this.router.url=='/dashboard' && this.ticketData.length > 0){
      if(confirm("You have unsaved Points; are you sure you want to leave this page?")){
        this.closeMenu();
        // localStorage.setItem('userLoggedInStatus',"0");
        // localStorage.removeItem('userId');

        this.api.send("updateDb",{
          table:"users",
          data:{
            "login_status":0
          },
          conditions:[{"id":localStorage.getItem('userId')}]
        }).then((res:any)=>{
          // console.log("Enabled User res",res)
          if(res.data.affectedRows){
            localStorage.clear();
            localStorage.setItem("logOutStatus",'1')
            this.observables.logout();
            this.router.navigate(['/home']);
          }else{
            this.observables.showToastMessage({type:1,message:'Some problem occured while logout...!'});
            localStorage.clear();
            localStorage.setItem("logOutStatus",'1')
            this.observables.logout();
            this.router.navigate(['/home']);
          }
        })


      }else{
          this.closeMenu();

        }
      }else{
        this.closeMenu();
        // localStorage.setItem('userLoggedInStatus',"0");
        // localStorage.removeItem('userId');

        this.api.send("updateDb",{
          table:"users",
          data:{
            "login_status":0
          },
          conditions:[{"id":localStorage.getItem('userId')}]
        }).then((res:any)=>{
          // console.log("Enabled User res",res)
          if(res.data.affectedRows){
            localStorage.clear();
            localStorage.setItem("logOutStatus",'1')
            this.observables.logout();
            this.router.navigate(['/home']);
          }else{
            this.observables.showToastMessage({type:1,message:'Some problem occured while logout...!'});

          }
        })
      }
    }

    showCopyPopup(){
      this.closeMenu()
      this.observables.showCopyPopupStatus.next()
    }

}
