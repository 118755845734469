import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
// import { timer } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ObservablesService {
  getLotStatus: any = new Subject<any>();
  clearAllStatus: any = new Subject<any>();
  toastMessageStatus: any = new Subject<any>();
  menuStatus: any = new Subject<any>();
  adminMenuStatus: any = new Subject<any>();
  logoutStatus: any = new Subject<any>();
  showPageStatus: any = new Subject<any>();
  showCurrentLotsStatus: any = new Subject<any>();
  showEditLotsStatus: any = new Subject<any>();
  showLotsStatus: any = new Subject<any>();
  sendMarketDataStatus: any = new Subject<any>();
  refreshSessionStatus: any = new Subject<any>();
  changeHeaderStatus: any = new Subject<any>();
  marketChangedStatus:any = new Subject<any>();
  userInfoStatus:any = new Subject<any>();
  cancelLotEntryStatus:any = new Subject<any>();
  modalClosedStatus:any = new Subject<any>();
  copyPointsStatus:any = new Subject<any>();
  showImageStatus:any = new Subject<any>();
  importFileStatus:any = new Subject<any>();
  fixedPointStatus:any = new Subject<any>();
  savePointsStatus:any = new Subject<any>();
  moreSubOptionsStatus:any = new Subject<any>();
  showPointsStatus:any = new Subject<any>();
  submitResultStatus:any = new Subject<any>();
  calculateResultStatus:any = new Subject<any>();
  calculateSubCustomerResultStatus:any = new Subject<any>();
  calculateCustomerResultStatus:any = new Subject<any>();
  setCurrentDataStatus:any = new Subject<any>();
  getSelectedUserStatus:any = new Subject<any>();
  setUserInHeaderStatus:any = new Subject<any>();
  enableEntryBlockStatus:any = new Subject<any>();
  saveUserPointsStatus:any = new Subject<any>();
  sendUserDataStatus:any = new Subject<any>();
  setViewHeaderResultStatus:any = new Subject<any>();
  editPointsStatus:any = new Subject<any>();
  showUserCurrentLotsStatus:any = new Subject<any>();
  refreshUserStatus:any = new Subject<any>();
  getUserDataStatusStatus:any = new Subject<any>();
  setSideNavStatus:any = new Subject<any>();
  showArrowStatus:any = new Subject<any>();
  hideArrowStatus:any = new Subject<any>();
  addPointsStatus:any = new Subject<any>();
  updateProgressBarStatus:any = new Subject<any>();
  setActiveTabStatus:any = new Subject<any>();
  moreOptionsStatus:any = new Subject<any>();
  moreOptionsCloseStatus:any = new Subject<any>();
  showCopyPopupStatus:any = new Subject<any>();
  checkSessionStatus:any = new Subject<any>();
  fixedCloseStatus:any = new Subject<any>();
  hideDelete:any = new Subject<any>();
 //  counter = 10;
 // tick = 1000;
  constructor() { }

  // getCounter() {
  //   return timer(0, this.tick)
  //     .take(this.counter)
  //     .map(() => --this.counter)
  // }

  showToastMessage(data){
    this.toastMessageStatus.next(data);
  }

  getLots(market){
    // console.log("in service")
    this.getLotStatus.next({market:market});
  }

  setMenuStatus(data){
    this.menuStatus.next(data);
  }

  setAdminMenuStatus(data){
    this.adminMenuStatus.next(data);
  }

  clearAll(){
    this.clearAllStatus.next();
  }
  logout(){
    this.logoutStatus.next();
  }

  showPage(data){
    this.showPageStatus.next(data);
  }

  showCurrentLots(){
    this.showCurrentLotsStatus.next();
  }
  showUserCurrentLots(){
    this.showUserCurrentLotsStatus.next();
  }

  sendMarketData(data){
    this.sendMarketDataStatus.next(data);
  }

  refreshSession(){
    this.refreshSessionStatus.next();
  }


  changeHeader(data){
    this.changeHeaderStatus.next(data);
  }
  marketChanged(data){
    this.marketChangedStatus.next(data);
  }
  userInfo(data){
    this.userInfoStatus.next(data);
  }
  cancelLotEntry(){
    this.cancelLotEntryStatus.next();
  }
  modalClosed(){
    this.modalClosedStatus.next();
  }

  submitResult(data){
    this.submitResultStatus.next(data);
  }

  calculateResult(data){
    this.calculateResultStatus.next(data);
  }
  calculateSubCustomerResult(data){
    this.calculateSubCustomerResultStatus.next(data);
  }
  calculateCustomerResult(data){
    this.calculateCustomerResultStatus.next(data);
  }

  setCurrentData(data){
    this.setCurrentDataStatus.next(data);
  }
  getSelectedUserData(data){
    this.getSelectedUserStatus.next(data);
  }
  setUserInHeader(data){
    this.setUserInHeaderStatus.next(data);
  }
  enableEntryBlock(){
    this.enableEntryBlockStatus.next();
  }

  addPoints(data){
    this.addPointsStatus.next(data);
  }
}
