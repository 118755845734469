import { Component, OnInit,Inject,OnDestroy, Input } from '@angular/core';
import { ObservablesService } from '../observables.service';
import { ApiService } from '../api.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ValidatorFn, AbstractControl, FormArray, FormControl } from '@angular/forms'
import { DatePipe } from '@angular/common'
import { Router } from '@angular/router';
@Component({
  selector: 'app-admin-right-side-nav',
  templateUrl: './admin-right-side-nav.component.html',
  styleUrls: ['./admin-right-side-nav.component.scss']
})
export class AdminRightSideNavComponent implements OnInit {

    deleteModel: any = null;
    loggedUserInfo:any=[];
    username:string ="Admin"
    deleteWindowStatus:boolean = false
    sundayDate:any
    hideDeleteSubscriber:any
    constructor(private datePipe:DatePipe, private router:Router,private api:ApiService, private observables:ObservablesService,private fb:FormBuilder) { }

    ngOnDestroy(){
      this.hideDeleteSubscriber.unsubscribe()
    }
    ngOnInit() {
      this.hideDeleteSubscriber = this.observables.hideDelete.subscribe((res:any)=>{
        this.closeDeleteWindow()
      })
      let dt:any =  this.getLastSunday(new Date())
      this.sundayDate =  dt.setDate( dt.getDate() - 1);
      this.deleteModel = this.sundayDate
      // this.config.maxDate = {year: this.sundayDate.getFullYear(), month: this.sundayDate.getMonth()+1, day: this.sundayDate.getDate()};
      // this.config.minDate = null
    }
    getLastSunday(d) {
       var t = new Date(d);
       t.setDate(t.getDate() - (t.getDay() + 7));
       return t;
     }

    closeMenu(){
      // console.log('called me')
      this.observables.setAdminMenuStatus({menuState:'out'})
    }

    logOut(){
      this.closeMenu();

    }

    close(){
      this.closeMenu();

    }

    deleteData(){
      this.deleteWindowStatus = true
      // this.closeMenu();
    }

    closeDeleteWindow(){
      this.deleteWindowStatus = false
      this.closeMenu();
    }

    viewDateSelected(event){

      console.log("event==",event)
      var selectedDate = new Date(event.year+"-"+event.month+"-"+event.day);

    }

    delete(){
      if(confirm(`Are you sure to Delete Data from date -  ${this.datePipe.transform(this.deleteModel, 'dd-MM-yyyy')} ?`)){

        if(new Date(this.deleteModel).getTime() < this.getLastSunday(new Date()).getTime()){
          // console.log('yes')
          this.api.send("queryDb",{
            sql:`DELETE FROM lots WHERE DATE(created) <= '${this.datePipe.transform(this.deleteModel, 'yyyy-MM-dd')}'`
          }).then((res:any)=>{

              this.api.send("queryDb",{
                sql:`DELETE FROM points WHERE DATE(created) <= '${this.datePipe.transform(this.deleteModel, 'yyyy-MM-dd')}'`
              }).then((res1:any)=>{
                this.api.send("queryDb",{
                  sql:`DELETE FROM calculations WHERE DATE(created) <= '${this.datePipe.transform(this.deleteModel, 'yyyy-MM-dd')}'`
                }).then((res1:any)=>{
                  this.observables.showToastMessage({status:0, message:"Data Removed..."})
                })
              })


          })
        }else{
          this.observables.showToastMessage({status:1, message:"Sorry Data can't remove, Selected Date should have 14 days gap..."})
        }
      }
    }




  }
