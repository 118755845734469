import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../api.service'
import { LoadingService } from '../loading.service'
import { ObservablesService } from '../observables.service'


@Component({
  selector: 'app-type-wise-report',
  templateUrl: './type-wise-report.component.html',
  styleUrls: ['./type-wise-report.component.scss']
})
export class TypeWiseReportComponent implements OnInit {
  days:any=[{
    name:"Mon",
    full:"Monday",
    value:1
  },{
    name:"Tue",
    full:"Tuesday",
    value:2
  },{
    name:"Wed",
    full:"Wednesday",
    value:3
  },{
    name:"Thu",
    full:"Thursday",
    value:4
  },{
    name:"Fri",
    full:"Friday",
    value:5
  },{
    name:"Sat",
    full:"Saturday",
    value:6
  }];
  showLotsStatusSubscription:any;
  market:any;
  userLots:any=[]
  userInfo:any=[]
  typeLots:any=[]
  types:any=[8,9]
  type1:any=[]
  type2:any=[]
  pointsArr:any=[]
  totalPoints:any=[]
  selectedType:any;
  selectedDay:any = 1;
  selectedMarket:any ="D"
  user_id:any;
  userStatus:boolean = false;
  refreshStatus:boolean = true;
  gunuleStatus:boolean = false;
  ultaGunuleStatus:boolean = false;
  sangamStatus:boolean = false;
  constructor(private loading:LoadingService, private api:ApiService, private observables:ObservablesService) { }
    // ngOnDestroy(){
    //   this.showLotsStatusSubscription.unsubscribe();
    // }

  ngOnInit() {

    // if(localStorage.getItem('adminLoggedInStatus') == "1"){
    //
    // }else
    if(localStorage.getItem('userLoggedInStatus') == "1"){
      this.userStatus = false;
      // this.refreshStatus = false;
      this.user_id=localStorage.getItem("userId")
     this.getLoggedUserInfo(this.user_id);
   }else if(localStorage.getItem('adminLoggedInStatus') == "1"){
     this.userStatus=true;

   }

    this.observables.sendUserDataStatus.subscribe((userData:any)=>{

      this.user_id = userData.data.id;
      this.types=[];
      this.types = userData.data.type.split(',');


      if(userData.typeViewStatus){

        this.refreshTable(this.types[0]);
      }
    })
    // this.showLotsStatusSubscription = this.observables.showLotsStatus.subscribe((res:any)=>{
    //   // console.log("in lot view =",res)
    //   if(res.length > 0){
    //     this.market = res[0].lot.day_night;
    //     // this.getSessionResult().then((result:any)=>{
    //
    //       this.userLots=[]
    //       this.userLots=res;
    //
    //         this.onLinkClick({index:0})
    //
    //
    //       this.filterAllData(this.userLots);
    //   //     if(result.data.length > 0){
    //   //
    //   //       this.result1 = result.data[0].p1;
    //   //       this.result2 = result.data[0].p2;
    //   //       this.middle_result = result.data[0].oc;
    //   //       // console.log("middle_result==",this.middle_result)
    //   //     }
    //   // //  });
    //   }else{
    //     this.userLots=[]
    //   }
    // })
  }

  async getCurrentMarketLots(type){
      this.loading.dataLoading(true);
      await this.api.send("findDb",{
        table:"lots as L",
        fields:["L.*, L.id as lot_id"],
        conditions:[{"L.type_no":type,"YEARWEEK(L.created, 1)":"$YEARWEEK(CURDATE(), 1)$",'L.user_id':this.user_id}],
        groupBy:"L.id",
        order:"L.lot_no"
      }).then((lotResponse:any)=>{
        // console.log("lots=",lotResponse)
        // console.log("user id=",this.user_id)
        this.userLots = [];
        this.pointsArr=[];
        this.totalPoints = [];
        if(lotResponse.data.length > 0){
          this.getPoints(lotResponse.data)
        }else{
          this.userLots = [];
          this.pointsArr=[];
          this.totalPoints = [];
          this.observables.showToastMessage({type:1,message:"No Record Found Available..!"});
          // this.message = "No Record Found Available..!";
          this.loading.dataLoading(false);
        }

      }).catch(error=>{console.log("error while fetching current lots=",error);})

  }

  getGunulePoint(points){
    return (points.gunule_point_id !== null || points.sangam_point_id !== null)?(parseFloat(points.points)/parseFloat(points.triple_digit)):((points.ulta_gunule_point_id !== null)?(parseFloat(points.points)/parseFloat(points.single_digit)):points.points);
  }

  async getPoints(lotResponse){
      this.pointsArr=[];
      for(let i=0; i<lotResponse.length;i++){
        await this.api.send("findDb",{
          table:"points",
          joins:[{type:"left",table:'type_variables as T',conditions:["T.type = points.type_no"]}],
          conditions:[{"lot_id":lotResponse[i].lot_id,consider_status:1}]
        }).then((pointResult:any)=>{
          console.log('lot result=',lotResponse[i]);
          lotResponse[i].points = pointResult.data;

          // this.pointsArr.push({lot:lotResponse[i],points:pointResult.data});
          this.pointsArr.push({day:new Date(lotResponse[i].created.split("T")[0]).getDay(),date:lotResponse[i].created,market:lotResponse[i].day_night,lot:lotResponse[i],gunule_status:lotResponse[i].gunule_status,ulta_gunule_status:lotResponse[i].ulta_gunule_status,sangam_status:lotResponse[i].sangam_status});
          if(i == lotResponse.length-1){
            this.filterTableData(this.selectedType,this.selectedDay,this.selectedMarket);
            this.loading.dataLoading(false);
          }
        }).catch(pointsErr=>{console.log("Error while getting points=",pointsErr); this.loading.dataLoading(false);});
      }
  }

  async filterTableData(type,day,market){
    this.totalPoints = [];
    this.gunuleStatus = false
    this.ultaGunuleStatus = false
    this.sangamStatus = false
    await this.pointsArr.filter((item:any)=>{

      if(day==item.day && market == item.market){
        item.lot.points.filter((points:any)=>{
          if(item.gunule_status){this.gunuleStatus = true}
          if(item.ulta_gunule_status){this.ultaGunuleStatus = true}
          if(item.sangam_status){this.sangamStatus = true}

          points.added_by = item.lot.added_by;
            this.totalPoints.push(points)
        })
      }
    })
  }

  getLoggedUserInfo(user_id){
    this.userInfo= []
    this.types= []
    this.api.send("findDb",{
      table:"users",
      conditions:[{id:user_id}]
    }).then((userInfo:any)=>{
      // console.log("userInfo in type wise report=",userInfo);
      this.userInfo = userInfo.data[0];
      this.types = this.userInfo.type.split(',');
    }).catch(error=>{console.log("user info error",error)})
  }

  onTypeChange(event){
    // console.log("selected type=",event)
    if(event.index == 0){
      this.selectedType = this.types[0];
    }else{
      this.selectedType = this.types[1];
    }

    // if(!this.refreshStatus){

      this.refreshTable(this.selectedType);
    // }


  }
  onDayChange(event){
    // console.log("selected day=",event)
    this.days.filter((item:any)=>{

      if((item.value - 1) == event.index){
        this.selectedDay = item.value;
        this.filterTableData(this.selectedType,this.selectedDay,this.selectedMarket);
      }
    })
  }

  onMarketChange(event){
    // console.log("selected market=",event)
    if(event.index == 0){

      this.selectedMarket = "D";
    }else{
      this.selectedMarket = "N";
    }

    this.filterTableData(this.selectedType,this.selectedDay,this.selectedMarket);
  }

  async refreshTable(type){
    await this.getCurrentMarketLots(type)
  }

  // onLinkClick(event){
  //   // console.log("selected tab=",event)
  //   if(event.index == 0){
  //     this.filterData(this.types[0]);
  //   }else if(event.index == 1){
  //     this.filterData(this.types[1]);
  //   }
  // }
  // filterData(type){
  //   // this.typeLots = []
  //   // this.userLots.filter((item:any)=>{
  //   //   if(item.lot.type_no == type){
  //   //
  //   //     this.typeLots.push(item);
  //   //   }
  //   // })
  //   this.totalPoints = [];
  //   this.userLots.filter((item:any)=>{
  //     item.points.filter((points:any)=>{
  //       if(points.type_no == type){
  //         this.totalPoints.push(points)
  //       }
  //     })
  //
  //
  //   })
  //
  // }
  //
  // async filterAllData(lotData){
  //   this.type1 = [];
  //   this.type2 = [];
  //   await lotData.filter((item:any)=>{
  //     if(item.lot.type_no == this.types[0] ){
  //
  //       this.type1.push(item);
  //     }else{
  //       this.type2.push(item);
  //     }
  //   })
  // }


  // getPointsSum(lotData){
  //   let total_points:any=0;
  //   if(!lotData){
  //     return total_points;
  //   }else{
  //     lotData.filter((item:any)=>{
  //       total_points= total_points + item.points;
  //     })
  //     return total_points;
  //   }
  // }
  //
  // getLotPointsSum(lotData){
  //   let total_points:any=0;
  //   if(!lotData){
  //     return total_points;
  //   }else{
  //     lotData.filter((item:any)=>{
  //       item.points.filter((item1:any)=>{
  //
  //         total_points= total_points + item1.points;
  //       })
  //     })
  //     return total_points;
  //   }
  // }
  // getLotPointsEntries(lotData){
  //   let total_entries:any=0;
  //   //
  //   // let i = 0;
  //   // lotData.filter((item:any)=>{
  //   //   i++;
  //   //   total_entries = total_entries + item.points.length;
  //   //   console.log(i+"value="+lotData.length+" length=",total_entries)
  //   // })
  //   // if(i == lotData.length){
  //   //
  //   //   return total_entries;
  //   // }
  //
  //   for(let i=0;i<lotData.length;i++){
  //     total_entries = total_entries + lotData[i].points.length;
  //     if(i == lotData.length-1){
  //
  //       return total_entries;
  //     }
  //   }
  //
  // }


  getTotalPointsSum(){
    // console.log("total points=",this.totalPoints)
    let totalPointsSum = 0;
    this.totalPoints.filter((item:any)=>{
      // totalPointsSum = totalPointsSum + ((item.gunule_point_id !== null || item.sangam_point_id !== null)?(parseFloat(item.points)/parseFloat(item.triple_digit)):((item.ulta_gunule_point_id !== null)?(parseFloat(item.points)/parseFloat(item.single_digit)):item.points));
      totalPointsSum = totalPointsSum + item.points
    })

    return totalPointsSum;
  }

}
