import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from '../api.service';
import { ObservablesService } from '../observables.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm:FormGroup
  registerForm:FormGroup
  registerStatus:boolean=false;
  returnUrl:any;
  mobileNumberPattern:any = /^(?=\d{10}$)/;
    constructor(private route:ActivatedRoute,private fb:FormBuilder,private router:Router,private api:ApiService,private observables:ObservablesService) {
      this.loginForm = this.fb.group({
        username:[null,Validators.compose([Validators.required])],
        password:[null,Validators.compose([Validators.required])],
      });
      this.registerForm = this.fb.group({
        username:[null,Validators.compose([Validators.required])],
        password:[null,Validators.compose([Validators.required])],
        mobile_no:[null,Validators.compose([Validators.required])],
        city:[null,Validators.compose([Validators.required])],
      });
    }

  ngOnInit() {
    localStorage.setItem("logOutStatus",'1')
    localStorage.removeItem("adminLoggedInStatus")
    localStorage.removeItem("subAdminLoggedInStatus")
    localStorage.removeItem("userLoggedInStatus")
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    // console.log("url ==",this.returnUrl)
  }

  login(value){
    // console.log(value);
    this.api.send('findDb',{
      table:"users",
      conditions:[{username:value.username,password:value.password,active_status:"0"}]
    }).then((response:any)=>{
      // console.log("resonse",response)
      if(response.data.length > 0 && response.status){

        if(response.data[0].role == 'admin'){
          localStorage.setItem("userId",response.data[0].id);
          this.observables.showToastMessage({type:0,message:'Logging in...',adminLoginStatus:true});
          localStorage.setItem("adminLoggedInStatus","1");
          localStorage.setItem("userLoggedInStatus","0");
          localStorage.setItem("subAdminLoggedInStatus","0");
          localStorage.removeItem("logOutStatus")
          this.router.navigate(['/admin-dashboard']);
        }else if(response.data[0].role == 'subadmin'){
          if(response.data[0].login_status){
            this.observables.showToastMessage({type:1,message:'Already LoggedIn...!'});
          }else{
            this.api.send("updateDb",{
              table:"users",
              data:{
                "login_status":1
              },
              conditions:[{id:response.data[0].id}]
            }).then((res:any)=>{
                if(res.data.affectedRows){

                  localStorage.setItem("userId",response.data[0].id);
                  localStorage.setItem("subAdminId",response.data[0].sub_admin_id);
                  this.observables.showToastMessage({type:0,message:'Logging in...',subAdminLoginStatus:true});
                  localStorage.setItem("subAdminLoggedInStatus","1");
                  localStorage.setItem("adminLoggedInStatus","0");
                  localStorage.setItem("userLoggedInStatus","0");
                  localStorage.removeItem("logOutStatus")
                  this.observables.setSideNavStatus.next({status:true})
                  this.router.navigate(['/sub-admin-dashboard']);
                }
            })
          }
        }else{

          if(response.data[0].login_status){
            this.observables.showToastMessage({type:1,message:'Already LoggedIn...!'});
          }else{

            this.observables.showToastMessage({type:0,message:'Logging in...',userLoginStatus:true});
            this.api.send("updateDb",{
              table:"users",
              data:{
                "login_status":1
              },
              conditions:[{id:response.data[0].id}]
            }).then((res:any)=>{
              // console.log("update res=",res)
              if(res.data.affectedRows){
                localStorage.setItem("userId",response.data[0].id);
                localStorage.setItem("userLoggedInStatus","1");
                localStorage.setItem("adminLoggedInStatus","0");
                localStorage.setItem("subAdminLoggedInStatus","0");
                localStorage.removeItem("logOutStatus")
                this.observables.setSideNavStatus.next({status:false})
                this.router.navigate(['/dashboard']);
              }
            })
          }
          // this.router.navigate([this.returnUrl]);
        }
      }else{
        this.observables.showToastMessage({type:1,message:'Invalid Username/Password'});

        // console.log("login failed")
      }
    }).catch(err=>{
      this.observables.showToastMessage({type:1,message:'Invalid Username/Password'});
      console.log("\n login failed: ",err)
    })
  }

  // register(value){
  //   // console.log(value);
  //
  //   this.api.send("insertDb",{
  //     "table":"users",
  //     "data":{
  //       "username":value.username,
  //       "password":value.password,
  //       "role":"user",
  //       "mobile_no":value.mobile_no,
  //       "city":value.city
  //     }
  //   }).then((response:any)=>{
  //     // console.log("resonse",response)
  //     if( response.data["affectedRows"]){
  //       // console.log("\n\nInsert successfull");
  //       this.observables.showToastMessage({type:0,message:'Registration successfull'});
  //       this.registerForm.reset()
  //       this.registerStatus=false;
  //     }else{
  //       this.observables.showToastMessage({type:1,message:'Registration failed..!'});
  //     }
  //   }).catch(err=>console.log("\n reg failed: ",err))
  //
  // }
  gotoRegister(){
    this.registerStatus=true;
  }
  gotoLogin(){
    this.registerStatus=false;
  }

}
