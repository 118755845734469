import { Injectable } from '@angular/core';
import { RequestOptions,Http, ResponseContentType } from '@angular/http';
import { environment } from '../environments/environment'
import { getFileNameFromResponseContentDisposition, saveFile } from './file-download-helper';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  host:string= environment.downloadApiHost;
  constructor( private http: Http) { }

   downloadFile(data) {
     return new Promise((resolve,reject)=>{
       // let uploaded_date:any=data.uploaded_date.replace(/(^|-)0+/g, "$1");
       // console.log("downloading file==",data.file)
       const url = this.host+data.file;
       // console.log("url====",encodeURI(url))
       const options = new RequestOptions({responseType: ResponseContentType.Blob });

       // Process the file downloaded
       this.http.get(encodeURI(url), options).subscribe(res => {
         const fileName = getFileNameFromResponseContentDisposition(res);
         saveFile(res.blob(), fileName).then((result:any)=>{
           resolve(result);

         }).catch(err=>{
           reject(err)
         })
       });
     })
   }

   }
